import Navbar from "../Navbar";
import Sidemenu from "../Sidemenu";
import "../Fiduciaries/ManageFiduciaries.css";
import Modal from "react-bootstrap/Modal";
import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Space,
  Table,
  Input,
  Button,
  Col,
  Radio,
  Select,
  Checkbox,
} from "antd";
import Services from "../../network/Services";
import axios from "axios";
// import AddBeneficiaries from '../Fiduciaries/AddBeneficiaries';
import { Link } from "react-router-dom";
import AddFidBeneficiaries from "../Fiduciaries/AddFidBeneficiaries";
import commonLib from "../../control/commonLib";
import RolePopup from "../AssignOptions/RolePopup";
import DeleteAgent from "../AssignOptions/DeleteAgent";
import { UserContext } from "../../App";
import konsole from "../../network/konsole";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import "./setguidance.css";
import { Steps } from "antd";
import Endoflife from "./Endoflife";
import Death from "./Death";
import Illness from "./Illness";
import Newsetguidance from "./New SetGuidance/Newsetguidance";
import Mentalhealth from "./MentalHealth";

const { Step } = Steps;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ["Emai", "Text"];
const { TextArea } = Input;
const Setupguaidance = () => {
  const [showFrom, setShowForm] = useState(1);
  const [changeBorder, setChangeBorder] = useState(1);

  const [yesTrueStateOne, setYesTrueStateOne] = useState(1);
  const [yesTrueStateTwo, setYesTrueStateTwo] = useState();
  const [yesTrueStateThree, setYesTrueStateThree] = useState(1);
  const [yesTrueStateFour, setYesTrueStateFour] = useState(1);

  const [radioValue, setRadioValue] = useState(1);
  const [radioValueone, setRadioValueOne] = useState(11);
  const [radioValueTwo, setRadioValueTwo] = useState(33);
  const [openpart, setOpenpart] = useState("");
  const [modalopen, setModalopen] = useState(true);
  const memberdetails = commonLib.getObjFromStorage("userPrimaryInDetail");
  const [radiovalue, setradioValue] = useState(1);

  const onChangeradio = (e) => {
    konsole.log("radio checked", e.target.value);
    setradioValue(e.target.value);
  };

  useEffect(() => {
    if (openpart != "") {
      setModalopen(false);
    }
    konsole.log(openpart, "openpart");
  }, [openpart]);

  const onChange = (e) => {
    konsole.log("radio checked", e.target.value);
    setRadioValue(e.target.value);
  };
  const onChangeone = (e) => {
    konsole.log("radio checked", e.target.value);
    setRadioValueOne(e.target.value);
  };
  const onChangeTwo = (e) => {
    konsole.log("radio checked", e.target.value);
    setRadioValueTwo(e.target.value);
  };

  const shwoComponents = (e) => {
    setShowForm(e);
  };
  const shwoEndLifeComponent = (e) => {
    setChangeBorder(e);
  };
  const yesNoQuestionFun = (event) => {
    setYesTrueStateOne(event);
    setYesTrueStateTwo(event);
    setYesTrueStateThree(event);
    setYesTrueStateFour(event);
  };

  return (
    <div>
      <Modal
        className="modalsetguidance"
        show={modalopen}
        size="lg"
        centered={true}
        onHide={() => {
          setModalopen(false);
        }}
      >
        <Modal.Header className="modal-header-color">
          <div className="d-flex w-100 justify-content-between  p-0">
            <span className="center-item">Select an option below</span>
            <button
              className="btnclose"
              onClick={() => {
                setModalopen(false);
                window.location.replace("/Dashboard")
              }}
            >
              X
            </button>
          </div>
        </Modal.Header>
        <Modal.Body style={{ margin: "0px auto", width: "80%" }}>
          <div className="d-flex justify-content-center align-items-center">
          <div
                className="modal-div"
                onClick={() => {
                  setOpenpart("Health");
                }}
              >
                <img src="/images/Health.svg" className="modal-img" />
                <h4 className="mt-2">Health</h4>
              </div>
              <div
                className="modal-div"
                onClick={() => {
                  setOpenpart("Finance");
                }}
              >
                <img src="/images/Finance.svg" className="modal-img" />
                <h4 className="mt-2">Finance</h4>
              </div>
          </div>
        </Modal.Body>
      </Modal>
      {openpart == "Health" ? (
        <div className="container-fluid pt-2">
          <h6 className="">
            <Link to="/Dashboard" className="text-primery">
              Dashboard
            </Link>
            <span>{">"}</span>Set Guidance
            <span>{">"}</span>Health
          </h6>

          <div className="row mt-3">
            <div className="col-sm-6 d-flex">
              <button
                className={`${
                  (changeBorder === 1) ? "borderOnClick" : ""
                } schedule  bg-white fw-bold`}
                onClick={() => shwoEndLifeComponent(1)}
              >
                Illness
              </button>
             <button
                className={`${
                  (changeBorder === 2) ? "borderOnClick" : ""
                } schedule ms-3  bg-white fw-bold`}
                onClick={() => shwoEndLifeComponent(2)}
              >
                Mental Health
              </button>
              <button
                className={`${
                  (changeBorder === 3) ? "borderOnClick" : ""
                } schedule ms-3  bg-white fw-bold`}
                onClick={() => shwoEndLifeComponent(3)}
              >
                End Of Life
              </button>
              <button
                className={`${
                  (changeBorder === 4) ? "borderOnClick" : ""
                } schedule ms-3  bg-white fw-bold`}
                onClick={() => shwoEndLifeComponent(4)}
              >
                Death
              </button>
            </div>
            <div className="col-sm-6">
           
            </div>
            {memberdetails?.spouseName && (
                        <div className="mt-2">
                          <Radio.Group
                            onChange={onChangeradio}
                            value={radiovalue}
                          >
                            <Radio value={1}>{memberdetails.memberName}</Radio>
                            <Radio value={2}>{memberdetails.spouseName}</Radio>
                          </Radio.Group>
                        </div>
                      )}
          </div>
        </div>
      ) : openpart == "Finance" ? (
        

        <Newsetguidance />
        
      
      ) : (
        ""
      )}
      {(changeBorder == 4 && openpart == "Health") && <Death memberid={ (radiovalue == 2) ? memberdetails.spouseUserId : memberdetails.memberUserId} />}
      {(changeBorder == 3 && openpart == "Health") && <Endoflife memberid={ (radiovalue == 2) ? memberdetails.spouseUserId : memberdetails.memberUserId} />}
      {(changeBorder == 2 && openpart == "Health") && <Mentalhealth memberid={ (radiovalue == 2) ? memberdetails.spouseUserId : memberdetails.memberUserId}  />}
      {(changeBorder == 1 && openpart == "Health") && <Illness memberid={ (radiovalue == 2) ? memberdetails.spouseUserId : memberdetails.memberUserId}  />}
    </div>
  );
};

export default Setupguaidance;
