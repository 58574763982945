import React, { useEffect, useState, useContext } from "react";
// import "../common/"
// import "./MemberContact.css"
import {
  Typography,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Radio,
} from "antd";
import Services from "../../../network/Services";
import commonLib from "../../../control/commonLib";
import Toaster from "../../Toaster";
import konsole from "../../../network/konsole";
import { UserContext } from "../../../App";
import InputMask from "../../PhoneNumberInputMask";
const { Text } = Typography;

const { Option } = Select;

export default function AddEditContact(props) {
  const [email, setEmail] = useState("");

  const [Code, setCode] = useState([]);
  const [reqData, setReqData] = useState();
  const [mobileOther, setMobileOther] = useState(null);
  const [emailOther, setEmailOther] = useState(null);
  const [commType, setCommType] = useState([]);
  const [commTypevalue, setCommTypevalue] = useState("1");
  const [contactwithoutprimary, setContactwithoutprimary] = useState([]);
  const [contactwithoutprimarymobile, setContactwithoutprimarymobile] = useState([]);
  const userId = props.userId;
  const [form] = Form.useForm();
  
  const useWatch = (name) => {
    return Form.useWatch(name, form);
  };
  const emailContactTypeId = useWatch("emailContactTypeId");
  const mobileContactTypeId = useWatch("mobileContactTypeId");
  const { disable, modalOnDisable, getSavebtn, setGetSaveBtn } =
    useContext(UserContext);
  const [phonenum, setphonenum] = useState(0);
  konsole.log(props, "propsprops");
  const handleChange = (value) => {
    konsole.log(`Selected: ${value}`);
  };

  // function GetContactType(label) {
  //   if (label == "Primary") {
  //     return 1;
  //   } else if (label == "Secondary") {
  //     return 2;
  //   } else if (label == "Work") {
  //     return 3;
  //   } else if (label == "Home") {
  //     return 4;
  //   } else if (label == "Fax") {
  //     return 5;
  //   }
  //   else{
  //     return 999999;
  //   }

  // }
  useEffect(() => {
    if (props.actionType == "PUT") {
      setCommTypevalue(
        props?.actionData[0]?.mobiles[0]?.commTypeId != null
          ? props?.actionData[0]?.mobiles[0]?.commTypeId
          : 1
      );
    }
  }, []);
  const onChangeCommtype = (e) => {
    konsole.log("radiochecked", e.target.value);
    
    form.setFieldsValue({
      mobileNo:""
    })
    onChangePhone("")
    setCommTypevalue(e.target.value);
    konsole.log( form.getFieldsValue().mobileNo,"mobileno")
  //  alert("tyuj")

  };

  const onChangePhone = (value) => {
    console.log("value", value);
    setphonenum(value);
    form.setFieldsValue({
      mobileNo: value,
    });
  };

  useEffect(() => {
    if (getSavebtn == true) {
      runContactApi();
    }
    // console.log("props.contactType", props.contactType);
  }, [getSavebtn]);

  useEffect(() => {
    if (props.actionType == "PUT") {
      form.setFieldsValue({
        mobileNo:
          props.actionData &&
          props.actionData.length > 0 &&
          props.actionData[0].mobiles.length > 0
            ? props.actionData[0].mobiles[0].mobileNo.slice(-10)
            : "",
        contactTypeId:
          props.actionData && props.actionData.length > 0
            ? props.actionData[0].contactTypeId
            : "",
        emailId:
          props.actionData &&
          props.actionData.length > 0 &&
          props.actionData[0].emails.length > 0
            ? props.actionData[0].emails[0].emailId
            : "",
        emailContactTypeId:
          props.actionData.length > 0 && props.actionData[0].emails.length > 0
            ? props.actionData[0].emails[0].contactTypeId
            : props.actionData[0].contactTypeId,
        mobileContactTypeId:
          (props.actionData.length > 0 && props.actionData[0].mobiles.length) >
          0
            ? props.actionData[0].mobiles[0].contactTypeId
            : props.actionData[0].contactTypeId,
        mobileOther:
          props.actionData.length > 0 &&
          props.actionData[0].mobiles.length > 0 &&
          props.actionData[0].mobiles[0].mobileOther !== null
            ? props.actionData[0].mobiles[0].mobileOther?.othersName
            : "",
        emailOther:
          props.actionData.length > 0 &&
          props.actionData[0].emails.length > 0 &&
          props.actionData[0].emails[0].mobileOther !== null
            ? props.actionData[0].emails[0].emailOther?.othersName
            : "",
        countryCode:
          props.actionData &&
          props.actionData.length > 0 &&
          props.actionData[0].mobiles.length > 0
            ? props.actionData[0].mobiles[0].mobileNo.slice(1, 2) == "1"
              ? props.actionData[0].mobiles[0].mobileNo.slice(0, 2)
              : props.actionData[0].mobiles[0].mobileNo.slice(0, 3)
            : "",
      });
    }

    const mobileOtherValue =
      props.actionData.length > 0 &&
      props.actionData[0].mobiles.length > 0 &&
      props.actionData[0].mobiles[0].mobileOther !== null
        ? props.actionData[0].mobiles[0]?.mobileOther
        : null;

    const emailOtherValue =
      props.actionData.length > 0 &&
      props.actionData[0].emails.length > 0 &&
      props.actionData[0].emails[0].mobileOther !== null
        ? props.actionData[0].emails[0]?.emailOther
        : null;

    setMobileOther(mobileOtherValue);
    setEmailOther(emailOtherValue);
  }, []);

  const handleFormSubmit = () => {
    form.submit();
    if (form.getFieldValue().countryCode == undefined) {
      form.setFieldsValue({
        countryCode: Code[0].value,
      });
    }
    // props?.actionData[0]?.contactTypeId
    // if (
    //   props.memberContactdata.length != 0 &&
    //   props.actionType !== "PUT" &&
    //   props.memberContactdata[0][0].contactTypeId == 1 &&
    //   form.getFieldsValue()?.mobileContactTypeId == 1
    // ) {
    //   Toaster.warning("Primary Contact cannot be added twice");
    // }
    //  else {
     

      form.validateFields().then((err, values) => {
        if (err) {
          return modalOnDisable();
        } else {
        }
        props.onOk();
      });
      const contactData = form.getFieldsValue();
      const req = {
        userId: userId,
        activityTypeId: 4,
        contact: {
          mobiles: [],
          emails: [],
        },
      };
      const mobileContact = {
        contactTypeId: contactData?.mobileContactTypeId,
        mobileNo: contactData.mobileNo
          ? contactData.countryCode + contactData.mobileNo
          : null,
        commTypeId: commTypevalue,
        createdBy: commonLib.getObjFromStorage("stateObj")?.userId
          ? commonLib.getObjFromStorage("stateObj")?.userId
          : "",
        mobileOther:
          contactData?.mobileOther !== undefined &&
          contactData.mobileOther !== ""
            ? {
                othersName: contactData?.mobileOther,
                othersCategoryId: 6,
                isActive:
                  contactData.mobileContactTypeId == "999999" &&
                  contactData?.mobileOther !== undefined &&
                  contactData.mobileOther !== ""
                    ? true
                    : false,
                othersId: 0,
                createdBy: commonLib.getObjFromStorage("stateObj")?.userId,
              }
            : null,
      };
      const emailContact = {
        contactTypeId: contactData?.emailContactTypeId,
        emailId: contactData.emailId ? contactData.emailId : null,
        createdBy: commonLib.getObjFromStorage("stateObj")?.userId
          ? commonLib.getObjFromStorage("stateObj")?.userId
          : "",
        emailOther:
          contactData?.emailOther !== undefined && contactData.emailOther !== ""
            ? {
                othersName: contactData?.emailOther,
                othersCategoryId: 6,
                isActive:
                  contactData.emailContactTypeId == "999999" &&
                  contactData?.emailOther !== undefined &&
                  contactData.emailOther !== ""
                    ? true
                    : false,
                othersId: 0,
                createdBy: commonLib.getObjFromStorage("stateObj")?.userId,
              }
            : null,
      };

      if (props.actionType == "PUT") {
        mobileContact["updatedBy"] = commonLib.getObjFromStorage("stateObj")
          ?.userId
          ? commonLib.getObjFromStorage("stateObj")?.userId
          : "";
        mobileContact["mobileOther"] =
          mobileOther !== null
            ? {
                ...mobileOther,
                othersName:
                  contactData.mobileContactTypeId == "999999" &&
                  contactData?.mobileOther !== undefined &&
                  contactData.mobileOther !== ""
                    ? contactData?.mobileOther
                    : mobileOther?.othersName,
                commTypeId: commTypevalue,
                updatedBy: commonLib.getObjFromStorage("stateObj")?.userId,
                isActive:
                  contactData.mobileContactTypeId == "999999" &&
                  contactData?.mobileOther !== undefined &&
                  contactData.mobileOther !== ""
                    ? true
                    : false,
              }
            : null;
        mobileContact["contactId"] =
          props.actionData &&
          props.actionData.length > 0 &&
          props.actionData[0].mobiles.length > 0
            ? props.actionData[0].mobiles[0]?.contactId
            : null;
        emailContact["updatedBy"] = commonLib.getObjFromStorage("stateObj")
          ?.userId
          ? commonLib.getObjFromStorage("stateObj")?.userId
          : "";
        emailContact["emailOther"] =
          emailOther !== null
            ? {
                ...emailOther,
                othersName:
                  contactData.emailContactTypeId == "999999" &&
                  contactData?.emailOther !== undefined &&
                  contactData.emailOther !== ""
                    ? contactData?.emailOther
                    : emailOther?.othersName,
                updatedBy: commonLib.getObjFromStorage("stateObj")?.userId,
                isActive:
                  contactData.emailContactTypeId == "999999" &&
                  contactData?.emailOther !== undefined &&
                  contactData.emailOther !== ""
                    ? true
                    : false,
              }
            : null;
        emailContact["contactId"] =
          props.actionData &&
          props.actionData.length > 0 &&
          props.actionData[0].emails.length > 0
            ? props.actionData[0].emails[0]?.contactId
            : null;
      }
      req.contact.mobiles.push(mobileContact);
      req.contact.emails.push(emailContact);
      setReqData(req);
      // props.onOk();

      // if (props.actionType === "PUT") {
      //   console.log("PUT", props.actionData);
      //   let putContactWithOtherPromise = Services.putContactWithOther(req);
      //   putContactWithOtherPromise
      //     .then((res) => {
      //       props.getContactNyUserID(props.contactType);
      //       props.onOk();
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       if(err.response.status !== 500){
      //         if (err.response.data.Messages !== undefined) {
      //           Toaster.warning(err.response.data.Messages[0]);
      //         }
      //         else if (err.response.data.messages !== undefined) {
      //           Toaster.warning(err.response.data.messages[0]);
      //         }
      //       }
      //       else{
      //         Toaster.warning("Please contact concern person");
      //       }
      //     });
      // } else {
      //   let postContactWithOtherPromise = Services.postContactWithOther(req);
      //   postContactWithOtherPromise
      //     .then((res) => {
      //       console.log("res", res);
      //       props.getContactNyUserID(props.contactType);
      //       props.onOk();
      //     })
      //     .catch((err) => {
      //       konsole.log("error", err.response);
      //       if (err.response.status !== 500) {
      //         if (err.response.data.Messages !== undefined) {
      //           Toaster.warning(err.response.data.Messages[0]);
      //         }
      //         else if (err.response.data.messages !== undefined) {
      //           Toaster.warning(err.response.data.messages[0]);
      //         }
      //       }
      //       else {
      //         Toaster.warning("Please contact concern person.");
      //       }
      //     });
      // }
      //console.log("common",commonLib.getObjFromStorage('stateObj').userId);

      // props.onOk();
    // }
  };

  const runContactApi = () => {
    if (props.actionType === "PUT") {
      konsole.log("reqData", reqData);

      let emails = reqData.contact.emails[0].emailId !== null
          ? [
              {
                contactTypeId: reqData.contact.emails[0].contactTypeId,
                emailId: reqData.contact.emails[0].emailId,
                createdBy: reqData.contact.emails[0].createdBy,
                emailOther: reqData.contact.emails[0].emailOther,
                updatedBy: reqData.contact.emails[0].updatedBy,
                contactId: reqData.contact.emails[0].contactId,
              },
            ]
          : [];

      let mobiles =
        reqData.contact.mobiles[0].mobileNo !== null
          ? [
              {
                contactTypeId: reqData.contact.mobiles[0].contactTypeId,
                mobileNo: reqData.contact.mobiles[0].mobileNo,
                commTypeId: commTypevalue,
                createdBy: reqData.contact.mobiles[0].createdBy,
                mobileOther: reqData.contact.mobiles[0].mobileOther,
                updatedBy: reqData.contact.mobiles[0].updatedBy,
                contactId: reqData.contact.mobiles[0].contactId,
              },
            ]
          : [];
         
      let jsonObj = {
        userId: userId,
        activityTypeId: 4,
        contact: {},
      };
      console.log("haml",reqData)
      if (reqData.contact.mobiles[0]?.contactTypeId !== props.actionData[0].mobiles[0]?.contactTypeId) {
        jsonObj.contact.emails = emails;
        jsonObj.contact.mobiles = mobiles;
  
      }
    else if (reqData.contact.emails[0]?.contactTypeId !== props.actionData[0].emails[0]?.contactTypeId) {
        jsonObj.contact.emails = emails;
        jsonObj.contact.mobiles = mobiles;
  
      }
       else if ( reqData.contact.mobiles[0]?.mobileNo !== props.actionData[0].mobiles[0]?.mobileNo && reqData.contact.emails[0]?.emailId !==
          props.actionData[0].emails[0]?.emailId) {
        jsonObj.contact.emails = emails;
        jsonObj.contact.mobiles = mobiles;
      } else if (reqData.contact?.mobiles[0]?.mobileNo !== props.actionData[0]?.mobiles[0]?.mobileNo) {
        jsonObj.contact.mobiles = mobiles;
      } 
      else if (reqData.contact?.emails[0]?.emailId !== props.actionData[0]?.emails[0]?.emailId ) {
        jsonObj.contact.emails = emails;

      }
      else if( reqData.contact.mobiles[0].commTypeId != props.actionData[0].mobiles[0].commTypeId){
        jsonObj.contact.emails = emails;
        jsonObj.contact.mobiles = mobiles;
      }

      // console.log("PUTjsonObj", reqData.contact.emails[0].emailId, props.actionData[0].emails[0].emailId);
      konsole.log("s", jsonObj);

      let putContactWithOtherPromise = Services.putContactWithOther(jsonObj);

      putContactWithOtherPromise
        .then((res) => {
          konsole.log("PUTresponsePUTresponse", res);
       
            props.getContactNyUserID(props.contactType);
          
         
          setGetSaveBtn(false);
          props.onOk();
        })
        .catch((err) => {
          konsole.log(err);
          if (err.response.status !== 500) {
            if (err.response.data.Messages !== undefined) {
              Toaster.warning(err.response.data.Messages[0]);
            } else if (err.response.data.messages !== undefined) {
              Toaster.warning(err.response.data.messages[0]);
            }
          } else {
            Toaster.warning("Please contact concern person");
          }
        })
        .finally(() => {
          setGetSaveBtn(false);
        });
    } else {
      let postContactWithOtherPromise = Services.postContactWithOther(reqData);
      postContactWithOtherPromise
        .then((res) => {
          konsole.log("Postres", res);
         
            props.getContactNyUserID(props.contactType);
          
          
          setGetSaveBtn(false);
          props.onOk();
        })
        .catch((err) => {
          konsole.log("errornew", err.response);
          if (err.response.status !== 500) {
            if (err.response.data.Messages !== undefined) {
              Toaster.warning(err.response.data.Messages[0]);
            } else if (err.response.data.messages !== undefined) {
              Toaster.warning(err.response.data.messages[0]);
            }
          } else {
            Toaster.warning(err.response?.data.Messages[0]);
          }
        })
        .finally(() => {
          setGetSaveBtn(false);
        });
    }
  };

  useEffect(() => {
    const countryCode = Services.getcountrycode();
    countryCode
      .then((response) => {
        konsole.log("CountryCode", response.data.data);
        setCode(response.data.data);
      })
      .catch((err) => {
        konsole.log("Error", err);
      });
    const CommTypeapi = Services.getCommTypeapi();
    CommTypeapi.then((response) => {
      konsole.log(
        "getCommTypeapidata",
        response.data.data,
        props?.actionData[0]?.mobiles,
        props.contactType
      );

      setCommType(response.data.data);
    }).catch((err) => {
      konsole.log("getCommTypeapiError", err);
    });
  }, []);


  console.log("sdfkhdsjkhfkjshgjkfdh", contactwithoutprimarymobile, props.memberContactdata?.flat(1), props.actionData)

  useEffect(() => {
    let membercontacts = props.memberContactdata?.flat(1);
    
    let userIDEmail = [] 
    let userIDMobile=[]
    // let stateJs = props?.actionData[0]?.contactTypeId
     if (membercontacts.length > 0) {
           
      if (props.actionType == "POST"
        // membercontacts.filter((e) => { return e.contactTypeId == "1";})?.length > 0
        //  && stateJs != "1" && stateJs != "2" && stateJs != "3" && stateJs != "4" && stateJs != "5" && stateJs != "6"
         ) {
       
        let contactwith = props?.memberContactdata
        for(let i=0;i < props?.memberContactdata.length;i++){
          if(contactwith[i]?.[0]?.emails.length !== undefined && contactwith[i]?.[0]?.emails.length !== 0 ){
            if(contactwith[i]?.[0]?.emails?.[0]?.contactTypeId !== 999999){
              userIDEmail.push(contactwith[i]?.[0]?.emails?.[0]?.contactTypeId)
            }
         
         }
         if(contactwith[i]?.[0]?.mobiles.length !== undefined && contactwith[i]?.[0]?.mobiles.length !== 0){
           if(contactwith[i]?.[0]?.mobiles?.[0]?.contactTypeId !== 999999){
            userIDMobile.push(contactwith[i]?.[0]?.mobiles?.[0]?.contactTypeId)
           }
          
         }
        }
     
        let mobile = props?.contactType.filter((element) => !userIDMobile.includes(parseInt(element.value)));
        let email = props?.contactType.filter((element) => !userIDEmail.includes(parseInt(element.value)));


        
        //  console.log(userIDMobile, "contactwithoutprimjary");
        setContactwithoutprimary(email);
        setContactwithoutprimarymobile(mobile)
      } else {
        setContactwithoutprimary(props?.contactType);
        setContactwithoutprimarymobile(props?.contactType)
      }
    } 
    else {
      setContactwithoutprimary(props?.contactType);
      setContactwithoutprimarymobile(props?.contactType)
    }
  }, [props.memberContactdata]);

  // if (form.getFieldsValue().emailContactTypeId == undefined) {
  //   form.setFieldsValue({
  //     emailContactTypeId: contactwithoutprimary[0]?.value,
  //   });
  // }
  // if (form.getFieldsValue().mobileContactTypeId == undefined) {
  //   form.setFieldsValue({
  //     mobileContactTypeId: contactwithoutprimary[0]?.value,
  //   });
  // }

  konsole.log(
    form.getFieldsValue().emailContactTypeId,
    form.getFieldsValue().mobileContactTypeId,
    "datsofslectd"
  );

  return (
    <Modal
      title={props.title}
      visible={props.visible}
      onOk={handleFormSubmit}
      onCancel={props.onCancel}
      okText="Save"
      style={{ width: "100%", resize: "auto" }}
      width={1000}
      className="my-modal-class"
      maskClosable={false}
    >
      <Row>
        <Col span={24}>
          <Form form={form}>
            <div className="main">
              <Row xs={24} lg={24} span={24}>
                {props.actionType == "PUT" &&
                props.actionData.length > 0 &&
                props.actionData[0].emails.length > 0 ? (
                  <Col xs={24} lg={12} span={12}>
                    <Row gutter={[8, 8]}>
                      <Col xs={24} lg={10}>
                        <Form.Item
                          name="emailContactTypeId"
                          rules={[
                            {
                              required: true,
                              message: "Please Choose Email Contact Type",
                            },
                          ]}
                        >
                          <select
                            className="form-select form-select-sm"
                            placeholder={commonLib.mandatory(
                              "Choose Contact Type"
                            )}
                          >
                            <option disabled selected>
                              {commonLib.mandatory(
                                "Please Choose Email Contact Type"
                              )}
                            </option>
                            {contactwithoutprimary.map((item, index) => (
                              
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </Form.Item>
                        {}
                      </Col>
                      <Col xs={24} lg={10}>
                        {emailContactTypeId == 999999 && (
                          <Form.Item
                            name="emailOther"
                            rules={[
                              {
                                required: true,
                                // message: 'Please enter valid E-mail!',
                                message:
                                  "Please enter valid Email Other Description",
                              },
                            ]}
                          >
                            <Input
                              placeholder={commonLib.mandatory(
                                "Other Description"
                              )}
                              size=""
                              className=""
                            />
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  props.actionType == "POST" && (
                    <Col xs={24} lg={12} span={12}>
                      <Row gutter={[8, 8]}>
                        <Col xs={24} lg={10}>
                          <Form.Item
                            name="emailContactTypeId"
                            rules={[
                              {
                                required: (mobileContactTypeId == undefined || mobileContactTypeId == null || mobileContactTypeId == "") && (form.getFieldsValue()?.mobileNo == undefined || form.getFieldsValue()?.mobileNo == null || form.getFieldsValue()?.mobileNo == "" || form.getFieldsValue()?.emailId )? true : false,
                                message: "Please Choose Email Contact Type",
                              },
                            ]}
                          >
                            <select
                              className="form-select form-select-sm"
                              placeholder={commonLib.mandatory(
                                "Choose Contact Type"
                              )}
                            >
                              <option disabled selected>
                                {commonLib.mandatory(
                                  "Please Choose Email Contact Type"
                                )}
                              </option>
                              {contactwithoutprimary.map((item, index) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </Form.Item>
                          {}
                        </Col>
                        <Col xs={24} lg={10}>
                          {emailContactTypeId == 999999 && (
                            <Form.Item
                              name="emailOther"
                              rules={[
                                {
                                  required: true,
                                  // message: 'Please enter valid E-mail!',
                                  message:
                                    "Please enter valid Email Other Description",
                                },
                              ]}
                            >
                              <Input
                                placeholder={commonLib.mandatory(
                                  "Other Description"
                                )}
                                size=""
                                className=""
                              />
                            </Form.Item>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )
                )}
                {props.actionType == "PUT" &&
                props.actionData.length > 0 &&
                props.actionData[0].mobiles.length > 0 ? (
                  <Col xs={24} lg={12} span={12}>
                    <Row gutter={[8, 8]}>
                      <Col xs={24} lg={10}>
                        <Form.Item
                          name="mobileContactTypeId"
                          rules={[
                            {
                              required: true,
                              message: "Please Choose Contact Type",
                            },
                          ]}
                        >
                          <select
                            className="form-select form-select-sm"
                            placeholder={commonLib.mandatory(
                              "Choose Contact Type"
                            )}
                          >
                            <option disabled selected>
                              {commonLib.mandatory(
                                "Please Choose Contact Type"
                              )}
                            </option>
                            {contactwithoutprimarymobile.map((item, index) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </Form.Item>
                        {}
                      </Col>
                      <Col
                        xs={24}
                        lg={12}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Radio.Group
                          onChange={onChangeCommtype}
                          value={commTypevalue.toString()}
                        >
                          {commType.map((comtypeitem) => (
                            <Radio
                              key={comtypeitem.value}
                              value={comtypeitem.value}
                            >
                              {comtypeitem.label}
                            </Radio>
                          ))}
                        </Radio.Group>
                      </Col>
                      <Col xs={24} lg={10}>
                        {mobileContactTypeId == "999999" && (
                          <Form.Item
                            name="mobileOther"
                            className="mt-0"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please enter valid Other Description",
                              },
                            ]}
                          >
                            <Input
                              placeholder={commonLib.mandatory(
                                "Other Description"
                              )}
                              size=""
                              className=""
                            />
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  props.actionType == "POST" && (
                    <Col xs={24} lg={12} span={12}>
                      <Row gutter={[8, 8]}>
                        <Col xs={24} lg={10}>
                          <Form.Item
                            name="mobileContactTypeId"
                            rules={[
                              {
                                required: (emailContactTypeId == undefined || emailContactTypeId == null || emailContactTypeId == "") && (form.getFieldsValue()?.emailId == undefined || form.getFieldsValue()?.emailId == null || form.getFieldsValue()?.emailId == "" || form.getFieldsValue()?.mobileNo.length > 0) ? true : false,
                                message: "Please Choose Contact Type",
                              }
                            ]}
                          >
                            <select
                              className="form-select form-select-sm"
                              placeholder={commonLib.mandatory(
                                "Choose Contact Type"
                              )}
                            >
                              <option disabled selected>
                                {commonLib.mandatory(
                                  "Please Choose Contact Type"
                                )}
                              </option>
                              {contactwithoutprimarymobile.map((item, index) => (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </select>
                          </Form.Item>
                          {}
                        </Col>
                        <Col
                          xs={24}
                          lg={12}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Radio.Group
                            onChange={onChangeCommtype}
                            value={commTypevalue.toString()}
                          >
                            {commType.map((comtypeitem) => (
                              <Radio
                                key={comtypeitem.value}
                                value={comtypeitem.value}
                              >
                                {comtypeitem.label}
                              </Radio>
                            ))}
                          </Radio.Group>
                        </Col>
                        <Col xs={24} lg={10}>
                          {mobileContactTypeId == "999999" && (
                            <Form.Item
                              name="mobileOther"
                              className="mt-0"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please enter valid Other Description",
                                },
                              ]}
                            >
                              <Input
                                placeholder={commonLib.mandatory(
                                  "Other Description"
                                )}
                                size=""
                                className=""
                              />
                            </Form.Item>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  )
                )}
              </Row>
              <Row>
                {props.actionType == "PUT" &&
                props.actionData.length > 0 &&
                props.actionData[0].emails.length > 0 ? (
                  <Col span={8} xs={24} lg={12}>
                    <Form.Item
                      className="mt-2 me-2"
                      name="emailId"
                      rules={[
                        // {pattern: new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}"),message: "Please enter correct email address"},
                        {
                          required: true,
                          type: "email",
                          // message: 'Please enter valid E-mail!',
                          message: "Please enter valid Email",
                        },
                      ]}
                    >
                      <Input
                        placeholder={commonLib.mandatory("Email")}
                        size=""
                        className="Input"
                      />
                    </Form.Item>
                  </Col>
                ) : (
                  props.actionType == "POST" && (
                    <Col span={8} xs={24} lg={12}>
                      <Form.Item
                        className="mt-2 me-2"
                        name="emailId"
                        rules={[
                          // {pattern: new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}"),message: "Please enter correct email address"},
                          {
                            required:((form.getFieldsValue()?.mobileNo == undefined)||(form.getFieldsValue()?.mobileNo == null)  ||(form.getFieldsValue()?.mobileNo == "")  || emailContactTypeId !== undefined) ? true : false,
                            type: "email",
                            // message: 'Please enter valid E-mail!',
                            message: "Please enter valid Email",
                          },
                        ]}
                      >
                        <Input
                          placeholder={commonLib.mandatory("Email")}
                          size=""
                          className="Input"
                        />
                      </Form.Item>
                    </Col>
                  )
                )}
                {props.actionType == "PUT" &&
                props.actionData.length > 0 &&
                props.actionData[0].mobiles.length > 0 ? (
                  <>
                    <Col span={8} xs={24} lg={3}>
                      <Form.Item
                        name="countryCode"
                        className=""
                        rules={[
                          {
                            message: "Please choose country code",
                          },
                        ]}
                      >
                        <select
                          // placeholder="Email"
                          className="form-select form-select-sm"
                          // defaultValue={Code.value}

                          size=""
                          style={{
                            marginTop: 10,
                            marginRight: 15,
                          }}
                        >
                          {props.actionType == "PUT" ? (
                            <option>
                              {props.actionData[0].mobiles[0]?.mobileNo.slice(
                                1,
                                2
                              ) == 1
                                ? Code[0]?.label
                                : Code[1]?.label}
                            </option>
                          ) : (
                            <option>{commonLib.mandatory("Code")}</option>
                          )}
                          {Code.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </Form.Item>
                    </Col>
                    <Col span={8} xs={24} lg={8}>
                      <Form.Item
                        name="mobileNo"
                        className="mt-2 ms-2"
                        rules={[
                          phonenum.length > 0 && phonenum.length < 10
                            ? {
                                required: true,
                                type: "number",
                                message: `Please enter valid ${commTypevalue.toString() == 1 ? `Cell` : "Land Line"} Number`,
                              }
                            : {
                              required: true,
                              // type: "number",
                              message: `Please enter valid ${commTypevalue.toString() == 1 ? `Cell` : "Land Line"} Number`,
                            },
                        ]}
                      >
                        <Input.Group>
                          <InputMask
                            value={form.getFieldsValue().mobileNo}
                            onValueChange={onChangePhone}
                            placeholder={`${commTypevalue.toString() == 1 ? `Cell No.*` : "Land Line No.*"}`}
                          />
                        </Input.Group>
                      </Form.Item>
                    </Col>
                  </>
                ) : (
                  props.actionType == "POST" && (
                    <>
                      <Col span={8} xs={24} lg={3}>
                        <Form.Item
                          name="countryCode"
                          className=""
                          rules={[
                            {
                              required: true,
                              message: "Please choose country code",
                            },
                          ]}
                        >
                          <select
                            // placeholder="Email"
                            className="form-select form-select-sm"
                            defaultValue={Code[0]?.value}
                            size=""
                            style={{
                              marginTop: 10,
                              marginRight: 15,
                            }}
                          >
                            {Code.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </Form.Item>
                      </Col>
                      <Col span={8} xs={24} lg={8}>
                        <Form.Item
                          name="mobileNo"
                          className="mt-2 ms-2"
                          rules={[
                            phonenum.length > 0 && phonenum.length < 10
                              ? {
                                  required:(form.getFieldsValue()?.emailId == undefined)||(form.getFieldsValue()?.emailId == null)  ||(form.getFieldsValue()?.emailId == "") ||mobileContactTypeId !== undefined ? true : false,
                                  type: "number",
                                  message:`Please enter valid ${commTypevalue.toString() == 1 ? `Cell` : "Land Line"} Number`,
                                }
                              : {
                                required: form.getFieldsValue()?.emailId == undefined || form.getFieldsValue()?.emailId == null || form.getFieldsValue()?.emailId == "" || mobileContactTypeId !== undefined  ? true : false,
                                // type: "number",
                                message: `Please enter valid ${commTypevalue.toString() == 1 ? `Cell` : "Land Line"} Number`,
                              },
                          ]}
                        >
                          <Input.Group>
                            <InputMask
                               value={form.getFieldsValue().mobileNo}
                              onValueChange={onChangePhone}
                              placeholder={`${commTypevalue.toString() == 1 ? `Cell No.*` : "Land Line No.*"}`}
                            />
                          </Input.Group>
                        </Form.Item>
                      </Col>
                    </>
                  )
                )}
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}
