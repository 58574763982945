import "./MemberContact.css";

import React, { useContext } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
  Dropdown,
  Menu,
  message,
  Space,
  Checkbox,
  Popconfirm,
} from "antd";
import { useState } from "react";
import { useEffect } from "react";
import edit from "./Images/edit.png";
import deletepng from "./Images/delete.png";
import AddEditContact from "./AddEditContact";
import Services from "../../../network/Services";
import konsole from "../../../network/konsole";
import { UserContext } from "../../../App";
import commonLib from "../../../control/commonLib";
const { Text } = Typography;

export default function AddMemberContact(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [emailId, setEmailId] = useState([]);
  const [mobileNo, setmobileNo] = useState([]);
  const userId = props.userId;
  const [Data, setData] = useState([]);
  const [contacttype, setcontacttype] = useState([]);
  const [memberContactdata, setMemberContactdata] = useState([]);
  const [cardData, setCardData] = useState();
  const newArray = [];
  const { disable, modalOnDisable, getSavebtn, setGetSaveBtn } =
    useContext(UserContext);
  const [actionType, setActionType] = useState("POST");
  const [actionData, setActionData] = useState([]);
  let primaryUserId = commonLib.getObjFromStorage(
    "userPrimaryInDetail"
  ).memberUserId;

  const showModal = (aType = "POST", aData = null) => {
    console.log("postType", props);
    if( props.form.getFieldsValue().fName != undefined &&
    props.form.getFieldsValue().lName != undefined &&
    props.form.getFieldsValue().maritalStatusId != undefined ){
      props.setClickedby('contact')
    }else{
      props.setClickedby('')
    }
    if (props.userId != undefined) {
    setActionType(aType);
    setActionData(aData);
    setIsModalVisible(true);
    props.setClickedby('')
    }
  };

  useEffect(()=>{
    if(props.clickedby == "contact" ){
      showModal("POST", [])
    }
  },[props])
  
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    deleteDetails();
  }, []);

  useEffect(() => {
    if (getSavebtn == true) {
      deleteData();
    }
  }, []);
  
  const deleteDetails = () => {
    const Details = Services.getContactType();
    Details.then((response) => {
      konsole.log("ContactData", response);
      konsole.log("ContactType", response.data.data);
      setcontacttype(response.data.data);
    
        getContactNyUserID(response.data.data);
      
      
      // konsole.log("TYPe", contact);
    }).catch((err) => {
      konsole.log("Error", err);
    });
  };

  const deleteData = (e) => {
    konsole.log("deleteDataababab", e);
    let emailcontactid = e[0].emails[0]?.contactId;
    let mobilecontactid = e[0].mobiles[0]?.contactId;
    konsole.log("CheckContactId", mobilecontactid);
    konsole.log("CheckContactId", emailcontactid);
    setGetSaveBtn(false);
    modalOnDisable();
    if (emailcontactid !== "" && mobilecontactid !== "") {
      let promises = Services.deletecontactuserid(
        userId,
        emailcontactid,
        userId
      );
      promises
        .then((response) => {
          deleteDetails();
          konsole.log("seeu", response);
          getmobile(mobilecontactid);
        })
        .catch((err) => {
          konsole.log("errorie", err);
        });
    } else if (emailcontactid !== "" || mobilecontactid == null) {
      let promises = Services.deletecontactuserid(
        userId,
        emailcontactid,
        userId
      );
      promises
        .then((response) => {
          konsole.log("see", response);
        })
        .catch((err) => {
          konsole.log("errorie", err);
        });
    } else if (mobilecontactid !== "" || emailcontactid == null) {
      getmobile(mobilecontactid);
    } else {
      alert("Please Enter email or mobile details");
    }
  };

  const getmobile = (mobilecontactid) => {
    let promises = Services.deletecontactuserid(
      userId,
      mobilecontactid,
      userId
    );
    promises
      .then((response) => {
        konsole.log("saw", response);
      })
      .catch((err) => {
        konsole.log("erroriey", err);
      });
  };

  const returnMobilesObject = (contactType, contactName, mobiles, emails) => {
    return {
      contactTypeId: contactType,
      contactName: contactName,
      mobiles: mobiles,
      emails: emails,
    };
  };

  const getContactNyUserID = (ContactTypeList) => {
    if(userId !== undefined && userId !== null){
      let MemberContactpromise = Services.getContactNyUserID(userId);
      MemberContactpromise.then((res) => {
        let contactListArray = [];
        konsole.log("konsole res", res.data.data);
        const contactListRes = res.data.data.contact;
        for (let type of ContactTypeList) {
          const contactType = type.value;
          const contactName = type.label;
          konsole.log("konsole res type", type);
          const mobiles = contactListRes.mobiles.filter((res) => {
            return res.contactTypeId == contactType;
          });
          const emails = contactListRes.emails.filter((res) => {
            return res.contactTypeId == contactType;
          });
          if (mobiles.length > 0 || emails.length > 0) {
            let loopArray = mobiles;
  
            if (mobiles.length > emails.length) {
              loopArray = mobiles.length;
            } else {
              loopArray = emails.length;
            }
  
            for (let index = 0; index < loopArray; index++) {
              console.log("emailemails[index]", emails[index]);
  
              const emailsPush =
                emails[index] !== undefined ? [emails[index]] : {};
              const mobilePush =
                mobiles[index] !== undefined ? [mobiles[index]] : {};
  
              contactListArray.push([
                returnMobilesObject(
                  contactType,
                  contactName,
                  mobilePush,
                  emailsPush
                ),
              ]);
            }
          }
        }
        setMemberContactdata(contactListArray);
        konsole.log("konsole contact", memberContactdata);
      }).catch((err) => {
        konsole.log(err);
      });

    }
   
  };

  return (
    <div>
      <div className="site-card-wrapper Info p-3 ms-2">
        {/* <h4 className=" addNewContact ms-2">Contact</h4>*/}
        <Text strong={true} type="danger" style={{ color: "#76272B" }}>
          Contact
        </Text>

        <Row gutter={[8, 8]} className="w-100">
          {memberContactdata.map((props) => {
            konsole.log("datadata", props);
            if (props != undefined && props.length > 0) {
              konsole.log("lsit", props);
              return (
                <>
                  <Col className="mt-2" xs={24} lg={8}>
                    <Card
                        style={{height:"120px"}}
                      extra={
                        <div>
                          {" "}
                          <Typography.Link>
                            {/* <img
                              onClick={() => showModal("PUT", props)}
                              src={edit}
                            /> */}
                            {/* {props[0].contactName !== "Primary" && ( */}
                            <Popconfirm
                              className="cursor-pointer"
                              width="500px"
                              title={
                                userId !== primaryUserId ? (
                                  "Are you sure? You want to edit your Contact."
                                ) : props[0].contactName !== "Primary" ? (
                                  "Are you sure? You want to edit your Contact."
                                ) : (
                                  <p>
                                    You cannot edit your primary phone number or
                                    <br />
                                    primary email because this is how you
                                    <br />
                                    established your log-in credentials.
                                  </p>
                                )
                              }
                              // title="Are you sure? You want to delete your Contact."
                              // onConfirm={() =>{ (props[0].contactName !== 'Primary') ? deleteData(props):""}
                              // && props[0].contactName !== "Primary"
                              onConfirm={() => {
                                userId !== primaryUserId ? (
                                  showModal("PUT", props)
                                ) : props[0].contactName !== "Primary" ? (
                                  showModal("PUT", props)
                                ) : (
                                  <></>
                                );
                              }}
                            >
                              <img
                                // onClick={() => showModal("PUT", props)}
                                src={edit}
                              />
                            </Popconfirm>
                            {/* )} */}
                            {props[0].contactName !== "Primary" && (
                              <Popconfirm
                                className="cursor-pointer"
                                title={
                                  props[0].contactName !== "Primary"
                                    ? "Are you sure? You want to delete your Contact."
                                    : "Primary details cannot be deleted"
                                }
                                // title="Are you sure? You want to delete your Contact."
                                // onConfirm={() =>{ (props[0].contactName !== 'Primary') ? deleteData(props):""}
                                onConfirm={() => {
                                  props[0].contactName !== "Primary" ? (
                                    deleteData(props)
                                  ) : (
                                    <></>
                                  );
                                }}
                              >
                                <img src={deletepng} />
                              </Popconfirm>
                            )}
                          </Typography.Link>
                        </div>
                      }
                      className=""
                      title={props[0]?.contactName}
                      size="small"
                    >
                      <div className="d-block">
                        <p className="m-1">{props[0].emails[0]?.emailId}</p>
                        <p className="m-1">
                          {props[0].mobiles[0]?.mobileNo !== undefined
                            ? commonLib.formatPhoneNumber(
                                props[0].mobiles[0]?.mobileNo
                              )
                            : ""}
                        </p>
                      </div>
                    </Card>
                  </Col>
                  {props.contactTypeId == "9999999"}
                </>
              );
            }
          })}
        </Row>
        <Row>
          {isModalVisible === true ? (
            <AddEditContact
              actionType={actionType}
              actionData={actionData}
              title="Contact Type"
              visible={isModalVisible}
              onOk={handleOk}
              userId={userId}
              onCancel={handleCancel}
              contactType={contacttype}
              getContactNyUserID={getContactNyUserID}
              cardData={cardData}
              memberContactdata={memberContactdata}
            />
          ) : (
            <></>
          )}
        </Row>
        <Row>
          {props.userId != undefined &&
              props.form.getFieldsValue().fName != undefined &&
              props.form.getFieldsValue().lName != undefined &&
              props.form.getFieldsValue().maritalStatusId != undefined ? (
            <Button
              className="mt-2"
              style={{ color: "#720C20", borderColor: "#720C20" }}
              onClick={() => showModal("POST", [])}
            >
              Add New
            </Button>
          ) : (
            <Button
            htmlType="submit"
              className="mt-2"
              style={{ color: "#720C20", borderColor: "#720C20" }}
              onClick={() => showModal("POST", [])}
            >
              Add New
            </Button>
          )}
        </Row>
      </div>
    </div>
  );
}
