import { Button, Col, Form, Input, Row, Select, Radio } from "antd";
import React, { useEffect, useState } from "react";
import commonLib from "../../control/commonLib";
import konsole from "../../network/konsole";
import Services from "../../network/Services";
import InputMask from "../PhoneNumberInputMask";
import ManageFiduciaries from "../Fiduciaries/ManageFiduciaries.css";
function EditContactInfo({
  keyIndex,
  handleChange,
  handleClick,
  index,
  items,
  selectedIndex,
  setSelectedIndex,
  selectuserId,
  getcontactwithother,
  commTypevalue,
  setCommTypevalue,
  newAddListFunction,
  contactype,
  setNewListSelectData,
  setCountryCodeSelectState,
  errorMsg,
  contactype1,
  contactinfo,
  setDeleteState,
  deleteState
}) {
  const { Option } = Select;

  const [countryCode, setcountryCode] = useState([]);
  const [commType, setCommType] = useState([]);
  const [showContactInfoCard, setShowContactInfoCard] = useState(false);

  // const [commTypevalue, setCommTypevalue] = useState("1");
  const [form] = Form.useForm();
  const defaultvaluecode =
    items?.mobiles?.countryCode !== "" &&
    items?.mobiles?.countryCode !== null &&
    items?.mobiles?.countryCode !== undefined
      ? items?.mobiles?.countryCode
      : "+1";

  konsole.log(contactype, "rtyueioprofih");

  // useEffect(()=>{
  //   if(items?.mobiles?.commTypeId != null)
  // {  setCommTypevalue(items?.mobiles?.commTypeId)}
  // else{
  //   setCommTypevalue("1")
  // }
  // },[items])

  konsole.log(
    keyIndex,
    handleChange,
    handleClick,
    index,
    items,
    selectedIndex,
    setSelectedIndex,
    selectuserId,
    getcontactwithother,
    commTypevalue,
    setCommTypevalue,
    newAddListFunction,
    contactype,
    setNewListSelectData,
    setCountryCodeSelectState,
    errorMsg,
    contactinfo,
    "propsteuo"
  );

  useEffect(() => {
    if (items.mobiles.commTypeId != null) {
      setCommTypevalue(items.mobiles.commTypeId);
    }
  }, []);

  useEffect(() => {
    callcountrycodeapi();
    CommTypeapi();
    konsole.log(items);
    if (selectedIndex !== null) {
      setSelectedIndex(null);
    }
    setCountryCodeSelectState(defaultvaluecode);
  }, [items.Type, selectedIndex]);

  const hideContactInfoFunction = (data) => {
    konsole.log("jggkgkgk", data);
    // setShowContactInfoCard(true)
    let arr2 = [{ value: data.ContactTypeId, label: data.name }];
    newAddListFunction(arr2, contactype);
    setNewListSelectData(true);
    items.Type = "";
    // konsole.log(,"wertuioiug")
  };
  konsole.log("jggkgkghjhjk", contactype, contactinfo);

  const callcountrycodeapi = () => {
    let promises = Services.getcountrycode();
    promises
      .then((res) => {
        setcountryCode(res.data.data);
      })
      .catch((error) => {
        konsole.log("Errorcountry", error);
      });
  };

  const CommTypeapi = () => {
    const CommTypeapiparams = Services.getCommTypeapi();
    CommTypeapiparams.then((response) => {
      konsole.log("getCommTypeapidata", response.data.data);
      setCommType(response.data.data);
    }).catch((err) => {
      konsole.log("getCommTypeapiError", err);
    });
  };

  const deleteAPIData = (name, Data) => {
    let userId = commonLib.getObjFromStorage(
      "userPrimaryInDetail"
    ).memberUserId;
    // let userIdlog=commonLib.getObjFromStorage("userLoggedInDetail").loggedUserId
    // let userId=commonLib.getObjFromStorage("stateObj").userId
    konsole.log(name, Data, "nameData");
    // const NewData = items?.some((data) => data?.ContactTypeId == Data?.ContactTypeId)
    // konsole.log("NewDatajjjj", NewData)
    let contactId;
    let createdBy;
    if (name == "emailDelete") {
      // userId = Data?.createdBy
      contactId = Data?.contactId;
    } else if (name == "contactNoDelete") {
      // userId = Data?.createdBy
      contactId = Data?.contactId;
    } else if (name == "AllDelete") {
      let delemail = Data.emailId.contactId;
      let delcontact = Data.mobiles.contactId;

      let promisesemail = Services.deletecontactuserid(
        selectuserId,
        delemail,
        userId
      );
      promisesemail
        .then((response) => {
          //   deleteDetails();
          // window.location.reload();
          getcontactwithother(contactype1)
          setDeleteState(true)
          konsole.log("seeuDeleteData", response.data.data);
          //   getmobile(mobilecontactid);
        })
        .catch((err) => {
          konsole.log("errorie", err);
        });
      let promisescontact = Services.deletecontactuserid(
        selectuserId,
        delcontact,
        userId
      );
      promisescontact
        .then((response) => {
          //   deleteDetails();
          // window.location.reload();
          getcontactwithother(contactype1)
          setDeleteState(true)

          konsole.log("seeuDeleteData", response);
          //   getmobile(mobilecontactid);
        })
        .catch((err) => {
          konsole.log("errorie", err);
        });
      // window.location.reload();
    }

    let promises = Services.deletecontactuserid(
      selectuserId,
      contactId,
      userId
    );
    promises
      .then((response) => {
        //   deleteDetails();
        // window.location.reload();
        getcontactwithother(contactype1)
        setDeleteState(true)

        konsole.log("seeuDeleteData", response);
        //   getmobile(mobilecontactid);
      })
      .catch((err) => {
        konsole.log("errorie", err);
      });
  };

  const onChangeCommtype = (e) => {
    konsole.log("radiocheckedw", e.target.value);
    setCommTypevalue(e.target.value);
    cellnumberchange("");
  };
  konsole.log("items2", items);

  const cellnumberchange = (value) => {
    handleChange("mobile", index, value);
  };

  return (
    <div key={keyIndex}>
      <>
        {items.Type == "add" ? (
          <Row
            className="mt-2 p-2 rounded w-100"
            style={{
              border: "1px solid rgba(0,0,0,.125)",
              borderRadius: "0.25rem",
            }}

            // gutter={[8, 8]}
          >
            <Col className="w-100">
              <Row>
                <Col span={24}>
                  <h6>{items.name}</h6>
                </Col>
              </Row>
              <Row className="d-flex w-100 mb-2">
                <Col span={12} className="p-0" xs={24} md={12} lg={12}></Col>
                <Col span={12} className="p-0 " xs={24} md={12} lg={11}>
                  <Radio.Group
                    onChange={onChangeCommtype}
                    value={commTypevalue?.toString()}
                  >
                    {commType.map((comtypeitem) => (
                      <Radio key={comtypeitem.value} value={comtypeitem.value}>
                        {comtypeitem.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Col>
              </Row>

              <Row className="w-100 justify-content-between">
                <Col xs={24} md={7} className="mt-1">
                  <Form.Item className="m-0">
                    <Input.Group className="border " size="large">
                      <input
                        placeholder={commonLib.mandatory("Email")}
                        className="w-100 p-1 border-0 "
                        defaultValue={items.emailId?.email}
                        onChange={(value) =>
                          handleChange("email", index, value)
                        }
                      />
                      {""}
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col className="p-0 mt-1 " xs={24} md={4}>
                  <Form.Item className="m-0">
                    <Select
                      defaultValue={defaultvaluecode}
                      className="contact-Info-Row"
                      onSelect={(value) => handleChange("code", index, value)}
                    >
                      {countryCode.map((code, index) => {
                        return <Option value={code.value}>{code.label}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col className="mt-1" xs={24} md={12} lg={12} xl={12}>
                  <Form.Item className="m-0">
                    <Input.Group>
                      <InputMask
                        value={items.mobiles?.mobileNo}
                        placeholder={`${
                          commTypevalue.toString() == 1
                            ? `Cell No.*`
                            : "Land Line No.*"
                        }`}
                        onValueChange={(value) =>
                          // handleChange("mobile", index, value)
                          cellnumberchange(value)
                        }
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row className="d-flex justify-content-end w-100 mt-2">
                <Col span={8} className="d-flex justify-content-start">
                  <p className="text-danger">{items.error}</p>
                </Col>
                <Col span={4} className="d-flex justify-content-start">
                </Col>
                <Col span={6} className="d-flex justify-content-start">
                  <p className="text-danger">{items.error1}</p>
                </Col>
                <Col span={6} className="d-flex justify-content-end">
                  <div className="d-flex me-2">

                    <Button
                      className="fw-bold rounded"
                      style={{
                        background: "#720C20",
                        color: "white",
                        borderRadius: 5,
                      }}
                      onClick={() => handleClick("add", index)}
                    >
                      Add
                    </Button>
                  </div>
                  <div className="d-flex justify-content-end ">
                    <Button
                      className="fw-bold mb-2"
                      style={{
                        color: "#720C20",
                        borderColor: "#720C20",
                        borderRadius: 5,
                      }}
                      onClick={() => hideContactInfoFunction(items)}
                    >
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : items.Type == "addedit" ? (
          <Row
            className="mt-2 p-2 rounded w-100"
            style={{
              border: "1px solid rgba(0,0,0,.125)",
              borderRadius: "0.25rem",
            }}
            // gutter={[8, 8]}
          >
            <Col className="w-100">
              <Row>
                <Col span={24}>
                  <h6>{items.name}</h6>
                </Col>
              </Row>
              <Row className="d-flex w-100 mb-2">
                <Col span={12} className="p-0" xs={24} md={12} lg={12}></Col>
                <Col span={12} className="p-0 " xs={24} md={12} lg={11}>
                  <Radio.Group
                    onChange={onChangeCommtype}
                    value={commTypevalue?.toString()}
                  >
                    {commType.map((comtypeitem) => (
                      <Radio key={comtypeitem.value} value={comtypeitem.value}>
                        {comtypeitem.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Col>
              </Row>

              <Row className="w-100 justify-content-between">
                <Col xs={24} md={7} className="mt-1">
                  <Form.Item className="m-0">
                    <Input.Group className="border " size="large">
                      <input
                        placeholder={commonLib.mandatory("Email")}
                        className="w-100 p-1 border-0 "
                        defaultValue={items.emailId?.email}
                        onChange={(value) =>
                          handleChange("email", index, value)
                        }
                      />
                      {""}
                    </Input.Group>
                  </Form.Item>
                </Col>
                <Col className="p-0 mt-1" xs={24} md={4}>
                  <Form.Item className="m-0">
                    <Select
                      defaultValue={defaultvaluecode}
                      className="contact-Info-Row"
                      onSelect={(value) => handleChange("code", index, value)}
                    >
                      {countryCode.map((code, index) => {
                        return <Option value={code.value}>{code.label}</Option>;
                      })}
                    </Select>
                  </Form.Item>
                </Col>

                <Col className="mt-1" xs={24} md={12} lg={12} xl={12}>
                  <Form.Item className="m-0">
                    <Input.Group>
                      <InputMask
                        value={items.mobiles?.mobileNo}
                        placeholder={`${
                          commTypevalue.toString() == 1
                            ? `Cell No.*`
                            : "Land Line No.*"
                        }`}
                        onValueChange={(value) =>
                          // handleChange("mobile", index, value)
                          cellnumberchange(value)
                        }
                      />
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row className="d-flex justify-content-end w-100 mt-2">
                <Col span={8} className="d-flex justify-content-start">
                  <p className="text-danger">{items.error}</p>
                </Col>
                <Col span={4} className="d-flex justify-content-start">
                </Col>
                <Col span={6} className="d-flex justify-content-start">
                  <p className="text-danger">{items.error1}</p>
                </Col>
                <Col span={6} className="d-flex justify-content-end">
                  <div className="d-flex me-2">

                    <Button
                      className="fw-bold rounded"
                      style={{
                        background: "#720C20",
                        color: "white",
                        borderRadius: 5,
                      }}
                      onClick={() => handleClick("add", index)}
                    >
                      Edit
                    </Button>
                  </div>
                  <div className="d-flex justify-content-end ">
                    <Button
                      className="fw-bold mb-2"
                      style={{
                        color: "#720C20",
                        borderColor: "#720C20",
                        borderRadius: 5,
                      }}
                      onClick={() => handleClick("add", index)}
                    >
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Row
            className="mt-2 mb-0 p-2 me-0 ms-0 rounded w-100"
            style={{
              border: "1px solid rgba(0,0,0,.125)",
              borderRadius: "0.25rem",
            }}
            gutter={[8, 8]}
          >
            <Col
              xs={24}
              md={24}
              className="d-flex w-100 justify-content-between"
            >
              <h6>{items.name}</h6>
              <div className="fw-bold" style={{ color: "#720C20" }}>
                <img
                  className="cursor-pointer pe-1"
                  src="/images/akar-icons_edit.png"
                  onClick={() => handleClick("Edit", index)}
                />
                {items.name == "Primary" || items.name == "Primary Contact" ? (
                  ""
                ) : (
                  <img
                    src="/images/Deletebox.png"
                    className="h-75"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      items.emailId.contactId || items.mobiles.contactId
                        ? deleteAPIData("AllDelete", items)
                        : hideContactInfoFunction(items)
                    }
                  />
                )}
              </div>
            </Col>
            <Col className="mt-2 d-flex align-items-center" xs={24} md={10}>
              {items.emailId?.email !== "" ? (
                <>
                  <Input.Group className="border rounded" size="large">
                    <input
                      className="border-0 p-1 form-control disabledInput"
                      disabled
                      value={items.emailId?.email}
                    />
                  </Input.Group>
                </>
              ) : (
                <></>
              )}
            </Col>

            <Col className="mt-2 d-flex align-items-center" xs={24} md={10}>
              {items.mobiles?.mobileNo !== "" ? (
                <>
                  <Input.Group className="border rounded">
                    <input
                      className="border-0 p-1 form-control disabledInput "
                      disabled
                      value={
                        items?.mobiles?.countryCode +
                        commonLib.formatPhoneNumber(items?.mobiles?.mobileNo)
                      }
                    />
                  </Input.Group>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        )}
      </>
    </div>
  );
}

export default EditContactInfo;
