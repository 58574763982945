import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "antd";
import "antd/dist/antd.css";
import "./myProfessionalTeam.css";
import konsole from "../../../network/konsole";
import Services from "../../../network/Services";
import commonLib from "../../../control/commonLib";
import { UserContext } from "../../../App";
import OtherInfo from "../../OtherInfo";

const Myprofessionalteam = (props) => {
  const [userAddress, setUserAddress] = useState([]);
  const [userContact, setUserContact] = useState();
  const [userEmail, setUserEmail] = useState([]);
  const { setLoader } = useContext(UserContext);

  useEffect(() => {
    const professionaluserId = props.item.professionalUserId;

    
    if(professionaluserId !== undefined){
      getAddressByUserIdFun(professionaluserId);
      getContactwithotherFun(professionaluserId);
    }
    
  }, []);

  const getAddressByUserIdFun = (professionaluserId) => {
 
    setLoader(true);
    const promises = Services.getAddressByUserIddata(professionaluserId);
    promises
      .then((response) => {
        setUserAddress(response.data.data.addresses[0]);
        konsole.log("resss", response);
        // setUserAddress(response.data.data.addresses[0].addressLine1)
        setLoader(false);
      })
      .catch((error) => {
        konsole.log("error", error);
        setLoader(false);
      })
      .finally(() => {
        konsole.log("finish");
      });
  };

  const getContactwithotherFun = (professionaluserId) => {
  
    const promises = Services.getcontactwithother(professionaluserId);
    promises
      .then((res) => {
        // setUserContact(res.data.mobiles[0])
        setUserContact(res.data.data.contact.mobiles[0]);
        setUserEmail(res.data.data.contact.emails[0]);
      })
      .catch((error) => {
        konsole.log("error", error);
      })
      .finally(() => {
        konsole.log("Finish");
      });
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <div class="card mainCard ">
            
            <Row>
              <Col className="editbutton">
                <div>
                  <img
                    src="./images/edit.png"
                    onClick={() => {
                      props?.editproff(props);
                    }}
                  />
                </div>
                <div>
                  <img
                    src="./images/Deletebox.png"
                    onClick={() => {
                      props?.deleteuser(props);
                    }}
                  />
                </div>
              </Col>
              <Col
                span={24}
                className="d-flex mt-2 justify-content-center align-items-center "
              >
                {/* <img src="/womanAvatar.svg" className='img-fluid' ></img> */}
                <img src="images/usersecondLPo.png"></img>
              </Col>
            </Row>
            <Row>
              <Col
                span={24}
                xs={24}
                xl={24}
                className="d-flex justify-content-center align-items-center mt-3 px-2"
              >
                <h6
                  style={{
                    fontSize: "18px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {props.item?.fName}{" "}
                  {props.item.mName == "" ? "" : props.item.mName}{" "}
                  {props.item.lName}
                </h6>
              </Col>
            </Row>
            <Row>
              <Col
                span={20}
                xs={24}
                className="d-flex justify-content-center align-items-center"
              >
                <h6>
                {/* {props.item?.proSerDesc} */}
                 <OtherInfo  othersCategoryId={26} othersMapNatureId={props?.item?.proUserId}   FieldName={props?.item.proSerDesc}  userId={props?.item.professionalUserId}/></h6>
              </Col>
            </Row>
            <div className="mx-3" style={{ height: "115px" }}>
              <Row>
                <Col span={24} xs={24} className="d-flex webTagPhoneH5">
                  <div>
                    <img
                      src="location.png"
                      style={{ width: "18px", height: "20px" }}
                    />
                  </div>
                  <div>
                    <h6
                      className="mx-1"
                      style={{
                        marginTop: "4px",
                        fontSize: "12px",
                        color: "#444444",
                      }}
                    >
                      {userAddress?.addressLine1 == null ||
                      userAddress?.addressLine1 == ""
                        ? "Address Unavailable"
                        : `${userAddress.addressLine1},${userAddress?.zipcode}`}
                    </h6>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24} xs={24} className="d-flex webTagPhoneH5">
                  {userContact == "" ? (
                    " "
                  ) : (
                    <div>
                      <h6 style={{ color: "#444444", fontSize: "13px" }}>
                        <img
                          src="phone.png"
                          className="mx-1"
                          style={{ width: "13px" }}
                        />
                        {userContact?.mobileNo == null ||
                        userContact?.mobileNo == ""
                          ? "Cell number Unavailable"
                          : commonLib.formatPhoneNumber(userContact?.mobileNo)}
                      </h6>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24} xs={24} className="d-flex webTagPhoneH5">
                  {userEmail == "" ? (
                    ""
                  ) : (
                    <div>
                      <div className="d-flex ">
                        <img
                          src="email.png"
                          className="mx-1 mt-1"
                          style={{ width: "13px", height: "10px" }}
                        />
                        <h6
                          style={{
                            color: "#444444",
                            fontSize: "13px",
                            marginLeft: "1.5px",
                          }}
                        >
                          {userEmail?.emailId == null ||
                          userEmail?.emailId == ""
                            ? "Email Unavailable"
                            : `${userEmail?.emailId}`}
                        </h6>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          
        </Col>
      </Row>
    </div>
  );
};

export default Myprofessionalteam;
