import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import React, { useState, useContext } from "react";
import { useEffect } from "react";
import Acordianfile from "./Acordianfile";
import "../FileCabinetModal/filecabinetmodal.css";
import Services from "../../../network/Services";
import konsole from "../../../network/konsole";
import { UserContext } from "../../../App";

const Filecabinetmodal = (props) => {
  const [docmentShow, setDocumentShow] = useState(false);
  const [numImage, SetNumImage] = useState(4);
  const [colorChange, setColorChange] = useState();
  const [showData, setShowData] = useState();
  const [showName, setShowName] = useState();
  const [fileStatus, setfileStatus] = useState();
  const [showNewModal, setShowNewModal] = useState(false);
  const [textValu, setTextValue] = useState("");
  const [filedata, setFiledata] = useState([]);
  const [filemenu, setFilemenu] = useState([]);
  const [pdfdata, setPdfdata] = useState([]);
  const [showloader,setshowLoader] = useState(false)
  const { setLoader } = useContext(UserContext);

  console.log("propsprops", props);
  let user = JSON.parse(sessionStorage.getItem("userPrimaryInDetail"));
  let userId = user.memberUserId;

  useEffect(() => {
    console.log("fileCabinetTypefileCabinetType", props.text.value);
    let user = JSON.parse(sessionStorage.getItem("userPrimaryInDetail"));
    let userId = user?.memberUserId;

    filecabinetfilestatusapiget();

    if (
      props.filestatus &&
      props.openModOther == true &&
      props.filestatus.length != 0
    ) {
      filemenu.filter((index, e) => {
        setDocumentShow(false);
        setColorChange(-1);
        filecabinetdocument(-1);
        setfileStatus(index);
      });
      // filemenu.filter((e, index) => {
      //   return e.label == props.filestatus ? docmentHandel(index, e.label) : "";
      // });
    }
  }, [props.text.value, showData]);

  useEffect(() => {
    let user = JSON.parse(sessionStorage.getItem("userPrimaryInDetail"));
    let userId = user?.memberUserId;
    if(userId !== undefined && userId !== null && props.text.value !== undefined  && props.text.value !== null && props.text.value !== ""){
      filecabinetPreparebyid(userId, props.text.value);
    }
    
  }, [props.text.value]);

  useEffect(() => {
    setColorChange(-1);
    setDocumentShow(false);
  }, [props.text.value]);

  const handleClose = (data) => {
    props.setShowCabinetModal(false);
    props.setDoctypeName(data);
    props.setBreadcrumbdata([showName, fileStatus, -1]);
    konsole.log("datadatadatadata", data);
  };

  const docmentHandel = (e, value, itemvalue) => {
    setDocumentShow(true);
    // setColorChange(e);
    // filecabinetdocument(e);
    setColorChange(e);
    filecabinetdocument(itemvalue);
    setfileStatus(value);
  };
  const documentNotShow = () => {
    setDocumentShow(false);
  };

  const ImageHandle = (e) => {
    setShowData(e.value);
    setShowName(e.label);
  };

  const lawHandleFun = () => {
    setShowNewModal(true);
  };
  const handleClosetwo = () => {
    setShowNewModal(!true);
    setTextValue("");
  };

  const getTextValue = (e) => {
    setTextValue(e.target.value);
  };
  const filecabinetPreparebyid = (userId, fileCabinetId) => {
    let filecabinetPreparebyget = Services.filecabinetPreparebyapi;
    filecabinetPreparebyget(userId, fileCabinetId)
      .then((res) => {
        // konsole.log("res", res);
        setFiledata(res.data.data);
        setShowData(res.data.data[0].value);
        setShowName(res.data.data[0].label);
      })
      .catch((err) => {
        konsole.log("err", err);
      });
  };
  const filecabinetfilestatusapiget = () => {
    setLoader(true)
    let filecabinetfilestatusapi = Services.filecabinetfilestatusapi;
    filecabinetfilestatusapi(true)
      .then((res) => {
        // setFilemenu(res.data.data);
        let download = res?.data?.data;
        if (parseInt(props?.text?.value) == 8) {
          download = res?.data?.data?.filter((filt) => {
            return filt.value !== "1";
          });
        }
        konsole.log("response dada", download,props?.text?.value);
        setFilemenu(download);
        setLoader(false)
      })
      .catch((err) => {
        konsole.log("err", err);
         setLoader(false)
      });
  };

  const filterdata = filemenu.filter((e) => {
    if (props.text.value != 6) {
      return e.label !== "Fee Agreement & Other Forms";
    } else {
      return e;
    }
  });

  const filecabinetdocument = (e) => {
    console.log("userId", userId, props.text.value, showData, e);
    setshowLoader(true)
    let filecabinetdocumentfuc = Services.filecabinetdocumentapi(
      userId,
      props.text.value,
      showData,
      e
    );

    filecabinetdocumentfuc
      .then((res) => {
        setPdfdata(res.data.data);
        setshowLoader(false)
      })
      .catch((err) => {
        konsole.log("err", err);
        setshowLoader(false)
      });
  };

  // konsole.log("filestatus", props.filestatus);

  return (
    <div>
      <Modal
        show={props.showCabinetModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered={true}
      >
        <Modal.Header closeButton className=" d-flex  mb-0 pb-0">
          <Modal.Title>
            <div>
              <Breadcrumb className="jk">
                <Breadcrumb.Item href="#" style={{ marginTop: "0px" }}>
                  {" "}
                  {props.text.label}{" "}
                </Breadcrumb.Item>
                <Breadcrumb.Item href="#" onClick={documentNotShow}>
                  <span style={{ marginTop: "0px" }}>{showName}</span>
                </Breadcrumb.Item>
                {docmentShow == true ? (
                  <Breadcrumb.Item href="#">
                    <span style={{ marginTop: "0px" }}>{fileStatus}</span>
                  </Breadcrumb.Item>
                ) : (
                  ""
                )}
              </Breadcrumb>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} sm={12} md={12} lg={5}>
              <Row>
                <Col xs={5} sm={5} md={5} lg={5} className="d-flex m-0 p-0">
                  <div
                    className="p-1"
                    style={{
                      border: "2px solid #EBEDEF",
                      borderRadius: "3px",
                    }}
                  >
                    {filedata.length != 0 &&
                      filedata.map((filename, index) => (
                        <>
                          <div
                            className="d-flex "
                            onClick={() => ImageHandle(filename)}
                          >
                            {showData == filename.value ? (
                              <>
                                <div className={"document-Image-Sell-Main-Div"}>
                                  <div className="d-flex document-Image-Div">
                                    {/* <img src="" className='m-0 p-0' style={{ cursor: "pointer" }} /> */}
                                  </div>
                                  <div className="d-flex justify-content-center align-items-center w-100 docText-Tag-Div">
                                    <h6 className="docText-Tag-H6">
                                      {filename.label}
                                    </h6>
                                  </div>
                                </div>

                                <img
                                  src="images/line24.png"
                                  className="sidhiLineImage h-25"
                                />
                              </>
                            ) : (
                              <div className="selDoc-Img-Main-Div selDoc-Img-Main-Div-Two ">
                                <div className="selDoc-Selef-Img-Div-Two"></div>
                                <div className="d-flex justify-content-center align-items-center w-100 docText2-Tag-Div m-0 p-0">
                                  <h6
                                    className="text-center m-0 p-0"
                                    style={{ fontSize: "8px", color: "white" }}
                                  >
                                    {filename.label}
                                  </h6>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ))}
                    <div className="d-flex justify-content-center align-items-center mt-3 Add-New-Button-Div">
                      <button
                        type="button"
                        className="btn"
                        style={{
                          color: "#720C20",
                          border: "2px solid #720C20",
                          width: "100px",
                          fontSize: "12px",
                          fontWeight: 500,
                          visibility: "hidden",
                        }}
                        onClick={lawHandleFun}
                      >
                        + Add New
                      </button>
                    </div>
                    {showNewModal == true ? (
                      <>
                        <Modal
                          show={showNewModal}
                          onHide={handleClosetwo}
                          backdrop="static"
                          keyboard={false}
                          // size="lg"
                          centered={true}
                        >
                          <Modal.Header
                            className=" d-flex justify-content-between align-items-center"
                            style={{ backgroundColor: "#720c20" }}
                          >
                            <Modal.Title className="w-100">
                              <div>
                                <h6 className="text-light">Add New File</h6>
                              </div>
                            </Modal.Title>
                            <div
                              className="text-light fw-bold"
                              style={{ cursor: "pointer" }}
                              onClick={handleClosetwo}
                            >
                              X
                            </div>
                          </Modal.Header>
                          <Modal.Body>
                            <div>
                              <div style={{ position: "relative" }}>
                                <div className="d-flex ">
                                  <img
                                    src="images/Group884.png"
                                    className="m-0 p-0"
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                                <div
                                  className=" docText-Tag-Div"
                                  style={{ top: "40px", width: "142px" }}
                                >
                                  <h6 className="docText-Tag-H6">{textValu}</h6>
                                </div>
                              </div>

                              <div className="mt-3">
                                <input
                                  type="text"
                                  placeholder="Enter File Name"
                                  maxlength="18"
                                  onChange={getTextValue}
                                />
                              </div>
                              <div className="d-flex justify-content-center align-items-center mt-3 Add-New-Button-Div">
                                <button
                                  type="button"
                                  className="btn"
                                  style={{
                                    color: "#720C20",
                                    border: "2px solid #720C20",
                                    width: "100px",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
                <Col xs={7} sm={7} md={7} lg={7} className="m-0 p-0">
                  <div className="">
                    <div id="tree">
                      <div className="branch">
                        <div className="entry">
                          <span></span>

                          <div className="branch">
                            {showData === undefined
                              ? "No data"
                              : filterdata &&
                                filterdata.map((item, index) => {
                                  return (
                                    <div className="entry">
                                      <span>
                                        <div
                                          className="d-flex "
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            docmentHandel(
                                              index,
                                              item.label,
                                              item.value
                                            )
                                          }
                                        >
                                          <div style={{ width: "46px" }}>
                                            <img
                                              src="images/Group.png"
                                              className="m-0 p-0 img-fluid"
                                            />
                                          </div>
                                          <div
                                            className="d-flex justify-content-start align-items-center px-1 pt-2"
                                            style={{ width: "100px" }}
                                          >
                                            <h6
                                              className="m-0 p-0"
                                              style={
                                                colorChange == index
                                                  ? {
                                                      color: "#720C20",

                                                      fontWeight: 600,
                                                      fontSize: "15px",
                                                    }
                                                  : {
                                                      color: "black",

                                                      fontWeight: 500,
                                                      fontSize: "14px",
                                                    }
                                              }
                                            >
                                              {item.label}
                                            </h6>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                  );
                                })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} lg={7} className="m-0 p-0">
              {docmentShow == true ? (
                <>
                  <div>
                    <Acordianfile handleClose={handleClose} pdfdata={pdfdata} showloader={showloader} />
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Filecabinetmodal;
