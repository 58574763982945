import React, { useState, useEffect } from "react";
import Services from "../network/Services";
import konsole from "../network/konsole";

const OtherInfo = ({ othersMapNatureId, FieldName, userId,othersCategoryId }) => {
  const [otherfieldname, setOtherfieldName] = useState("");
  useEffect(() => {
    if(FieldName=='Other'){
   getOtherFromApi();
    }
    return () => {};
  }, []);

  const getOtherFromApi = () => {
    let jsonobj = [{userId: userId, othersMapNatureId: othersMapNatureId,isActive: true,othersMapNature: ""}];
    konsole.log("JSONJSON",JSON.stringify(jsonobj))
    let result=Services.getOtherFromAPI(jsonobj)
    result.then((res)=>{
        konsole.log("resresres",res)
        let responsedata=res?.data?.data
        let otherObj = responsedata.filter(otherRes => { return otherRes.othersCategoryId == othersCategoryId});
        let othersName= otherObj.length > 0 ? otherObj[0].othersName : ""
         setOtherfieldName(othersName)
    }).catch((err)=>{
        konsole.log("err",err)
    })
    // $CommonServiceFn.InvokeCommonApi("POST",  $Service_Url.getOtherFromAPI,  jsonobj,  (response,err) => {
    //     if (response) {
    //       konsole.log("responseresponseresponseresponse", response);
    //     let responsedata=response.data.data
    //       let otherObj = responsedata.filter(otherRes => { return otherRes.othersCategoryId == othersCategoryId});
    //       console.log("otherObjotherObj",otherObj)
    //       let othersName= otherObj.length > 0 ? otherObj[0].othersName : ""
    //       setOtherfieldName(othersName)
    //     }else{
    //         konsole.log("Error",err)
    //     }
    //   }
    // );
  };
  let fieldnamewithother=(otherfieldname !=='' && otherfieldname !==undefined && otherfieldname !==null)?`${FieldName}-${otherfieldname}`:FieldName;
  konsole.log("fieldnamewithother",fieldnamewithother)
  return fieldnamewithother;
};
export default OtherInfo;
