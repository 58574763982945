import React, { useEffect, useState, useContext } from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { imagePath } from "../../control/Constant";
import Layout from "antd/lib/layout";
import Cabinet from "./Cabinet";
import "./Filecabinet.css";
import Services from "../../network/Services";
import konsole from "../../network/konsole";
import Filecabinetmodal from "./FileCabinetModal/Filecabinetmodal";
import PdfViwer from "./PdfViwer";
import commonLib from "../../control/commonLib";
import { UserContext } from "../../App";

// import FileCabinetModal from "../FileCabinet/FileCabinetModal";

const Modalfilecabinet = (props) => {
  const { setLoader } = useContext(UserContext);

  const [userDetail, setUserDetail] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const [fileCabinetTypeList, setFileCabinetTypeList] = useState([]);
  const [fileCabinetType, setFileCabinetType] = useState({});

  const [doctypeName, setDoctypeName] = useState();
  const [lifePointv, setLifePointv] = useState([]);
  const [showCabinetModal, setShowCabinetModal] = useState(false);
  const [breadcrumbdata, setBreadcrumbdata] = useState();
  const [openModOther, setOpenModOther] = useState(true);
  const [pdfname, setpdfname] = useState();

  const handleClose = () => setShowCabinetModal(false);
  const loggedUserDetail = commonLib.getObjFromStorage("userLoggedInDetail");
  //Sumit code end

  const navigate = useNavigate();

  useEffect(() => {
    let userDetail = JSON.parse(sessionStorage.getItem("userPrimaryInDetail"));
    setUserDetail(userDetail);
    getFileCabinetType();
  }, []);

  // konsole.log("aaaa",imagePath)
  konsole.log("fileCabinetTypeList", fileCabinetTypeList);
  konsole.log("doctypeName", doctypeName);

  useEffect(() => {
    if (breadcrumbdata && !doctypeName?.fileTypeName) {
      setSelectedIndex(-1);
    }
    // setLoader(true);
    // setTimeout(() => {
    //   {
    //     setLoader(false);
    //   }
    // }, 500)
  }, [breadcrumbdata]);

  const getFileCabinetType = () => {
    let promise = Services.getFileCabinet();

    promise
      .then((response) => {
        console.log("ressssss gyhh", response);
        if (response) {


          let fileTypeObj = response.data.data;
          for (let loop = 0; loop < fileTypeObj.length; loop++) {
            fileTypeObj[loop]["imageUrl"] = imagePath[loop];
          }
          setFileCabinetTypeList(fileTypeObj);
          setFileCabinetType(fileTypeObj[0]);
          setLifePointv(response.data.data);
        }
        // setLoader(false)
      })
      .catch((err) => {
        console.log("errrrrr", err);
      });
  };

  const handleClickButton = (index) => {
    setShowCabinetModal(true);
    const fileTypeObj = fileCabinetTypeList[index];
    setSelectedIndex(index);
    setFileCabinetType(fileTypeObj);
  };

  console.log("showCabinetModal", showCabinetModal);
  console.log("life", lifePointv);

  const openModal = (e) => {
    setOpenModOther(true);
    setShowCabinetModal(true);
  };
  const openModalOther = (e) => {
    setOpenModOther(false);
    setShowCabinetModal(true);
  };

  return (
    <Layout>
      {/* <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#" onClick={() => { navigate("/dashboard") }}> Home </Breadcrumb.Item>
                        <Breadcrumb.Item href="#">File Cabinet</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">My lifeplan</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row> */}
      <div className="cabinet container-fluid">
        {/*  */}
        <div className="container-fluid py-2">
          <Row className=" file-cabinet-head">
            <Col className="p-0">
              <div className="d-flex align-items-center justify-content-between ">
                <div className="file-cabinet-head-content-box">
                  <div className="d-flex d-flex align-items-center justify-content-start">
                    {" "}
                    <img
                      src="/MainAvatar.svg"
                      className="maleAvatarUser"
                      alt="user"
                    />
                    <h2 className="ms-2">{loggedUserDetail?.loginUserName}</h2>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="mt-4">
            <Row>
              <Col xs={2} className="">
                <div className="file-cabinet-file">
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    id="  "
                  >
                    {fileCabinetTypeList.length > 0 &&
                      fileCabinetTypeList.map((map, index) => {
                        return (
                          <button
                            className={`${selectedIndex == index
                              ? "activeButtonCard"
                              : "buttonCard"
                              }`}
                            onClick={() => handleClickButton(index)}
                            key={index}
                            disabled={(map.value == 6 || map.value == 8) ? false : true}
                          >
                            <img src={map.imageUrl} alt="life" />
                            {(map.value == 6 || map.value == 8) ? (""):(
                             <img src="/images/icons8-lock.svg" alt="same"style={{width:"22px"}}></img>
                            )}
                           
                            <center className="ms-1">{map.label}</center>
                          </button>
                        );
                      })}
                    {/* <button onClick={handleClickButton}
                          >clik me</button> */}
                  </div>
                </div>
              </Col>
              <Col xs={10} className="ps-0">
                {/* <Cabinet fileCabinetType={fileCabinetType} /> */}
                {doctypeName || PdfViwer ? (
                  // <button className="breadcrumb-btn">
                  <Breadcrumb className="breadcrumb" >
                    <Breadcrumb.Item href="/Dashboard" >
                      <span style={{ color: "#0d6efd" }}>
                        Dashboard
                      </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="#">File Cabinet</Breadcrumb.Item>
                    {breadcrumbdata && doctypeName?.fileTypeName && (
                      <Breadcrumb.Item href="#">
                        {doctypeName?.fileCategoryType
                          ? doctypeName?.fileCategoryType
                          : fileCabinetType.label}
                      </Breadcrumb.Item>
                    )}

                    {breadcrumbdata &&
                      doctypeName?.fileTypeName &&
                      breadcrumbdata != undefined &&
                      breadcrumbdata?.length !== 0 ? (
                      <>
                        {breadcrumbdata[0] != "  " &&
                          breadcrumbdata[0] !== undefined ? (
                          <Breadcrumb.Item href="#" onClick={openModalOther}>
                            <span>{breadcrumbdata[0]}</span>
                          </Breadcrumb.Item>
                        ) : (
                          ""
                        )}
                        {breadcrumbdata[1] != "  " &&
                          breadcrumbdata[1] !== undefined ? (
                          <Breadcrumb.Item href="#" onClick={openModal}>
                            <span>{breadcrumbdata[1]}</span>
                          </Breadcrumb.Item>
                        ) : (
                          ""
                        )}
                        <Breadcrumb.Item href="#">
                          <span>{doctypeName?.fileTypeName}</span>
                        </Breadcrumb.Item>
                      </>
                    ) : (
                      ""
                    )}
                  </Breadcrumb>
                ) : (
                  // </button>
                  ""
                )}
                {breadcrumbdata && doctypeName?.fileTypeName ? (
                  <PdfViwer doctypeName={doctypeName} setpdfname={setpdfname} breadcrumbdata={breadcrumbdata}  setBreadcrumbdata={setBreadcrumbdata} fileCategoryType={doctypeName?.fileCategoryType ? doctypeName?.fileCategoryType : fileCabinetType?.label} setDoctypeName={setDoctypeName} setShowCabinetModal={setShowCabinetModal} setSelectedIndex={setSelectedIndex} />
                ) : (
                  "Please Select a FileCabinet."
                )}
              </Col>
              <Filecabinetmodal
                text={fileCabinetType}
                showCabinetModal={showCabinetModal}
                setShowCabinetModal={setShowCabinetModal}
                setDoctypeName={setDoctypeName}
                lifeData={lifePointv}
                setBreadcrumbdata={setBreadcrumbdata}
                filestatus={breadcrumbdata ? breadcrumbdata[1] : ""}
                openModOther={openModOther}
              />
            </Row>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Modalfilecabinet;
