import React, { useState, useEffect, useContext } from "react";

// import ImmediateActions from "./immediateActions";
import {
  Steps,
  Row,
  Col,
  Space,
  Table,
  Input,
  Button,
  Radio,
  Select,
  Checkbox,
  Form,
} from "antd";

import { Link } from "react-router-dom";
import Services from "../../../network/Services";
import Toaster from "../../Toaster";
import konsole from "../../../network/konsole";
import commonLib from "../../../control/commonLib";
import { UserContext } from "../../../App";
import AddnewProfessmember from "../../Component/MyProfessionalModal/AddnewProfessmember";
import Addnewprofessmodal from "../../Component/MyProfessionalModal/Addnewprofessmodal";
const { Option } = Select;

const Newsetguidance = () => {


  let memberName = JSON.parse(
    sessionStorage.getItem("userPrimaryInDetail")
  ).loginUserName;

  const CheckboxGroup = Checkbox.Group;
  const plainOptions = ["Emai", "Text"];
  const { TextArea } = Input;
  const [formlabelData, setFormLabelData] = useState({});
  const [professionalUser, setProfessionalUser] = useState([]);
  const [professionalUserAccountant, setProfessionalUserAccountant] = useState(
    []
  );
  const [professionalUserBookKeeper, setProfessionalUserBookKeeper] = useState(
    []
  );
  const [professionalUserFinceAdvicer, setProfessionalUserFinceAdvicer] =
    useState([]);
  const [professionalUserProprtyMang, setProfessionalUserProprtyMang] =
    useState([]);
  const [professionalUserHandymanServce, setProfessionalUserHandymanServce] =
    useState([]);

  const [switchComponent, setswitchComponent] = useState(false);
  const [secoundComponent, setsecoundComponent] = useState(false);
  const [secoundExtraComponent, setsecoundExtraComponent] = useState(false);
  const [selectedStep, setStepper] = useState(1);
  const [thirdComponent, setthirdComponent] = useState(false);
  const [thirdExtraComponent, setthirdExtraComponent] = useState(false);
  const [forthComponent, setforthComponent] = useState(false);
  const [fifthComponent, setfifthComponent] = useState(false);
  const [organizationCare, setOranizationCare] = useState([]);
  const [agentCareManager, setAgentCareManager] = useState([]);
  const [careManagerInMind, setCareManagerInMind] = useState([]);
  const [specialInstructions, setSpecialInstructions] = useState([]);
  const [compnayWorkWith, setCompanyWorkWith] = useState([]);
  const [answerObj, setAnswerObj] = useState();
  const [specialInsAns, setSpecialInsAns] = useState();
  const [specialInsstep2, setSpecialInsstep2] = useState();
  const [getselectedGCM, setGetselectedGCM] = useState();
  const { setLoader } = useContext(UserContext);
  const [selectProprtyMang, setSelectProprtyMang] = useState();
  const [selectBillService, setSelectBillService] = useState();
  const [selectAcoount, setSelectAccount] = useState();

  const [selectBookKeeper, setSelectBookKeeper] = useState();
  const [selectFinceAdvicer, setSelectFinceAdvicer] = useState();
  const [selectHandymanServce, setSelectHandymanServce] = useState();
  const [showaddprofessmodal, setshowaddprofessmodal] = useState(false);
  const [professionaltype, setprofessionaltype] = useState();
  const memberdetails = commonLib.getObjFromStorage("userPrimaryInDetail");
  const [radiovalue, setradioValue] = useState(1);
  let memberUserId = (radiovalue == 2) ? memberdetails.spouseUserId : memberdetails.memberUserId ;

  const onChangeradio = (e) => {
    setradioValue(e.target.value);
  };

  var selectKey;


  useEffect(() => {
    getProfessionalByUser();
  }, [showaddprofessmodal,radiovalue]);

  const onChangeSelect = (value) => {
    setSelectBillService(value);
  };

  konsole.log("selectKeyselectKey", selectBillService);

  const onChangeSelectAccountant = (value) => {
    konsole.log("selectedvalue", value);
    setSelectAccount(value);
  };

  const onChangeSelectBookKeeper = (value) => {
    konsole.log("selectedvalue", value);
    setSelectBookKeeper(value);
  };

  const onChangeSelectFinceAdvicer = (value) => {
    konsole.log("selectedvalue", value);
    setSelectFinceAdvicer(value);
  };

  const onChangeSelectProprtyMang = (value) => {
    konsole.log("selectedvalue", value);
    setSelectProprtyMang(value);
  };

  const onChangeSelectHandymanServce = (value) => {
    konsole.log("selectedvalue", value);
    setSelectHandymanServce(value);
  };

  const getProfessionalByUser = () => {
    
    setLoader(true);
    let getProfessionalpramas = Services.getProfessionalByUserId(memberUserId);
    getProfessionalpramas
      .then((res) => {
        let filterdata = res.data.data.professionalUser.filter((items) => {
          return items.proSerDescId === 2;
        });
        let filterbillpay = filterdata.filter((e) => {
          return e.lpoStatus == true;
        });
        konsole.log(filterbillpay, "datagetfilter");
        setSelectBillService(
          filterbillpay.length > 0
            ? filterbillpay[0]?.fName + " " + filterbillpay[0]?.lName
            : null
        );
        let filterdataAccountant = res.data.data.professionalUser.filter(
          (items) => {
            return items.proSerDescId === 3;
          }
        );
        let dataAccountant = filterdataAccountant.filter((e) => {
          return e.lpoStatus == true;
        });
        konsole.log(dataAccountant, "datagetfilter2");
        setSelectAccount(
          dataAccountant.length > 0
            ? dataAccountant[0]?.fName + " " + dataAccountant[0]?.lName
            : null
        );
        let filterdataBookKeper = res.data.data.professionalUser.filter(
          (items) => {
            return items.proSerDescId === 12;
          }
        );
        let dataBookKeper = filterdataBookKeper.filter((e) => {
          return e.lpoStatus == true;
        });
        konsole.log(dataBookKeper, "datagetfilter3");
        setSelectBookKeeper(
          dataBookKeper.length > 0
            ? dataBookKeper[0]?.fName + " " + dataBookKeper[0]?.lName
            : null
        );
        let filterdataFinceAdvicer = res.data.data.professionalUser.filter(
          (items) => {
            return items.proSerDescId === 1;
          }
        );
        let FinceAdvicer = filterdataFinceAdvicer.filter((e) => {
          return e.lpoStatus == true;
        });
        konsole.log(FinceAdvicer, "datagetfilter4");
        setSelectFinceAdvicer(
          FinceAdvicer.length > 0
            ? FinceAdvicer[0]?.fName + " " + FinceAdvicer[0]?.lName
            : null
        );
        let filterdataPropertyMang = res.data.data.professionalUser.filter(
          (items) => {
            return items.proSerDescId === 14;
          }
        );
        let PropertyMang = filterdataPropertyMang.filter((e) => {
          return e.lpoStatus == true;
        });
        konsole.log(PropertyMang, "datagetfilter5");
        setSelectProprtyMang(
          PropertyMang.length > 0
            ? PropertyMang[0]?.fName + " " + PropertyMang[0]?.lName
            : null
        );
        let filterdataHandyman = res.data.data.professionalUser.filter(
          (items) => {
            return items.proSerDescId === 4;
          }
        );
        let dataHandyman = filterdataHandyman.filter((e) => {
          return e.lpoStatus == true;
        });
        konsole.log(dataHandyman, "datagetfilter6");
        setSelectHandymanServce(
          dataHandyman.length > 0
            ? dataHandyman[0]?.fName + " " + dataHandyman[0]?.lName
            : null
        );

        konsole.log("filterdataljljlj;j;", filterdata);

        let selectedGCM = res.data.data.professionalUser.filter((items) => {
          return items.lpoStatus === true;
        });
        setGetselectedGCM(selectedGCM);
        setProfessionalUser(filterdata);
        setProfessionalUserAccountant(filterdataAccountant);
        setProfessionalUserBookKeeper(filterdataBookKeper);
        setProfessionalUserFinceAdvicer(filterdataFinceAdvicer);
        setProfessionalUserProprtyMang(filterdataPropertyMang);
        setProfessionalUserHandymanServce(filterdataHandyman);
        setLoader(false);
      })
      .catch((err) => {
        konsole.log("err", err);
        setLoader(false);
      });
  };

  const putProfessionaluserfunc = () => {


    let stateUserId = JSON.parse(sessionStorage.getItem("stateObj")).userId;
    konsole.log(
      selectBillService,
      selectAcoount,
      selectBookKeeper,
      selectFinceAdvicer,
      selectHandymanServce,
      selectProprtyMang,
      "dataofselectedvaluse"
    );
    if (selectBillService != undefined || selectBillService != "") {
      const dataObj = {
        userId: memberUserId,
        updatedBy: stateUserId,
        professionalUser: {
          proTypeId: professionalUser[selectBillService]?.proTypeId,
          proUserId: professionalUser[selectBillService]?.proiUserId,
          userProId: professionalUser[selectBillService]?.userProId,
          lpoStatus: true,
        },
      };
      let putProfessionaluserpramas = Services.putProfessionaluser(dataObj);
      setLoader(true);
      putProfessionaluserpramas
        .then((res) => {
          konsole.log("response1", res.data.data);
          setLoader(false);
          // Toaster.success("Successfully Saved");
        })
        .catch((err) => {
          konsole.log("err1", err);
          //   Toaster.warning("Invalid Data");
          setLoader(false);
        });
    }
    if (selectAcoount != undefined || selectAcoount != "") {
      const dataObj = {
        userId: memberUserId,
        updatedBy: stateUserId,
        professionalUser: {
          proTypeId: professionalUserAccountant[selectAcoount]?.proTypeId,
          proUserId: professionalUserAccountant[selectAcoount]?.proiUserId,
          userProId: professionalUserAccountant[selectAcoount]?.userProId,
          lpoStatus: true,
        },
      };
      let putProfessionaluserpramas = Services.putProfessionaluser(dataObj);
      setLoader(true);
      putProfessionaluserpramas
        .then((res) => {
          konsole.log("response2", res.data.data);
          setLoader(false);
          // Toaster.success("Successfully Saved");
        })
        .catch((err) => {
          konsole.log("err2", err);
          setLoader(false);
          //   Toaster.warning("Invalid Data");
        });
    }
    if (selectBookKeeper != undefined || selectBookKeeper != "") {
      const dataObj = {
        userId: memberUserId,
        updatedBy: stateUserId,
        professionalUser: {
          proTypeId: professionalUserBookKeeper[selectBookKeeper]?.proTypeId,
          proUserId: professionalUserBookKeeper[selectBookKeeper]?.proiUserId,
          userProId: professionalUserBookKeeper[selectBookKeeper]?.userProId,
          lpoStatus: true,
        },
      };
      let putProfessionaluserpramas = Services.putProfessionaluser(dataObj);
      setLoader(true);
      putProfessionaluserpramas
        .then((res) => {
          konsole.log("response3", res.data.data);
          setLoader(false);
          // Toaster.success("Successfully Saved");
        })
        .catch((err) => {
          konsole.log("err3", err);
          setLoader(false);
          //   Toaster.warning("Invalid Data");
        });
    }
    if (selectFinceAdvicer != undefined || selectFinceAdvicer != "") {
      const dataObj = {
        userId: memberUserId,
        updatedBy: stateUserId,
        professionalUser: {
          proTypeId:
            professionalUserFinceAdvicer[selectFinceAdvicer]?.proTypeId,
          proUserId:
            professionalUserFinceAdvicer[selectFinceAdvicer]?.proiUserId,
          userProId:
            professionalUserFinceAdvicer[selectFinceAdvicer]?.userProId,
          lpoStatus: true,
        },
      };
      let putProfessionaluserpramas = Services.putProfessionaluser(dataObj);
      setLoader(true);
      putProfessionaluserpramas
        .then((res) => {
          konsole.log("response4", res.data.data);
          setLoader(false);
          // Toaster.success("Successfully Saved");
        })
        .catch((err) => {
          konsole.log("err4", err);
          setLoader(false);
          //   Toaster.warning("Invalid Data");
        });
    }
    if (selectHandymanServce != undefined || selectHandymanServce != "") {
      const dataObj = {
        userId: memberUserId,
        updatedBy: stateUserId,
        professionalUser: {
          proTypeId:
            professionalUserHandymanServce[selectHandymanServce]?.proTypeId,
          proUserId:
            professionalUserHandymanServce[selectHandymanServce]?.proiUserId,
          userProId:
            professionalUserHandymanServce[selectHandymanServce]?.userProId,
          lpoStatus: true,
        },
      };
      let putProfessionaluserpramas = Services.putProfessionaluser(dataObj);
      setLoader(true);
      putProfessionaluserpramas
        .then((res) => {
          konsole.log("response5", res.data.data);
          setLoader(false);
          // Toaster.success("Successfully Saved");
        })
        .catch((err) => {
          konsole.log("err5", err);
          setLoader(false);
          //   Toaster.warning("Invalid Data");
        });
    }
    if (selectProprtyMang != undefined || selectProprtyMang != "") {
      const dataObj = {
        userId: memberUserId,
        updatedBy: stateUserId,
        professionalUser: {
          proTypeId: professionalUserProprtyMang[selectProprtyMang]?.proTypeId,
          proUserId: professionalUserProprtyMang[selectProprtyMang]?.proiUserId,
          userProId: professionalUserProprtyMang[selectProprtyMang]?.userProId,
          lpoStatus: true,
        },
      };
      let putProfessionaluserpramas = Services.putProfessionaluser(dataObj);
      setLoader(true);
      putProfessionaluserpramas
        .then((res) => {
          konsole.log("response6", res.data.data);
          setLoader(false);
          // Toaster.success("Successfully Saved");
        })
        .catch((err) => {
          konsole.log("err6", err);
          setLoader(false);
          //   Toaster.warning("Invalid Data");
        });
    }
    Toaster.success("Data saved successfully");
  };

  const onClickSave = () => {
    if (
      organizationCare?.responseId == 199 &&
      agentCareManager?.responseId == 201 &&
      careManagerInMind?.responseId == 203
    ) {
      //   putProfessionaluserpramas();
    }
  };

  const postSubjectData = () => {
    putProfessionaluserfunc();
  };

  const addprofessfuncclick = (type) => {
    konsole.log(type, "typetypetypetypetype");

    if (type == "BillService") {
      setprofessionaltype(2);
    } else if (type == "Accountant") {
      setprofessionaltype(3);
    } else if (type == "Bookkeeper") {
      setprofessionaltype(12);
    } else if (type == "FinancialAdvisor") {
      setprofessionaltype(1);
    } else if (type == "PropertyManager") {
      setprofessionaltype(14);
    } else if (type == "HandymanService") {
      setprofessionaltype(4);
    }

    setshowaddprofessmodal(true);
  };

  return (
    <Row>
      <Col span={24}>
        {showaddprofessmodal && (
          <Addnewprofessmodal
            showaddprofessmodal={showaddprofessmodal}
            setshowaddprofessmodal={setshowaddprofessmodal}
            professionaltype={professionaltype}
            memberUserId={memberUserId}
          />
        )}
        <h6 className="ms-4 mt-2">
          <Link to="/Dashboard" className="text-primery">
            Dashboard
          </Link>
          <span>{">"}</span>Set Guidance
          <span>{">"}</span>Finance
        </h6>
        {memberdetails?.spouseName && (
          <div className="ms-4 mt-2">
            <Radio.Group
              onChange={onChangeradio}
              value={radiovalue}
            >
              <Radio value={1}>{memberdetails.memberName}</Radio>
              <Radio value={2}>{memberdetails.spouseName}</Radio>
            </Radio.Group>
          </div>
        )}
        <Row className="p-3">
          <Col
            span={24}
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            className="Question-Card-Col"
          >
            <div>
              <h6>Choose a professional who would be paying your bills</h6>

              <div className="select-Div d-flex">
                <Select
                  className="m-0 p-0 mb-1 w-100"
                  placeholder={"Select a Bill Pay Service"}
                  optionFilterProp="children"
                  style={{
                    // width: 250,
                    height: "40px",
                    border: "black",
                  }}
                  
                  value={selectBillService == null || selectBillService == undefined ?
                    professionalUser.length == 0 ? "Select a person" : (professionalUser[professionalUser.length-1]?.fName != undefined  ? professionalUser[professionalUser.length-1]?.fName : "") +
                    " " + (professionalUser[professionalUser.length-1]?.mName != undefined ? professionalUser[professionalUser.length-1]?.mName : "") +""+
                   (professionalUser[professionalUser.length-1]?.lName != undefined ? professionalUser[professionalUser.length-1]?.lName : "")
                   :(selectBillService)
                  }
                  // value={selectBillService}
                  onChange={onChangeSelect}
                >
                  {professionalUser.map((data, key) => (
                    <Option value={key}>
                      {data.fName} {data.mName} {data.lName}
                    </Option>
                  ))}
                </Select>
                <button
                  className="addnewproffbtn"
                  style={{marginTop:"-10px"}}
                  onClick={() => {
                    addprofessfuncclick("BillService");
                  }}
                >
                  +
                </button>
              </div>
            </div>
            <div className="mt-1">
              <h6>Choose a professional who would be filing your taxes</h6>

              <div className="select-Div d-flex">
                <Select
                  className="m-0 p-0 w-100 mb-1"
                  placeholder={"Select an Accountant"}
                  optionFilterProp="children"
                  style={{
                    // width: 250,
                    height: "40px",
                    border: "black",
                  }}
                  value={ selectAcoount == null || selectAcoount == undefined ?
                    professionalUserAccountant.length == 0 ? "Select a person" : (professionalUserAccountant[professionalUserAccountant.length-1]?.fName != null ? professionalUserAccountant[professionalUserAccountant.length-1]?.fName : "") +
                    " " + (professionalUserAccountant[professionalUserAccountant.length-1]?.mName != null ? professionalUserAccountant[professionalUserAccountant.length-1]?.mName : "") +""+
                   (professionalUserAccountant[professionalUserAccountant.length-1]?.lName != null ? professionalUserAccountant[professionalUserAccountant.length-1]?.lName : "")
                   : (getselectedGCM)
                  }
                  // value={selectAcoount}
                  onChange={onChangeSelectAccountant}
                >
                  {professionalUserAccountant.map((data, key) => (
                    <Option value={key}>
                      {data.fName} {data.mName} {data.lName}
                    </Option>
                  ))}
                </Select>
                <button
                  className="addnewproffbtn"
                  style={{marginTop:"-10px"}}
                  onClick={() => {
                    addprofessfuncclick("Accountant");
                  }}
                >
                  +
                </button>
              </div>
            </div>
            <div className="mt-1">
              <h6>
              Choose  the professionals below who will be managing  your
                investments
              </h6>

              <div className="select-Div d-flex">
                <Select
                  className="m-0 p-0 w-100 mb-1"
                  placeholder={"Select a Book Keeper"}
                  optionFilterProp="children"
                  style={{
                    // width: 250,
                    height: "40px",
                    border: "black",
                  }}
                  
                  value={ selectBookKeeper == undefined || selectBookKeeper == null ?
                    professionalUserBookKeeper.length == 0 ? "Select a person" : (professionalUserBookKeeper[professionalUserBookKeeper.length-1]?.fName != null ? professionalUserBookKeeper[professionalUserBookKeeper.length-1]?.fName : "") +
                    " " + (professionalUserBookKeeper[professionalUserBookKeeper.length-1]?.mName != null ? professionalUserBookKeeper[professionalUserBookKeeper.length-1]?.mName : "") +""+
                   (professionalUserBookKeeper[professionalUserBookKeeper.length-1]?.lName != null ? professionalUserBookKeeper[professionalUserBookKeeper.length-1]?.lName : "")
                   
                   : (selectBookKeeper)
                  }
                  // value={selectBookKeeper}
                  onChange={onChangeSelectBookKeeper}
                >
                  {professionalUserBookKeeper.map((data, key) => (
                    <Option value={key}>
                      {data.fName} {data.mName} {data.lName}
                    </Option>
                  ))}
                </Select>
                <button
                  className="addnewproffbtn"
                  style={{marginTop:"-10px"}}
                  onClick={() => {
                    addprofessfuncclick("Bookkeeper");
                  }}
                >
                  +
                </button>
              </div>
            </div>

            <div>
              <div className="select-Div d-flex">
                <Select
                  className="m-0 p-0 w-100 mb-1"
                  placeholder={"Select a Financial Advisor"}
                  optionFilterProp="children"
                  style={{
                    // width: 250,
                    height: "40px",
                    border: "black",
                  }}
                 
                  value={ selectFinceAdvicer == null || selectFinceAdvicer == undefined ?
                    professionalUserFinceAdvicer.length == 0 ? "Select a person" : (professionalUserFinceAdvicer[professionalUserFinceAdvicer.length-1]?.fName != null ? professionalUserFinceAdvicer[professionalUserFinceAdvicer.length-1]?.fName : "") +
                    " " + (professionalUserFinceAdvicer[professionalUserFinceAdvicer.length-1]?.mName != null ? professionalUserFinceAdvicer[professionalUserFinceAdvicer.length-1]?.mName : "") +""+
                   (professionalUserFinceAdvicer[professionalUserFinceAdvicer.length-1]?.lName != null ? professionalUserFinceAdvicer[professionalUserFinceAdvicer.length-1]?.lName : "")
                   
                   : (selectFinceAdvicer)
                  }
                  // value={selectFinceAdvicer}
                  onChange={onChangeSelectFinceAdvicer}
                >
                  {professionalUserFinceAdvicer.map((data, key) => (
                    <Option value={key}>
                      {data.fName} {data.mName} {data.lName}
                    </Option>
                  ))}
                </Select>
                <button
                  className="addnewproffbtn"
                  style={{marginTop:"-10px"}}
                  onClick={() => {
                    addprofessfuncclick("FinancialAdvisor");
                  }}
                >
                  +
                </button>
              </div>
            </div>

            <div className="mt-1">
              <h6>
                Choose the professionals below who will be managing your Real
                Property
              </h6>

              <div className="select-Div d-flex">
                <Select
                  className="m-0 p-0 w-100 mb-1"
                  placeholder={"Select a Property Manager"}
                  optionFilterProp="children"
                  style={{
                    // width: 250,
                    height: "40px",
                    border: "black",
                  }}
                  value={selectProprtyMang == null || selectProprtyMang == undefined ?
                    professionalUserProprtyMang.length == 0 ? "Select a person" : (professionalUserProprtyMang[professionalUserProprtyMang.length-1]?.fName != null ? professionalUserProprtyMang[professionalUserProprtyMang.length-1]?.fName : "") +
                    " " + (professionalUserProprtyMang[professionalUserProprtyMang.length-1]?.mName != null ? professionalUserProprtyMang[professionalUserProprtyMang.length-1]?.mName : "") +""+
                   (professionalUserProprtyMang[professionalUserProprtyMang.length-1]?.lName != null ? professionalUserProprtyMang[professionalUserProprtyMang.length-1]?.lName : "")
                   
                   : (selectProprtyMang)
                  }
                  // value={selectProprtyMang}
                  onChange={onChangeSelectProprtyMang}
                >
                  {professionalUserProprtyMang.map((data, key) => (
                    <Option value={key}>
                      {data.fName} {data.mName} {data.lName}
                    </Option>
                  ))}
                </Select>
                <button
                  className="addnewproffbtn"
                  style={{marginTop:"-10px"}}
                  onClick={() => {
                    addprofessfuncclick("PropertyManager");
                  }}
                >
                  +
                </button>
              </div>
            </div>

            <div>
              <div className="select-Div d-flex">
                <Select
                  className="m-0 p-0 w-100 mb-1"
                  placeholder={"Select a Handyman"}
                  optionFilterProp="children"
                  style={{
                    // width: 250,
                    height: "40px",
                    border: "black",
                  }}
                  value={ selectHandymanServce == null || selectHandymanServce == undefined ?
                    professionalUserHandymanServce.length == 0 ? "Select a person" : (professionalUserHandymanServce[professionalUserHandymanServce.length-1]?.fName != null ? professionalUserHandymanServce[professionalUserHandymanServce.length-1]?.fName : "") +
                    " " + (professionalUserHandymanServce[professionalUserHandymanServce.length-1]?.mName != null ? professionalUserHandymanServce[professionalUserHandymanServce.length-1]?.mName : "") +""+
                   (professionalUserHandymanServce[professionalUserHandymanServce.length-1]?.lName != null ? professionalUserHandymanServce[professionalUserHandymanServce.length-1]?.lName : "")
                   
                   : (selectHandymanServce)
                  }
                  // value={selectHandymanServce}
                  onChange={onChangeSelectHandymanServce}
                >
                  {professionalUserHandymanServce.map((data, key) => (
                    <Option value={key}>
                      {data.fName} {data.mName} {data.lName}
                    </Option>
                  ))}
                </Select>
                <button
                  className="addnewproffbtn"
                  style={{marginTop:"-10px"}}
                  onClick={() => {
                    addprofessfuncclick("HandymanService");
                  }}
                >
                  +
                </button>
              </div>
            </div>
            <div className="Sava-Button-Div">
              <button className="Save-Button" onClick={postSubjectData}>
                Save
              </button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Newsetguidance;
