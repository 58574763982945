import { Divider, Menu } from 'antd'
import Card from 'antd/lib/card/Card'
import { Link, Navigate } from "react-router-dom";
import React, { useState,useEffect } from 'react'
import "../styles/Sidemenu.css";

import Setup from './Component/Setup'
import commonLib from '../control/commonLib';


function Sidemenu() {

  const [changeBorder, setChangeBorder] = useState("changeFont")

  const changeBorderColor = (index) => {
    setChangeBorder(index)

  }

    useEffect(() => {
    setChangeBorder(1)
  }, [])


  // const handleClick = () => {
  //       const stateObj = commonLib.getObjFromStorage('stateObj');
  //       let params = `appState=${stateObj.appState}&userId=${stateObj.userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggenInId}`;
  //       window.location.replace(`https://stgintakeform.azurewebsites.net/?token=` + window.btoa(params));
  //     }

      const handleClick = () => {
      const stateObj = commonLib.getObjFromStorage('stateObj');
      let params = `appState=${stateObj.appState}&userId=${stateObj.userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggenInId}`;
      window.location.replace(`https://stgintakeform.azurewebsites.net/?token=` + window.btoa(params));
    
      }
  return (
    <Menu className='sidersq'>
      <ul class="list-group list-group-flush">
      
        {/* onClick={handleClick} */}
        <li className='list-group-item' style={{ border: "none" }} onClick={handleClick}>
          <div onClick={() => changeBorderColor(2)}
            className={`${(changeBorder === 2) ? 'borderOnClick' : ''} sidebar`}>
            <Card className='DashBoardCardsq' style={{ borderRadius: "100px"  }} onClick={handleClick}>
              <div className='d-flex justify-content-between align-items-center'>
                <div className={`${(changeBorder === 2) ? 'buttonColor' : ''} changeFont`}>
             
                </div>
                {changeBorder === 2 ? <div>
                  <img src="/images/SettingsMaroon.svg" className='img-fluid img-thumbnail mt-1' style={{ width: "40px", height: "40px", border: "none" }}></img>

                </div> : <div>
                <img src="/images/Settings.svg" className='img-fluid img-thumbnail mt-1' style={{ width: "40px", height: "40px", border: "none" }}></img>

                </div>}

              </div>
            </Card>
            <h5 className='mt-2 text-center'>Setup</h5>
          </div>
        </li>

        
      </ul>
    </Menu>
  )
}


export default Sidemenu



