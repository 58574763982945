import React, { useContext, useEffect, useState } from "react";
import Navbar from "../Navbar";
import { Row, Col, Button, Checkbox, Card, Typography, Popconfirm } from "antd";
import "antd/dist/antd.css";
import "./familyinfo.css";
import Services from "../../network/Services";
import commonLib from "../../control/commonLib";
import konsole from "../../network/konsole";
import EditMemberDetails from "./common/editMemberDetails";
import AddNewMember from "./common/AddNewMember";
import { Link } from "react-router-dom";
import { UserContext } from "../../App";
import moment from "moment";
import { Toast } from "react-bootstrap";
import Toaster from "../Toaster";

const Familyinfo = () => {
  const [familymemberDetails, setfamiymemberDetails] = useState([]);
  const [spouseDetails, setSpouseDetails] = useState([]);
  const [childrenDetails, setChildrenDetails] = useState([]);
  const [colorFamily, setColorFamily] = useState("");

  const primaryUserId = commonLib.getObjFromStorage("userPrimaryInDetail").memberUserId;
  const [showEditpopup, setshowEditPopup] = useState(false);
  const [editProfieUserid, SetEditProfieUserid] = useState("");
  const [editProfieType, SetEditProfieType] = useState("");
  const [editProfieActionType, SetEditProfieActionType] = useState("ADD");
  const [changeBorder, setChangeBorder] = useState(1);
  const [familyinfo, setFamilyinfo] = useState(true);
  const { disable, modalOnDisable } = useContext(UserContext);
  const [marital, setMarital] = useState("");
  const { setLoader } = useContext(UserContext);
  // const [IsDeleteDescendant,setIsDeleteDescendant]= useState(false)
  const loggedUserDetail = commonLib.getObjFromStorage("userLoggedInDetail");

  const invokeEditprofileDetails = (userid, edittype, actionType) => {
    setshowEditPopup(!showEditpopup);
    SetEditProfieUserid(userid);
    SetEditProfieType(edittype);
    SetEditProfieActionType(actionType);
    setColorFamily("#6882fd");
    konsole.log("showDetails", edittype);
  };
  // const invokeAddprofileDetails = (userid, edittype, actionType) => {
  //     const editProfieUserid = userid;
  //     setshowEditPopup(!showEditpopup);
  //     SetEditProfieUserid(userid);
  //     SetEditProfieType(edittype)
  //     SetEditProfieActionType(actionType)
  //     konsole.log("show");
  // }

  useEffect(() => {
    callFamilyMemberApi();
  }, []);

  const callFamilyMemberApi = () => {
    setLoader(true);
    let Promise = Services.getfamilyMemberByParentId(primaryUserId);
    Promise.then((res) => {
      setLoader(false);
      konsole.log("abab", res.data.data);
      if (res.data.data[0].maritalStatusId == 1 || res.data.data[0].maritalStatusId == 2 && res.data.data[0].children.length !== 0) {
        let spouseDetails = res.data.data[0].children;
        setfamiymemberDetails(res.data.data);
        setSpouseDetails(res.data.data[0].children);
        konsole.log("setMarital", res.data.data[0].maritalStatusId);
        setMarital(res.data.data[0].maritalStatusId);
        setChildrenDetails(res.data.data[0].children[0].children);
        konsole.log("grandchild", res.data.data[0].children[0].children);
      } else if (res.data.data[0].children.length !== 0) {
        setfamiymemberDetails(res.data.data);
        setChildrenDetails(res.data.data[0].children);
        setMarital(res.data.data[0].maritalStatusId);
      }
    }).catch((error) => {
      setLoader(false);
      konsole.log("Error", error);
    });
  };

  const redirectToFamily = () => {
    callFamilyMemberApi();
    setshowEditPopup(!showEditpopup);
    SetEditProfieActionType("0");
    setColorFamily("black");
  };

  const familyInfo = (addFiduciries) => {
    // setshowaddfiduciries(true)
    // setficuciriespropstype(addFiduciries)
    setChangeBorder(2);
    setFamilyinfo(false);
  };

  const memberChildDelete = (item, type, relativeuserid) => {
    konsole.log("memberChildDelete", item)
    let { memberId, userId, isFiduciary, isBeneficiary, children, userRelationshipId } = item;

    let childrenobj = children.length > 0 && children[0]
    konsole.log("childrenobjchildrenobj", childrenobj)
    let grandchildobj = childrenobj?.children?.some(item => item?.isFiduciary == true || item?.isBeneficiary == true)

    konsole.log("grandchildobj", grandchildobj)
    konsole.log("childrenchildren", childrenobj.children)
    konsole.log('childrenchildrenchildrenchildren', children)

    let userIdd = userId
    let memberIdd = memberId
    let deletedBy = loggedUserDetail.loggedUserId
    let memberRelationshipId = userRelationshipId
    let IsDeleteDescendant = (type == 'child') ? true : false

    konsole.log('IsDeleteDescendant', IsDeleteDescendant)
    // if(children.length !==0){
    //   IsDeleteDescendant =  this.context.confirm(true, "Are you sure, you want to delete your child.", "Confirmation","CHILD_DELETE")
    // }else{
    //  let responseYesNO=  this.context.confirm(true, "Are you sure, you want to delete your child.", "Confirmation")
    //  konsole.log("responseYesNO",responseYesNO)
    //  if (responseYesNO == false) { return;}
    // }
    if (IsDeleteDescendant == false && (isFiduciary == true || isBeneficiary == true)) {
      Toaster.warning("Child can't be delete when child is fiduciary or beneficiary, please remove child from fiduciary or beneficiary.")
      return;
    } else if (IsDeleteDescendant == true && (isFiduciary == true || isBeneficiary == true || childrenobj?.isFiduciary == true || childrenobj?.isBeneficiary == true || grandchildobj == true)) {
      Toaster.warning("Child and his family can't be delete when his family is fiduciary or beneficiary, please remove them from has context menu fiduciary or beneficiary.")
      return;
    }
    let deletefamilymemberserviceparams = Services.deletefamilymemberservice(userIdd, memberIdd, deletedBy, memberRelationshipId, IsDeleteDescendant)
    deletefamilymemberserviceparams.then(async (res) => {
      konsole.log("deletememberchildres", res)

      let result = await Services.updateMemberChildByUserId(relativeuserid, !IsDeleteDescendant)
      konsole.log('resultresult', result)
      window.location.reload();
    }).catch((err) => {
      konsole.log("deletememberchilderr", err)
    }
    )

  }

  return (
    <div>
      <Row className="bg-white mt-2">
        <h6 className="ms-2">
          <Link to="/Dashboard" className="text-primery">
            Dashboard
          </Link>
          <span>{">"}</span>
          <span
            onClick={() => redirectToFamily()}
            style={{ cursor: "pointer", color: colorFamily }}
          >
            Family Info
          </span>{" "}
          {editProfieActionType == "EDIT" ? (
            <span>  {">"}  {editProfieType}</span>
          ) : ("")}
        </h6>
        <br />
        <br />
        <Col span={24} className="bg-white">
          {editProfieActionType == "EDIT" && showEditpopup ? (
            <EditMemberDetails userId={editProfieUserid} editProfieActionType={editProfieActionType} editProfieType={editProfieType} redirectToFamily={redirectToFamily} />
          ) : editProfieActionType == "ADD" && showEditpopup ? (
            <AddNewMember parentUserId={editProfieUserid} editProfieActionType={editProfieActionType} editProfieType={editProfieType} redirectToFamily={redirectToFamily} />
          ) : (
            <>
              <div className={`${changeBorder === 1 ? "borderOnClick" : ""} schedule  bg-white fw-bold ms-2 text-center`} style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                Family Info
              </div>

              {spouseDetails.length > 0 && (marital == 1 || marital == 2) && (
                <>
                  <Row>
                    <Col span={24}>
                      <h5 className="SpouceInfoTag-H5 ms-4 pt-2"> Spouse Info</h5>
                    </Col>
                  </Row>
                  <Row className="cardd d-flex justify-content-between ms-2">
                    <Col span={14} xs={14} sm={14} md={14} lg={14} xl={14} className="cardd1">
                      <Row>
                        <Col span={3} xs={14} sm={3} md={3} lg={3} xl={3} className="imagProfile">
                          <img src="/MainAvatar.svg" className="img-fluid " />
                        </Col>
                        <Col span={20} xs={24} sm={20} md={20} lg={20} xl={20} className="RowProfile ms-2">
                          <h4 className="SpouceNameTag-H4 mb-0">
                            {spouseDetails[0].fName.includes("-") ? spouseDetails[0].fName.split("-").slice(1) + " " + spouseDetails[0].lName : `${spouseDetails[0].fName}
                           ${spouseDetails[0].mName !== null ? " " + spouseDetails[0].mName + " " : " "}${spouseDetails[0].lName}`}
                          </h4>

                          <Row>
                            <Col span={20} xs={24} className="SpouceDetailCol">
                              <h6 className="SpouceTag-H6"> {" "} {spouseDetails[0].relationshipName}</h6>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={20} xs={24} className="SpouceDetailCol">
                              <h6 class="card-text SpouceTag-H6">
                                {commonLib.calculate_age(spouseDetails[0].dob) > 0 ? `${commonLib.calculate_age(spouseDetails[0].dob)} years` : `${" "}`}
                              </h6>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={10} className="cardd2">
                      <Row className="d-flex justify-content-end align-items-end">
                        <Col className="cursor-pointer">
                          <Typography.Link onClick={() => invokeEditprofileDetails(spouseDetails[0].userId, "Spouse", "EDIT")}>
                            <img src="/images/akar-icons_edit.png" className="me-1" />
                            <spna className="EditSpan ">Edit</spna>
                          </Typography.Link>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-end align-items-end  mt-1" style={{ marginRight: "13px" }}>
                        <Col>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={spouseDetails[0].isFiduciary} disabled={disable} />
                            <label class="form-check-label" className="checkBox" for="flexCheckDefault"> Fiduciary</label>
                          </div>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-end align-items-end">
                        <Col>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={spouseDetails[0].isBeneficiary} disabled={disable} />
                            <label class="form-check-label" className="checkBox" for="flexCheckDefault">  Beneficiary</label>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}


              <Row className="scrolling-wrapper ms-3 ps-3"  >
                <Row className="pt-1 w-100">
                  <Col span={24} className="mb-0 pb-0" style={{ height: "25px" }}>
                    <h5 className="SpouceInfoTag-H5" style={{ marginLeft: "-6px" }}>Children Info</h5>
                    <br />
                  </Col>
                </Row>
                {childrenDetails.length > 0 &&
                  childrenDetails.map((child, index) => {
                    console.log("Childchildchild", child?.dob);
                    let childrendob = child?.dob !== null ? moment(child?.dob)?.format("MM/DD/YYYY") : null;
                    let childdatechilddate = commonLib?.calculate_age(childrendob);
                    konsole.log("childrendob", childrendob, childdatechilddate);
                    let childrenSpouse = [];
                    if ((child.maritalStatusId == 1 || child.maritalStatusId == 2) && childdatechilddate >= 18) {
                      childrenSpouse = child.children;
                    }
                    let grandchild = childrenSpouse.length > 0 && childrenSpouse[0].children.length > 0 ? childrenSpouse[0].children : [];
                    if (child.maritalStatusId == 3 || child.maritalStatusId == 4 || child.maritalStatusId == 5) {
                      grandchild = child.children;
                    }

                    console.log("grand-C", child.children);
                    console.log("childdd", child, childrenSpouse);
                    return (
                      <Col span={24} className="d-flex align-items-center justify-contect-center vertical-scroll pb-1  border-bottomborder-bottom"
                        style={(grandchild?.length >= 1 || childrenSpouse?.length > 0 || commonLib.calculate_age(child.dob) >= 18) ? { overflowX: "scroll", overflowY: "hidden", borderLeft: "4px dotted black" } : { borderBottom: "2px solid #d9d9d9", borderLeft: "4px dotted black" }}
                      >
                        <div className="row ">
                          <div className="col d-flex mt-1 tree-div">
                            <div className="card chidrenMainCard pt-2  " style={{}}>
                              <Row className="w-100" >
                                <Col span={21} lg={21} className="d-flex justify-content-center align-items-center ">
                                  <img src="/MainAvatar.svg" alt="..." style={{ marginLeft: "32px" }} />
                                </Col>
                                <Col span={3} lg={3} className="d-flex justify-content-end" style={{ paddingRight: "10px" }}>
                                  <Popconfirm className="cursor-pointer" title="Are you sure, you want to delete your child."
                                    onConfirm={() => { memberChildDelete(child, 'child', primaryUserId) }}
                                    okText={grandchild?.length >= 1 || childrenSpouse?.length > 0 ? "Only Child" : "Yes"}
                                    cancelText={grandchild?.length >= 1 || childrenSpouse?.length > 0 ? "Child and Child's Family tree" : "No"}
                                    okType="primary"
                                    onCancel={() => grandchild?.length >= 1 || childrenSpouse?.length > 0 ? memberChildDelete(child, 'child', primaryUserId) : null}
                                  >
                                    <img src="./images/Deletebox.png" style={{ height: "22px", cursor: "pointer" }} />
                                  </Popconfirm>
                                </Col>
                              </Row>
                              <div class="card-body pb-0  pt-1">
                                <div className="NameDiv">
                                  <h6 className="SpouceNameTag-H4 card-title mb-0">
                                    {child?.fName?.includes("-") ? child?.fName.split("-").slice(1) + " " + child?.lName : `${child?.fName?.length > 15 ? child?.fName?.slice(0, 15) + "..." : child?.fName}
                                    ${child?.mName !== null ? " " + child?.mName + " " : " "}${child?.lName}`}
                                  </h6>
                                </div>
                                <div className="childRelationShipDiv ">
                                  <p class="card-text SpouceTag-H6 mb-0">{child.relationshipName}</p>
                                  <p class="card-text SpouceTag-H6">
                                    {(commonLib.isUnder14Years(child.dob) > 0 && child.dob != null) ? `${commonLib.isUnder14Years(child.dob)} years` : `${" "}`}
                                  </p>
                                </div>
                                <div className="pb-0 absolute-bottom">
                                  <div class="form-check ms-2">
                                    <input class="form-check-input " type="checkbox" value="" id="flexCheckDefault" checked={child.isFiduciary} />
                                    <label class="form-check-label" className="checkBox" for="flexCheckDefault"> Fiduciary</label>
                                  </div>

                                  <div class="form-check ms-2">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={child.isBeneficiary} />
                                    <label class="form-check-label" className="checkBox" for="flexCheckDefault"
                                    // disabled={disable}
                                    > Beneficiary</label>
                                  </div>
                                </div>
                              </div>
                              <div class="card-footer p-1 w-100 d-flex justify-content-center align-items-center cursor-pointer ">
                                <Typography.Link
                                  onClick={() => invokeEditprofileDetails(child.userId, "Children", "EDIT")} className=" d-flex justify-content-center align-items-center"
                                // disabled={disable}
                                >
                                  <img src="/images/akar-icons_edit.png" className="img-fluid " />
                                  <span className="EditSpan ">Edit</span>
                                </Typography.Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        {childrenSpouse.length > 0 &&
                          childrenSpouse.map((childrenSpouseprop) => (
                            <div className="">
                              <div className="row ">
                                <div className="col d-flex mt-2 tree-div">
                                  <div class="card chidrenMainCard pt-2">
                                    <img src="/MainAvatar.svg" class="card-img-top w-25 h-25 mt-1" alt="..." />
                                    <div class="card-body pb-0  pt-1">
                                      <div className="NameDiv">
                                        <h6 className="SpouceNameTag-H4 card-title mb-0">
                                          {childrenSpouseprop?.fName.includes("-") ? childrenSpouseprop?.fName.split("-").slice(1) + " " + childrenSpouseprop?.lName : `${childrenSpouseprop?.fName?.length > 15 ? childrenSpouseprop?.fName?.slice(0, 15) + "..." : childrenSpouseprop?.fName}
                                      ${childrenSpouseprop?.mName !== null ? " " + childrenSpouseprop?.mName + " " : " "}${childrenSpouseprop?.lName}`}
                                        </h6>
                                      </div>
                                      <div className="childRelationShipDiv">
                                        <p class="card-text SpouceTag-H6 mb-0">
                                          {childrenSpouseprop.relationshipName}
                                        </p>
                                        <p class="card-text SpouceTag-H6">
                                          {(commonLib.isUnder14Years(childrenSpouseprop.dob) && childrenSpouseprop.dob != null) > 0
                                            ? `${commonLib.isUnder14Years(childrenSpouseprop.dob)} years` : `${" "}`}
                                        </p>
                                      </div>
                                      <div className="absolute-bottom">
                                        <div class="form-check ms-2">
                                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={childrenSpouseprop.isFiduciary}
                                          // disabled={disable}
                                          />
                                          <label class="form-check-label" className="checkBox" for="flexCheckDefault">  Fiduciary</label>
                                        </div>

                                        <div class="form-check ms-2">
                                          <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={childrenSpouseprop.isBeneficiary}
                                          // disabled={disable}
                                          />
                                          <label class="form-check-label" className="checkBox" for="flexCheckDefault">Beneficiary</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card-footer p-1 w-100 d-flex justify-content-center align-items-center cursor-pointer">
                                      <Typography.Link
                                        onClick={() => invokeEditprofileDetails(childrenSpouseprop.userId, "In Law", "EDIT")}
                                        className=" d-flex justify-content-center align-items-center"
                                      // disabled={disable}
                                      >
                                        <img src="/images/akar-icons_edit.png" className="img-fluid " />
                                        <span className="EditSpan ">
                                          Edit
                                        </span>
                                      </Typography.Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                        {grandchild.length > 0 &&
                          grandchild.map((grand, index) => {
                            return (
                              <div>
                                <div className="row">
                                  <div className="col d-flex mt-2 tree-div">
                                    <div class="card grandchidrenMainCard pt-2 mb-0">
                                      <Row className="w-100" >
                                        <Col span={21} lg={21} className="d-flex justify-content-center align-items-center ">
                                          <img src="/MainAvatar.svg" alt="..." style={{ marginLeft: "32px" }} />
                                        </Col>
                                        <Col span={3} lg={3} className="d-flex justify-content-end" style={{ paddingRight: "10px" }}>
                                          <Popconfirm  className="cursor-pointer"  title="Are you sure, you want to delete your Grand child." 
                                           onConfirm={() => { memberChildDelete(grand, 'grandchild', child?.userId) }}  okText="Yes"  cancelText="No"  >
                                            <img src="./images/Deletebox.png" style={{ height: "22px", cursor: "pointer" }} />
                                          </Popconfirm>
                                        </Col>
                                      </Row>

                                      <div class="card-body pb-0  pt-1">
                                        <div className="NameDiv">
                                          <h6 className="SpouceNameTag-H4 card-title mb-0">
                                            {grand?.fName?.includes("-") ? grand?.fName?.split("-")?.slice(1) + " " + grand?.lName : `${grand?.fName?.length > 15 ? grand?.fName?.slice(0, 15) + "..." : grand?.fName}
                                       ${grand.mName !== null  ? " " + grand.mName + " "  : " "}${grand.lName}`}
                                          </h6>

                                        </div>
                                        <div className="childRelationShipDiv">
                                          <p class="card-text SpouceTag-H6 mb-0">
                                            {grand.relationshipName}
                                          </p>
                                          <p class="card-text SpouceTag-H6">
                                            {(commonLib.isUnder14Years(grand.dob) > 0 && grand?.dob != null)? `${commonLib.isUnder14Years(  grand.dob)} years`: `${" "}`}</p>
                                        </div>
                                        <div className="absolute-bottom">
                                          <div class="form-check mb-0 ms-3">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={grand.isFiduciary}
                                            // disabled={disable}
                                            />
                                            <label class="form-check-label" className="checkBox" for="flexCheckDefault">  Fiduciary</label>
                                          </div>

                                          <div class="form-check ms-3">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={grand.isBeneficiary}
                                            // disabled={disable}
                                            />
                                            <label class="form-check-label" className="checkBox" for="flexCheckDefault">    Beneficiary  </label>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="card-footer p-1 w-100 d-flex justify-content-center align-items-center cursor-pointer">
                                        <Typography.Link
                                          onClick={() =>invokeEditprofileDetails(grand.userId,"Grand Children","EDIT")}
                                          className=" d-flex justify-content-center align-items-center"
                                        // disabled={disable}
                                        >
                                        <img src="/images/akar-icons_edit.png" className="img-fluid "/>
                                      <span className="EditSpan "> Edit  </span>
                                        </Typography.Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        {
                          // child.maritalStatusId !== null &&
                          //   child.maritalStatusId !== 3 &&
                          child.dob != null &&
                          child.dob != undefined &&
                          commonLib.calculate_age(child.dob) >= 18 && (
                            <Row className=" justify-content-center align-items-center">
                              <div className="d-flex tree-div">
                                <Col className="grandchild cursor-pointer ">
                                  <div className="pt-3 ">
                                    <Row>
                                      <Col span={24} xs={24} xl={24} className="d-flex justify-content-center align-items-center" >
                                        <h5  className="SpouceNameTag-H4 mb-0" style={{ textAlign: "center" }} >
                                         Add information about {child.fName}{" "} 's Child
                                        </h5>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={24} xs={24} className="d-flex justify-content-center align-items-cente">
                                        <Typography.Link
                                          onClick={() =>invokeEditprofileDetails(child.userId,"Grand Children","ADD")  }
                                        // disabled={disable}
                                        >
                                          <img src="/images/addPlusIcon.svg" className="img-fluid"/>
                                        </Typography.Link>
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </div>
                            </Row>
                          )
                        }
                      </Col>
                    );
                  })}
                {
                  // familymemberDetails.length > 0 &&
                  //   familymemberDetails[0].maritalStatusId !== 3 &&
                  <Row className="d-flex">
                    <Col className="carddChild2 cursor-pointer mt-2 mb-3  ">
                      <div className="pt-3 ">
                        <Row>
                          <Col  span={24}  xs={24}  xl={24}  className="d-flex justify-content-center align-items-center">
                            <h5 className="SpouceNameTag-H4">Add Child</h5>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24} xs={24} className="d-flex justify-content-center align-items-center" >
                            <Typography.Link
                              onClick={() =>invokeEditprofileDetails( primaryUserId, "Children", "ADD")}
                            // disabled={disable}
                            >
                              <img src="/images/addPlusIcon.svg" className="img-fluid"/>
                            </Typography.Link>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                }
              </Row>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Familyinfo;
