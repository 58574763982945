import { Col, Form, Input, Row } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { useState } from "react";
import { mapApi } from "../control/Constant";
import konsole from "../network/konsole";
import Services from "../network/Services";
import Toaster from "./Toaster"


const Address = (props) => {
  const [address, updateAddress] = useState({});
  const buttonRef = useRef(null);
  const addressRef = useRef(null);
  const [addressfiledErr, setAddressfieldErr] = useState(false);


  konsole.log(addressRef.current,props,'addressRef')
  useEffect(() => {
    if (
      props.address !== null &&
      props.address !== undefined &&
      props.address !== ""
    ) {
      addressRef.current.value = props.address;
    }
    // props.form.setFieldsValue({
    //   streetAddress : addressRef?.current?.value.substring( 0, addressRef?.current?.value.indexOf(","))
    // })
  }, [props.address]);

  useEffect(() => {
    Services.initMapScript().then(() => initAutoComplete());
  }, []);
  console.log("samog8r87478",props.addressfiledError)
  const extractAddress = (place) => {
    konsole.log("place11",place)
    const address = {
      address: "",
      //  streetAddress:"",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      county: "",
      suite:"",
      plain() {
        const address= this.address? this.address+",":"";
        const city = this.city ? this.city + ", " : "";
        const zip = this.zip ? this.zip + ", " : "";
        const state = this.state ? this.state + ", " : "";
        const county = this.county ? this.county + "," : "";
        return  address + city + zip + state + county + this.country;
      },
    };
    
    if (!Array.isArray(place?.address_components)) {
      return address;
    }
    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;
      // console.log("address compoenen",address, component);
      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.zipcode = value;
      }

      if (types.includes("country")) {
        address.country = value;
      }
      if (types.includes("suite")) {
        address.suite = value;
      }
      if (types.includes("administrative_area_level_2")) {
        address.county = value;
      }
      // if (types.includes("route")) {
      //   address.streetAddress = value;
      // }
    });
    // konsole.log(addressRef?.current?.value.substring( 0, addressRef?.current?.value.indexOf(",")),"hyudjd")
    props.form.setFieldsValue({
      streetAddress : addressRef?.current?.value.substring( 0, addressRef?.current?.value.indexOf(",")),
    })

    return address;
  };

  const onChangeAddress = (autocomplete) => {
    setAddressfieldErr(false);
    const place = autocomplete.getPlace();
    konsole.log(place,"placespalce")
    setAddress(extractAddress(place));
  };

  const setAddress = (address) => {
    address.address = addressRef.current.value;
    konsole.log(addressRef.current.value,"addressaddress")

    updateAddress({
      city: address.city,
      state: address.state,
      zipcode: address.zipcode,
      country: address.country,
      county: address.county,
      suite:address.suite ,
      streetAddress:address.streetAddress,
    });
    // console.log("asp", address);
    props.form.setFieldsValue(address);
    if (
      address.address == "" ||
      address.streetAddress == "" ||
      address.city == "" ||
      address.state == "" ||
      address.zipcode == "" ||
      address.country == "" 
    ) {
      setAddressfieldErr(true);
      // Toaster.error("Please enter correct address")
    }
    
  };
  props.form.setFieldsValue({
    streetAddress : addressRef?.current?.value.substring( 0, addressRef?.current?.value.indexOf(","))
  })
  const initAutoComplete = () => {
    if (!addressRef.current) return;
    const autocomplete = new window.google.maps.places.Autocomplete(
      addressRef.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  const changeDaadressData = (e) => {
    props?.setAddressfieldError(false)
    
    // konsole.log( e.target.value,"addresssio")
    addressRef.current.value = e.target.value;
    props.form.setFieldsValue({
      address: e.target.value,
    });
    
  };

  konsole.log("addresscomponent", address);
  konsole.log("khkhlklhl",addressfiledErr)
  return (
    <div>
      <Row className="w-100" gutter={[8, 8]}>
        <Col xs={24} lg={24}>
          <Form.Item
            name="address"
            // rules={[
            //   {
            //     required:false,
            //     // message: 'Please Enter address',
            //   },
            //   {
            //     required: true,
            //     message: 'Please Enter address',
            //   },
            // ]}
          >
            <Input.Group>
              <input
                placeholder="Address"
                className={props.hidebtn ? " ant-input" : "form-control"}
                style={props.hidebtn ? {marginLeft:"4px"}:null}
                ref={addressRef}
                onChange={changeDaadressData}
                defaultValue={props.form.getFieldsValue().address}
              />
            </Input.Group>
            { props.addressfiledError ? <h6 style={{color:"red"}}>Please enter correct address</h6> : null}
            {(props.hidebtn && addressfiledErr) ? <h6 style={{color:"red"}}>Please enter correct address</h6> : null}
          </Form.Item>
        </Col>

        {props.hidebtn ? null : (
          <>
            {" "}
            <Col xs={24} lg={8}>
              <Form.Item name="suite">
                <Input placeholder="Suite"  />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="county">
                <Input placeholder="County"  />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="streetAddress">
                <Input placeholder="Street Address"  />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="city">
                <Input placeholder="City"  />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="state">
                <Input placeholder="State"  />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="country">
                <Input placeholder="Country"  />
              </Form.Item>
            </Col>
            <Col xs={24} lg={8}>
              <Form.Item name="zipcode">
                <Input placeholder="Zip Code"  />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};

export default Address;