import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../App';
import commonLib from '../../control/commonLib';
import konsole from '../../network/konsole';
import Services from '../../network/Services';
import Container from '../Container';


export default function RequireAuth({ children }) {
    const AuthToken = commonLib.getSessionStoarge("AuthToken");
    const authed = (AuthToken !== '' && AuthToken !== null)? true : false;
    const { setLoader } = useContext(UserContext)
    const [ validUser, setValidUser ] = useState(false);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            const stateObj = commonLib.getObjFromStorage("stateObj");
            const promises = Services.getLoggedInUser(stateObj?.userId, stateObj?.appState, stateObj?.loggenInId, stateObj?.roleId)
            // setLoader(true);
            promises.then(response => {
                if (response) {
                    // setLoader(false);
                    setValidUser(true);
                }
            }).catch((error)=> {
                setLoader(false);
                commonLib.unAuthurizeAccess();
            })
            .finally(()=>{
                setLoader(false);
            })
        }
        return () => {
            isMounted = false; 
        }
    }, [])




    konsole.log("authed", authed, AuthToken) ;
    return (authed === true) ? 
        <Container>
            {children}
        </Container>
     : 
        <>
        </>
}