import ReactDOM from "react-dom/client";
import Container from './components/Container';

// import ContainerDashbord from './components/ContainerDashbord';
import Housing from "./components/LpoProfessional/Housing";
import ManageFiduciaries from "./components/Fiduciaries/ManageFiduciaries";
import Beneficiaries from "./components/Beneficiaries/Beneficiaries"
import Delete from "./components/Fiduciaries/Delete";
import Sucessfully from "./components/Fiduciaries/FiduciarySucessfully";
import AssignOption from "./components/AssignOptions/AssignOption";
import AddBenrficiaries from "./components/Fiduciaries/AddFidBeneficiaries";
import './styles/App.css'
import RolePopup from "./components/AssignOptions/RolePopup";
import DeleteAgent from "./components/AssignOptions/DeleteAgent";
import Subtenants from "./components/FirmPartner/Subtenants";
import SetupBorad from "./components/Component/Setup";
import FamilyInfo from './components/MemberInfo/Familyinfo';
import PrivateRoute from './components/Routes/PrivateRoute'
import Filecabinet from "./components/Filecabinet/Filecabinet";
import "antd/dist/antd.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import commonLib from './control/commonLib';
import SetupGuidance from './components/SetupGuidance/Setupguaidance'
import Agents from './components/MyAgents/Agents'
import React, { useEffect, useState, useContext } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './styles/Lpo-lite.css';
import LoginPage from "./pages/Login";
import MemberInfo from "./components/MemberInfo/MemberInfo";
import Navbar from "./components/Navbar";
import konsole from "./network/konsole";
import { Modal } from 'antd'
// import Myprofessionalteams from "./components/Component/MyProfessonialTeam/MyProfessionalteam";
import Myprofessonialmodal from "./components/Component/MyProfessionalModal/Myprofessonialmodal";
import Dashboard from "./components/NewlpoDashboard/Dashboard";
import Health from "./components/LpoProfessional/AgentTeaCard";
import Other from "./components/LpoProfessional/Other";
import Legal from "./components/LpoProfessional/Legal";
import Finance from "./components/LpoProfessional/Finance";
import Modalfilecabinet from "./components/NewModalFileCabinet/Modalfilecabine";
import Occupation from "./components/MemberInfo/common/MemberInfoOccupation";


export const UserContext = React.createContext({
  disable: false,
  loader: false,
});



function App() {
  const [disable, setdisable] = useState(false);
  const [isDisableModalVisible, setIsDIsableModalVisible] = useState(false);
  const [getSavebtn, setGetSaveBtn] = useState(false)
  const [sendSavebtn, setSendSaveBtn] = useState("")
  konsole.log("getSavebtn", getSavebtn)
  const disabled = commonLib.getSessionStoarge("disabled");
  const [loader, setLoader] = useState(false)

  const showModal = () => {
    setIsDIsableModalVisible(true);
  };

  const closeModal = () => {

    setGetSaveBtn(true)

    setIsDIsableModalVisible(false);
  };

  if(isDisableModalVisible){
    
    setGetSaveBtn(true)

    setIsDIsableModalVisible(false);
  }


  useEffect(() => {
    if (disabled == 'true') {
      setdisable(true)
    }
  }, [disable])

  const modalOnDisable = () => {
    if (disable === true) {
      setIsDIsableModalVisible(!isDisableModalVisible);
    }
  }

  return (
    <>

      <UserContext.Provider value={{ disable: disable, setdisable: setdisable, modalOnDisable: modalOnDisable, setGetSaveBtn: setGetSaveBtn, getSavebtn: getSavebtn, loader: loader, setLoader: setLoader }}>
        <div className="overlay  overlay position-relative">
          {loader &&
            <div className="d-flex align-items-center justify-content-center position-absolute overLayLoader">
              <div className="spinner-border" role="status" aria-hidden="true"></div>
            </div>
          }

          <BrowserRouter>

            <Routes>
              <Route path="/" exact element={<LoginPage />} />
              {/* <Route path="/SetupBorad" exact element={ */}
              <Route path="/Dashboard" exact element={
                <PrivateRoute>
                  <Dashboard/>
                </PrivateRoute>
              } />
              <Route path="/MemberInfo" element={
                <PrivateRoute>
                  <MemberInfo />
                </PrivateRoute>
              } exact />
              <Route path="/Fiduciaries" exact element={
                <PrivateRoute>
                  <ManageFiduciaries />
                </PrivateRoute>
              } />
              <Route path="/beneficiaries" exact element={
                <PrivateRoute>
                  <Beneficiaries />
                </PrivateRoute>
              } />
              <Route path="/Familyinfo" exact element={
                <PrivateRoute>
                  <FamilyInfo />
                </PrivateRoute>
              } />
              <Route path="/AssignOption" element={
                <PrivateRoute>
                  <Agents />
                </PrivateRoute>
              } exact />
              <Route path="/filecabinet" exact element={
                <PrivateRoute>
                {/* <Filecabinet /> */}
                <Modalfilecabinet/>
              </PrivateRoute>
              } />
              <Route path="/setupGuidance" exact element={
                <PrivateRoute>
                  <SetupGuidance />
                </PrivateRoute>
              } />

              <Route path="/myprofessoinal" exact element={
                <PrivateRoute>
                  <Myprofessonialmodal />
                </PrivateRoute>
              } />
              <Route path="/Health" exact element={

                <Health />

              } />
              <Route path="/Housing" exact element={

                <Housing />

              } />
              <Route path="/Finance" exact element={

                <Finance />

              } />
              <Route path="/Legal" exact element={

                <Legal />

              } />
              <Route path="/Other" exact element={

                <Other />

              } />

            </Routes>
          </BrowserRouter>
        </div>
      </UserContext.Provider>

      {/* <Modal className="modalStyle " style={{ position: "top" }}
        visible={isDisableModalVisible}
        maskClosable={false}
        footer={false}
        closable={true}
        destroyOnClose={true}
        onCancel={modalOnDisable}
        centered width={750}
      >
        <div className='justify-contant-center' >
          <h4 className='fs-5 text-center'>Changes made here are not reflected in your legal document. To make changes in your legal document contact your paralegal</h4>
          <p className='fs-5 mt-2 text-center'>Clicking the email button below will trigger an email to your paralegal with all changes made for furthur processing.</p>
          <div className=' d-flex justify-content-center align-items-center'>
            <button style={{ backgroundColor: "#800000", color: "white", width: "80px", height: "30px",  borderRadius: "5px", border: "none" }} onClick={closeModal} >
            Email</button>
            </div>
        </div>
      </Modal> */}
    </>
  );
}

export default App;
