import React, { useState, useContext, useEffect } from "react";
import { Table } from "antd";
import "antd/dist/antd.css";
import konsole from "../../network/konsole";
import { UserContext } from "../../App";
import commonLib from "../../control/commonLib";

export default function AgentsTableLegal({ dataSource,Rendermember }) {
  const { setLoader } = useContext(UserContext);
  const[tabledata,setTabledata] = useState(dataSource)
  const [paginationInfo, setPaginationInfo] = useState({
    current: 1,
    pageSize: 10,
  });

  const userId = commonLib.getObjFromStorage(
    "userPrimaryInDetail"
  )?.memberUserId;

  // useEffect(()=>{
  //   if(Rendermember == "Spouse"){
  //     let filterdata = dataSource.filter((item)=>{
  //      return item.relationWithMember != "Spouse"
  //     })
  //     setTabledata(filterdata)
  //     konsole.log(filterdata,"filterdata")
  //   }else{
  //     setTabledata(dataSource)
  //   }
  // },[dataSource])



  useEffect(() => {
    if (Rendermember == "Spouse") {
      let filterdatas = dataSource?.filter((e) => {
        return e.relationWithMember != "Spouse";
      }).map(d => (d.agentUserId === userId)? {...d, relationWithSpouse: "Spouse"} : d );

      setTabledata(
        filterdatas.sort((a, b) => a.fullName.localeCompare(b.fullName))
      );
      konsole.log("fileteedd", filterdatas, userId);
    } else {
      setTabledata(
        dataSource.sort((a, b) => a.fullName.localeCompare(b.fullName))
        );
      }
  }, [dataSource]);


  konsole.log("dataSource", dataSource);
  const columns = [
    {
      title: "Document Type & Role",
      dataIndex: "legalDocName",
      key: "legalDocName",
      className: "manage",
      render: (value, row, index) => {
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);
        const obj = {
          children: value,
          props: {},
        };
        if (index >= 1 && value === tabledata[trueIndex - 1].legalDocName) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== tabledata.length &&
            value === tabledata[trueIndex + i].legalDocName;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }
        }

        return obj;
      },
    },

    {
      title: "Order",
      render: (Order) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{Order}</span>
        </div>
      ),
      dataIndex: "agentRank",
      key: "agentRank",
      className: "manage",
    },
    {
      title: "Agent",
      render: (theImageURL) => <span>{theImageURL}</span>,
      render: (Order) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{Order}</span>
        </div>
      ),

      dataIndex: "fullName",
      key: "fullName",
      className: "manage",
    },

    {
      title: "Relation",
      render: (relationWithSpouse, data) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{ Rendermember == "Spouse" &&
        ( data.relationWithSpouse != null ||
          data.relationWithSpouse != undefined)
          ? data.relationWithSpouse
          : data.relationWithMember}</span>
        </div>
      ),
      dataIndex: "relationWithMember",
      key: "Relation",
      className: "manage ",
    },

    {
      title: "Status",
      render: (Status) => (
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        >
          {Status == "Accepted" ? (
            <span className="statusbtn" style={{ color: "#117800" }}>
              {Status}
            </span>
          ) : Status == "Pending" ? (
            <span className="statusbtn" style={{ color: "#DD7819" }}>
              {Status}
            </span>
          ) : Status == "Declined" ? (
            <span className="statusbtn" style={{ color: "#d2222d" }}>
              {Status}
            </span>
          ) : (
            <span className="statusbtn" style={{ color: "#DD7819" }}>
              Pending
            </span>
          )}
        </div>
      ),
      dataIndex: "statusName",
      key: "statusName",
      className: "manage ",
    },
  ];

  const handleChange = (pagination) => {
    setPaginationInfo(pagination);
  };
  return (
    <div className="App">
      <Table
        onChange={handleChange}
        dataSource={tabledata}
        columns={columns}
        bordered
        pagination={false}
      />
    </div>
  );
}
