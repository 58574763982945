
import { formatCountdown } from 'antd/lib/statistic/utils';
import React from 'react'
import konsole from '../network/konsole';
import Services from '../network/Services';

class Other extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            userId: this.props.userId,
            othersName: "",
            othersCategoryId: this.props.othersCategoryId,
            othersId: "",
            loggedInUser: sessionStorage.getItem("stateObj").userId || '',
        }
    }


    componentDidMount() {
        if(this.props.natureId !== undefined  && this.props.natureId !== null){
            this.getOtherFrmAPI(this.props.natureId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.natureId !== this.props.natureId) {
            this.getOtherFrmAPI(this.props.natureId);
        }
    }

    getOtherFrmAPI = (natureId) => {
        let othersCategoryId = this.props.othersCategoryId;
        let getData = [{
            userId: this.props.userId,
            isActive: true,
            othersMapNatureId: natureId,
            othersMapNature: ""
        }]


        const getOtherFromAPIResponse = Services.getOtherFromAPI(getData);

        getOtherFromAPIResponse.then((response) => {
            if (response) {
                console.log("response other", response);
                let res = response.data.data;
                let otherObj = res.filter(otherRes => { return otherRes.othersCategoryId == othersCategoryId });
                this.setState({
                    othersName: otherObj.length > 0 ? otherObj[0].othersName : "",
                    othersId: otherObj.length > 0 ? otherObj[0].othersId : "",
                });
                // form.setFieldsValue("Ot")
            }
        }).catch(error => {
            console.log("error", error);
        }).finally(() => console.log("finish"))
    }




    handleOthers = (event) => {
        let getName = event.target.name;
        let getValue = event.target.value;

        this.setState({
            [getName]: getValue
        })
        this.props.form.setFieldsValue({Other: getValue})
    }

    saveHandleOther = (uniqueId) => {
        let disdata = [];
        if (this.state.othersId == "") {
            disdata = [{
                othersCategoryId: this.props.othersCategoryId,
                othersName: this.state.othersName,
                createdBy: this.state.loggedInUser,
                isActive: true,
            }]
            this.apiForOther("POST", uniqueId, disdata);
        }
        else if (this.state.othersId !== "") {
            disdata = [{
                othersCategoryId: this.props.othersCategoryId,
                othersName: this.state.othersName,
                othersId: this.state.othersId,
                updatedBy: this.state.loggedInUser,
                isActive: true,
            }]
            this.apiForOther("PUT", uniqueId, disdata);
        }
    }

    apiForOther = (method, uniqueId, totArray) => {
        if (totArray.length > 0) {

            const postAddOtherResponse = Services.postAddOther(method, totArray);

            postAddOtherResponse.then((response)=> {
                console.log("response other post", response);
                let responseData = response.data.data;
                if (method == "POST") {
                    this.mapOtherToForm(uniqueId, responseData);
                }
            }).catch(error => {
                console.log("error",error);
            }).finally(()=> console.log("finish"))

        }
    }

    mapOtherToForm = (uniqueId, veteranMapToOther) => {
        if (this.props.dropValue == "999999") {
            let disObjId = this.mapOtherToObj(uniqueId, veteranMapToOther);

            const postAddOtherResponse = Services.postMapOther("POST", disObjId);

            postAddOtherResponse.then((response) => {
                if (response) {
                    console.log("response other post", response);
                    konsole.log("done");
                }
            }).catch(error => {
                console.log("error", error);
            }).finally(() => console.log("finish"))
        }
    }

    
    mapOtherToObj = (objId, objOther) => {
        let totArray = [{
            userId: this.state.userId,
            othersCategoryId: objOther[0].othersCategoryId,
            othersId: objOther[0].othersId,
            othersMapNatureId: objId,
            othersMapNatureType: "",
            isActive: true,
            createdBy: objOther[0].createdBy,
            remarks: ""
        }]

        return totArray;
    }

    render() {
        return (
            <>
                {(this.props.dropValue == "999999")
                    &&
                    <input type='text' className='w-100 border border-gray py-1 rounded px-2' name="othersName" onChange={(event) => this.handleOthers(event)} value={this.state.othersName} placeholder=" Other Description" />
                }
            </>
        )
    }
}

export default Other;