import React, { useEffect, useState } from "react";

import { Button, Modal, Table, Form, Tab, Row, Col } from "react-bootstrap";
import { $AHelper } from "./AHelper";
import konsole from "../../network/konsole";
import Services from "../../network/Services";
import './Filecabinet.css'
import commonLib from "../../control/commonLib";

const Remarks = (props) => {
  const primaryUser = JSON.parse(sessionStorage.getItem("userPrimaryInDetail")) || "";
  const primaryUserId = JSON.parse(sessionStorage.getItem("userPrimaryInDetail")).memberUserId || " ";
  const loggedInUser = sessionStorage.getItem("loggedUserId") || "";
  const subtenantId = sessionStorage.getItem("subtenantId")
  const [remarks, setRemarks] = useState("");
  const [remarksList, setRemarksList] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedRemark, setSelectedRemarks] = useState({});
  const [filename,setFilename] = useState("")
  const [fileCabinetName,setFileCabinetName] = useState("")
  const [paralegalEmail,setParalegalemail] = useState([])

  useEffect(() => {
    getFileCabinetRemarks();
    getUserListByRoleIdfunc()
  }, []);

  konsole.log(props,"props")
  const getUserListByRoleIdfunc=()=>{
    let dataobj = {
      "subtenantId": subtenantId,
      "isActive": true,
      "roleId": 3
    }
    let getUserListByRoleIdparams = Services.getUserListByRoleIdpathservice(dataobj)
    getUserListByRoleIdparams.then((res)=>{
      konsole.log(res,"resparalegal")
      let paralegalids = res?.data?.data?.map((e)=>{return e.primaryEmail})
      konsole.log(paralegalids,"paralegalids")
      setParalegalemail(paralegalids)
    }).catch((err)=>{
      konsole.log(err,"errparalegal")
    })
  }

  const getFileCabinetRemarks = () => {
    const postData = {
      userId: primaryUserId,
      fileCategoryId: props?.fileCabinetId,
      fileTypeId: props?.fileTypeId,
      fileId: props?.fileId,
      remarks: remarks,
      createdBy: loggedInUser,
    };
  
    let promise = Services.getFileCabinetRemarksp(postData);
    promise
      .then((response) => {
        konsole.log("responsesaaaabbb", response);
        if (response) {
          let res = response.data.data;
          if (res.fileCabinets.length > 0) {
            let fileCabinets = res.fileCabinets[0].fileTypes[0].fileRemarks;
            setRemarksList(fileCabinets);
            let filename = res.fileCabinets[0].fileTypes[0].fileTypeName;
            let filecabinetname = res.fileCabinets[0].fileCabinetName;
            setFileCabinetName(filecabinetname)
            setFilename(filename)
          }
        }
      })
      .then((err) => {
        konsole.log("err", err);
      });
  };

  const handleChange = (e) => {
    let value = e.target.value;
    setRemarks(value);
  };

  const handleRemarksSubmit = () => {
    if (remarks == "") {
      alert("Remarks cannot be empty.");
      return;
    }
    const postData = {
      userId: primaryUserId,
      fileCategoryId: props?.fileCategoryId,
      fileTypeId: props?.fileTypeId,
      fileId: props?.fileId,
      remarks: remarks,
    };
    let method = "POST";
    if (isUpdate == false) {
      postData["createdBy"] = loggedInUser;
    } else {
      method = "PUT";
      postData["updatedBy"] = loggedInUser;
      postData["fileRemarkId"] = selectedRemark.fileRemarkId;
    }


    let promises = Services.postFileCabinetRemarksPath(method, postData);
    promises
      .then((response) => {
        console.log("responseaaa", response);
        if (response) {
          getFileCabinetRemarks();
          konsole.log("res at post file cabinet remarks", response.data.data);
          props.handleClose();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
      // let emailto = subtenantId == 742 ? 'TechSupport@agingoptions.com' : 'Nitinkumar@agingoptions.com';
      let emailContent = ` <table>
      <tr><h2>Notification to paralegal regarding client comments on document</h2></tr>
      <tr> <b> Client : </b> @@CLIENT_NAME <br></tr>
      <tr> <b> Email address of the client : </b> @@CLIENT_EMAIL <br></tr>
      <tr> <b>Application url : </b> @@APPLICATION_URL<br></tr>
      <tr><b>Document Name : </b> @@Document_Name<br/></tr> 
      <tr><b>Comment : </b> @@Camment</tr> 
      </table>`
  
      let urllist = new URL(window.location.href) 
      let urlsend = " File Cabinet" + " > " + props?.fileCategoryType +" > "+ props?.breadcrumbdata[0] + " > " + props?.breadcrumbdata[1] + " > " + props?.doctypeName?.fileTypeName;
      let paralegalmails = paralegalEmail.join(",")
      emailContent = emailContent.replace('@@CLIENT_NAME', primaryUser?.memberName);
      emailContent = emailContent.replace('@@CLIENT_EMAIL', primaryUser?.primaryEmailId);
      emailContent = emailContent.replace('@@Camment', remarks);
      emailContent = emailContent.replace('@@APPLICATION_URL', urlsend);
      emailContent = emailContent.replace('@@Document_Name', props?.doctypeName?.fileTypeName);
    
      let dataobj ={
        "emailType": "Notification to paralegal regarding client comments on document",
        "emailTo":paralegalmails,
        "emailSubject": "Notification to paralegal regarding client comments on document",
        "emailContent": emailContent,
        "emailTemplateId": 1,
        "emailStatusId": 1,
        "emailMappingTable": "tblMember",
        "emailMappingTablePKId": primaryUserId,
        "createdBy": primaryUserId
      }

      let remarkpromise = Services.sendremarktoparalegalservice(dataobj)
      remarkpromise.then((response)=>{
        konsole.log(response,"responseparalegal")
      })
      .catch((error)=>{
        konsole.log(error,"errorparalegal")
      })
  };

  const handleRemarksForUpdate = (SelectedRemarkObj) => {
    setRemarks(SelectedRemarkObj.fileRemark);
    setSelectedRemarks(SelectedRemarkObj);
    setIsUpdate(true);

    konsole.log("selected Remarks obj", SelectedRemarkObj);
  };
  const tableMap =
    remarksList.length > 0 &&
    remarksList.map((mapRemark, index) => {
//  console.log("date",$AHelper.getDateFromString(mapRemark.createdOn))
//  console.log("date11",commonLib.getFormattedDate(mapRemark.createdOn))


      return (
        <tr
          className="cursor-pointer"
          key={index}
          onClick={() => handleRemarksForUpdate(mapRemark)}
        >
          <td>{index + 1}</td>
          <td
            className="text-start text-truncate w-75"
            style={{ maxWidth: "400px" }}
          >
            {mapRemark.fileRemark}
          </td>
          <td>{commonLib.getFormattedDate(mapRemark.createdOn)}</td>
        </tr>
      );
    });

  return (
    <>
      <style jsx global>{`
        .modal-open .modal-backdrop.show {
          opacity: 0.5 !important;
        }
      `}</style>

      <Modal
        show={props.show}
        size="lg"
        centered
        onHide={props.handleClose}
        animation="false"
        id="remarksId"
        backdrop="static"
      >
        <Modal.Header closeButton closeVariant="black">
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3 pt-4">
          <div className="m-2">
            <h3>Describe</h3>
            <Form.Control
              className=""
              as="textarea"
              rows={3}
              value={remarks}
              onChange={(e) => handleChange(e)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button className="theme-btn" onClick={handleRemarksSubmit}>
            {!isUpdate ? "Submit" : "Update"}
          </Button>
          <Button className="theme-btn" onClick={props.handleClose}>
            Cancel
          </Button>
          {remarksList.length > 0 && (
            <Table striped bordered className="text-center mt-4" size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th className="text-start w-75">Comments</th>
                  <th className="">Date</th>
                </tr>
              </thead>
              <tbody>{tableMap}</tbody>
            </Table>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Remarks;
