import React, { useState, useEffect, useContext } from "react";
import { message, Modal, Table, Popconfirm } from "antd";
import { Link } from "react-router-dom";
// import "antd/dist/antd.css";
import konsole from "../../network/konsole";
import { AlignType } from "rc-table/lib/interface";
import Services from "../../network/Services";
import commonLib from "../../control/commonLib";
import "./Agent.css";
import Toaster from "../Toaster";
import { UserContext } from "../../App";
import "./AgentTable.css";
import { getConfirmLocale } from "antd/lib/modal/locale";

export default function AgentsTable({ dataSource, Rendermember }) {
  konsole.log("dataSourcedataSource",dataSource,Rendermember)
  const { setLoader } = useContext(UserContext);
  const [paginationInfo, setPaginationInfo] = useState({ current: 1, pageSize: 10,});
  const loggedUser = commonLib.getObjFromStorage("userLoggedInDetail");
  const userId = commonLib.getObjFromStorage( "userPrimaryInDetail")?.memberUserId;
  const subtenantId = JSON.parse(sessionStorage.subtenantId);
  const [commmediumdata, setcommmediumdata] = useState("");
  const [emailtemp, setemailtemp] = useState("");
  const [emailtempLocal, setemailtemplocal] = useState("");
  const [emailtempdata, setemailtempdata] = useState("");
  const [userData, setUserData] = useState();
  const [showModal, closeModal] = useState(false);
  const [texttempLocal, settexttempLocal] = useState("");
  const [texttemp, settexttemp] = useState();
  const [texttempdata, settexttempdata] = useState();
  const [filterdata, setFilterdata] = useState(dataSource);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [data, setdata] = useState(null);
  const [disabledButton, setdisabledButton] = useState(false);

  const [showwarningpoput, setshowwarningLagecyModal] = useState(false);
  const [agentName, setAgentName] = useState("");
  // konsole.log("hsfuyehcjjf", open)

  useEffect(() => {
    getCommMediumPathfuc();
  }, []);

  useEffect(() => {
    if (Rendermember == "Spouse") {
      let filterdatas = dataSource?.map(d => (d.agentUserId === userId)? {...d, relationWithSpouse: "Spouse"} : d );

      setFilterdata(
        filterdatas.sort((a, b) => a.fullName.localeCompare(b.fullName))
      );
      konsole.log("fileteedd", filterdatas, userId);
    } else {
      setFilterdata(
        dataSource.sort((a, b) => a.fullName.localeCompare(b.fullName))
        );
      }
  }, [dataSource]);
  // useEffect(() => {
  //   if(showModal === true){
  //     // document.getElementById("showEmailTemplate").innerHTML = emailtemp;
  //   }
  // }, [showModal]);

  const getCommMediumPathfuc = () => {
    let dataobj = {
      occurrenceId: 20,
      isActive: true,
      subtenantId: subtenantId,
    };
    let getCommMediumparam = Services.getCommMediumPathapi(dataobj);
    getCommMediumparam
      .then((res) => {
        let data = res.data.data[0];
        konsole.log("getCommMediumPath", res.data.data);
        setcommmediumdata(data);
        if (data.commChannelId == 2) {
          getTextTemplateapifuc(
            data.applicableTextTempId,
            data.isActive,
            data.subtenantId
          );
        } else if (data.commChannelId == 1) {
          GetEmailTemplateapifuc(
            data.applicableEmailTempId,
            data.isActive,
            data.subtenantId
          );
        } else if (data.commChannelId == 3) {
          GetEmailTemplateapifuc(
            data.applicableEmailTempId,
            data.isActive,
            data.subtenantId
          );
          getTextTemplateapifuc(
            data.applicableTextTempId,
            data.isActive,
            data.subtenantId
          );
        }
      })
      .catch((err) => {
        konsole.log("getCommMediumPath", err);
      });
  };

  const GetEmailTemplateapifuc = (tempid, isactive, subid) => {
    let GetEmailTemplateparam = Services.GetEmailTemplateapi(
      tempid,
      isactive,
      subid
    );
    GetEmailTemplateparam.then((res) => {
      konsole.log("GetEmailTemplateapi", res.data.data);
      setemailtempdata(res.data.data[0]);
      setemailtemp(res.data.data[0].templateContent);
    }).catch((err) => {
      konsole.log("GetEmailTemplateapi", err);
    });
  };

  const getTextTemplateapifuc = (tempid, isactive, subid) => {
    let GetEmailTemplateparam = Services.getTextTemplateapi(
      tempid,
      isactive,
      subid
    );
    GetEmailTemplateparam.then((res) => {
      konsole.log("GettextTemplateapiresponse", res);
      settexttempdata(res.data.data[0]);
      settexttemp(res.data.data[0].textTemplateContent);
    }).catch((err) => {
      konsole.log("GettextTemplateapierr", err);
    });
  };

  const showEmailPreview1 = (userData) => {
    setLoader(true);
    konsole.log("userDatauserDatauserData", userData);
    setAgentName(userData?.fullName);
    let results = Services.getUserDetaildByEmail(userData[0]?.agentEmailId);
    results
      .then((res) => {
        konsole.log("getUserDetaildByEmailres", res);
        setLoader(false);
        let responseData = res?.data[0];
        let rolesDetails = responseData?.roleDetails;
        let checkRoleLagecy = rolesDetails?.some((item) => item?.roleId === 20);
        if (checkRoleLagecy == true && responseData?.auth0Id !== null) {
          setshowwarningLagecyModal(true);
          konsole.log("one1");
        } else {
          setLoader(false);
          konsole.log("one2");
          showEmailPreview(userData);
        }
        konsole.log("getUserDetaildByEmailres", res, responseData);
      })
      .catch((err) => {
        showEmailPreview(userData);
        konsole.log("getUserDetaildByEmailerr", err);
      });
  };
  const showEmailPreview = (userData) => {
    let TemplateContent = emailtemp;
    konsole.log("TemplateContentTemplateContent", TemplateContent);
    TemplateContent = TemplateContent.replace(
      "@@AGENTNAME",
      userData[0].fullName
    );
    if (Rendermember == "Primary") {
      TemplateContent = TemplateContent.replace(
        "@@USERNAME",
        loggedUser.loginUserName
      );
    } else {
      TemplateContent = TemplateContent.replace(
        "@@USERNAME",
        loggedUser.spouseName
      );
    }

    let TemplateContenttext = texttemp;
    TemplateContenttext = TemplateContenttext.replace(
      "@@AGENTNAME",
      userData[0].fullName
    );
    if (Rendermember == "Primary") {
      TemplateContenttext = TemplateContenttext.replace(
        "@@USERNAME",
        loggedUser.loginUserName
      );
    } else {
      TemplateContenttext = TemplateContenttext.replace(
        "@@USERNAME",
        loggedUser.spouseName
      );
    }
  
    konsole.log(TemplateContenttext,texttemp,"TemplateContenttext")

    setemailtemplocal(TemplateContent);
    settexttempLocal(TemplateContenttext);
    setUserData(userData);
    closeModal(true);
  };

  const sendInvite = (userdata) => {
    konsole.log(userdata, "postaddmemberres");
    // var dataObj = {
    //   subtenantId: subtenantId,
    //   signUpPlateform: 10,
    //   userId: userdata.agentUserId,
    //   roleId: 5,
    //   activateUser: false,
    //   createdBy: loggedUser.loggedUserId,
    // };
    let dataObj = {
      subtenantId: subtenantId,
      linkTypeId: 2,
      linkStatusId: 1,
      userId: userdata[0].agentUserId,
      occurrenceId: 20,
      createdBy: loggedUser.loggedUserId,
    };
    message.destroy();
    if (userdata[0].agentEmailId == null) {
      message.error(
        "Please enter the user's primary email to send the invitation link"
      );
    } else if (userdata[0].agentMobileNo == null) {
      message.error(
        "Please enter the user's primary cell no. to send the invitation link"
      );
    } else {
      getAddMemberUserByUserId(userdata, dataObj);
    }
  };

  const getAddMemberUserByUserId = async (userdata, dataObj) => {
    setLoader(true);
    let getAddMemberUserByUserIdAsyncpramas =
      // Services.getAddMemberUserByUserIdAsync(dataObj);
      Services.generateUserLinks(dataObj);
    getAddMemberUserByUserIdAsyncpramas
      .then(async (res) => {
        konsole.log(res, "postaddmemberres");
        let responsedata = res.data.data;

        // let acceptLink = `${responsedata.uniqueLinkURL}&&ACCEPT`
        // let declineLink = `${responsedata.uniqueLinkURL}&&DECLINE`

        let UniQuiLink = `${responsedata.uniqueLinkURL}&&SHOW_LIST_ALL`;
        try {
          if (emailtemp !== null && emailtemp !== "") {
            await SendEmailCommPathFunc(userdata, responsedata, UniQuiLink);
          }
          if (texttemp !== null && texttemp !== "") {
            await postSendTextPathapifunc(userdata, responsedata, UniQuiLink);
          }
        } catch (error) {
          konsole.log("errorin messafe", error);
        }
        closeModal(false);
        setLoader(false);
      })
      .then((err) => {
        konsole.log(err, "postaddmembererr");
        setLoader(false);
      });
  };

  const SendEmailCommPathFunc = (userdata, responsedata, UniQuiLink) => {
    let TemplateContent = document.getElementById("emailtemplate").innerHTML;
    konsole.log("TemplateContentTemplateContent", TemplateContent);
    TemplateContent = TemplateContent.replace(
      "@@AGENTNAME",
      userdata[0].fullName
    );
    if (Rendermember == "Primary") {
      TemplateContent = TemplateContent.replace(
        "@@USERNAME",
        loggedUser.loginUserName
      );
    } else {
      TemplateContent = TemplateContent.replace(
        "@@USERNAME",
        loggedUser.spouseName
      );
    }
    // TemplateContent = TemplateContent.replace(
    //   "@@UNIQUELINK",
    //   responsedata.activationLink
    // );
    // TemplateContent = TemplateContent.replace(
    //   "@@UNIQUELINK",
    //   responsedata.activationLink
    // );
    TemplateContent = TemplateContent.replace("@@UNIQUELINK", UniQuiLink);
    // TemplateContent = TemplateContent.replace(
    //   "@@ACCPETLINK",
    //   acceptLink
    // );
    // TemplateContent = TemplateContent.replace(
    //   "@@DECLINELINK",
    //   declineLink
    // );

    konsole.log(
      TemplateContent,
      responsedata.activationLink,
      "TemplateContentemail"
    );
    konsole.log("userdataagentEmailId", userdata[0].agentEmailId);
    let dataObj = {
      emailType: emailtempdata.templateType,
      emailTo: userdata[0].agentEmailId,
      emailSubject: emailtempdata.emailSubject,
      emailContent: TemplateContent,
      // "emailFrom": commmediumdata.commMediumRoles[0].fromRoleId,
      emailFromDisplayName: commmediumdata.commMediumRoles[0].fromRoleName,
      emailTemplateId: emailtempdata.templateId,
      emailStatusId: 1,
      emailMappingTable: "string",
      emailMappingTablePKId: "string",
      createdBy: loggedUser.loggedUserId,
    };
    setLoader(true);
    let SendEmailCommPathAsyncParams = Services.SendEmailCommPathAsync(dataObj);
    return SendEmailCommPathAsyncParams.then((res) => {
      setLoader(false);
      konsole.log("SendEmailComm", res);
      message.success("Invitation for agent sent successfully");
    }).catch((err) => {
      setLoader(false);
      konsole.log("SendEmailComm", err);
    });
  };

  const SendmailInvite = (data) => {
    let sentMailSate = filterdata?.filter((ele) => {
      return ele?.agentUserId == data?.agentUserId;
    });
    // konsole.log("newState",sentMailSate)

    sentMailSate.filter((ele) => {
      // konsole.log("newState",ele)
    });
    
    // if (data?.statusName == "Pending" && data?.isUserActive == true || data?.isUserActive == false) {
    //   showEmailPreview1(data);

    // }
    // else if (data?.statusName == "Declined" && data?.isUserActive == true || data?.isUserActive == false) {
    //   setOpen(true)
    //   setdata(data)

    //  } else if (sentMailSate.every((ele)=>ele?.statusName == "Accepted" && ele?.isUserActive == true)) {
    //   Toaster.error("User is already activated")

    // }
    let boolValue = sentMailSate.every(
      (ele) => ele.statusName == "Accepted" && ele.isUserActive == true
    );
    if (boolValue == false) {
      if (
        sentMailSate.every(
          (ele) =>
            ele.statusName == "Pending" &&
            (ele.isUserActive == true || ele.isUserActive == false)
        )
      ) {
        showEmailPreview1(sentMailSate);
      } else if (
        sentMailSate.some(
          (ele) =>
            ele.statusName == "Declined" &&
            (ele.isUserActive == true || ele.isUserActive == false)
        )
      ) {
        setOpen(true);
        setdata(sentMailSate);
        // konsole.log(sentMailSate,"sentMailSate")
      } else {
        showEmailPreview1(sentMailSate);
      }
    }
  };
  // konsole.log("confirmLoading", confirmLoading)

  const postSendTextPathapifunc = (userdata, responsedata, acceptLink) => {
    let TemplateContenttext =
      document.getElementById("templateData1").innerHTML;
    konsole.log("TemplateContenttext", TemplateContenttext);
    TemplateContenttext = TemplateContenttext.replace(
      "@@AGENTNAME",
      userdata[0].fullName
    );
    TemplateContenttext = TemplateContenttext.replace(
      "@@USERNAME",
      loggedUser.loginUserName
    );
    TemplateContenttext = TemplateContenttext.replace(
      "@@UNIQUELINK",
      acceptLink
    );

    konsole.log(TemplateContenttext, "TemplateContenttext");

    let dataObj = {
      smsType: texttempdata.textTemplateType,
      textTo: userdata[0].agentMobileNo,
      textContent: TemplateContenttext,
      smsTemplateId: texttempdata.textTemplateId,
      smsStatusId: 1,
      smsMappingTable: "string",
      smsMappingTablePKId: "string",
      createdBy: loggedUser.loggedUserId,
    };
    // konsole.log(dataObj,"dataObj")
    let postSendTextPathapiAsyncParams = Services.postSendTextPathapi(dataObj);
    return postSendTextPathapiAsyncParams
      .then((res) => {
        konsole.log("postSendTextPath", res);
      })
      .catch((err) => {
        konsole.log("postSendTextPath", err);
      });
  };
  const handelconfrimation = () => {

    showEmailPreview1(data);
    setOpen(false);
  };
  const shoButtons = (filterdata, data, fname, trueIndex) => {
    let filterSate = filterdata.filter((ele) => {  return ele?.fullName == fname;});
    let FilterdButton = filterSate.every( (ele) => ele?.statusName === "Accepted" && ele?.isUserActive === true);
    let Filterddata = filterSate.filter( (ele) => ele?.statusName === "Declined" && (ele?.isUserActive === true || ele.isUserActive == false) );
    
    let AllAccepted=filterSate.every(item=>item?.statusName==='Accepted' && item?.isUserActive==false )
    let AllAccepted2=filterSate.every(item=>item?.statusName==='Accepted' && item?.isUserActive==true )
    let checkDecline=filterSate.some(item=>item?.statusName==='Declined')

    // konsole.log("gajfiuyiofd",data)
    konsole.log("filterSatefilterSatefilterSate",filterSate)
    konsole.log("AllAcceptedAllAccepted",AllAccepted)

    konsole.log("filterdatafilterdata",filterdata)

    return (
      <>
      <button className={AllAccepted2 ? 'disable' : 'activebtn'}
       disabled={(AllAccepted2==true)?true:false} 
         onClick={() => {  SendmailInvite(data);}}>
         {(AllAccepted==true)?"Activate Agent":(AllAccepted2==true)?"User Activated":(checkDecline==true)?"Send Invite for Declined Role":"Send Invite"}
         </button>
          { 
            Filterddata[0]?.statusName == "Declined" &&
             open == true ? (
              <>
                <Popconfirm
                  title={<p>{Filterddata[0]?.fullName} had declined to act as your agent,<br /> Are you sure you want to resend the invite?</p>}
                  onConfirm={() => {handelconfrimation();}}
                  placement="topLeft"
                  onCancel={() => { setOpen(false);}}
                  visible={open}
                  okText="Yes"
                  cancelText="No"
                />
              </>
            ) : ( "")}
      </>
    );
    // data.isUserActive == true ? (
    //   <button className="disable" disabled>
    //     User Activated
    //   </button>
    // ) : (
    //   <button
    //     className="activebtn"
    //     onClick={() => {
    //       SendmailInvite(data)
    //      }}
    //   >
    //     Send Invite
    //   </button>
    // )
  };

  const columns = [
    {
      title: "Agent",
      dataIndex: "fullName",
      key: "Agent",
      className: "manage",
      render: (fname, data, index, ActivateUser) => {
        let newjson = [];
        newjson.push(data);
        let arr = [...[data]];
        // { konsole.log("new888878fheyyf", arr, index) }
        const trueIndex =
          index + paginationInfo.pageSize * (paginationInfo.current - 1);
        const obj = {
          children: fname,
          props: {},
        };
        // konsole.log("kvnkdjgaoidjv",filterdata)
        if (index >= 1 && fname === filterdata[trueIndex - 1].fullName) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let i = 0;
            trueIndex + i !== filterdata.length &&
            fname === filterdata[trueIndex + i].fullName;
            i += 1
          ) {
            obj.props.rowSpan = i + 1;
          }

          obj.children = (
            <div style={{ textAlign: "center" }}>
              {" "}
              {fname} <br />
              {
                shoButtons(filterdata, data, fname, trueIndex)
                //             data.isUserActive == true ? (
                //   <button className="disable" disabled>
                //     User Activated
                //   </button>
                // ) : (
                //   <button
                //     className="activebtn"
                //     onClick={() => {
                //       SendmailInvite(data)
                //      }}
                //   >
                //     Send Invite
                //   </button>
                // )
              }
            </div>
          );
        }
        return obj;
      },
    },
    {
      title: "Relation",
      key: "Relation",
      render: (relationWithSpouse, data) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{ Rendermember == "Spouse" &&
        ( data.relationWithSpouse != null ||
          data.relationWithSpouse != undefined)
          ? data.relationWithSpouse
          : data.relationWithMember}</span>
        </div>
      ),
      dataIndex:"relation",
      className: "manage",
    },
    {
      title: "Document Type & Role",

      render: (theImageURL) => <span>{theImageURL}</span>,
      dataIndex: "legalDocName",
      key: "legalDocName",
      className: "manage",
    },

    {
      title: "Order",
      render: (Relation) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span>{Relation}</span>
        </div>
      ),
      dataIndex: "agentRank",
      key: "Rank",
      className: "manage ",
    },

    {
      title: "Status",
      render: (Status) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {Status == "Accepted" ? (
            <span className="statusbtn" style={{ color: "#117800" }}>
              {Status}
            </span>
          ) : Status == "Pending" ? (
            <span className="statusbtn" style={{ color: "#DD7819" }}>
              {Status}
            </span>
          ) : Status == "Declined" ? (
            <span className="statusbtn" style={{ color: "#d2222d" }}>
              {Status}
            </span>
          ) : (
            <span className="statusbtn" style={{ color: "#DD7819" }}>
              Pending
            </span>
          )}
        </div>
      ),
      dataIndex: "statusName",
      key: "statusName",
      className: "manage ",
    },
    // {
    //   title: "Activate User",
    //   render: (ActivateUser,value) => <>
    //     {
    //       (ActivateUser == true) ? (<span style={{lineHeight:"40px"}}>Accepted</span>) : (<span  style={{lineHeight:"40px"}} onClick={()=>{sendInvite(value)}}>Send Invite</span>)
    //     }
    //   </>,
    //   dataIndex: "isUserActive",
    //   key:"isUserActive",
    //   className: "manage "
    // },
  ];

  const handleChange = (pagination) => {
    setPaginationInfo(pagination);
  };

  const modalOnDisable = () => {
    setshowwarningLagecyModal(!showwarningpoput);
  };

  return (
    <div className="agentModuleShowMap">
      <Modal
        className="modalStyle"
        style={{ maxWidth: "768px" }}
        visible={showwarningpoput}
        maskClosable={false}
        footer={null}
        closable
        destroyOnClose
        onCancel={modalOnDisable}
        centered
      >
        <div className="text-center">
          <p className="mt-3" style={{ fontSize: "1.1rem" }}>
            This user email address is already associated with the legacy
            system.
            <br />
            <Link to="/Fiduciaries">Click here</Link> to update primary email
            address for {agentName}.
          </p>
        </div>
      </Modal>

      <Modal
        className="p-0 m-0 "
        style={{ position: "top" }}
        bodyStyle={{ padding: 0 }}
        visible={showModal}
        footer={false}
        closable={true}
        closeIcon={<img src="/icons/crossIcon.svg" width={15} />}
        destroyOnClose={true}
        centered
        width={750}
        onCancel={() => closeModal(false)}
      >
        <div
          className="header text-center"
          style={{
            background: "#720c20",
            color: "white",
            position: "sticky",
            top: 0,
          }}
        >
          {" "}
          Send Invite
        </div>
        <div
          style={{
            minHeight: "50vh",
            maxHeight: "60vh",
            height: "100vh",
            overflow: "scroll",
          }}
        >
          <div className="position-relative">
            {/* <div className="position-absolute w-100 h-100" style={{ zIndex: 299 }} > */}
            {/* </div> */}
            {emailtemp !== null && emailtemp !== "" ? (
              <>
                <h6 className="ps-4 ms-5 mt-3">Email Template</h6>
                <div
                  dangerouslySetInnerHTML={{ __html: emailtempLocal }}
                  id="emailtemplate"
                  contentEditable="true"
                  className="p-0 m-0"
                />
              </>
            ) : (
              <></>
            )}
            <div className="px-5 mb-2">
              {texttemp !== null && texttemp !== "" ? (
                <>
                  <h6 className="mt-3">Text Template</h6>
                  <div
                    contentEditable="true"
                    id="templateData1"
                    className="border p-2"
                    style={{ padding: "10px 0 30px 0" }}
                  >
                    {texttempLocal}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="d-grid place-items-center p-4 gap-2">
          <button
            style={{
              background: "#720c20",
              color: "white",
              outline: "none",
              borderRadius: "5px",
              border: "2px solid #720C20",
            }}
            className="p-1"
            onClick={() => sendInvite(userData)}
          >
            {" "}
            Send Invite
          </button>
          <button
            style={{
              color: "#720c20",
              background: "white",
              border: "2px solid #720C20",
              borderRadius: "5px",
            }}
            className="p-1"
            onClick={() => closeModal(false)}
          >
            {" "}
            Cancel
          </button>
        </div>
      </Modal>
      {/* {open == true ? (
        <>
          <Popconfirm
            title={`${data[0]?.fullName} had declined to act as your agent,
           Are you sure you want to resend the invite?`}
            onConfirm={() => {
              handelconfrimation();
            }}
            placement="top"
            onCancel={() => {
              setOpen(false);
            }}
            visible={open}
            okText="Yes"
            cancelText="No"
          />
        </>
      ) : (
        ""
      )} */}

      <Table
        onChange={handleChange}
        dataSource={filterdata}
        columns={columns}
        bordered
        pagination={false}
      />
    </div>
  );
}
