import React from 'react'
import Navbar from '../Navbar';
import "../NewlpoDashboard/NewLpo.css"

import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'antd';

export default function Finance() {
  return (
    <div>
      <Navbar />
      <h6 className='ms-2'>
        <Link to="/NewLpo" className='text-primery'>Dashboard</Link>{">"}Finance
      </h6>
      <div className='container-fluid'>
        <div class="row">
          <div className='col-md-3 mt-2'>
            <div class="card">

              <div class="card-body">
                <div className=" text-center">
                  <h5 class="card-title"><img src='images/usersecondLPo.png'></img></h5>
                  <h6>Priyanka mishra</h6>
                  <h6>Geriatric Care Manager</h6>
                </div>
                <div>
                  <Row>
                    <Col span={24} xs={24} className="d-flex webTagPhoneH5">

                      <div>
                        <img src='location.png' style={{ width: "18px", height: "20px" }} />

                      </div>
                      <div>
                        <h6 className='mx-1' style={{ marginTop: "4px", fontSize: "12px", color: "#444444" }}>
                          Florida Medical Clinic, Market Square Drive, Zephyrhills, FL, USA,33542
                          {/* {userAddress.addressLine1}, {userAddress.zipcode} */}
                        </h6>
                      </div>
                    </Col>

                    <Col span={24} xs={24} className="d-flex webTagPhoneH5">

                      <div>
                        <img src='/images/calling.png' style={{ width: "18px", height: "20px" }} />

                      </div>
                      <div>
                        <h6 className='mx-1' style={{ marginTop: "4px", fontSize: "12px", color: "#444444" }}>
                          +17589456945
                          {/* {userAddress.addressLine1}, {userAddress.zipcode} */}
                        </h6>
                      </div>
                    </Col>
                    <Col span={24} xs={24} className="d-flex webTagPhoneH5">

                      <div>
                        <img src='/images/mail.png' style={{ width: "18px", height: "20px" }} />

                      </div>
                      <div>
                        <h6 className='mx-1' style={{ marginTop: "4px", fontSize: "12px", color: "#444444" }}>
                          Phillipj@mailinator.com
                          {/* {userAddress.addressLine1}, {userAddress.zipcode} */}
                        </h6>
                      </div>
                    </Col>


                  </Row>
                </div>
              </div>
            </div>

          </div>
          <div className='col-md-3 mt-2'>
            <div class="card">

              <div class="card-body">
                <div className=" text-center">
                  <h5 class="card-title"><img src='images/usersecondLPo.png'></img></h5>
                  <h6>Priyanka mishra</h6>
                  <h6>Geriatric Care Manager</h6>
                </div>
                <div>
                  <Row>
                    <Col span={24} xs={24} className="d-flex webTagPhoneH5">

                      <div>
                        <img src='location.png' style={{ width: "18px", height: "20px" }} />

                      </div>
                      <div>
                        <h6 className='mx-1' style={{ marginTop: "4px", fontSize: "12px", color: "#444444" }}>
                          Florida Medical Clinic, Market Square Drive, Zephyrhills, FL, USA,33542
                          {/* {userAddress.addressLine1}, {userAddress.zipcode} */}
                        </h6>
                      </div>
                    </Col>

                    <Col span={24} xs={24} className="d-flex webTagPhoneH5">

                      <div>
                        <img src='/images/calling.png' style={{ width: "18px", height: "20px" }} />

                      </div>
                      <div>
                        <h6 className='mx-1' style={{ marginTop: "4px", fontSize: "12px", color: "#444444" }}>
                          +17589456945
                          {/* {userAddress.addressLine1}, {userAddress.zipcode} */}
                        </h6>
                      </div>
                    </Col>
                    <Col span={24} xs={24} className="d-flex webTagPhoneH5">

                      <div>
                        <img src='/images/mail.png' style={{ width: "18px", height: "20px" }} />

                      </div>
                      <div>
                        <h6 className='mx-1' style={{ marginTop: "4px", fontSize: "12px", color: "#444444" }}>
                          Phillipj@mailinator.com
                          {/* {userAddress.addressLine1}, {userAddress.zipcode} */}
                        </h6>
                      </div>
                    </Col>


                  </Row>
                </div>
              </div>
            </div>

          </div>
          <div className='col-md-3 mt-2'>
            <div class="card">

              <div class="card-body">
                <div className=" text-center">
                  <h5 class="card-title"><img src='images/usersecondLPo.png'></img></h5>
                  <h6>Priyanka mishra</h6>
                  <h6>Geriatric Care Manager</h6>
                </div>
                <div>
                  <Row>
                    <Col span={24} xs={24} className="d-flex webTagPhoneH5">

                      <div>
                        <img src='location.png' style={{ width: "18px", height: "20px" }} />

                      </div>
                      <div>
                        <h6 className='mx-1' style={{ marginTop: "4px", fontSize: "12px", color: "#444444" }}>
                          Florida Medical Clinic, Market Square Drive, Zephyrhills, FL, USA,33542
                          {/* {userAddress.addressLine1}, {userAddress.zipcode} */}
                        </h6>
                      </div>
                    </Col>

                    <Col span={24} xs={24} className="d-flex webTagPhoneH5">

                      <div>
                        <img src='/images/calling.png' style={{ width: "18px", height: "20px" }} />

                      </div>
                      <div>
                        <h6 className='mx-1' style={{ marginTop: "4px", fontSize: "12px", color: "#444444" }}>
                          +17589456945
                          {/* {userAddress.addressLine1}, {userAddress.zipcode} */}
                        </h6>
                      </div>
                    </Col>
                    <Col span={24} xs={24} className="d-flex webTagPhoneH5">

                      <div>
                        <img src='/images/mail.png' style={{ width: "18px", height: "20px" }} />

                      </div>
                      <div>
                        <h6 className='mx-1' style={{ marginTop: "4px", fontSize: "12px", color: "#444444" }}>
                          Phillipj@mailinator.com
                          {/* {userAddress.addressLine1}, {userAddress.zipcode} */}
                        </h6>
                      </div>
                    </Col>


                  </Row>
                </div>
              </div>
            </div>

          </div>
          <div className='col-md-3 mt-2'>
            <div class="card">

              <div class="card-body">
                <div className=" text-center">
                  <h5 class="card-title"><img src='images/usersecondLPo.png'></img></h5>
                  <h6>Priyanka mishra</h6>
                  <h6>Geriatric Care Manager</h6>
                </div>
                <div>
                  <Row>
                    <Col span={24} xs={24} className="d-flex webTagPhoneH5">

                      <div>
                        <img src='location.png' style={{ width: "18px", height: "20px" }} />

                      </div>
                      <div>
                        <h6 className='mx-1' style={{ marginTop: "4px", fontSize: "12px", color: "#444444" }}>
                          Florida Medical Clinic, Market Square Drive, Zephyrhills, FL, USA,33542
                          {/* {userAddress.addressLine1}, {userAddress.zipcode} */}
                        </h6>
                      </div>
                    </Col>

                    <Col span={24} xs={24} className="d-flex webTagPhoneH5">

                      <div>
                        <img src='/images/calling.png' style={{ width: "18px", height: "20px" }} />

                      </div>
                      <div>
                        <h6 className='mx-1' style={{ marginTop: "4px", fontSize: "12px", color: "#444444" }}>
                          +17589456945
                          {/* {userAddress.addressLine1}, {userAddress.zipcode} */}
                        </h6>
                      </div>
                    </Col>
                    <Col span={24} xs={24} className="d-flex webTagPhoneH5">

                      <div>
                        <img src='/images/mail.png' style={{ width: "18px", height: "20px" }} />

                      </div>
                      <div>
                        <h6 className='mx-1' style={{ marginTop: "4px", fontSize: "12px", color: "#444444" }}>
                          Phillipj@mailinator.com
                          {/* {userAddress.addressLine1}, {userAddress.zipcode} */}
                        </h6>
                      </div>
                    </Col>


                  </Row>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>

  )
}
