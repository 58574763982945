import { Drawer, Layout } from 'antd'
import React, { useState } from 'react'
import Navbar from './Navbar';
import Sidemenu from './Sidemenu';
import "../styles/Container.css"
import Feedback from './Feedback';
import html2canvas from 'html2canvas';

const { Sider, Content} = Layout;

function Container({children}) {
    const [image, setImage ] = useState("");

    
    const handleScreenshot = async () => {

         const canvas = await html2canvas(document.body);
        
         const screenshotUrl = canvas.toDataURL('image/png');
        
         setImage(screenshotUrl)
        
        
         };
    return (
        <Layout>
            <Navbar className="">
            
            </Navbar>
            <Layout>
                {/*<Sider breakpoint='lg'
                className='border-0 border-right'
                theme='light'
                width={300}
                collapsedWidth={0}
                trigger={null}
                >
                     <Sidemenu />
    </Sider>*/}
                <Content className='rigth-container'>
                    <div className="site-layout-background" >
                    {children}
                    </div>
                </Content>
                <Feedback  getImage={handleScreenshot} image={image} classNameLocal={"positionFeedBackButton"}/>
            </Layout>
        </Layout>
    )
}

export default Container;