import React, { useState } from "react";
import konsole from "../network/konsole";
import moment from "moment";
import commonLib from "../control/commonLib";
import Toaster from "./Toaster";

const DatePickerComponent = (props) => {
    const[ dob,setdob] = useState("");
    const[isCalenderOpen,setisCalenderOpen]= useState( false)
    const[count,setCount] = useState(0)

    let name = props.name;
    let maxDate = props.maxDate;
    let minDate = props.minDate;
    props.setvalue(  props.value ? moment(props.value).format("YYYY-MM-DD") : "")
    // let placeholderText = props.placeholderText;
    if (maxDate) {
        maxDate = moment().subtract(maxDate, "years").format("YYYY-MM-DD");
    }
    if (maxDate == 0) {
        maxDate = moment().format("YYYY-MM-DD");
    }
    if (minDate) {
        minDate = moment().subtract(minDate, "years").format("YYYY-MM-DD");
    }

   const handleDate = (date) => {
        props.setvalue(date.target.value)
             props?.userAges(date.target.value)
            setdob(date.target.value)
            setisCalenderOpen( false)

    };

    const handleRawDate = (ev) => {
        if (ev.type === "change") {
            let [month, date] = ev.target.value.split(/\-/);
            if (ev.nativeEvent.data && !/^[\d\-]+$/.test(ev.nativeEvent.data) || ev.target.value.length > 10 ||
                (ev.target.value.length === 3 && ev.target.value.indexOf('-') === -1) ||
                (ev.target.value.length === 6 && ev.target.value.match(/\-/g).length !== 2)) {
                ev.preventDefault();
            }
            if (parseInt(month) > 12 || (date && parseInt(date) > 31)) {
                ev.target.value = ev.target.value.substr(0, ev.target.value.length - 1) + '-';
            }
            if ((/^\d+$/.test(ev.target.value) && ev.target.value.length === 2) ||
                (ev.target.value.length === 5 && ev.target.value.match(/[0-9]/g).length === 4 && ev.target.value.match(/\-/g).length === 1)) {
                ev.target.value = ev.target.value + '-';
            }
        }
    }


   const handleDateFocusOut = (e) => {
        e.preventDefault();
        const dateString = dob;
        const maxDateProps = props.maxDate;
        const minDateProps = props.minDate
        const selectDatePicker = e.target;
        konsole.log("asdfggfgsahfhgdhfgsdhgfhs", dateString, typeof (dateString));
        if (selectDatePicker.value == "") return;

        if (props.validDate === 18) {
            konsole.log("calculated date", commonLib.checkIFMinor(selectDatePicker.value))
            if (commonLib.checkIFMinor(selectDatePicker.value) < 18) {
                // alert("Member Cannot be minor.")
                Toaster.error("Member Cannot be minor.")
                selectDatePicker.value = "";
                props.setvalue("")
                setdob("") 
                // return;
                return selectDatePicker.focus();
            }
            // if(handleOnBlurFocus){
            //     handleOnBlurFocus(e.target.value);
            // }
        }

    }


    const handleCalenderOpen = () => {

      setisCalenderOpen(true)
   
    }

    const handleCalenderClose = () => {
     
     setisCalenderOpen(false)
        
    }




  return (
    <>
      <div className="position-relative w-100">
        <input
          className={`border rounded-1 ${props.value == "" ? "inptDateType" : ""} w-100 p-1`}
          type={"date"}
          value={moment(props.value).format("YYYY-MM-DD")}
          placeholder={`${props.value == "" ? `${props.placeholderText}` : ""}`}
          onChange={handleDate}
          min={minDate}
          max={maxDate}
          onBlur={handleDateFocusOut}
        />
      </div>
    </>
  );
};

export default DatePickerComponent;