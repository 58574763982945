import React, { Component } from "react";
import { Row, Col, Input, Radio, Typography, Form } from "antd";
import Services from "../../../network/Services";
import konsole from "../../../network/konsole";
import commonLib from "../../../control/commonLib";
import moment from "moment";
import Toaster from "../../Toaster";
const { Text } = Typography;

export class Occupation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.userId,
      dob : null,
      updateOccupation: false,
      occupationObj: {},
      isWorking: null,
      occupationType: "",
      ageOfRetirement: 0,
      professionalBackground: null,
      isDisabled: null,
      activityTypeId: 5,
      logginUserId: null,
    };
  }
  componentDidUpdate(props) {
    if (this.props.userAge !== props.userAge) {
      this.setState({
        dob: this.props.userAge,
      });
    }
  }
  componentDidMount() {
    
    
    let logginUserId = commonLib.getObjFromStorage("stateObj").userId;
    this.setState(
      {
        logginUserId: logginUserId,
      },
      () => {
        this.getOccupationbyUserId();
      }
    );
  }

  getOccupationbyUserId = () => {
    const promises = Services.getOccupationByUserId(this.props.userId);
    promises
      .then((response) => {
        if (response) {
          konsole.log("setvalue", response);
          let responseData = response.data.data[0];
          this.setState({
            occupationObj: responseData,
            updateOccupation: true,
          });
          this.autoFillOccupationData(responseData);
        }
      })
      .catch((err) => {
        konsole.log("error", err);
      })
      .finally(() => {
        konsole.log("finish");
      });
  };

  autoFillOccupationData = (responseDataObj) => {
    konsole.log("occupationType1", responseDataObj);
    if (responseDataObj.isWorking == true) {
      this.setState({
        isWorking: true,
        occupationType: responseDataObj.occupationType,
        ageOfRetirement: parseInt(responseDataObj.ageOfRetirement),
      });
    } else if (responseDataObj.isWorking == false) {
      this.setState({
        isWorking: false,
        professionalBackground: responseDataObj.professionalBackground,
        isDisabled: responseDataObj.isDisabled,
      });
    }
  };

  handleInputChange = (event) => {
    event.preventDefault();
    let attrname = event.target.name;
    let attrvalue = event.target.value;
    switch (attrname) {
      case "professionalBackground":
      case "occupationType":
        {
          let regex = /^[a-zA-Z ]+$/;
          if (regex.test(attrvalue)) {
            this.setState({
              [attrname]: attrvalue,
            });
          } else {
            this.setState({
              [attrname]: "",
            });
          }
        }
        break;
      default:
        if (this.isNumberRegex(attrvalue)) {
          this.setState({
            [attrname]: attrvalue,
          });
        } else {
          this.setState({
            [attrname]: "",
          });
        }
    }
  };
  isNumberRegex = (value) => {
    let regex = /^[0-9]*$/;
    return regex.test(value);
  };

  handleRadio = (event) => {
    const radioName = event.target.name;
    const radioValue = event.target.value;
    // konsole.log(event.target.name);
    this.setState({ ...this.state, [radioName]: radioValue });
  };

  handleClearState = () => {
    this.setState({
      occupationType: "",
      ageOfRetirement: 0,
      professionalBackground: null,
      isDisabled: null,
    });
  };

  handleOccupationSubmit = () => {
    const stateObj = this.state;
    let postDataObj = {};
    if (stateObj.isWorking == null) {
      return null;
    }
    if (stateObj.isWorking == true) {
      if (stateObj.occupationType == "") {
        return "occuption";
      }
      if (stateObj.ageOfRetirement == "") {
        return "ageOfRetirement";
      }
      postDataObj = {
        isWorking: true,
        occupationType: stateObj.occupationType,
        ageOfRetirement: parseInt(stateObj.ageOfRetirement),
      };
    }
    if (stateObj.isWorking == false) {
      if (stateObj.professionalBackground == null) {
        return "professBackground";
      }
      if (stateObj.isDisabled == null) {
        return "isDisabled";
      }
      postDataObj = {
        isWorking: false,
        professionalBackground: stateObj.professionalBackground,
        isDisabled: stateObj.isDisabled,
      };
    }
    let method = "POST";
    if (this.state.updateOccupation !== null && this.state.updateOccupation) {
      postDataObj["updatedBy"] = this.state.logginUserId;
      postDataObj["userOccId"] = this.state.occupationObj.userOccId;
      method = "PUT";
    } else {
      postDataObj["createdBy"] = this.state.logginUserId;
    }

    postDataObj["userId"] = this.props.userId;
    konsole.log("occupation", JSON.stringify(postDataObj));

    const promises = Services.upsertOccupationByUserId(method, postDataObj);
    promises
      .then((response) => {
        konsole.log("Success res" + JSON.stringify(response));
        if (response) {
          return "success";
        }
      })
      .catch((err) => {
        konsole.log("error", err);
      })
      .finally(() => {
        konsole.log("finish");
      });
  };
  handleAge =(e)=>{
  // const birthDate = this.state?.dob;
  const today = new Date();
  const dateToDivide = new Date(this.state?.dob);
  const ageInMilliseconds = today - dateToDivide;
  const ageInYears = ageInMilliseconds / (365 * 24 * 60 * 60 * 1000);
  let  age = (Math.floor(ageInYears));
  // console.log("happySampe",age,e)
     if(e.target.value > age){
      
     }else{
      this.setState({
        ageOfRetirement: "",
      });
      Toaster.warning("Retirement age cannot be less than your current age.");
     }

  }
  render() {
    konsole.log("state at ocuu", this.state);

    const occupationTypevalue =
      this.state?.occupationType != null
        ? this.state?.occupationType?.charAt(0)?.toUpperCase() +
          this.state?.occupationType?.slice(1)
        : "";

    return (
        <div className="px-2">
      <div className="Info">
        <Row className="m-0 mb-1 p-0 d-flex align-items-start flex-column ">
          <Text strong={true} type="danger" style={{ color: "#76272B" }}>
            Are you still employed?
          </Text>
          <Col sm="3" lg="3" className="">
            <Col xs="12">
              <Col
                span={24}
                xs={24}
                className="mt-2"
                onClick={this.props.toModalOnDisable}
              >
                <Radio.Group
                  name="isWorking"
                  onChange={this.handleRadio}
                  value={this.state.isWorking}
                  disabled={this.props.todisable}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Col>
            </Col>
          </Col>
        </Row>

        {this.state.isWorking == true ? (
          <>
            <Row className="m-0 mb-2">
              <Col xs={24} sm={8} lg={8} className="ps-0 me-4">
                <label className="mb-1">Occupation</label>

                <Form.Item
                  onClick={this.props.toModalOnDisable}
                  rules={[
                    {
                      message: "Please enter Occupation Type",
                    },
                  ]}
                >
                  <Input
                    name="occupationType"
                    onChange={this.handleInputChange}
                    value={occupationTypevalue}
                    disabled={this.props.todisable}
                  />
                </Form.Item>
              </Col>
              <Col sm="6" lg="6" className="ps-0">
                <label className="mb-1">At what age do you anticipate retiring?</label>
                <Col xs={6} sm={6} lg={8} className="ps-0">
                  <Form.Item onClick={this.props.toModalOnDisable}>
                    <Input
                      name="ageOfRetirement"
                      onChange={this.handleInputChange}
                      value={this.state.ageOfRetirement}
                      disabled={this.props.todisable}
                      onBlur={this.handleAge}
                      maxLength={2}
                    />
                  </Form.Item>
                </Col>
              </Col>
            </Row>
          </>
        ) : this.state.isWorking == false ? (
          <Row className="m-0 mb-2">
            <Col xs={24} sm={8} lg={8} className="ps-0 me-4">
              <label className="mb-1">Professional Background</label>
              <Form.Item onClick={this.props.toModalOnDisable}>
                <Input
                  name="professionalBackground"
                  onChange={(event) => this.handleInputChange(event)}
                  value={this.state.professionalBackground}
                  disabled={this.props.todisable}
                />
              </Form.Item>
            </Col>
            <Col xs sm="2" lg={5} className="ps-0 align-items-center">
              <label className="mb-1">Are you disabled?</label>
              <Col onClick={this.props.toModalOnDisable}>
                <Radio.Group
                  name="isDisabled"
                  onChange={this.handleRadio}
                  value={this.state.isDisabled}
                  disabled={this.props.todisable}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Col>
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>
      </div>
    );
  }
}

export default Occupation;
