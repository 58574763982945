import React, { useEffect, useState } from 'react'
import "../NewlpoDashboard/NewLpo.css"
import Navbar from '../Navbar';
import konsole from '../../network/konsole';
import Services from '../../network/Services';
import { Link } from 'react-router-dom';

import { Card, Col, Row } from 'antd';
// import Myprofessionalteam from '../Component/MyProfessonialTeam/MyProfessionalteam';
import Myprofessionalteams from '../Component/MyProfessonialTeam/MyProfessionalteams';
import commonLib from '../../control/commonLib';
import { AoIntakeBaseUrl } from '../../network/url';




export default function NewLpo() {
    const loggedUserDetail = commonLib.getObjFromStorage('userLoggedInDetail');
    const [roleid, setroleid] = useState(1)
    const [healthProfessional, setHealthProfessional] = useState([])
    const [housingProfessional, setHousingProfessional] = useState([])
    const [financeProfessional, setFinanceProfessional] = useState([])
    const [legalProfessional, setLegalProfessional] = useState([])
    const [otherProfessional, setOtherProfessional] = useState([])
    const [getData, setData] = useState([]);


    useEffect(() => {
        let roleid = commonLib.getObjFromStorage("stateObj").roleId

        setroleid(roleid)
    }, [])
    console.log("roleidroleid", housingProfessional)

    const handleSetup = () => {
        konsole.log("clicked")
        const stateObj = commonLib.getObjFromStorage('stateObj');
        const userPrimaryInDetail = commonLib.getObjFromStorage('userPrimaryInDetail');
        let params = `appState=${stateObj.appState}&userId=${userPrimaryInDetail.memberUserId}&loggedUserId=${stateObj.userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggenInId}`;
        window.location.replace(`${AoIntakeBaseUrl}?token=` + window.btoa(params));
        konsole.log("stateObj", stateObj)
    }


    // sumit code start

    const [divdisabled,setdivdisabled]=useState(false)
    konsole.log("roleid", roleid)

    useEffect(() => {
        const userId = commonLib.getObjFromStorage("userPrimaryInDetail")?.memberUserId;
        getProfessionalByUserId(userId);
        let roleid = commonLib.getObjFromStorage("stateObj").roleId
        if(roleid==1 ){
            setdivdisabled(true)
        }

        setroleid(roleid)
    }, [])



    const getProfessionalByUserId = (userId) => {
        const promises = Services.getProfessionalByUserId(userId);
        promises.then((response) => {
            konsole.log("responseData", response);
            const professional = response.data.data.professionalUser
            const financeProfessional = professional.filter((res) => { return (res.proTypeId === 1 || res.proTypeId === 2 || res.proTypeId === 3 || res.proTypeId === 8 || res.proTypeId === 12 || res.proTypeId === 15) })
            const healthProfessional = professional.filter((res) => { return (res.proTypeId === 7 || res.proTypeId === 10 || res.proTypeId === 11) })
            const housingProfessional = professional.filter(res => [14, 4].includes(res.proTypeId))
            const legalProfessional = professional.filter(res => [6, 13].includes(res.proTypeId))
            const otherProfessional = professional.filter((res) => { return (res.proTypeId === 9 || res.proTypeId === 999999) });

            setData(professional)
            setHealthProfessional(healthProfessional);
            setFinanceProfessional(financeProfessional);
            setHousingProfessional(housingProfessional);
            setLegalProfessional(legalProfessional);
            setOtherProfessional(otherProfessional);
            konsole.log('professional567890', legalProfessional);

        }).catch((error) => {
            konsole.log("error", error)
        }).finally(() => {
            konsole.log("finish")
        })


    }


    return (
        <div className="main" >
            <div className='pt-2 px-2 pe-2' style={{ height: "90vh" }} >

                <div className="HeaderImageDivs1">
                    <div className="ImageDivs11">
                        <div  className='d-flex align-items-center h-100 px-4'>
                            <div className=' flex-column'>
                                <h6 className='text-light' style={{ fontWeight: 400 }}>
                                    {loggedUserDetail?.loginUserName}, Welcome to</h6>
                                <h2 className='text-light '>Your Life Plan<br/>
                               
                                    Organizer
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="SetupMainDivs111 align-self-center">
                        <div className="SettingCard d-flex  flex-column" onClick={handleSetup}>
                            <div className="SettingImage">
                                <img src="images/Expanded.png" />
                            </div>
                            <div className='mt-1 SetupDiv'>
                                <h6 className='fw-bold'>Setup</h6>
                            </div>
                        </div>
                    </div>


                </div>
                
                <div className={`${(roleid == 1) ? 'upperlayercss ' : ''}`} ></div>
               
                <div className="myProfileColMainDivs3 mt-2" >
                    <Row>
                        <Col span={24} className="px-2 myProfileCol">
                            
                            <h6 className='pb-0 mb-0 fw-bold'>My Profile</h6>
                        </Col>
                    </Row>
                    <Row className='myProfileAllDiv px-2 d-flex justify-content-between'>
                        <Col className="s33 d-flex  align-items-center">
                            <Link to="/MemberInfo" style={{ color: "black" }}>
                                <div className='d-flex'>
                                    <div className='mx-3 NewLpouserDiv d-flex  align-items-center my-Profile-Div'>
                                        <img src='/images/Newlpouser.png' />

                                    </div>
                                    <div className='NewLpouserTextDiv'>
                                        <p className='mb-0 fw-bold'>Personal Info<br />
                                            <span className='updateLpo'>Update Info</span> </p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col className="s33 d-flex  align-items-center">
                            <Link to="/Familyinfo" style={{ color: "black" }}>
                                <div className='d-flex'>
                                    <div className='mx-3 NewLpouserDiv d-flex  align-items-center my-Profile-Div'>
                                        <img src='/images/familyLpo.png' />


                                    </div>
                                    <div className='NewLpouserTextDiv'>
                                        <p className='mb-0 fw-bold'>Family Info<br />
                                            <span className='updateLpo'>Update Info</span>
                                        </p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col className="s33 d-flex  align-items-center">
                            <Link to="/beneficiaries" style={{ color: "black" }}>
                                <div className='d-flex'>
                                    <div className='mx-3 NewLpouserDiv d-flex  align-items-center my-Profile-Div-img'>
                                        <img src='/images/business-teamLpo.png' />

                                    </div>
                                    <div className='NewLpouserTextDiv'>
                                        <p className='mb-0 fw-bold'>Beneficiaries <br></br>
                                            <span className='updateLpo'>Update Info</span> </p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col className="s33 d-flex  align-items-center">
                            <Link to="/Fiduciaries" style={{ color: "black" }}>
                                <div className='d-flex'>
                                    <div className='mx-3 NewLpouserDiv d-flex  align-items-center my-Profile-Div'>
                                        <img src='/images/usersecondLPo.png' />

                                    </div>
                                    <div className='NewLpouserTextDiv'>
                                        <p className='mb-0 fw-bold'>Fiduciaries <br></br>
                                            <span className='updateLpo'>Update Info</span> </p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    </Row>


                </div>

                <div class="AgentGuidanceMainDivs2 mt-2">
                    <Row>
                        <Col span={24} className="px-2 AgentGuidanceColHeading">
                            <h6 className='pb-0 mb-0 fw-bold'>Agent Guidance</h6>
                        </Col>
                    </Row>
                    <Row className='agentGuidanceDiv px-2 d-flex justify-content-between'>
                        <Col className="s22 s221">
                            <Link to="/AssignOption">
                                <div className='NewLpoHeaderss d-flex align-items-center h-100 px-4'>
                                    <div className='AgentsGuidanceOption'>
                                        <h6 className='fw-bold'>My</h6>
                                        <h6 className='fw-bold'>Agents</h6>
                                    </div>

                                </div>
                            </Link>
                        </Col>
                        <Col className="s22 s222">
                            <Link to="/setupGuidance">
                                <div className='NewLpoHeaderss d-flex align-items-center h-100 px-4'>
                                    <div className='AgentsGuidanceOption'>
                                        <h6 className='fw-bold'>Set</h6>
                                        <h6 className='fw-bold'>Guidance</h6>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col className="s22 s223">
                            <Link to="/filecabinet">
                                <div className='NewLpoHeaderss d-flex align-items-center h-100 px-4'>
                                    <div className='AgentsGuidanceOption'>
                                        <h6 className='fw-bold'>File</h6>
                                        <h6 className='fw-bold'>Cabinet</h6>
                                    </div>
                                </div>
                            </Link>
                        </Col>

                    </Row>

                </div>

                <div className="MyProfessionalTeamMainDivs4 mt-1">
                    <Row>
                        <Col span={24} className="px-2 MyProfessionalTeamheading ">
                            <h6 className='pb-0 mb-0 fw-bold'>My Professional Team </h6>
                        </Col>
                    </Row>
                    <Row className='MyProfessionalTeamDiv pt-1 px-2 d-flex justify-content-between'>
                        <Col className="s44 d-flex  align-items-center">
                            <Link to={ "/myprofessoinal"} 
                             style={{cursor: "pointer"}}
                            state={[healthProfessional,"Health"]}  location={getData}>
                                <div className='d-flex mx-4'>
                                    <div className=' NewLpouserDiv d-flex  align-items-center'>
                                        <img src='/images/medical-recovery.png' className='h-75' />

                                    </div>
                                    <div className='NewLpouserTextDivProTeam d-flex  align-items-center' >
                                        <p className='mb-0 '>HEALTH <br></br>
                                            <span className='fw-bold' style={{ color: "black" }}>
                                                {healthProfessional.length > 9 ? healthProfessional.length : <>0{healthProfessional.length}</>}
                                            </span> </p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col className="s44 d-flex  align-items-center">
                            <Link  to={"/myprofessoinal"} state={[housingProfessional,"Housing"]}location={getData}
                            style={{cursor: "pointer"}}
                            >
                                <div className='d-flex mx-4'>
                                    <div className='NewLpouserDiv ' >
                                        <img src='/images/buildingLpo.png' className='h-75' />

                                    </div>
                                    <div className='NewLpouserTextDivProTeam d-flex  align-items-center'>
                                        <p className='mb-0 ' style={{ color: "#751521" }}>HOUSING<br></br>
                                            <span className='fw-bold' style={{ color: "black" }}>
                                                {housingProfessional?.length > 9 ? housingProfessional?.length : <>0{housingProfessional?.length}</>}

                                            </span> </p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col className="s44 d-flex  align-items-center">
                            <Link to={ "/myprofessoinal"} 
                            state={[financeProfessional,"Finance"]} 
                            style={{cursor: "pointer"}}
                            
                            >

                                <div className='d-flex mx-4'>
                                    <div className=' NewLpouserDiv d-flex  align-items-center'>
                                        <img src='/images/cashLpo.png' className='h-75' />

                                    </div>
                                    <div className='NewLpouserTextDivProTeam d-flex  align-items-center'>
                                        <p className='mb-0 '>FINANCE <br></br>
                                            <span className='fw-bold' style={{ color: "black" }}>
                                                {financeProfessional.length > 9 ? financeProfessional.length : <>0{financeProfessional.length}</>}

                                            </span> </p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col className="s44 d-flex  align-items-center">
                            <Link to={ "/myprofessoinal"} 
                            style={{cursor: "pointer" }}
                            
                            state={[legalProfessional,"Legal"]}>

                                <div className='d-flex mx-4'>
                                    <div className=' NewLpouserDiv d-flex  align-items-center'>
                                        <img src='/images/balancelpo.png' className='h-75' />

                                    </div>
                                    <div className='NewLpouserTextDivProTeam d-flex  align-items-center'>
                                        <p className='mb-0 ' style={{ color: "#751521" }}>LEGAL <br></br>
                                            <span className='fw-bold' style={{ color: "black" }}>
                                                {legalProfessional.length > 9 ? legalProfessional.length : <div>0{legalProfessional.length}</div>}

                                            </span> </p>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col className="s44 d-flex  align-items-center">
                            <Link to={ "/myprofessoinal"} 
                            style={{cursor: "pointer" }}
                            
                            state={[otherProfessional,"Other"]}>

                                <div className='d-flex mx-4'>
                                    <div className='NewLpouserDiv d-flex  align-items-center'>
                                        <img src='/images/addLpo.png' className='h-75' />

                                    </div>
                                    <div className='NewLpouserTextDivProTeam d-flex  align-items-center'>
                                        <p className='mb-0 ' style={{ color: "#751521" }}>OTHER <br></br>
                                            <span className='fw-bold' style={{ color: "black" }}>
                                                {otherProfessional.length > 9 ? otherProfessional.length : <>0{otherProfessional.length}</>}

                                            </span> </p>
                                    </div>
                                </div>
                            </Link>
                        </Col>

                    </Row>
                </div>
            

            </div>

        </div >

    )
}