import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import commonLib from "../control/commonLib";
import konsole from "../network/konsole";
import Services from "../network/Services";

const LoginPage = (props) => {
    console.log("props", props);
    const navigate = useNavigate();
    const tokenKey = useLocation().search;
    const { setdisable } = useContext(UserContext);
    useEffect(() => {
        const tokenMapKey = commonLib.GetQueryValues(tokenKey, "token");
        konsole.log("tokenMapKey", tokenMapKey);
        const decodedKey = window.atob(tokenMapKey);
        konsole.log("dece", decodedKey);
        const loggenInId = commonLib.GetQueryValues(decodedKey, "loggenInId") || "";
        const userId = commonLib.GetQueryValues(decodedKey, "userId") || "";
        const roleId = commonLib.GetQueryValues(decodedKey, "roleId") || "";
        const appState = commonLib.GetQueryValues(decodedKey, "appState") || "";

        if (loggenInId !== null && userId !== null && roleId !== null && appState !== null) {
            getLoggedInUser(userId, appState, loggenInId, roleId);
        }
    }, [])

    
    const getLoggedInUser = (userId, appState, loggenInId, roleId) => {

        const promises = Services.getLoggedInUser(userId, appState, loggenInId, roleId)
        promises.then(response => {
            if (response) {
                let loginDetail = {};
                let primaryDetail = {};
                konsole.log("responsesadasas", response);
                if (response.data !== null || response.data !== "") {
                    sessionStorage.setItem("AuthToken", response.data.data.accessToken);
                    loginDetail = {
                        loggedUserId: response.data.data.memberUserId,
                        roleId: response.data.data.roleId,
                        memberName: response.data && response.data.data.memberName,
                        primaryEmailId: response.data && response.data.data.primaryEmailId,
                        userName: response.data && response.data.data.userName,
                        spouseUserId: response.data && response.data.data.spouseUserId,
                        loginUserName: response.data && response.data.data.loginUserName
                    }
                    if (true) {
                        konsole.log(response.data.data,"response.data.data")
                        loginDetail = {
                            loggedUserId: response.data.data.memberUserId,
                            roleId: response.data.data.roleId,
                            memberName: response.data && response.data.data.memberName,
                            primaryEmailId: response.data && response.data.data.primaryEmailId,
                            userName: response.data && response.data.data.userName,
                            spouseUserId: response.data && response.data.data.spouseUserId,
                            spouseName: response.data && response.data.data.spouseName,
                            loginUserName: response.data && response.data.data.loginUserName
                        }
                        sessionStorage.setItem("userLoggedInDetail", JSON.stringify(loginDetail));
                    }
                    if (true) {
                        primaryDetail = {
                            memberUserId: response.data.data.memberUserId,
                            roleId: response.data.data.roleId,
                            memberName: response.data && response.data.data.memberName,
                            primaryEmailId: response.data && response.data.data.primaryEmailId,
                            userName: response.data && response.data.data.userName,
                            spouseUserId: response.data && response.data.data.spouseUserId,
                            spouseName: response.data && response.data.data.spouseName,
                            loginUserName: response.data && response.data.data.loginUserName
                        }
                        sessionStorage.setItem("userPrimaryInDetail", JSON.stringify(primaryDetail));
                    }
                    const stateObj = {
                        loggenInId: loggenInId,
                        userId: userId,
                        roleId: roleId,
                        appState: appState
                    }
                    commonLib.setSessionStoarge("stateObj", JSON.stringify(stateObj));
                    const subtenantId = response.data.data.subtenant_Id;
                    sessionStorage.setItem("subtenantId", response.data.data.subtenant_Id);
                    // getSubtenantDetail(subtenantId, true);
                }
                if (appState != null && (roleId == 9 || roleId == 1)) {
                    setdisable(true);
                    commonLib.setSessionStoarge("disabled", true);
                    navigate({
                        pathname: '/Dashboard',
                    })
                }
            }
        }).catch(err => {
            konsole.log("err", err);
            commonLib.unAuthurizeAccess();
        })
            .finally(() => {
                konsole.log("finish");
            })
    }

    // const getSubtenantDetail = (subtenantId, isActive) => {
    //   setLoader(true);
    //   const promises = Services.getSubtenantDetail(subtenantId, isActive);
    //   promises.then(res => {
    //     setLoader(false);
    //     konsole.log('subtenantResObj', res);
    //     const subtenantRes = res.data.data[0];
    //     setSubtenantLogo(subtenantRes.subtenantLogoUrl);
    //     const seminarEventObj = commonLib.getEventListSubtenantObj(subtenantRes);
    //     getSeminarPublisherDetail(subtenantId, true, seminarId, seminarEventObj);
    //   }).catch(err => {
    //     konsole.log("error", err.response);
    //     setLoader(false);
    //   })
    // }



    return (
        <div>
            {
                <>
                </>
            }
        </div>
    )
};


export default LoginPage;

