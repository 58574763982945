import React, { Component } from "react";
import { Button, Form, Row, Col, Modal, Select, Radio, Checkbox } from "antd";
// import Other from './asssets/Other';
import Services from "../../../network/Services";
import konsole from "../../../network/konsole";
import Toaster from "../../Toaster";
import Other from "../../Other";

class Veteran extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      logginUserId: sessionStorage.getItem("stateObj").userId || "",
      warTimePeriodList: [],
      DischargeTypes: [],
      updateVeteran: false,
      userId: this.props.userId,
      activeServiceDuration: null,
      warzone: null,
      wartimePeriod: "",
      dischargeTypeId: "",
      activityTypeId: "2",
      veteranId: "",
      natureId: "",
      veteranChecked: false,
      newStateCheckeed: false
    };
    this.checkboxRef = React.createRef();
    this.warTimeRef = React.createRef();
    this.dischargeRef = React.createRef();
  }

  componentDidMount() {
    let newuserid = this.props.userId || "";
    let logginUserId = sessionStorage.getItem("stateObj").userId || "";
    this.setState({
      userId: newuserid,
      logginUserId: logginUserId,
    });

    this.fetchDischargeType();
    this.fetchWarTimePeriodList();
    konsole.log("isVeteranChecked", this.props.isVeteranChecked);
    // if (this.props.isVeteranChecked) {
    this.fetchVeteranData();
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userId !== this.props.userId) {
      this.setState({
        userId: this.props.userId,
      });
    }
    if (prevProps.isVeteranChecked !== this.props.isVeteranChecked) {
      if (this.props.isVeteranChecked) {
        this.fetchVeteranData();
      }
    }
  }

  fetchDischargeType = () => {
    let promises = Services.getDischargeType();
    promises
      .then((response) => {
        konsole.log("response ", response.data.data);
        if (response) {
          this.setState({
            ...this.state,
            DischargeTypes: response.data.data,
          });
        }
      })
      .catch((error) => {
        konsole.log("errnamesuffixes", error);
      })
      .finally(() => {
        konsole.log("errnamesuffixes");
      });
  };

  fetchWarTimePeriodList = () => {
    let promises = Services.getWarTimePeriod();
    promises
      .then((response) => {
        konsole.log("response ", response.data.data);
        if (response) {
          this.setState({
            ...this.state,
            warTimePeriodList: response.data.data,
          });
        }
      })
      .catch((error) => {
        konsole.log("errnamesuffixes", error);
      })
      .finally(() => {
        konsole.log("errnamesuffixes");
      });
  };

  fetchVeteranData = () => {
    let promises = Services.getVeteranByUserId(this.props.userId);
    promises
      .then((response) => {
        konsole.log("response veteran", response.data.data);
        if (response) {
          konsole.log("fetch response data", response);
          let disy = response.data.data.dischargeTypeId.toString();
          this.setState({
            activeServiceDuration: response.data.data.activeServiceDuration,
            warzone: response.data.data.warzone,
            wartimePeriod: response.data.data.wartimePeriod,
            dischargeTypeId: disy,
            natureId: response.data.data.veteranId,
            veteranId: response.data.data.veteranId,
            updateVeteran: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          updateVeteran: false,
        });
      })
      .finally(() => {
        konsole.log("errnamesuffixes");
      });
  };

  handleClose = () => {
    this.setState({
      show: !this.state.show,
      newStateCheckeed: true
    });


  };

  handleShow = (value) => {
    konsole.log("clicked", value.target.checked);
    if (value.target.checked) {
      konsole.log("clicked true");
      this.props.form.setFieldsValue({ isVeteran: value.target.checked });
      this.setState({
        newStateCheckeed: false,

        show: !this.state.show,
        veteranChecked: value.target.checked,
      });
    } else {
      this.props.form.setFieldsValue({ isVeteran: value.target.checked });
      this.setState({
        veteranChecked: value.target.checked,
      });
    }
  };

  handleVeteranSubmit = () => {
    if(this.warTimeRef.current?.state.othersName.length == 0){
      // konsole.log("kkkkkkkkkkkkkkllllllllk",this.warTimeRef.current.state.othersName.length)
      Toaster.error("Please enter the war time period description");

    }else if(this.dischargeRef.current?.state.othersName.length == 0){
      Toaster.error("Please enter the discharge type ");

    }
    else{


      let activeServiceDuration =
      this.state.activeServiceDuration == ""
        ? null
        : this.state.activeServiceDuration;
    let warzone = this.state.warzone == "" ? null : this.state.warzone;

    const PostData = {
      userId: this.state.userId,
      activeServiceDuration: activeServiceDuration,
      warzone: warzone,
      wartimePeriod: this.state.wartimePeriod,
      dischargeTypeId: this.state.dischargeTypeId,
      activityTypeId: this.state.activityTypeId,
      createdBy: this.state.logginUserId,
    };

    let promises = Services.postVeteranByUserid(PostData);
    promises
      .then((response) => {
        konsole.log("response ", response.data.data);
        if (response) {
          let responseVeteran = response.data.data;
          //   alert("Information saved successfully");
          this.checkboxRef.current.checked = true;
          this.props.checkVeternProfile(this.checkboxRef.current.checked);
          if (this.state.wartimePeriod == "999999") {
            this.warTimeRef.current.saveHandleOther(responseVeteran.veteranId);
          }

          if (this.state.dischargeTypeId == "999999") {
            this.dischargeRef.current.saveHandleOther(responseVeteran.veteranId)
          }
          this.fetchVeteranData();
          this.handleClose();
          Toaster.success("Veteran Saved Succesfully");

        }
      })
      .catch((error) => {
        konsole.log("errnamesuffixes", error);
      })
      .finally(() => {
        konsole.log("errnamesuffixes");
      });

    }
   
  };

  handleUpdateVeteran = () => {
    konsole.log("kkkkkkkkkkkkkkllllllllk",this.state.wartimePeriod)
    if(this.warTimeRef.current?.state.othersName.length == 0){
      // konsole.log("kkkkkkkkkkkkkkllllllllk",this.dischargeRef.current.state.othersName.length)
      Toaster.error("Please enter the war time period description");

    }else if(this.dischargeRef.current?.state.othersName.length == 0){
      Toaster.error("Please enter the discharge type ");
    }
    else{

      const updateData = {
        veteranId: this.state.veteranId,
        userId: this.state.userId,
        activeServiceDuration: this.state.activeServiceDuration,
        warzone: this.state.warzone,
        wartimePeriod: this.state.wartimePeriod,
        dischargeTypeId: this.state.dischargeTypeId,
        updatedBy: this.state.logginUserId,
      };
      konsole.log(updateData, "updateData");
  
      let promises = Services.updateVeteranData(updateData);
      promises
        .then((response) => {
          konsole.log("response ", response.data.data.veteranId);
          if (response) {
            let responseVeteran = response.data.data;
            //   alert("Information saved successfully");
            this.checkboxRef.current.checked = true;
            this.props.checkVeternProfile(this.checkboxRef.current.checked);
            if (this.state.wartimePeriod == "999999") {
              this.warTimeRef.current.saveHandleOther(responseVeteran.veteranId);
            }
  
            if (this.state.dischargeTypeId == "999999") {
              this.dischargeRef.current.saveHandleOther(
                responseVeteran.veteranId
              );
            }
            this.fetchVeteranData();
            this.handleClose();
            Toaster.success("Veteran Update Succesfully");

          }
        })
        .catch((error) => {
          konsole.log("errnamesuffixes", error);
        })
        .finally(() => {
          konsole.log("errnamesuffixes");
        });

    }


   
  };

  handleactiveServiceDuration = (event) => {
    const radioName = event.target.name;
    const radioValue = event.target.value;
    // konsole.log(radioName);
    if (radioName == "activeServiceDuration" && radioValue == "Yes") {
      this.setState({ ...this.state, [radioName]: "Yes" });
    } else {
      this.setState({ ...this.state, [radioName]: "No" });
    }
  };

  handleWarZone = (event) => {
    const radioName = event.target.name;
    const radioValue = event.target.value;
    // konsole.log(event.target.name);
    if (radioName == "warzone" && radioValue == "Yes") {
      this.setState({ ...this.state, [radioName]: "Yes" });
    } else {
      this.setState({ ...this.state, [radioName]: "No" });
    }
  };

  handleDeleteVeteran = () => {
    let deleteData = {
      veteranId: this.state.veteranId,
      userId: this.props.userId,
      deletedBy: this.state.logginUserId,
    };
    let promises = Services.deleteVeteranByUserId(deleteData);
    promises
      .then((response) => {
        konsole.log("response ", response.data.data);
        if (response) {
          
          konsole.log("veteran delete response", response);
          this.setState({
            updateVeteran: false,
          });
          this.handleClose();
          Toaster.success("Veteran Delete Succesfully");
          this.resetState();
          this.checkboxRef.current.checked = false;
          konsole.log("checked vox", this.checkboxRef.current.checked);
          this.props.checkVeternProfile(this.checkboxRef.current.checked);
          this.setState({
            dischargeTypeId: "",
            wartimePeriod: "",
          });
        }
      })
      .catch((error) => {
        konsole.log("errnamesuffixes", error);
      })
      .finally(() => {
        konsole.log("errnamesuffixes");
      });
  };

  validate = () => {
    let nameError = "";
    if ((this.state.warzone = "")) {
      nameError = "please choose war zone";
    }
    if (this.state.activeServiceDuration == "") {
      nameError = "Please choose active service";
    }
    if (this.state.dischargeTypeId == "") {
      nameError = "Discharge Type cannot be blank";
    }
    if (this.state.wartimePeriod == "") {
      nameError = "War time cannot be blank";
    }

    if (nameError) {
      alert(nameError);
      return false;
    }
    return true;
  };

  handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };

  resetState = () => {
    this.setState({
      activeServiceDuration: "",
      warzone: "",
      wartimePeriod: "",
      dischargeTypeId: "",
      natureId: "",
      veteranId: "",
    });
  };

  render() {
    konsole.log("veteran state", this.state, this.props);
    return (
      <>
        {" "}
        <span>
          <div className="d-flex align-items-center mx-1">
            <Form.Item
              name="isVeteran"
              valuePropName={this.state.newStateCheckeed == true ? "" : "checked"}
            // style={{accentColor:"gray"}}
            >
              <Checkbox
                disabled={
                  this.props.isVeteranChecked && this.state.updateVeteran
                }

                label={this.props.label}
                ref={this.checkboxRef}
                onChange={this.handleShow}
                checked={this.props.isVeteranChecked}
                className="checkBox-Class"
              >

              </Checkbox>
            </Form.Item>
            <span className="mx-2">
              Check if you are a U.S. Veteran?
            </span>

            {this.props.isVeteranChecked && this.state.updateVeteran && (
              <span className="mx-2 cursor-pointer" onClick={this.handleClose}>
                <img
                  src="/icons/pen-icon.svg"
                  width="25px"
                  className="img-thumbnail rounded-circle"
                />
              </span>
            )}
          </div>
        </span>
        <Modal
          show={this.state.show}
          visible={this.state.show}
          onOk={this.handleClose}
          onCancel={this.handleClose}
          okText="Submit"
          width={500}
          footer={null}
          className="my-modal-class modalpaddingremoveupper"
          maskClosable={false}
          title="Veteran Details"
        >
          <Row className="">
            <Col span={24}>
              <Form>
                <div className="main">
                  <Row>
                    <Col span={24} xs={24} lg={24}>
                      <Form.Item>
                        <Select
                          className="Input"
                          value={this.state.wartimePeriod || undefined}
                          placeholder="War time period"
                          onChange={(event) =>
                            this.setState({ wartimePeriod: event })
                          }
                          size=""
                          style={{
                            marginTop: 10,
                            marginRight: 15,
                          }}
                        >
                          {this.state.warTimePeriodList.map((props) => (
                            <Select.Option value={props.value}>
                              {props.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} lg={24} className="mt-2">
                      {this.state.wartimePeriod == "999999" && (
                        <Form.Item
                          name='Other'
                          rules={[
                            {
                              required: true,
                              message: "Please enter the Other value",
                            },
                          ]}
                        >
                          <Other
                            othersCategoryId={33}
                            dropValue={this.state.wartimePeriod}
                            ref={this.warTimeRef}
                            show="true"
                            form={this.props.form}
                            natureId={this.state.veteranId}
                            userId={this.state.userId}
                          />
                        </Form.Item>
                      )}
                    </Col>

                    <Col span={24} xs={24} lg={24} >
                      <Form.Item>
                        <Select
                          className="Input"
                          placeholder="Discharge Type"
                          value={this.state.dischargeTypeId || undefined}
                          onSelect={(event) =>
                            this.setState({ dischargeTypeId: event })
                          }
                          size=""
                          style={{
                            marginTop: 10,
                            marginRight: 15,
                          }}
                        >
                          {this.state.DischargeTypes.map((props) => (
                            <Select.Option value={props.value}>
                              {props.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24} xs={24} lg={24} className="mt-2">
                      {this.state.dischargeTypeId == "999999" && (
                        <Form.Item
                          //   name='Other'
                          rules={[
                            {
                              required: true,
                              message: "Please enter the Other value",
                            },
                          ]}
                        >
                          <Other
                            othersCategoryId={32}
                            dropValue={this.state.dischargeTypeId}
                            ref={this.dischargeRef}
                            show="true"
                            form={this.props.form}
                            userId={this.state.userId}
                            natureId={this.state.veteranId}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} xs={24} className="mt-2">
                      <p>Did you served at least(1 Day) in active Service ? </p>
                      <Radio.Group
                        name="activeServiceDuration"
                        onChange={this.handleactiveServiceDuration}
                        value={this.state.activeServiceDuration}
                      >
                        <Radio value={"Yes"}>Yes</Radio>
                        <Radio value={"No"}>No</Radio>
                      </Radio.Group>
                    </Col>
                    <Col span={24} xs={24} className="mt-2">
                      <p>Did you serve in war zone ?</p>
                      <Radio.Group
                        name="warzone"
                        onChange={this.handleWarZone}
                        value={this.state.warzone}
                      >
                        <Radio value={"Yes"}>Yes</Radio>
                        <Radio value={"No"}>No</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                </div>
              </Form>

              <></>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end">
            {this.state.updateVeteran && (
              <Button
                className="delete-button me-3"
                onClick={this.handleDeleteVeteran}
                style={{backgroundColor:"#720c20",color:"white"}}
              >
                {" "}
                Delete{" "}
              </Button>
            )}
            <Button
              className="delete-button"
              onClick={
                this.state.updateVeteran
                  ? this.handleUpdateVeteran
                  : this.handleVeteranSubmit
              }
              style={{backgroundColor:"#720c20",color:"white"}}
            >
              {this.state.updateVeteran ? "Update" : "Save"}
            </Button>
          </Row>
        </Modal>
      </>
    );
  }
}

export default Veteran;
