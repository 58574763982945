
import React, { useState } from 'react'
import Navbar from './Navbar';
import Sidemenu from './Sidemenu';
import "../styles/Container.css"
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';

import { Layout, Menu } from 'antd';
const { Header, Sider, Content } = Layout;




function Container({ children }) {

  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout>
      <Navbar className="">
        <Sidemenu />
      </Navbar>
      {/* 
            <Sider trigger={null} collapsible collapsed={collapsed}>
          <div className="logo" />
          <Menu
            theme="white"
            mode="inline"
            defaultSelectedKeys={['1']}
            items={[
              {
                key: '1',
                icon: <UserOutlined />,
                label: 'nav 1',
              },
              {
                key: '2',
                icon: <VideoCameraOutlined />,
                label: 'nav 2',
              },
              {
                key: '3',
                icon: <UploadOutlined />,
                label: 'nav 3',
              },
            ]}
          />
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            //   className: 'trigger',
              onClick: () => setCollapsed(!collapsed),
            })}
        </Sider> */}

      <Layout>
        <Sider breakpoint='lg'
          className='border-0 border-right'
          theme='light'
          width={100}
          collapsedWidth={0}
          trigger={null}
        >
          <Sidemenu />
        </Sider>
        <Content className='rigth-container'>

          <div className="site-layout-background" >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default Container;