import React, {
  useState,
  useEffect,
  useContext,
  useImperativeHandle,
} from "react";
import "./PersonalInfo.css";
import { Typography, Row, Col, Card, Button, Radio, Popconfirm } from "antd";
import edit from "./Images/edit.png";
import deletepng from "./Images/delete.png";
import AddEditAddress from "./AddEditAddress";
import Services from "../../../network/Services";
import konsole from "../../../network/konsole";

import { UserContext } from "../../../App";
import commonLib from "../../../control/commonLib";
const { Text } = Typography;

// export default function AddnewmemberAddress(props) {
const AddnewmemberAddress = React.forwardRef((props, ref) => {
  // useImperativeHandle(ref, () => ({
  //   // This function can be called by the parent component using the ref
  //   runrefun
  //   }));

  const { setLoader } = useContext(UserContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [address, setAddress] = useState([]);
  const [editAddressObj, setEditAddressObj] = useState("");
  const [editAddressActionType, setEditAddressActionType] = useState("ADD");
  const [iSchecked, setiSchecked] = useState(false);
  const [userPrimaryInDetail, setuserPrimaryInDetail] = useState({});
  const [userLoggedInDetail, setuserLoggedInDetail] = useState({});
  const [ischeckedaddresss, setischeckedaddresss] = useState(false);
  const [userprimaryid, setuserprimaryid] = useState("");
  const [loginuserId, setLoginuserId] = useState("");
  const [alladdress, setalladdress] = useState([]);
  const loggedInUserId =
    commonLib.getObjFromStorage("stateObj")?.userId || "demo";
  const userId = props.userId;

  const showAddressModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  useEffect(() => {
    let userPrimaryInDetail = JSON.parse(
      sessionStorage.getItem("userPrimaryInDetail")
    );
    let userLoggedInDetail = JSON.parse(
      sessionStorage.getItem("userLoggedInDetail")
    );
    setuserprimaryid(userPrimaryInDetail.memberUserId);
    setLoginuserId(userLoggedInDetail.loggedUserId);
    getAdressListByUserId();
    getAdressListByUserIdofPrimary(userPrimaryInDetail.memberUserId);
    // props.defaultopen.current = runrefun()
  }, []);

  useEffect(() => {
    if (props.clickedby == "address") {
      invokeEditAddress({}, "ADD");
    }
  }, [props]);

  konsole.log(
    "userLoggedInDetail",
    props,
    userLoggedInDetail,
    userPrimaryInDetail
  );

  const getAdressListByUserId = () => {
    konsole.log(userId,"userIduserIduserIduserId")
    if(userId !== undefined && userId !== null){
      let getAddressByUserIddatapramas = Services.getAddressByUserIddata(userId);
    getAddressByUserIddatapramas
      .then((res) => {
        konsole.log("addressContactRes", res);
        setAddress(res.data.data.addresses);
        const filterAddress = res.data.data.addresses.filter(
          (item) => parseInt(item?.addressTypeId) === 1
        );
        konsole.log("filterAddressfilterAddress", filterAddress);
        let statusoflive =
          filterAddress.length !== 0 &&
          filterAddress.some((item) => {
            return item.sameAsUserId !== null && item?.addressTypeId == 1;
          })
            ? true
            : false;
        setiSchecked(statusoflive);
        konsole.log("statusoflivestatusoflive", statusoflive);
      })
      .catch((err) => {
        console.log(err);
      });

    }
    
  };
  const getAdressListByUserIdofPrimary = (primaryid) => {
   if(primaryid !== undefined && primaryid !== null){
    let results = Services.getAddressByUserIddata(primaryid);
    results
      .then((res) => {
        konsole.log("results", res);
        setalladdress(res.data.data.addresses);
      })
      .catch((err) => {
        konsole.log("results", err);
      });

   }
   
  };

  const deleteAddressByContactId = (userId, addressId) => {
    konsole.log("address delete", userId, addressId);
    const promises = Services.deleteAddressByUserId(
      userId,
      addressId,
      loggedInUserId
    );
    promises
      .then((res) => {
        konsole.log("deleteAdress res => ", res);
        getAdressListByUserId();
      })
      .catch((err) => {
        konsole.log("error deleteAdress res => ", err);
      })
      .finally(() => {
        konsole.log("finish");
      });
  };

  const invokeEditAddress = (address, type) => {
    konsole.log(address, type, "propsofnewaddmember");
    if (
      props.form.getFieldsValue().fName != undefined &&
      props.form.getFieldsValue().lName != undefined &&
      props.form.getFieldsValue().maritalStatusId != undefined
    ) {
      props.setClickedby("address");
    } else {
      props.setClickedby("");
    }

    // if(props.userId == undefined){
    //   props.onFinish()
    // }
    if (props.userId != undefined) {
      showAddressModal();
      setEditAddressObj(address);
      setEditAddressActionType(type);
      props.setClickedby("");
      konsole.log("22222222222222222222");
    }
  };

  const runrefun = () => {
   
  };

  konsole.log("profiletype", props?.profiletype);

  const handleRadioisLive = (e) => {
    if (
      props.userId != undefined &&
      props.form.getFieldsValue().fName != undefined &&
      props.form.getFieldsValue().lName != undefined &&
      props.form.getFieldsValue().maritalStatusId != undefined
    ) {
      props.setClickedby("addresscheckbox");
    } else {
      props.setClickedby("addresscheckbox");
      props.form.submit();
      // props.onFinish()
    }
    konsole.log(props.userId, "propsuserId");

    if (props.userId != undefined) {
      let checkedValue = e.target.value;
      setischeckedaddresss(true);
      konsole.log("handleRadioisLivehandleRadioisLive", props, checkedValue);
      if (
        userId !== "" &&
        userId !== undefined &&
        userId !== null &&
        props.clickedby == "addresscheckbox"
      ) {
        if (checkedValue == true) {
          const addresss = [];
          address.unshift(address[0]);
          console.log("address", addresss);
          konsole.log("address unshift", address);
          setischeckedaddresss(true);
          handleMemberAddress();
        }
        if (checkedValue == false) {
          setischeckedaddresss(false);
          updateAddress();
        }
      }
    }
  };

  const handleMemberAddress = () => {
    setLoader(true);
    const addressPrimary = alladdress[0]?.addressId;
    konsole.log(
      "userPrimaryInDetail",
      userPrimaryInDetail?.memberUserId,
      userLoggedInDetail?.loggedUserId
    );
    let jsonobj = {
      userId: userId,
      addressId: addressPrimary,
      sameAsUserId: userprimaryid,
      isActive: true,
      createdBy: loginuserId,
    };
    konsole.log("jsonobj", JSON.stringify(jsonobj));
    let results = Services.postaddmemberaddress(jsonobj);
    results
      .then((res) => {
        konsole.log("postaddmemberaddress", res);
        setLoader(false);
        getAdressListByUserId();
      })
      .catch((err) => {
        konsole.log("postaddmemberaddress", err);
        setLoader(false);
      })
      .finally(() => {
        konsole.log("postaddmemberaddress");
        setLoader(false);
      });
  };

  const updateAddress = () => {
    setLoader(true);
    const addressPhysical = address.filter((item) => {
      return item.sameAsUserId !== null && item.addressTypeId == 1;
    });
    const isActive = false;
    const isDeleted = true;
    if (
      addressPhysical.length > 0 &&
      addressPhysical[0]?.sameAsUserId == userprimaryid
    ) {
      let jsonobj = {
        userId: userId,
        addressId: addressPhysical[0]?.addressId,
        sameAsUserId: userprimaryid,
        isActive: isActive,
        userAddressId: addressPhysical[0]?.userAddressId,
        isDeleted: isDeleted,
        updatedBy: loginuserId,
      };
      konsole.log("jsonobj", JSON.stringify(jsonobj));
      let results = Services.putupdteMemberAddress(jsonobj);
      results
        .then((res) => {
          konsole.log("postaddmemberaddress", res);
          setLoader(false);
          getAdressListByUserId();
        })
        .catch((err) => {
          setLoader(false);
          konsole.log("postaddmemberaddress", err);
        })
        .finally(() => {
          konsole.log("postaddmemberaddress");
          setLoader(false);
        });
    }
  };
  konsole.log("address", address);

  return (
    <Row>
      <Col span={24} xs={24}>
        <div className="Info ms-2">
          {props?.editProfieType == "Spouse" ||
            (props?.editProfieType == "Children" && commonLib.calculate_age(props.userAge) < 14 && commonLib.calculate_age(props.userAge) < 100 && commonLib.calculate_age(props.userAge)  != null) ? (
            <Text>
              <Row className="m-0 mb-3 p-0 d-flex align-items-start flex-column ">
                <Text strong={true} type="danger" style={{ color: "#76272B" }}>
                  Does your {props?.editProfieType} live with you?
                </Text>
                <Col sm="3" lg="3" className="ps-2">
                  <Col xs="12">
                    <Col span={24} xs={24} className="mt-2">
                      <Radio.Group
                        name="isLive"
                        onChange={handleRadioisLive}
                        value={ischeckedaddresss}
                      >
                        <Radio htmlType="submit" value={true}>
                          Yes
                        </Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Col>
                  </Col>
                </Col>
              </Row>
            </Text>
          ) : null}
          <div className="main">
            <Text strong={true} type="danger" style={{ color: "#76272B" }}>
              Contact Address
            </Text>

            <Row>
              {address.map((address) => (
                <Col className="mt-2" xs={24} lg={8}>
                  <Card
                    title={address?.addressType}
                    style={{ height:"150px" }}
                    extra={
                      <div>
                        {" "}
                        {/* {address.addressType != "Physical" &&  */}
                        <a href="#">
                          <img
                            src={edit}
                            onClick={() => invokeEditAddress(address, "EDIT")}
                          />
                        </a>
                        {/* } */}{" "}
                        {address?.addressType != "Physical" && (
                          <Popconfirm
                            className="cursor-pointer"
                            title='"Are you sure? You want to delete your Contact."'
                            onConfirm={() =>
                              deleteAddressByContactId(
                                userId,
                                address?.addressId
                              )
                            }
                          >
                            <img src={deletepng} />
                          </Popconfirm>
                        )}
                      </div>
                    }
                    bordered={false}
                    className="card"
                  >
                    <p>
                      {/* Massachusetts General Hospital, Fruit Street, Boston, MA,
                     USA Boston 02114 */}
                      <p>{address?.addressLine1}</p>
                    </p>
                  </Card>
                </Col>
              ))}

              {isModalVisible ? (
                <AddEditAddress
                  title="Contact Address"
                  visible={isModalVisible}
                  onOk={showAddressModal}
                  onCancel={showAddressModal}
                  editAddressObj={editAddressObj}
                  getAdressListByUserId={getAdressListByUserId}
                  editAddressActionType={editAddressActionType}
                  address={address}
                  userId={userId}
                />
              ) : (
                <></>
              )}
            </Row>
            <Row>
              {props.userId != undefined &&
              props.form.getFieldsValue().fName != undefined &&
              props.form.getFieldsValue().lName != undefined &&
              props.form.getFieldsValue().maritalStatusId != undefined ? (
                <Button
                  className="mt-2"
                  style={{ color: "#720C20", borderColor: "#720C20" }}
                  onClick={() => invokeEditAddress({}, "ADD")}
                >
                  Add New
                </Button>
              ) : (
                <Button
                  htmlType="submit"
                  className="mt-2"
                  style={{ color: "#720C20", borderColor: "#720C20" }}
                  onClick={() => invokeEditAddress({}, "ADD")}
                >
                  Add New
                </Button>
              )}
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
});
export default AddnewmemberAddress;
