import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Navbar";
// import Sidemenu from '../Sidemenu'
import "../Fiduciaries/ManageFiduciaries.css";
import {
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Col,
  Checkbox,
  Radio,
} from "antd";
import Services from "../../network/Services";
import konsole from "../../network/konsole";
import axios from "axios";
import AddFidBeneficiaries from "./AddFidBeneficiaries";
import { AudioOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import commonLib from "../../control/commonLib";
import Toaster from "../Toaster";
import { Input } from "antd";
import { UserContext } from "../../App";
import Occupation from "../MemberInfo/common/MemberInfoOccupation";
import OccuranceCom from "../OccuranceCom";
const { Search } = Input;
const { Option } = Select;

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1890ff",
    }}
  />
);

const onSearch = (value) => console.log(value);

const data = [
  {
    key: "1",
    Name: "Ruben Dokidis",
    Role: "Fiduciary and Beneficiary ",
    Status: "active",
    Relationship: "Child",
    tags: ["loser"],
  },
  {
    key: "2",
    Name: "Ruben Dokidis",
    Role: "Fiduciary and Beneficiary ",
    Status: "Active",
    Relationship: "Spouse",
    tags: ["loser"],
  },
  {
    key: "3",
    Name: "Ruben Dokidis",
    Role: "Fiduciary and Beneficiary ",
    Status: "Active",
    Relationship: "Friend",
    tags: ["loser"],
  },

  {
    key: "3",
    Name: "Ruben Dokidis",
    Role: "Fiduciary and Beneficiary ",
    Status: "Active",
    Relationship: "Friend",
    tags: ["loser"],
  },
];

export default function ManageFiduciaries() {
  const [apicondition, setApiCondition] = useState("");

  const [deleteUser, setDeleteUser] = useState({
    deleteFiduciaryUserId: "",
    deleteUserRelationshipId: "",
    deleteMemberId: "",
    deleteIsFiduciary: "",
    deleteIsAgent: "",
    deleteMemberIdName: "",
  });

  const [selectApiData, setSelectApiData] = useState({
    fiduciaryUserId: "",
    userRelationshipId: "",
    memberId: "",
    isFiduciary: "",
    isAgent: "",
  });
  const stateObj = commonLib.getObjFromStorage("stateObj");
  const [assignOptionsList, setAssignOptionsList] = useState([]);
  const [role, setRole] = useState();
  const [act, setAct] = useState("");
  const { disable, modalOnDisable, getSavebtn, setGetSaveBtn } =
    useContext(UserContext);
  const { setLoader } = useContext(UserContext);
  const [addfidbenuser, setAddfibenuser] = useState(false);
  const [fidbenuserdata, setfidbenuserdata] = useState();
  const [emailtemp, setemailtemp] = useState();
  const [loggedUserId, setloggedUserId] = useState();
  const [commmediumdata, setcommmediumdata] = useState();
  const [emailtempdata, setemailtempdata] = useState();
  const subtenantId = JSON.parse(sessionStorage.subtenantId);
  const memberdetails = commonLib.getObjFromStorage("userPrimaryInDetail");
  const [value, setValue] = useState(1);
  const [totalarr,setTotalarr] = useState([])


  const onChangeradio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  useEffect(() => {
    konsole.log("userID", stateObj.userId);
    getapicall(stateObj.userId);
   
  }, [value]);

  useEffect(() => {
    if (getSavebtn == true) {
      runGetUpdateFiduciaryStatusAPI();
    }
  }, [getSavebtn]);

  const getapicall = (loggeUserId) => {
    setLoader(true);
    let callapia = Services.getdata(loggeUserId);
    callapia
      .then((res) => {
        console.log("ressFiduciaries", res);
        console.log("viv", res.data.data.fiduciaries);
        setRole(res.data.data.fiduciaries);
        setAssignOptionsList(res.data.data.fiduciaries);
        filteredData(res.data.data.fiduciaries)
        setLoader(false);
        let newData = res.data.data.fiduciaries.map((item, index) => {
          return item.fiduciaryStatus;
        });
        setAct(newData);
      })
      .catch((error) => {
        console.log("errorr", error);
        setLoader(false);
      })
      .finally(() => {
        konsole.log("finish");
      });
  };

  const [selectRowId, setSelectRowId] = useState("");

  const [change, setChange] = useState(false);
  const [showaddfiduciries, setshowaddfiduciries] = useState(false);
  const [fiduciriespropstype, setficuciriespropstype] = useState("");
  const [changeBorder, setChangeBorder] = useState(1);

  const changepage = () => {
    setChange(true);
    setshowaddfiduciries(true);
    // document.getElementById('border').style.border = "2px solid #720c20"
  };

  const ManageFiduciaries = () => {
    setficuciriespropstype("");
    setChange(false);
    setshowaddfiduciries(false);
    setChangeBorder(1);
    document.getElementById("border").style.border = "";
  };

  const addFiduciries = (addFiduciries) => {
    setshowaddfiduciries(true);
    setficuciriespropstype(addFiduciries);
    setChangeBorder(2);
  };

  const selectactive = (record, e) => {
    konsole.log("isfidu", record);
    const fiduciaryUserId = record.fiduciaryUserId;
    const userRelationshipId = record.userRelationshipId;
    const memberId = record.memberId;
    const isFiduciary = e.target.value == "true" ? true : false;
    const isAgent = record.isAgent;

    setApiCondition("status");

    setSelectApiData({
      fiduciaryUserId: fiduciaryUserId,
      userRelationshipId: userRelationshipId,
      memberId: memberId,
      isFiduciary: isFiduciary,
      isAgent: isAgent,
    });

    modalOnDisable();
  };

  const del = (record, e) => {
    konsole.log("deleteId", record);



    let deleteFiduciaryUserId = record.fiduciaryUserId;
    let deleteUserRelationshipId = record.userRelationshipId;
    let deleteMemberId = record.memberId;
    let deleteIsFiduciary = false;
    let deleteIsAgent = record.isAgent;
    let deletemembername = record?.fiduciaryFullName;

    setApiCondition("delete");

    setDeleteUser({
      deleteFiduciaryUserId: deleteFiduciaryUserId,
      deleteUserRelationshipId: deleteUserRelationshipId,
      deleteMemberId: deleteMemberId,
      deleteIsFiduciary: deleteIsFiduciary,
      deleteIsAgent: deleteIsAgent,
      deleteMemberName: deletemembername,
    });

    modalOnDisable();

    // Modal.confirm({
    //   title: 'Confirm',
    //   icon: <ExclamationCircleOutlined />,
    //   content: 'Are you sure you want to delete?',
    //   okText: 'Yes',
    //   cancelText: 'No',
    // });
  };

  const runGetUpdateFiduciaryStatusAPI = () => {
    if (apicondition == "status") {
      konsole.log("selectApiData", selectApiData);
      // setGetSaveBtn(false)

      let promises = Services.UpdateFiduciaryStatus(
        selectApiData.fiduciaryUserId,
        selectApiData.userRelationshipId,
        selectApiData.memberId,
        selectApiData.isFiduciary,
        selectApiData.isAgent,
        stateObj.userId
      );
      promises
        .then((res) => {
          konsole.log("update fiducaries", res);
          Toaster.success("Status changed successfully");
          setGetSaveBtn(false);
        })
        .catch((error) => {
          konsole.log("error", error);
          Toaster.error(error);
        })
        .finally(() => {
          konsole.log("Finish");
          setGetSaveBtn(false);
        });
    } else if (apicondition == "delete") {
      //   let promises = Services.UpdateFiduciaryStatus(deleteUser.deleteFiduciaryUserId, deleteUser.deleteUserRelationshipId, deleteUser.deleteMemberId, deleteUser.deleteIsFiduciary, deleteUser.deleteIsAgent, stateObj.userId);
      //   promises.then((res) => {
      //     konsole.log('DeleteRes', res);
      //     Toaster.success("Fiduciary Delete successfully")
      //     getapicall(stateObj.userId)
      //     setGetSaveBtn(false)
      //   })
      //     .catch((error) => {
      //       konsole.log('error', error)
      //       Toaster.error(error)
      //     }).finally(() => {
      //       konsole.log('Finish')
      //       setGetSaveBtn(false)
      //     })

      let jsonobj = {
        memberUserId: memberdetails?.memberUserId,
        emailToRoleId: 3,
        emailText: `${memberdetails?.memberName} requested deletion of ${deleteUser?.deleteMemberName} from Fiduciaries list.`,
      };
      console.log("jsonobj", JSON.stringify(jsonobj));
      // let promise = Services.emailtoparalegal(jsonobj);
      // setLoader(true);
      // promise
      //   .then((res) => {
      //     console.log("resres", res);
      //     getapicall(stateObj.userId);
      //     setLoader(false);
      //     setGetSaveBtn(false);
      //   })
      //   .catch((err) => {
      //     console.log("err", err);
      //     setGetSaveBtn(false);
      //     // setLoader(false)
      //   })
      //   .finally(() => {
      //     konsole.log("Finish");
      //     setGetSaveBtn(false);
      //   });
    }
  };

  useEffect(() => {
    addfidbeneMail(addfidbenuser, fidbenuserdata);
    getCommMediumPathfuc();
  }, [addfidbenuser, fidbenuserdata]);

  const getCommMediumPathfuc = () => {
    let dataobj = {
      occurrenceId: 9,
      isActive: true,
      subtenantId: subtenantId,
    };
    let getCommMediumparam = Services.getCommMediumPathapi(dataobj);
    getCommMediumparam
      .then((res) => {
        let data = res.data.data[0];
        konsole.log("resresresresres", res.data.data);
        setcommmediumdata(data);
        if (data.commChannelId == 1) {
          GetEmailTemplateapifuc(
            data.applicableEmailTempId,
            data.isActive,
            data.subtenantId
          );
        } else if (data.commChannelId == 2) {
          // GetEmailTemplateapifuc(data.applicableEmailTempId,data.isActive,data.subtenantId)
        } else if (data.commChannelId == 3) {
          // GetEmailTemplateapifuc(data.applicableEmailTempId,data.isActive,data.subtenantId)
        }
      })
      .catch((err) => {
        konsole.log("resresresresres", err);
      });
  };
  const GetEmailTemplateapifuc = (tempid, isactive, subid) => {
    let GetEmailTemplateparam = Services.GetEmailTemplateapi(
      tempid,
      isactive,
      subid
    );
    GetEmailTemplateparam.then((res) => {
      konsole.log("deuu", res.data.data);
      setemailtempdata(res.data.data[0]);
      setemailtemp(res.data.data[0].templateContent);
    }).catch((err) => {
      konsole.log("deuu", err);
    });
  };

  const addfidbeneMail = (fidbenuser, userdata) => {
    if (fidbenuser == true && fiduciriespropstype == "AddFiduciries") {
      konsole.log("hello", userdata);

      let jsonobj = {
        memberUserId: memberdetails?.memberUserId,
        emailToRoleId: 3,
        emailText: `${memberdetails?.memberName} requested to add ${
          userdata?.fName
        } ${userdata?.mName ? userdata?.mName : ""} ${
          userdata?.lName
        } to the Fiduciaries list.`,
      };
      console.log("jsonobj", JSON.stringify(jsonobj));
      // let promise = Services.emailtoparalegal(jsonobj);
      // setLoader(true);
      // promise
      //   .then((res) => {
      //     console.log("resres", res);
      //     getapicall(stateObj.userId);
      //     setLoader(false);
      //     setGetSaveBtn(false);
      //   })
      //   .catch((err) => {
      //     console.log("err", err);
      //     setGetSaveBtn(false);
      //     // setLoader(false)
      //   })
      //   .finally(() => {
      //     konsole.log("Finish");
      //     setGetSaveBtn(false);
        
      //   });
      window.location.replace("/Fiduciaries");
      setAddfibenuser(false);
    } else if (fidbenuser == true && fiduciriespropstype == "EditFiduciary") {
      let newtemp = emailtemp;
      let emailmess = `<span>In Fiduciaries list:<ul>
       @@list
       </ul> </span>`;
      newtemp = newtemp?.replace(newtemp, emailmess);
      let newarr = [];
      for (let i = 0; i < userdata.length; i++) {
        let list = `<li>${userdata[i]}</li>`;
        newarr.push(list);
      }
      let newarrd = newarr.join(" ");
      let finalemail = emailmess.replace("@@list", newarrd);
      konsole.log("newtempnewtemp", newarrd, finalemail);
      let jsonobj = {
        memberUserId: memberdetails?.memberUserId,
        emailToRoleId: 3,
        emailText: finalemail,
      };
      console.log("jsonobj", JSON.stringify(jsonobj));
      // let promise = Services.emailtoparalegal(jsonobj);
      // setLoader(true);
      // promise
      //   .then((res) => {
      //     console.log("resres", res);
      //     getapicall(stateObj.userId);
      //     setLoader(false);
      //     setGetSaveBtn(false);
      //   })
      //   .catch((err) => {
      //     console.log("err", err);
      //     setGetSaveBtn(false);
      //     // setLoader(false)
      //   })
      //   .finally(() => {
      //     konsole.log("Finish");
      //     setGetSaveBtn(false);
         
      //   });
      window.location.replace("/Fiduciaries");
      setAddfibenuser(false);
    }
  };

  const isCheckboxActive = (e) => {
    if ("checked" == e) {
      document.getElementById("flexCheckDefaultin").checked = false;
    }
  };

  const isCheckboxInactive = (e) => {
    if ("checked" == e) {
      document.getElementById("flexCheckDefault").checked = false;
    }
  };

  const [inputFilter, setInputFilter] = useState("");
  const [activeClass, setActiveClass] = useState(false);
  const [inActiveClass, setInActiveClass] = useState(false);

  const checkboxactive = (e) => {
    if (e.target.checked == true) {
      setActiveClass(true);
      setInActiveClass(false);
    } else {
      setInActiveClass(true);
    }
  };

  const checkboxInactive = (e) => {
    if (e.target.checked == true) {
      setInActiveClass(true);
      setActiveClass(false);
    } else {
      setActiveClass(true);
    }
  };

  // ...........................................................................................................................

  const filteredData = (data) => {
    let filteredData = [];
   var assignOptionsList =data
    if (inputFilter !== "") {
      filteredData = assignOptionsList.filter((items) =>
        items.fiduciaryFullName
          .toLowerCase()
          .includes(inputFilter.toLocaleLowerCase())
      );
    } else if (
      inputFilter !== "" &&
      activeClass == true &&
      inActiveClass == false
    ) {
      filteredData = assignOptionsList.filter(
        (items) =>
          items.fiduciaryFullName
            .toLowerCase()
            .includes(inputFilter.toLocaleLowerCase()) &&
          items.fiduciaryStatus == "Active"
      );
    } else if (
      inputFilter !== "" &&
      activeClass == true &&
      inActiveClass == true
    ) {
      filteredData = assignOptionsList.filter(
        (items) =>
          items.fiduciaryFullName
            .toLowerCase()
            .includes(inputFilter.toLocaleLowerCase()) &&
          items.fiduciaryStatus == "Active" &&
          items.fiduciaryStatus == "Inactive"
      );
    } else if (
      inputFilter !== "" &&
      activeClass == false &&
      inActiveClass == true
    ) {
      filteredData = assignOptionsList.filter(
        (items) =>
          items.fiduciaryFullName
            .toLowerCase()
            .includes(inputFilter.toLocaleLowerCase()) &&
          items.fiduciaryStatus == "Inactive"
      );
    } else if (
      inputFilter == "" &&
      activeClass == true &&
      inActiveClass == false
    ) {
      filteredData = assignOptionsList.filter(
        (items) => items.fiduciaryStatus == "Active"
      );
    } else if (
      inputFilter == "" &&
      activeClass == false &&
      inActiveClass == true
    ) {
      filteredData = assignOptionsList.filter(
        (items) => items.fiduciaryStatus == "Inactive"
      );
    } else if (
      inputFilter == "" &&
      activeClass == true &&
      inActiveClass == true
    ) {
      filteredData = assignOptionsList;
    } else {
      filteredData = assignOptionsList;
    }
    let finaldata = (value == 2 ? filteredData.filter((e)=>e.fiduciaryUserId != memberdetails.spouseUserId) : filteredData.filter((e)=>e.memberRelationshipId != 0))

    // konsole.log(finaldata.sort((a, b) => a.memberRelationshipId - b.memberRelationshipId),"finaldata")
    // let sortedarr = finaldata.sort((a, b) => a.memberRelationshipId - b.memberRelationshipId)

    const index = finaldata.findIndex(obj => obj.fiduciaryUserId === memberdetails.spouseUserId);

if (index !== -1) {
  const [result] = finaldata.splice(index, 1);
  finaldata.unshift(result);
}
  getMemberrelationship(finaldata)
  };

  const getMemberrelationship =(finaldata)=>{
    let foundIndex = finaldata.findIndex((e)=>e.fiduciaryUserId == memberdetails.memberUserId)
    var filterdatalist = finaldata
    let getmemberrelationshiparamas = Services.getmemberrelationshipservice(memberdetails.spouseUserId)
    getmemberrelationshiparamas.then((response)=>{
      konsole.log(response,"responserelation")
      let res = response.data.data
      if(value == 2){
        if(res.isFiduciary  == false        ){
          filterdatalist = filterdatalist.filter((e)=>e.fiduciaryUserId != memberdetails.memberUserId)
        }else{
          filterdatalist[foundIndex].userRolesName = res.isFiduciary == true ? "Fiduciary" : null
        }
      }
      setTotalarr(filterdatalist)
    }).catch((error)=>{
      konsole.log(error,"error")
    })
    if(value == 1){
      setTotalarr(finaldata)
    }
  }
  konsole.log(totalarr,value,"totalarrtotalarrtotalarrtotalarr")

  // ...........................................................................................................................

  return (
    <div>
      <div className="container-fluid">
        <h6>
          <Link to="/Dashboard" className="text-primery">
            Dashboard
          </Link>
          {">"}Fiduciaries
        </h6>
        {/* <OccuranceCom /> */}
        <br />
        <div className="row">
          <div className="col-sm-6 d-flex">
            <button
              className={`${
                changeBorder === 1 ? "borderOnClick" : ""
              } schedule  bg-white fw-bold`}
              onClick={() => ManageFiduciaries()}
            >
              Manage Fiduciaries
            </button>{" "}
            <span>
              <button
                className={`${
                  changeBorder === 2 ? "borderOnClick" : ""
                } schedule ms-3 bg-white fw-bold`}
                onClick={() => addFiduciries("AddFiduciries")}
                disabled={fiduciriespropstype == "EditFiduciary" ? true : false}
              >
                {fiduciriespropstype == "EditFiduciary"
                  ? "Edit Fiduciaries"
                  : "Add Fiduciaries"}{" "}
              </button>
            </span>
          </div>
          <div className="col-sm-6"></div>
        </div>

        {showaddfiduciries == true ? (
          <AddFidBeneficiaries
            type={fiduciriespropstype}
            userId={selectRowId}
            ManageFiduciaries={ManageFiduciaries}
            Addfiduser={setAddfibenuser}
            Fiduserdata={setfidbenuserdata}
          />
        ) : (
          <>
            <div>
              <form>
                <Row className="">
                  <Col span={14} xs={24} sm={18} md={14} lg={14} xl={14}>
                    {memberdetails?.spouseName && <div className="mt-4">
                      <Radio.Group onChange={onChangeradio} value={value}>
                        <Radio value={1}>{memberdetails.memberName}</Radio>
                        <Radio value={2}>{memberdetails.spouseName}</Radio>
                      </Radio.Group>
                    </div>}
                    <Input.Search
                      className="colors1 w-100 mt-2"
                      placeholder="Search for  Fiduciaries"
                      onSearch={onSearch}
                      style={{
                        marginTop: 15,
                        borderRadius: 10,
                      }}
                      onChange={(e) => {
                        const currValue = e.target.value;
                        let filteredData = [];
                        let ficiaryDetailsUser = [];
                        ficiaryDetailsUser = assignOptionsList;
                        if (currValue !== "") {
                          filteredData = totalarr.filter(
                            (entry) => {
                              if (
                                entry.fiduciaryFullName?.toLowerCase()?.includes(currValue.toLowerCase()) ||entry.memberRelationshipName?.toLowerCase()?.includes(currValue.toLocaleLowerCase()) ||entry.userRolesName?.toLowerCase()?.includes(currValue.toLocaleLowerCase())
                              ) {
                                return entry;
                              }
                            }
                          );
                        } else {
                          filteredData = assignOptionsList;
                        }
                        setTotalarr(filteredData);
                      }}
                    />
                  </Col>
                  {/* <button className='Fiduciaries-add border-0 px-3 text-light rounded  fw-bold p-2 ms-5 mt-3' onClick={() => addFiduciries("AddFiduciries")}>
                      + ADD NEW
                    </button> */}
                </Row>
              </form>
            </div>

            {/* <div  style={{ display: "flex" , marginTop:"4px" }}>

              <div class="form-check"  >
                <input class="form-check-input" type="checkbox" id="flexCheckDefault" onChange={checkboxactive} onClick={() => isCheckboxActive("checked")} />

                <label class="form-check-label text-success fw-bold" for="flexCheckDefault">
                  Active
                </label>
              </div>
              <div class="form-check ms-4 " >
                <input class="form-check-input" type="checkbox" onChange={checkboxInactive} onClick={() => isCheckboxInactive("checked")} />
                <label class=" text-warning fw-bold" for="flexCheckDefaultin">
                  Inactive
                </label>
              </div>
            </div> */}

            {/* <div className='col-sm-10 text-end '><button className='Fiduciaries-add float-end  border-0 p-2  px-3 text-light rounded float-end fw-bold' onClick={changepage}><img src="./images/succesfullyp.png"></img> ADD NEW</button></div> */}
            {/* <div className='col-sm-10 text-end '><button className='Fiduciaries-add float-end  border-0 p-2  px-3 text-light rounded float-end fw-bold' onClick={changepage}><img src="./images/succesfullyp.png"></img> ADD NEW</button></div> */}

            {/*  */}

            <Table
              className="mt-2"
              dataSource={totalarr}
              pagination={false}
              onRow={(record) => ({
                onClick: (e) => {
                  console.log("AAAAA", record);
                  setSelectRowId(record.fiduciaryUserId);
                },
              })}
              scroll={{
                y: 1000,
              }}
            >
              <Row
                title="Name"
                className="manage shadow-sm text-center  "
                dataIndex="fiduciaryFullName"
                key="name"
                style={{ height: 30, textAlign: "center", position: "sticky" }}
              />

              <Row
                title="Role"
                className="manage   text-center shadow-sm h-30"
                dataIndex="userRolesName"
                key="Role"
                style={{ position: "sticky" }}
              />

              {/* <rowGroup
                // Status: newData === true?
                className="manage shadow-sm "
                title="Status"
                key="fiduciaryStatus"
                dataIndex="fiduciaryStatus"
                style={{ position:"sticky" }}




                render={(_, record) => (

                  <Space size="middle" className="fw-bold border-gray " >


                    <select
                      onChange={(event) => selectactive(record, event)}

                      className={`${(record.fiduciaryStatus == "Active") ? 'colorChange' : 'colorRed'} border-0`}
                      value={record.isFiduciary}
                    >



                      <option

                        className='Active border-0 text-Success text-center'
                        // style={{color: "green"}}
                        value="true"
                      >
                        Active
                      </option>


                      <option
                        className='Inactive border-0 text-warning text-center'
                        value="false"
                      // style={{color: "red"}}
                      >
                        Inactive
                      </option>



                    </select>

                  </Space>
                )}
              /> */}

              <Row
                title="Relationship"
                className="manage  text-center shadow-sm border-gray"
                // dataIndex={`${value == 2  ? "rltnTypeWithSpouse" : "memberRelationshipName" } ` == null ? "memberRelationshipName" : "memberRelationshipName"}
                style={{ position: "sticky" }}
                render={(_, record) => (
                  <Space size="middle">
                    {  value == 2 && record.rltnTypeWithSpouse != null ? record.rltnTypeWithSpouse :  record.memberRelationshipId == 0
                        ? "Spouse" :record.memberRelationshipName}
                  </Space>
                )}
              />

              <Row
                className="manage   text-center shadow-sm "
                title="Action"
                key="action"
                style={{ position: "sticky" }}
                render={(_, record) => (
                  
                  <Space size="small" className=" Delete-fiduciaries mb-2 ps-3">
{                record.memberRelationshipId != 0 ?    <Typography.Link
                      onClick={() => addFiduciries("EditFiduciary")}
                      className="text-black"
                    >
                      <img src="/images\Fiduciaries-edit.png"></img>
                      <br />
                      Edit{" "}
                    </Typography.Link>: <div style={{height:"54px"}}>
                      </div>}
                    {/* <Typography.Link className="text-black">
                      {" "}
                      <span onClick={(event) => del(record, event)}>
                        <img
                          height={25}
                          src="/images\Fiduciaries-delete.png"
                        ></img>
                        <br />
                        Delete
                      </span>
                    </Typography.Link> */}
                  </Space>
                )}
              />
            </Table>
            {/*  */}
          </>
        )}
      </div>
    </div>
  );
}
