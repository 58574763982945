import Navbar from '../Navbar'
import { React, useState, useEffect, useContext } from "react";
import "../AssignOptions/AssignOption.css";
import DeleteAgent from "./DeleteAgent";
import RolePopup from "./RolePopup";
import Services from "../../network/Services";
import {
  Row,
  Space,
  Input,
  Table,
  Col,
  Form,
  Button,
  Modal,
  Typography,
} from "antd";
import { Link } from "react-router-dom";
import konsole from "../../network/konsole";
import commonLib from "../../control/commonLib";
import Toaster from "../Toaster";
import { UserContext } from '../../App';

const { Search } = Input;

const columns = [
  {
    align: "center",
    dataIndex: "name",
    key: "name",
    title: "Name",
  },
  {
    align: "center",
    dataIndex: "relation",
    key: "relation",
    title: "Relation",
  },
  {
    align: "center",
    dataIndex: "role",
    key: "role",
    title: "Role",
  },
  {
    align: "center",
    dataIndex: "status",
    key: "status",
    title: "Status",
  },
  {
    align: "center",
    dataIndex: "action",
    title: "Action",
    key: "action",
  },
];

export default function AssignOption() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModal2Visible, setIsModal2Visible] = useState(false);
  const [userAgent, setuserAgent] = useState([]);
  const [userAgentFullData, setUserAgentFullData] = useState([]);
  const [assignOptionsList, setAssignOptionsList] = useState([]);
  const [value, setValue] = useState("");
  const [roleName, setroleName] = useState("");
  const {disable, modalOnDisable} = useContext(UserContext)

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModal2 = () => {
    setIsModal2Visible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleOk2 = () => {
    setIsModal2Visible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancel2 = () => {
    setIsModal2Visible(false);
  };

  const loggeduserid = commonLib.getObjFromStorage("stateObj").userId;
  const primaryUserId = commonLib.getObjFromStorage(
    "userPrimaryInDetail"
  ).userId;

  useEffect(() => {
    const Details = Services.getUserAgent(loggeduserid);
    Details.then((response) => {
      console.log("POST_GET", response);
      console.log("Getted", response.data.data);

      setUserAgentFullData(response.data.data);
      const mappedData = response.data.data.map((data) => ({
        ...data,
        name: data.fullName,
        relation: data.relationWithMember,
        role:
          data.agentRole === null ? (
            <div style={{textDecoration: "underline"}}  onClick={modalOnDisable}>
              <Typography.Link onClick={showModal2} style={{
                color: "rgba(114, 12, 32, 1)",
              }}
              disabled={disable}>Assign a Role</Typography.Link >
            </div>
          ) : (
            <div className="text-center" onClick={modalOnDisable} >
                <Typography.Link onClick={showModal2} disabled={disable} style={{
                  color: "rgba(0,0,0)",
                }}>{data.agentRole}</Typography.Link><br/>
                <Typography.Link onClick={showModal2} disabled={disable} style={{
                  color: "rgba(0,0,0)",
                }}>{data.agentRank}</Typography.Link>
            </div>

          ),
        // rank:data.agentRank,
        status:
          data.agentAcceptanceStatus === true &&
            (data.agentRoleId !== 0 || data.agentRoleId !== null)
            ? "Accepted"
            : data.agentAcceptanceStatus === false &&
              (data.agentRoleId == 0 || data.agentRoleId == null)
              ? "Assign"
              : data.agentAcceptanceStatus === false &&
                (data.agentRoleId !== 0 || data.agentRoleId !== null)
                ? "Pending"
                : "",
        action: (
          <Space size="middle" style={{ padding: "8px" }}  onClick={modalOnDisable}>
            {data.agentAcceptanceStatus === true &&
              (data.agentRoleId !== 0 || data.agentRoleId !== null) ? (
              <>
                <Typography.Link style={{ fontWeight: "bold", color:"black" }} onClick={showModal} disabled={disable}>
                  <img alt="Delete" src="/images/Fiduciaries-delete.png" />
                  <br />
                  Delete
                </Typography.Link>
              </>
            ) : data.agentAcceptanceStatus === false &&
              (data.agentRoleId == 0 || data.agentRoleId == null) ? (
              <>
                <Typography.Link
                  style={{ fontWeight: "bold", color:"black"}}
                  onClick={() =>
                    Toaster.success({
                      content: "Invite Sent Successfuly",
                      afterClose: () => {
                        console.log("close");
                      },
                    })
                  }
                  disabled={disable}
                >
                  <img alt="ReInvite" src="/images/ReInvite.png" />
                  <br />
                  Invite
                </Typography.Link>
              </>
            ) : data.agentAcceptanceStatus === false &&
              (data.agentRoleId !== 0 || data.agentRoleId !== null) ? (
              <>
                <Typography.Link
                  style={{ fontWeight: "bold", color:"black" }}
                  onClick={() =>
                    Toaster.success({
                      content: "Invite Sent Successfully",
                      afterClose: () => {
                        console.log("close");
                      },
                    })
                  }
                  disabled={disable}
                >
                  <img alt="Invite" src="/images/ReInvite.png" />
                  <br />
                  Re-Invite
                </Typography.Link>
              </>
            ) : ""}
          </Space>
        ),
      }));

      // setroleName()
      console.log("ROLENAME", mappedData);
      setuserAgent(mappedData);
      setAssignOptionsList(mappedData);
    }).catch((error) => {
      console.log("Error", error);
    });
  }, []);

  console.log("userAgent", userAgent);

  const deleteFooter = () => {
    return (
      
      <div className="d-flex align-middle rolePopBtns">
        <Form.Item>
          <Button
            htmlType="submit"
            className="text-white fw-bold rounded border-0 align-middle ms-2"
            style={{ backgroundColor: "#720c20" }}
            onClick={handleCancel}
          >
            Delete
          </Button>
        </Form.Item>
      </div>
    );
  };

  return (
    <div>
    
      <div className="container-fluid">
        <h6>
          <Link to="/Dashboard" className="text-primery">
            Dashboard
          </Link>
          <span>{">"}</span>Assign Roles
        </h6>
        <br/>
        {/*<div className="row">
          <div>
            <div className="row d-flex">
              <div>
                {" "}
                <button
                  style={{ color: "" }}
                  className=" top-btnM bg-white fw-bold shadow"
                >
                  Manage Roles
                </button>
              </div>
            </div>
          </div>
  </div>*/}

        <Form>
          <Row className="mt-2">
            <Col span={24} lg={16}>
              <Form.Item  onClick={modalOnDisable}>
                <Input.Search
                  style={{ borderRadius: "4px" }}
                  placeholder="Search for Successor"
                  value={value}
                  onChange={(e) =>
                  // setuserAgent(e.target.value)
                  {
                    const currValue = e.target.value;
                    setValue(currValue);
                    let filteredData = [];
                    let userAgent = [];
                    userAgent = assignOptionsList;
                    // konsole.log("mpaeed", userAgent);
                    if (currValue !== "") {
                      filteredData = userAgent.filter((entry) => {
                        if (
                          entry.name
                            .toLowerCase()
                            .includes(currValue.toLowerCase()) ||
                          entry.relation
                            .toLowerCase()
                            .includes(currValue.toLocaleLowerCase()) ||
                          entry.status
                            .toLowerCase()
                            .includes(currValue.toLocaleLowerCase())
                        ) {
                          return entry;
                        }
                      });
                    } else {
                      filteredData = assignOptionsList;
                    }
                    konsole.log("mpaeed filtered", userAgent);
                    setuserAgent(filteredData);
                  }
                  }
                  disabled={disable}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <div className="row mt-3">
          <div className="col-12 chechBoxes">
            <div class="form-check "  onClick={modalOnDisable}>
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                disabled={disable}
              />
              <label
                class="form-check-label text-success fw-bold"
                for="flexCheckDefault"
              >
                Agent Accepted
              </label>
            </div>

            <div class="form-check ms-4"  onClick={modalOnDisable}>
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                disabled={disable}
              />
              <label
                class="form-check-label text-warning fw-bold"
                for="flexCheckDefault"
              >
                Agent Pending
              </label>
            </div>

            <div class="form-check  ms-4"  onClick={modalOnDisable}>
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                disabled={disable}
              />
              <label
                class="form-check-label text-danger fw-bold"
                for="flexCheckDefault"
              >
                Assign Agent
              </label>
            </div>
          </div>
        </div>

        {/*  */}

        <Table
          dataSource={userAgent}
          pagination={false}
          columns={columns}
          bordered
          className="rounded-3 table-responsive-md mt-3"
          onRow={(record) => ({
            onClick: (e) => {
              const filteredData = userAgent.filter((filter) => {
                return filter.agentUserId === record.agentUserId;
              });
              const rolePopData = {
                assignAgent: filteredData[0],
                handleCancel2,
              };
              setroleName(rolePopData);
              console.log("name", rolePopData.assignAgent);
            },
          })}
          scroll={{
            y: 300,
          }}
        ></Table>
        {isModal2Visible == true ? (
          <RolePopup
            agent={roleName}
            isModal2Visible={isModal2Visible}
            handleCancel2={handleCancel2}
          />
        ) : (
          <></>
        )}
        {isModalVisible == true ? (
          <Modal
            maskClosable={false}
            visible={isModalVisible}
            onCancel={handleCancel}
            footer={deleteFooter()}
            onOk={handleOk}
            width={550}
            closable={true}
          >
            <DeleteAgent />
          </Modal>
        ) : (
          <></>
        )}


      </div>
    </div>
  );
}
