import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../../Navbar";
import { Button, Col, Row } from "antd";
import Myprofessionalteam from "../MyProfessonialTeam/MyProfessionalteam";
import konsole from "../../../network/konsole";
import AddnewProfessmember from "./AddnewProfessmember";
import Services from "../../../network/Services";
import commonLib from "../../../control/commonLib";
import { UserContext } from "../../../App";
import Toaster from "../../Toaster";
import "../MyProfessionalModal/./mymidal.css"

const Myprofessonialmodal = (props) => {
  const location = useLocation();
  const loggedUser =
    commonLib.getObjFromStorage("userLoggedInDetail")?.loggedUserId;
  const [data, setData] = useState([]);
  const [proTypId, setProTypId] = useState(
    location?.state ? location?.state[0][0] : null
  );
  const [AddnewProfessmemberis, setAddnewProfessmemberis] = useState(false);
  const [editmember, setEditmember] = useState();
  const { setLoader } = useContext(UserContext);
  const [protype, setprotype] = useState();
  const [typeofmodal,settyoeofmodal]=useState('ADD')
  
  const [getData, setDatas] = useState([]);

 konsole.log("jdaeyyu777",proTypId)
  useEffect(() => {
    konsole.log("daplyyu",location)
    const userId = commonLib.getObjFromStorage("userPrimaryInDetail")?.memberUserId;
    getProfessionalByUserId(userId);
    // setData(location?.state ? location.state[0] : null);
    
  }, []);
  konsole.log(location.state, props, "location");
  const editproff = (e) => {
    konsole.log(e, "editproff");
    setAddnewProfessmemberis(true);
    setEditmember(e.item);
    settyoeofmodal('EDIT')
  };
  const getProfessionalByUserId = (userId) => {
    const promises = Services.getProfessionalByUserId(userId);
    promises.then((response) => {
        konsole.log("responseData", response);
        const professional = response.data.data.professionalUser
        const financeProfessional = professional.filter((res) => { return (res.proTypeId === 1 || res.proTypeId === 2 || res.proTypeId === 3 || res.proTypeId === 8 || res.proTypeId === 12 || res.proTypeId === 15) })
        const healthProfessional = professional.filter((res) => { return (res.proTypeId === 7 || res.proTypeId === 10 || res.proTypeId === 11) })
        const housingProfessional = professional.filter(res => [14, 4].includes(res.proTypeId))
        const legalProfessional = professional.filter(res => [6, 13].includes(res.proTypeId))
        const otherProfessional = professional.filter((res) => { return (res.proTypeId === 9 || res.proTypeId === 999999) });

        setDatas(professional)
        if(location.state[1] == "Housing"){
          setData(housingProfessional)
        }else if(location.state[1] == "Health"){
          setData(healthProfessional)
        }else if(location.state[1] == "Finance"){
          setData(financeProfessional)
        }else if(location.state[1] == "Legal"){
          setData(legalProfessional)
        }else{
          setData(otherProfessional)
        }
        
        konsole.log('professional567890', legalProfessional);

    }).catch((error) => {
        konsole.log("error", error)
    }).finally(() => {
        konsole.log("finish")
    })


}

  const addmemberfunc = () => {
    setLoader(true);
    let dataobj = {
      fName: "fname",
      mName: "mName",
      lName: "lName",
      isPrimaryMember: false,
      memberRelationship: null,
      createdBy: loggedUser,
    };

    konsole.log(dataobj, "dataobj");
    let addmemberprams = Services.postaddmember(dataobj);
    addmemberprams
      .then((response) => {
        konsole.log(response, "responseadd");
        setAddnewProfessmemberis(true);
        setEditmember(response.data.data.member);
        setLoader(false);
      })
      .then((error) => {
        konsole.log(error, "error");
        setLoader(false);
      });
  };

  const deleteuser = (data) => {
    konsole.log(data.item, "deleteuser")
    deleteprofessionalfunc(data.item)
  }

  const deleteprofessionalfunc = (data) => {
    setLoader(true)
    let dataobj = {
      userId: loggedUser,
      userProId: data.userProId,
      deletedBy: loggedUser
    }
    let deleteprofessionalparams = Services.deleteprofessional(dataobj);
    deleteprofessionalparams
      .then((response) => {
        konsole.log(response, "response");
        setLoader(false)
        Toaster.success("Professional Member deleted successfully.")
        // window.location.replace("/Dashboard")
        window.location.replace("/myprofessoinal");
      })
      .then((error) => {
        konsole.log(error, "error");
        setLoader(false)
      });
  };
  konsole.log(proTypId, props, "proTypId");
  return (
    <>
      {AddnewProfessmemberis == true   ? (
        <span>
          <AddnewProfessmember
            protype={location?.state != null && location.state[1]}
            proffdata={data}
            editmember={editmember}
            typeofmodal={typeofmodal}
            

          />
        </span>
      ) : (
        <>
          <div style={{ height: "83vh", overflow: "hidden scroll" }}>
            <div className="BackToDash">
              <div>
              {
                proTypId?.proTypeId == 7 ||
                  proTypId?.proTypeId == 11 ||
                  proTypId?.proTypeId == 10 ?
                  <div className="d-flex">
                    &nbsp;{" "}
                    <h6>
                      <Link to="/Dashboard" className="text-primery">
                        Dashboard
                      </Link>
                      {">"}

                      <span className="pt-3">Health</span>
                    </h6>
                  </div>
                  
                    :  (proTypId?.proTypeId == 4 ||   proTypId?.proTypeId == 14) ? 
                      <div className="d-flex">
                        {" "}
                     
                        &nbsp;
                        <h6>
                          <Link to="/Dashboard" className="text-primery">
                            Dashboard
                          </Link>
                          {">"}
                          Housing
                        </h6>
                        </div> 
                        : proTypId?.proTypeId == 13 || proTypId?.proTypeId == 6 ? 
                          <div className="d-flex">
                            {" "}
                            &nbsp;
                            <h6 className="ms-2">
                              <Link to="/Dashboard" className="text-primery">
                                Dashboard
                              </Link>
                              {">"}Legal
                            </h6>
                    </div>
                    :proTypId?.proTypeId == 1 ||
                    proTypId?.proTypeId == 2 ||
                    proTypId?.proTypeId == 3 ||
                    proTypId?.proTypeId == 8 ||
                    proTypId?.proTypeId == 12||
                    proTypId?.proTypeId == 15  ?
                    <div className="d-flex ">
                      &nbsp;
                      <h6>
                        <Link to="/Dashboard" className="text-primery">
                          Dashboard
                        </Link>
                        {">"}
                        Finance
                      </h6>
                    </div>
                    : proTypId?.proTypeId == 9 || proTypId?.proTypeId == 999999 ? 
                      <div className="d-flex">
                        &nbsp;
                        <h6 className="ms-2 ">
                          <Link to="/Dashboard" className="text-primery">
                            Dashboard
                          </Link>
                          {">"}
                          <span className="pt-2">Other</span>
                        </h6>
                        </div>:""
              }
              </div>
              <div>
              <h6>
                    <Link to="/Dashboard" className="text-primery">
                        Home
                      </Link>
                    </h6>
              </div>
              
            </div>
            <div
              className="Box mt-1 ms-4"
              style={{
                position: "sticky",
                top: 0,
                zIndex: 5,
                backgroundColor: "white",
              }}
            >
              {
                proTypId?.proTypeId == 7 ||
                  proTypId?.proTypeId == 11 ||
                  proTypId?.proTypeId == 10 ? (
                  <div className="d-flex justify-content-center">
                    {/* &nbsp;{" "}
                  <h6>
                    <Link to="/Dashboard" className="text-primery">
                      Dashboard
                    </Link>
                    {">"}

                    <span className="pt-3">Health</span>
                  </h6> */}
                    <div className="container d-flex justify-content-center mt-4 ms-4 hold">
                      <Row>
                        <Col
                          span={24}
                          className="ps-3 mt-2 d-flex justify-content-start flex-wrap"
                        >
                          {data?.length > 0 &&
                            data.map((item, index) => {
                              return (
                                <>
                                 <div>
                                  <Myprofessionalteam
                                    item={item}
                                    editproff={editproff}
                                    deleteuser={deleteuser}
                                  />
                                </div>
                                </>
                              );
                            })}
                          <div
                            class="card mainCard w-80 align-item-center "
                            style={{ textAlign: "center", cursor: "pointer" }}
                            onClick={() => {
                              addmemberfunc();
                              setprotype("Health");
                            }}
                          >
                            <h5 style={{ color: "#444444" }}>
                              Add Professional Member{" "}
                            </h5>
                            <div>
                              <img
                                width="50px"
                                height="auto"
                                src="./images/addPlusIcon.svg"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : proTypId?.proTypeId == 1 ||
                  proTypId?.proTypeId == 2 ||
                  proTypId?.proTypeId == 3 ||
                  proTypId?.proTypeId == 8 ||
                  proTypId?.proTypeId == 12 ||
                  proTypId?.proTypeId == 15  ? (
                  <div className="d-flex ">
                    {/* &nbsp;
                  <h6>
                    <Link to="/Dashboard" className="text-primery">
                      Dashboard
                    </Link>
                    {">"}Finance
                  </h6> */}
                    <div className="container d-flex justify-content-center ms-4 mt-4">
                      <Row>
                        <Col
                          span={24}
                          className="ps-3 mt-2 d-flex justify-content-start flex-wrap"
                        >
                          {data?.length > 0 &&
                            data.map((item, index) => {
                              return (
                                <div>
                                  <Myprofessionalteam
                                    item={item}
                                    editproff={editproff}
                                    deleteuser={deleteuser}
                                  />
                                </div>
                              );
                            })}
                          <div
                            class="card mainCard w-80 align-item-center "
                            style={{ textAlign: "center", cursor: "pointer" }}
                            onClick={() => {
                              addmemberfunc();
                              setprotype("Finance");
                            }}
                          >
                            <div>
                              <h5 style={{ color: "#444444" }}>
                                Add Professional Member{" "}
                              </h5>
                              <img
                                width="50px"
                                height="auto"
                                src="./images/addPlusIcon.svg"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : (proTypId?.proTypeId == 4 || proTypId?.proTypeId == 14)  ? (
                  <div className="d-flex">
                    {" "}
                    {/* &nbsp;
                    <h6>
                      <Link to="/Dashboard" className="text-primery">
                        Dashboard
                      </Link>
                      {">"}
                      Housing
                    </h6> */}
                    <div className="container justify-content-center mt-4">
                      <Row>
                        <Col
                          span={24}
                          className="ps-3 mt-2 d-flex justify-content-start flex-wrap"
                        >
                          {data?.length > 0 &&
                            data.map((item, index) => {
                              return (
                                <div>
                                  <Myprofessionalteam
                                    item={item}
                                    editproff={editproff}
                                    deleteuser={deleteuser}
                                  />
                                </div>
                              );
                            })}
                          <div
                            class="card mainCard w-80 align-item-center "
                            style={{ textAlign: "center", cursor: "pointer" }}
                            onClick={() => {
                              addmemberfunc();
                              setprotype("Housing");
                            }}
                          >
                            <div>
                              <h5 style={{ color: "#444444" }}>
                                Add Professional Member{" "}
                              </h5>
                              <img
                                width="50px"
                                height="auto"
                                src="./images/addPlusIcon.svg"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : (proTypId?.proTypeId == 6 || proTypId?.proTypeId == 13) ? (
                  <div className="d-flex">
                    {/* &nbsp;
                    <h6 className="ms-2">
                      <Link to="/Dashboard" className="text-primery">
                        Dashboard
                      </Link>
                      {">"}Legal
                    </h6> */}
                    <div className="container d-flex ms-5 mt-4">
                      <Row>
                        <Col
                          span={24}
                          className="ps-3 mt-2 d-flex justify-content-start flex-wrap"
                        >
                          {data?.length > 0 &&
                            data.map((item, index) => {
                              return (
                                <div>
                                  <Myprofessionalteam
                                    item={item}
                                    editproff={editproff}
                                    deleteuser={deleteuser}
                                  />
                                </div>
                              );
                            })}
                          <div
                            class="card mainCard w-80 align-item-center "
                            style={{ textAlign: "center", cursor: "pointer" }}
                            onClick={() => {
                              addmemberfunc();
                              setprotype("Legal");
                            }}
                          >
                            <div>
                              <h5 style={{ color: "#444444" }}>
                                Add Professional Member{" "}
                              </h5>
                              <img
                                width="50px"
                                height="auto"
                                src="./images/addPlusIcon.svg"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : proTypId?.proTypeId == 9 || proTypId?.proTypeId == 999999 ? (
                  <div className="d-flex">
                    &nbsp;
                    {/* <h6 className="ms-2 ">
                      <Link to="/Dashboard" className="text-primery">
                        Dashboard
                      </Link>
                      {">"}
                      <span className="pt-2">Other</span>
                    </h6> */}
                    <div className="container d-flex mt-4">
                      <Row>
                        <Col
                          span={24}
                          className="mt-2 d-flex justify-content-start flex-wrap ms-5 "
                        >
                          {data?.length > 0 &&
                            data.map((item, index) => {
                              return (
                                <div>
                                  <Myprofessionalteam
                                    item={item}
                                    editproff={editproff}
                                    deleteuser={deleteuser}
                                  />
                                </div>
                              );
                            })}
                          <div
                            class="card mainCard w-80 align-item-center "
                            style={{ textAlign: "center", cursor: "pointer" }}
                            onClick={() => {
                              addmemberfunc();
                              setprotype("Other");
                              settyoeofmodal('ADD')
                            }}
                          >
                            <div>
                              <h5 style={{ color: "#444444" }}>
                                Add Professional Member{" "}
                              </h5>
                              <img
                                width="50px"
                                height="auto"
                                src="./images/addPlusIcon.svg"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                ) : (
                  <div
                    class="card mainCard w-80 align-item-center m-4 "
                    style={{ textAlign: "center", cursor: "pointer" }}
                    onClick={() => {
                      addmemberfunc();
                      setprotype(location.state[1]);
                    }}
                  >
                    <div>
                      <h5 style={{ color: "#444444" }}>
                        Add Professional Member{" "}
                      </h5>
                      <img
                        width="50px"
                        height="auto"
                        src="./images/addPlusIcon.svg"
                      />
                    </div>
                  </div>
                )}
              {/* {data?.length == 0 && (
                <div
                  class="card mainCard w-80 align-item-center m-4 "
                  style={{ textAlign: "center", cursor: "pointer" }}
                  onClick={() => {
                    addmemberfunc();
                    setprotype(location.state[1]);
                  }}
                >
                  <div>
                    <h5 style={{ color: "#444444" }}>
                      Add Professional Member 6{" "}
                    </h5>
                    <img
                      width="50px"
                      height="auto"
                      src="./images/addPlusIcon.svg"
                    />
                  </div>
                </div>
              )} */}
            </div>
          </div>
          
          <Row style={{ position: "relative", bottom: 0 }} className="w-100 ">
            <Col span={24} className="ps-3 d-flex justify-content-center ">
              <Link to="/Dashboard" className="text-primery">
                <Button
                  className="fw-bold mb-2"
                  style={{
                    color: "#720C20",
                    borderColor: "#720C20",
                    borderRadius: 5,
                  }}
                >
                  Go Back{" "}
                </Button>
              </Link>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Myprofessonialmodal;
