import Navbar from "../Navbar";
import Sidemenu from "../Sidemenu";
import "../Fiduciaries/ManageFiduciaries.css";
import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Space,
  Table,
  Input,
  Button,
  Col,
  Modal,
  Typography,
  Radio,
} from "antd";
import { AudioOutlined } from "@ant-design/icons";
import Services from "../../network/Services";
import axios from "axios";
// import AddBeneficiaries from '../Fiduciaries/AddBeneficiaries';
import { Link } from "react-router-dom";
import AddFidBeneficiaries from "../Fiduciaries/AddFidBeneficiaries";
import commonLib from "../../control/commonLib";
import RolePopup from "../AssignOptions/RolePopup";
import DeleteAgent from "../AssignOptions/DeleteAgent";
import { UserContext } from "../../App";
import konsole from "../../network/konsole";
import Toaster from "../Toaster";
const { Search } = Input;
const onSearch = (value) => console.log(value);
const data = [
  {
    key: "1",
    Name: "Ruben Dokidis",
    Role: "Fiduciary and Beneficiary ",
    Relationship: "Child",
    render: (text) => <a>{text}</a>,
  },
  {
    key: "2",
    Name: "Jane Williom",
    Role: "Beneficiary ",
    Relationship: "Child",
    responsive: ["md"],
  },
  {
    key: "3",
    Name: "Alex may",
    Role: "Fiduciary and Beneficiary ",
    Relationship: "Spouse",
    responsive: ["lg"],
  },
  {
    key: "3",
    Name: "Copper Aminoff",
    Role: "Beneficiary ",
    Relationship: "Child",
  },
];

export default function BeneficiarysName() {
  // let userId = '2278ab5d-1c21-452b-9387-362e3547c3ea'

  const userId = commonLib.getObjFromStorage("stateObj");
  const loggeUserId = userId.userId;

  const memberdetails = commonLib.getObjFromStorage("userPrimaryInDetail");
  const [value, setvalue] = useState(false);
  const [change, setChange] = useState(false);
  const [addbeneficiriesshow, setaddbeneficiries] = useState(false);
  const [addbenefiiriespropstype, setaddbeneficiriestype] = useState("");
  const [changeBorder, setChangeBorder] = useState(1);

  const [usersData, setUsersData] = useState([]);
  const [beneficiaryDetailsUser, setbeneficiaryDetailsUser] = useState([]);
  const [assignOptionsList, setAssignOptionsList] = useState([]);
  const [query, setQuery] = useState("");
  const [role, setRole] = useState();
  const [data, setData] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModal2Visible, setIsModal2Visible] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [selectRowId, setSelectRowId] = useState("");
  const { disable, modalOnDisable, getSavebtn, setGetSaveBtn } =
    useContext(UserContext);
  const [deleteUser, setDeleteUser] = useState({
    deleteBeneficiaryUserId: "",
    deleteUserRelationshipId: "",
    deleteMemberId: "",
    deleteIsFiduciary: "",
    deleteIsAgent: "",
    deleteMemberIdName: "",
    deleteMemberName: "",
  });
  const { setLoader } = useContext(UserContext);
  const [deleteclick, setdelteclick] = useState(false);
  const [benuserdata, setbenuserdata] = useState();
  const [addbenuser, setAddbenuser] = useState(false);
  const [radiovalue, setradioValue] = useState(1);
  const[totalarr,setTotalarr] = useState([])

  const onChangeradio = (e) => {
    console.log("radio checked", e.target.value);
    setradioValue(e.target.value);
  };

  useEffect(() => {
    if (deleteUser?.deleteIsAgent == true && addbenuser == false) {
      // beneficiariesDeleteApi()
      emailforbeneficierydelete();
    }
  }, [getSavebtn, addbenuser, deleteclick]);
  konsole.log(addbenuser, "addbenuser");

  // console.log("getSavebtn",getSavebtn)
  // useEffect(()=>{

  // },[deleteUser])

  const stateObj = commonLib.getObjFromStorage("stateObj");
  console.log("addbenefiiriespropstype", addbenefiiriespropstype);
  // setaddbeneficiriestype

  konsole.log("disabled", disable);

  console.log("selectRowIdselectRowId", selectRowId);

  const showModal = (record, event) => {
    setdelteclick(true);
    konsole.log("BeneDelete", record?.beneficiaryFullName);
    konsole.log("BeneDeleteBeneDelete", event);
    let deleteBeneficiaryUserId = record.beneficiaryUserId;
    let deleteUserRelationshipId = record.userRelationshipId;
    let deleteMemberId = record.memberId;
    let deleteIsFiduciary = false;
    let deleteIsAgent = true;
    let deletemembername = record?.beneficiaryFullName;
    setDeleteUser({
      deleteBeneficiaryUserId: deleteBeneficiaryUserId,
      deleteUserRelationshipId: deleteUserRelationshipId,
      deleteMemberId: deleteMemberId,
      deleteIsFiduciary: deleteIsFiduciary,
      deleteIsAgent: deleteIsAgent,
      deleteMemberName: deletemembername,
    });
    modalOnDisable();
    // setIsModalVisible(true);
  };

  const showModal2 = () => {
    setIsModal2Visible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleOk2 = () => {
    setIsModal2Visible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCancel2 = () => {
    // upsertUserAgent();
    setIsModal2Visible(false);
  };

  const AddBeneficiries = (type) => {
    setaddbeneficiriestype(type);
    console.log("aaaaa", type);
    setaddbeneficiries(true);
    setChangeBorder(2);
  };

  const changepage = () => {
    setChange(true);
    setaddbeneficiries(true);
    // document.getElementById("border").style.border = "2px solid #720c20";
  };

  const managebeneficiries = () => {
    setaddbeneficiriestype("");
    setaddbeneficiries(false);
    setChange(false);
    setChangeBorder(1);
    setIsShown(false);

    // document.getElementById("border").style.border = "5px solid black";
  };

  useEffect(() => {
    getBeneficiarydetails();
    const promiseApi = Services.getBenTable(loggeUserId);
    promiseApi
      .then((res) => {
        console.log("response", res.data.data);
        setUsersData(res.data.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [radiovalue]);

  const getBeneficiarydetails = () => {
    setLoader(true);
    let promise = Services.getBeneficiarydetailsByUserId(loggeUserId);
    promise
      .then((res) => {
        // console.log("responseeeTable", JSON.stringify(res.data.data.beneficiaries));
        console.log("responseeeTable", res.data.data.beneficiaries);
        setbeneficiaryDetailsUser(res.data.data.beneficiaries);
        setAssignOptionsList(res.data.data.beneficiaries);
        beneficiaryDetailsUserfunc(res.data.data.beneficiaries)
        setLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoader(false);
      });
  };

  console.log("aaa66a", beneficiaryDetailsUser);

  const beneficiariesDeleteApi = () => {
    console.log("deleteUser", deleteUser);
    let promises = Services.UpdateBeneficiaryStatus(
      deleteUser.deleteUserRelationshipId,
      deleteUser.deleteMemberId,
      stateObj.userId,
      deleteUser.deleteIsFiduciary,
      stateObj.userId
    );
    setLoader(true);
    promises
      .then((res) => {
        konsole.log("DeleteRes", res);
        Toaster.success("Delete successfully");
        getBeneficiarydetails();

        setGetSaveBtn(false);
        setLoader(false);
      })
      .catch((error) => {
        konsole.log("Deleteerr", error);
        Toaster.error(error);
        setLoader(false);
      })
      .finally(() => {
        konsole.log("Finish");
        setGetSaveBtn(false);
      });
  };

  const emailforbeneficierydelete = () => {
    setDeleteUser(false);
    konsole.log("deleteUserdeleteUser", deleteUser);
    konsole.log("deleteUser", deleteUser?.deleteMemberName);
    const memberdetails = commonLib.getObjFromStorage("userPrimaryInDetail");
    let jsonobj = {
      memberUserId: memberdetails?.memberUserId,
      emailToRoleId: 3,
      emailText: `${memberdetails?.memberName} requested deletion of ${deleteUser?.deleteMemberName} from Beneficiary list.`,
    };
    console.log("jsnobj", JSON.stringify(jsonobj));
    // setLoader(true);
    // let promise = Services.emailtoparalegal(jsonobj);
    // // setLoader(true)
    // promise
    //   .then((res) => {
    //     console.log("resres", res);
    //     getBeneficiarydetails();
    //     setGetSaveBtn(false);
    //     setLoader(false);
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //     setGetSaveBtn(false);
    //     setLoader(false);
    //   })
    //   .finally(() => {
    //     console.log("finish");
    //     setGetSaveBtn(false);
    //     setLoader(false);
    //   });
  };

  useEffect(() => {
    beneficiaryupdatemail(addbenuser, benuserdata);
  }, [addbenuser, benuserdata]);

  const beneficiaryupdatemail = (benuser, userdata) => {
    if (benuser == true && addbenefiiriespropstype == "AddBeneficiaries") {
      konsole.log("hello", userdata);

      const memberdetails = commonLib.getObjFromStorage("userPrimaryInDetail");
      let jsonobj = {
        memberUserId: memberdetails?.memberUserId,
        emailToRoleId: 3,
        emailText: `${memberdetails?.memberName} requested add ${
          userdata?.fName
        } ${userdata?.mName ? userdata?.mName : ""} ${
          userdata?.lName
          } to Beneficiary list.`,
      };
      console.log("jsonobj", JSON.stringify(jsonobj));
      // let promise = Services.emailtoparalegal(jsonobj);
      // setLoader(true);
      // promise
      //   .then((res) => {
      //     console.log("resres", res);
      //     getBeneficiarydetails();
      //     setGetSaveBtn(false);
      //     setLoader(false);
      //   })
      //   .catch((err) => {
      //     console.log("err", err);
      //     setGetSaveBtn(false);
      //     setLoader(false);
      //   })
      //   .finally(() => {
      //     console.log("finish");
      //     setGetSaveBtn(false);
      //     setLoader(false);

      //   });
      setLoader(true);

      setTimeout(() => {
        window.location.replace("/beneficiaries");
      }, 4000)
      setAddbenuser(false);
    } else if (
      benuser == true &&
      addbenefiiriespropstype == "EditBeneficiries"
    ) {
      let newarr = [];
      for (let i = 0; i < userdata.length; i++) {
        let list = `<li>${userdata[i]}</li>`;
        newarr.push(list);
      }
      let newarrd = newarr.join(" ");

      const memberdetails = commonLib.getObjFromStorage("userPrimaryInDetail");
      let jsonobj = {
        memberUserId: memberdetails?.memberUserId,
        emailToRoleId: 3,
        emailText: `<span>In Beneficiary list: <ul>
        ${newarrd}
        </ul> </span>`,
      };
      console.log("jsonobj", JSON.stringify(jsonobj));
      // let promise = Services.emailtoparalegal(jsonobj);
      // setLoader(true);
      // promise
      //   .then((res) => {
      //     console.log("resres", res);
      //     getBeneficiarydetails();
      //     setGetSaveBtn(false);
      //     setLoader(false);
      //   })
      //   .catch((err) => {
      //     console.log("err", err);
      //     setGetSaveBtn(false);
      //     setLoader(false);
      //   })
      //   .finally(() => {
      //     console.log("finish");
      //     setGetSaveBtn(false);
      //     setLoader(false);

      //   });
      setLoader(true);

      setTimeout(() => {
        window.location.replace("/beneficiaries");
      }, 4000)
      setAddbenuser(false);
    }
  };

  const beneficiaryDetailsUserfunc = (beneficiariesdata) => {
    let finaldata =
      radiovalue == 2
        ? beneficiariesdata.filter((e) => e.beneficiaryUserId != memberdetails.spouseUserId)
        : beneficiariesdata.filter((e) => e.memberRelationshipId != 0);

    const index = finaldata.findIndex(obj => obj.beneficiaryUserId === memberdetails.spouseUserId);

    if (index !== -1) {
      const [result] = finaldata.splice(index, 1);
      finaldata.unshift(result);
    }

    getmemberrelationfun(finaldata)
  };

  const getmemberrelationfun = (finaldata) => {
    let foundIndex = finaldata.findIndex((e)=>e.beneficiaryUserId == memberdetails.memberUserId)
    var filterdatalist = finaldata
    let getmemberrelationshiparamas = Services.getmemberrelationshipservice(memberdetails.spouseUserId)
    getmemberrelationshiparamas.then((response)=>{
      konsole.log(response,filterdatalist, radiovalue,"responserelation")
      let res = response.data.data
      if(radiovalue == 2){
        if(res.isBeneficiary == false){
          filterdatalist = filterdatalist.filter((e)=>e.beneficiaryUserId != memberdetails.memberUserId)
        }else{
          filterdatalist[foundIndex].userRoleName =( res.isBeneficiary == true ? "Beneficiary" : null)
        }

      }
      setTotalarr(filterdatalist)
    }).catch((error)=>{
      konsole.log(error,"error")
    })
    if(radiovalue == 1){
      setTotalarr(finaldata)
    }

  }
  konsole.log(totalarr,"responserelation2")
  return (
    <div>
      <div className="container-fluid">
        <h6>
          <Link to="/Dashboard" className="text-primery">
            Dashboard
          </Link>
          <span>{">"}</span>Beneficiaries
        </h6>
        <br />

        <div className="row">
          <div className="col-sm-6 d-flex">
            <button
              className={`${
                changeBorder === 1 ? "borderOnClick" : ""
              } schedule  bg-white fw-bold`}
              onClick={() => {
                managebeneficiries();
              }}
            >
              Manage Beneficiaries
            </button>

            <button
              className={`${
                changeBorder === 2 ? "borderOnClick" : ""
                } schedule ms-3  bg-white fw-bold`}
              onClick={() => AddBeneficiries("AddBeneficiaries")}
              disabled={
                addbenefiiriespropstype == "EditBeneficiries" ? true : false
              }
            >
              {addbenefiiriespropstype == "EditBeneficiries"
                ? "Edit Beneficiaries"
                : "Add Beneficiaries"}
            </button>
          </div>
          {/* <div className='col-sm-6 d-flex'><button className='schedule '>Manage Beneficiaries</button> <button className='schedule ms-3' onClick={fun}>Add Beneficiaries</button></div> */}
          <div className="col-sm-6"></div>
        </div>

        {/* <br></br> */}
        <div className="mt-2">
          {addbeneficiriesshow == true ? (
            <AddFidBeneficiaries
              type={addbenefiiriespropstype}
              userId={selectRowId}
              setaddbeneficiries={managebeneficiries}
              Addbenuser={setAddbenuser}
              benuserdata={setbenuserdata}
            />
          ) : (
            <>
              <div>
                <form>
                  <Row>
                    <Col span={14} xs={24} sm={18} md={14} lg={14} xl={14}>
                      {memberdetails?.spouseName && (
                        <div className="">
                          <Radio.Group
                            onChange={onChangeradio}
                            value={radiovalue}
                          >
                            <Radio value={1}>{memberdetails.memberName}</Radio>
                            <Radio value={2}>{memberdetails.spouseName}</Radio>
                          </Radio.Group>
                        </div>
                      )}
                      <Input.Search
                        placeholder="Search for Beneficiaries"
                        className="mt-2"
                        onChange={(e) => {
                          const currValue = e.target.value;
                          setQuery(currValue);
                          let filteredData = [];
                          let beneficiaryDetailsUser = [];
                          beneficiaryDetailsUser = assignOptionsList;

                          if (currValue !== "") {
                            filteredData = totalarr.filter(
                              (entry) => {
                                // console.log('bbb', entry);
                                if (
                                  entry.beneficiaryFullName?.toLowerCase()?.includes(currValue.toLowerCase()) ||entry.memberRelationshipName?.toLowerCase()?.includes(currValue.toLocaleLowerCase()) ||entry.userRoleName?.toLowerCase()?.includes(currValue.toLocaleLowerCase())
                                ) {
                                  return entry;
                                }
                              }
                            );
                          } else {
                            filteredData = assignOptionsList;
                          }
                          setTotalarr(filteredData);
                        }}
                      />
                    </Col>
                  </Row>
                </form>
              </div>

              <br></br>

              <Table
                dataSource={totalarr}
                pagination={false}
                onRow={(record) => ({
                  onClick: (e) => {
                    console.log(record.beneficiaryUserId);
                    setSelectRowId(record.beneficiaryUserId);
                  },
                })}
                scroll={{
                  y: 300,
                }}
              >
                <Row
                  title="Name"
                  className="manage  shadow-sm text-center "
                  dataIndex="beneficiaryFullName"
                  key="label"
                />
                <Row
                  title="Role"
                  className="manage  shadow-sm text-center"
                  dataIndex="userRoleName"
                  key="Role"
                />
                <Row
                  title="Relationship"
                  className="manage  shadow-sm border-gray text-center"
                  // dataIndex={
                  //   `${
                  //     radiovalue == 2
                  //       ? "rltnTypeWithSpouse"
                  //       : "memberRelationshipName"
                  //   }`
                  // }
                  render={(_, record) => (
                    <Space size="middle">
                      {radiovalue == 2 && record.rltnTypeWithSpouse != null
                        ? record.rltnTypeWithSpouse
                        : record.memberRelationshipId == 0
                        ? "Spouse" :record.memberRelationshipName}
                    </Space>
                  )}
                  key="Relationship"
                />

                <Row
                  className="manage  shadow-sm text-center"
                  title="Action"
                  key="action"
                  render={(_, record) => (
                    <Space size="middle">
                      {record.memberRelationshipId != 0 ? (
                        <Typography.Link
                          to=""
                          onClick={() => AddBeneficiries("EditBeneficiries")}
                          className="text-black"
                        >
                          <img src="/images\Fiduciaries-edit.png"></img>
                          <br />
                          Edit{" "}
                        </Typography.Link>
                      ) : (
                        <div style={{ height: "54px" }}></div>
                      )}

                      {/* <Typography.Link
                        to=""
                        onClick={(event) => showModal(record, event)}
                        className="text-black"
                      >
                        {" "}
                        <img src="/images\Fiduciaries-delete.png"></img>
                        <br />
                        Delete
                      </Typography.Link> */}
                    </Space>
                  )}
                />
              </Table>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
