import React from "react";
import "../AssignOptions/AssignOption.css";
import { Select, Row, Col, Button, Form, Modal } from "antd";
import Services from "../../network/Services";
import { useState, useEffect } from "react";
import commonLib from "../../control/commonLib";
import AssignOption from "./AssignOption";

const { Option } = Select;

export default function RolePopup(props) {
  const [userRole, setuserRole] = useState([]);
  const [userRank, setuserRank] = useState([]);
  const [memberId, setmemberId] = useState([]);
  const [memberDetail, setmemberDetail] = useState([]);
  const [form] = Form.useForm();

  const assignOptVal = props.agent?.assignAgent;
  const userId = commonLib.getObjFromStorage("stateObj");
  const loggeUserId = userId.userId;

  console.log("memmemem", props);
  console.log("PROU P", props.agent?.assignAgent);
  console.log("Modal", props.agent?.handleCancel2);
  const memberUserId = commonLib.getObjFromStorage(
    "userPrimaryInDetail"
  ).memberUserId;
  const loggeduserid = commonLib.getObjFromStorage("stateObj").userId;

  useEffect(() => {
    const Roles = Services.getUserAgentRole();
    Roles.then((response) => {
      setuserRole(response.data.data);
    }).catch((error) => {
      console.log("Error", error);
    });

    const Ranks = Services.getUserAgentRank();
    Ranks.then((response) => {
      setuserRank(response.data.data);
    }).catch((error) => {
      console.log("Error", error);
    });
    let userDetails = commonLib.getObjFromStorage("userLoggedInDetail");
    const Umember = Services.getMember(userDetails.spouseUserId);
    Umember.then((response) => {
      let userName = userDetails.memberName;
      let userId = memberUserId;
      let spouseName =
        response.data.data.member.fName + " " + response.data.data.member.lName;
      let spouseUserId = userDetails.spouseUserId;
      let memberDetail = [
        { value: userId, text: userName },
        { value: spouseUserId, text: spouseName },
      ];

      setmemberId(response.data.data.member);
      setmemberDetail(memberDetail);

      console.log("MEMBER", memberId);
    }).catch((error) => {
      console.log("Error", error);
    });

  }, []);

  const formonFinish = (items) => {
    let agentrankId = items.rank;

    let agentroleId = items.role;
    let memberUserId = items.name;

    const upsertAgent = Services.postupsertuserAgent(
      assignOptVal.agentId,
      assignOptVal.agentUserId,
      memberUserId,
      false,
      true,
      loggeduserid,
      agentroleId,
      agentrankId
    );
    upsertAgent
      .then((response) => {
        console.log("POST", response);
        window.location.replace("/Assign");
        console.log("Post_Upsert", response.data.data);
      })
      .catch((err) => {
        console.log("UpError", err);
      })
      .finally(() => {
        console.log("Finish");
      });
  };



  return (
    <Modal
      visible={props.isModal2Visible}
      maskClosable={false}
      onCancel={props.handleCancel2}
      width={700}
      footer={null}
      closable={true}
    >
      <Form onFinish={formonFinish}
        form={form}
      >
        <div className="container">
          <div className="RolePopModal bg-white p-4">
            <h5>
              <span className="Popup_name">Name : </span>
              {assignOptVal.name}
            </h5>

            <Row className="mt-3">
              <Col className="ms-2">
                <Form.Item
                  name="role"
                  rules={[
                    {
                      required: true,
                      message: "Select Role",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 250 }}
                    placeholder="Select Role"
                    rules={[
                      {
                        required: true,
                        message: "Select Role",
                      },
                    ]}
                  >
                    {userRole.map((item) => {
                      return <Option value={item.value}>{item.label}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="ms-2">
                <Form.Item
                  name="rank"
                  rules={[
                    {
                      required: true,
                      message: "Select Rank",
                    },
                  ]}
                >
                  <Select
                    name="rank"
                    style={{ width: 150 }}
                    placeholder="Select Rank"
                  >
                    {userRank.map((item) => {
                      return <Option value={item.value}>{item.label}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="ms-2">
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Select Name",
                    },
                  ]}
                >
                  <Select style={{ width: 150 }} placeholder="Name">
                    {memberDetail.map((item) => {
                      return <Option value={item.value}>{item.text}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <div className="d-flex align-middle rolePopBtns">
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      className="text-white fw-bold rounded border-0 justify-content-center mt-3 ms-5 mt-5"
                      // onClick={props.agent?.handleCancel2}
                      style={{ backgroundColor: "#720c20" }}
                    >
                      SAVE
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
