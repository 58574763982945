import React, { useState, useEffect,useContext } from "react";
import {
  Steps,
  Row,
  Col,
  Space,
  Table,
  Input,
  Button,
  Radio,
  Select,
  Checkbox,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Services from "../../network/Services";
import konsole from "../../network/konsole";
import {} from "antd";
import { Link } from "react-router-dom";
import { endOfLife } from "../../control/Constant";
import Toaster from "../Toaster";
import ImmediateActions from "./immediateActions";
import { UserContext } from '../../App';
import UploadFile from "./UploadFile";
import AgentPreview from "./AgentPreview";
const { Step } = Steps;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ["Emai", "Text"];
const { TextArea } = Input;

const Endoflife = (props) => {
  const [dataUserId, setDatamemberUserId] = useState("");
  const [dataResponseId, setDataResponseId] = useState("");
  let memberName = JSON.parse(
    sessionStorage.getItem("userPrimaryInDetail")
  ).loginUserName;
  // let memberUserId = JSON.parse(
  //   sessionStorage.getItem("userPrimaryInDetail")
  // ).memberUserId;
  let memberUserId = props.memberid


  const [formlabelData, setFormLabelData] = useState({});
  const [selectedStep, selectSelectedStepper] = useState(1);
  const [changeBorder, setChangeBorder] = useState(1);
  const [crp, setCrp] = useState(null);
  const [hydration, setHydration] = useState(null);
  const [nutrition, setNutrition] = useState(null);
  const [sideConditions, setSideConditions] = useState(null);
  const [heroicMeasure, setHeroicMeasure] = useState(null);
  const [artificialAdministration, setArtificialAdministration] = useState(null);
  const [firstQuestionId, setFirstQuestionId] = useState("");
  const [specialInsAns, setSpecialInsAns] = useState();
  const [plzSpecify, setPlzSpecify] = useState();
  const { setLoader } = useContext(UserContext);
  const[specialInstructions,setSpecialInstructions] = useState('')
  
  const [CRPQuestionDetails, setCRPQuestionDetails] = useState({
    CRPQuestionId: "",
    CRPAnswerId: "",
  });

  const [artificially, setArtificially] = useState({
    artificiallyQuestionId: "",
    artificiallyAnswerId: "",
  });

  const [Cardiopulmnatory, setCardiopulmnatory] = useState({
    CardiopulmnatoryQuestionId: "",
    CardiopulmnatoryAnswerId: "",
  });

  const [Antibiotic, setAntibiotic] = useState({
    AntibioticQuestionId: "",
    AntibioticAnswerId: "",
  });

  const [heroic, setHeroic] = useState({
    heroicQuestionId: "",
    heroicAnswerId: "",
  });
  const [heroicAgent, setHeroicAgent] = useState({
    heroicAgentQuestionId: "",
    heroicAgentAnswerId: "",
  });



  useEffect(() => {
    selectSelectedStepper(1)
    // let memberUserId = JSON.parse(
    //   sessionStorage.getItem("userPrimaryInDetail")
    // ).memberUserId;
    let memberUserId = props.memberid
    getsubjectForFormLabelId(memberUserId);
    setDatamemberUserId(memberUserId);
    setPlzSpecify(); 
    setHeroicAgent({
      heroicAgentQuestionId: "",
      heroicAgentAnswerId: "",
    });
    setHeroic({
      heroicQuestionId: "",
      heroicAnswerId:"",
    });
    setAntibiotic({
      AntibioticQuestionId: "",
      AntibioticAnswerId: "",
    });
    setCardiopulmnatory({
      CardiopulmnatoryQuestionId:
        "",
      CardiopulmnatoryAnswerId: "",
    });
    setArtificially({
      artificiallyQuestionId:
        "",
      artificiallyAnswerId: "",
    });
    setCRPQuestionDetails({
      CRPQuestionId: "",
      CRPAnswerId: "",
    });
    setArtificialAdministration();
    setSpecialInsAns('')
  }, [props.memberid]);

 console.log("kjh8778iu",heroicAgent)
  const selectedStepper = (step) => {
    selectSelectedStepper(step);
  };

  const getsubjectForFormLabelId = (userId) => {
    setLoader(true)
    let formlabelData = {};
    endOfLife.formLabelId.map((id, index) => {
      let data = [id.id];
      const promises = Services.getSubjectQuestions(data);
      promises.then((response) => {
        if (response) {
          setLoader(false)
          if (id.id == response.data.data[0].formLabelId) {
            let label = "label" + response.data.data[0].formLabelId;
            konsole.log("label", label);
            formlabelData[label] = response.data.data[0].question;
            const promises = Services.getSubjectResponse(
              userId,
              formlabelData[label].questionId
            );
            promises.then((response) => {
              setHydration(response?.data?.data?.userSubjects[0]?.responseId);
            
              konsole.log("EndofLifeRes", response.data.data);
              let getResponseId = response.data.data.userSubjects[0];
              if (response) {
              
                if (response.data.data.userSubjects.length !== 0) {
                  let responseData = response.data.data.userSubjects[0];
                  for (
                    let i = 0;
                    i < formlabelData[label].response.length;
                    i++
                  ) {
                    konsole.log("datashownatcaregiver at", i, responseData);
                    if (
                      formlabelData[label].response[i].responseId ===
                      responseData.responseId
                    ) {
                      if (formlabelData[label].questionId == 102) {
                        setArtificialAdministration(responseData.responseId);
                        setFirstQuestionId(formlabelData[label].questionId);
                        // setArtificialAdministration(getResponseId.responseId)
                      }
                      if (formlabelData[label].questionId == 103) {
                        setCRPQuestionDetails({
                          CRPQuestionId: formlabelData[label].questionId,
                          CRPAnswerId: responseData.responseId,
                        });
                      }
                      if (formlabelData[label].questionId == 104) {
                        setArtificially({
                          artificiallyQuestionId:
                            formlabelData[label].questionId,
                          artificiallyAnswerId: responseData.responseId,
                        });
                      }
                      if (formlabelData[label].questionId == 105) {
                        setCardiopulmnatory({
                          CardiopulmnatoryQuestionId:
                            formlabelData[label].questionId,
                          CardiopulmnatoryAnswerId: responseData.responseId,
                        });
                      }
                      if (formlabelData[label].questionId == 106) {
                        setAntibiotic({
                          AntibioticQuestionId: formlabelData[label].questionId,
                          AntibioticAnswerId: responseData.responseId,
                        });
                      }
                      if (formlabelData[label].questionId == 107) {
                        setHeroic({
                          heroicQuestionId: formlabelData[label].questionId,
                          heroicAnswerId: responseData.responseId,
                        });
                      }
                      if (formlabelData[label].questionId == 200) {
                     
                        setHeroicAgent({
                          heroicAgentQuestionId: formlabelData[label].questionId,
                          heroicAgentAnswerId: responseData.responseId,
                        });
                      }
                      if (responseData.responseNature == "Radio") {
                        formlabelData[label].response[i]["checked"] = true;
                        formlabelData[label]["userSubjectDataId"] =
                          responseData.userSubjectDataId;
                      } else if (responseData.responseNature == "Text") {
                        if (responseData.responseId == 260) {
                          setPlzSpecify( responseData.response);
                        }
                        if(responseData.responseId == 257){
                          // konsole.log(responseData.response,"tryeuiop")
                          setSpecialInstructions(responseData.response)
                        }
                        formlabelData[label].response[i]["response"] =
                          responseData.response;
                        formlabelData[label]["userSubjectDataId"] =
                          responseData.userSubjectDataId;
                      }
                    }
                  }
                }
              }
            });
            setFormLabelData(formlabelData);
          }
        }
      });
    });
  };
  console.log("log", formlabelData);

  const onChange = (event) => {
    const eventName = event.target.name;
    const eventValue = event.target.value;
    konsole.log("eventValue", eventValue, formlabelData.label899.questionId);
    setArtificialAdministration(eventValue);
    setFirstQuestionId(formlabelData.label899.questionId);

    // switch (eventName) {
    //     case "artificialAdministration": {
    //         setArtificialAdministration(eventValue);
    //         if (eventValue !== 3) {
    //             setCrp(null);
    //             setHydration(null);
    //             setNutrition(null);
    //             setSideConditions(null);
    //             setHeroicMeasure(null);
    //         }
    //     }
    //         break;
    //     case "crp": {
    //         setCrp(eventValue);
    //     }
    //         break;
    //     case "hydration": {
    //         setHydration(eventValue);
    //     }
    //         break;
    //     case "nutrition": {
    //         setNutrition(eventValue);
    //     }
    //         break;
    //     case "sideConditions": {
    //         setSideConditions(eventValue);
    //     }
    //         break;
    //     case "heroicMeasure": {
    //         setHeroicMeasure(eventValue);
    //     }
    //         break;
    // }
  };

  // const ApiRadioData = (e)=>{
  //     konsole.log("ApiRadioData",e.target)
  // }

  const secondQuestion = (e) => {
    let questionName = e.target.name;
    if (questionName == "SecondQuestion") {
      konsole.log("ClickValue", e.target.value);
      setCRPQuestionDetails({
        CRPQuestionId: formlabelData.label900.questionId,
        CRPAnswerId: e.target.value,
      });
    } else if (questionName == "ThirdQuestion") {
      konsole.log("ClickValue", e.target.value);
      setArtificially({
        artificiallyQuestionId: formlabelData.label901.questionId,
        artificiallyAnswerId: e.target.value,
      });
    } else if (questionName == "FourthQuestion") {
      konsole.log("ClickValue", e.target.value);
      setCardiopulmnatory({
        CardiopulmnatoryQuestionId: formlabelData.label902.questionId,
        CardiopulmnatoryAnswerId: e.target.value,
      });
    } else if (questionName == "FifthQuestion") {
      konsole.log("ClickValue", e.target.value);
      setAntibiotic({
        AntibioticQuestionId: formlabelData.label903.questionId,
        AntibioticAnswerId: e.target.value,
      });
    } else if (questionName == "SixQuestion") {
      konsole.log("ClickValue", e.target.value);
      setHeroic({
        heroicQuestionId: formlabelData.label904.questionId,
        heroicAnswerId: e.target.value,
      });
    }else if (questionName == "SevenQuestion") {
      konsole.log("ClickValue", e.target.value);
      setHeroicAgent({
        heroicAgentQuestionId: formlabelData.label996.questionId,
        heroicAgentAnswerId: e.target.value,
      });
    }
  };

  const PostQuestionData = () => {
    konsole.log(
      "artificialAdministration",
      artificialAdministration,
      CRPQuestionDetails.CRPAnswerId,
      artificially.artificiallyAnswerId,
      Cardiopulmnatory.CardiopulmnatoryAnswerId,
      Antibiotic.AntibioticAnswerId,
      heroic.heroicAnswerId
    );

    konsole.log(
      "questionId",
      firstQuestionId,
      CRPQuestionDetails.CRPQuestionId,
      artificially.artificiallyQuestionId,
      Cardiopulmnatory.CardiopulmnatoryQuestionId,
      Antibiotic.AntibioticQuestionId,
      heroic.heroicQuestionId
    );

    let addArray =
      artificialAdministration !== 211
        ? [
            {
              subjectId: firstQuestionId,
              userSubjectDataId: formlabelData.label899?.userSubjectDataId,
              responseId: artificialAdministration,
            },
            
          ]
        : [
            {
              subjectId: firstQuestionId,
              userSubjectDataId: formlabelData.label899?.userSubjectDataId,
              responseId: artificialAdministration,
            },
            {
              subjectId: CRPQuestionDetails.CRPQuestionId,
              userSubjectDataId: formlabelData.label900?.userSubjectDataId,
              responseId: CRPQuestionDetails.CRPAnswerId,
            },
            {
              subjectId: artificially.artificiallyQuestionId,
              userSubjectDataId: formlabelData.label901?.userSubjectDataId,
              responseId: artificially.artificiallyAnswerId,
            },
            {
              subjectId: Cardiopulmnatory.CardiopulmnatoryQuestionId,
              userSubjectDataId: formlabelData.label902?.userSubjectDataId,
              responseId: Cardiopulmnatory.CardiopulmnatoryAnswerId,
            },
            {
              subjectId: Antibiotic.AntibioticQuestionId,
              userSubjectDataId: formlabelData.label903?.userSubjectDataId,
              responseId: Antibiotic.AntibioticAnswerId,
            },
            {
              subjectId: heroic.heroicQuestionId,
              userSubjectDataId: formlabelData.label904?.userSubjectDataId,
              responseId: heroic.heroicAnswerId,
            },
            // {
            //   subjectId: heroicAgent.heroicAgentQuestionId,
            //   userSubjectDataId: formlabelData.label996?.userSubjectDataId,
            //   responseId: heroicAgent.heroicAgentAnswerId,
            // },
            {
              userSubjectDataId: formlabelData.label928?.userSubjectDataId
                ? formlabelData.label928?.userSubjectDataId
                : 0,
              subjectId: formlabelData.label928?.questionId,
              subResponseData: plzSpecify,
              responseId: formlabelData.label928?.response[0].responseId,
            },
          ];

    let putApi = {
      userId: dataUserId,
      userSubjects: addArray,
    };

    // const objCheck =

    //     artificialAdministration !== 211 ?
    //         [{
    //             userSubjectDataId: 0,
    //             subjectId: firstQuestionId,
    //             responseId: artificialAdministration,
    //             userId: dataUserId
    //         }] :
    //         [{
    //             userSubjectDataId: 0,
    //             subjectId: firstQuestionId,
    //             responseId: artificialAdministration,
    //             userId: dataUserId
    //         },
    //         {
    //             userSubjectDataId: 0,
    //             subjectId: CRPQuestionDetails.CRPQuestionId,
    //             responseId: CRPQuestionDetails.CRPAnswerId,
    //             userId: dataUserId
    //         },
    //         {
    //             userSubjectDataId: 0,
    //             subjectId: artificially.artificiallyQuestionId,
    //             responseId: artificially.artificiallyAnswerId,
    //             userId: dataUserId
    //         },
    //         {
    //             userSubjectDataId: 0,
    //             subjectId: Cardiopulmnatory.CardiopulmnatoryQuestionId,
    //             responseId: Cardiopulmnatory.CardiopulmnatoryAnswerId,
    //             userId: dataUserId
    //         },
    //         {
    //             userSubjectDataId: 0,
    //             subjectId: Antibiotic.AntibioticQuestionId,
    //             responseId: Antibiotic.AntibioticAnswerId,
    //             userId: dataUserId
    //         },
    //         {
    //             userSubjectDataId: 0,
    //             subjectId: heroic.heroicQuestionId,
    //             responseId: heroic.heroicAnswerId,
    //             userId: dataUserId
    //         }]

    // let stringObj = JSON.stringify(objCheck)
    // konsole.log("stringObj", stringObj)

    // let promise = Services.postaddusersubjectapi(objCheck)
    // promise.then((res)=>{
    //     konsole.log("PostQuestionData",res)
    // Toaster.success("Data Saved Successfully")
    // }).catch((err)=>{
    //     konsole.log("PostQuestionData",err)
    //     Toaster.error("Error! Please Attempt All Questions ")

    // })
konsole.log(JSON.stringify(putApi),"putApiputApi")
    let promises = Services.upsertSubjectdata("PUT", putApi);
    promises
      .then((res) => {
        konsole.log("successful", res);
        Toaster.success("Data Saved Successfully");
        selectSelectedStepper(2)
      })
      .catch((error) => {
        konsole.log("error", error.response);
        Toaster.error("Please enter complete details.");
      });

      getsubjectForFormLabelId(memberUserId);
      // selectedStep == 3
  };
  const postSubjectDatastep3 = () => {
   
    let inputArray = {
      userId: dataUserId,
      userSubjects: [
        {
          userSubjectDataId: formlabelData.label926?.userSubjectDataId
            ? formlabelData.label926?.userSubjectDataId
            : 0,
          subjectId: formlabelData.label926?.questionId,
          subResponseData: specialInsAns,
          responseId: formlabelData.label926?.response[0].responseId,
        },
        {
         
      userSubjectDataId: formlabelData.label996?.userSubjectDataId
        ? formlabelData.label996?.userSubjectDataId
        : 0,
      subjectId: formlabelData.label996?.questionId,
      userId: memberUserId,
      responseId: heroicAgent?.heroicAgentAnswerId,
    
          
        },
      ],
    };
    konsole.log(heroicAgent,"heroicAgent")
    if(heroicAgent?.heroicAgentAnswerId == ""){
      Toaster.warning("Please make selection and save")
    }
    

    let promises = Services.upsertSubjectdata("PUT", inputArray);
    promises
      .then((res) => {
        konsole.log("successful", res);
        Toaster.success("Data Saved Successfully");
        selectSelectedStepper(4)
      })
      .catch((error) => konsole.log("error", error.response));
      getsubjectForFormLabelId(memberUserId);
      // selectedStep === 3
  };

  konsole.log("dataResponseId", formlabelData);
const backButton=()=>{
  selectSelectedStepper(2)
}
  return (
    <div>
      <div
 className="mt-3 ms-3 ms-3"
         style={{ display: "flex", justifyContent: "center" }}
      >
        <div style={{ width: 810 }}>
          <Steps
            labelPlacement="vertical"
            current={selectedStep - 1}
            size="small"
          >
            <Step
              title="Immediate Actions"
              onClick={(e) => selectSelectedStepper(1)}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Notify"
              onClick={(e) => selectSelectedStepper(2)}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Special Instructions"
              onClick={() => selectSelectedStepper(3)}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Agents Guidance"
              onClick={() => selectSelectedStepper(4)}
              style={{ cursor: "pointer" }}
            />
          </Steps>
        </div>
      </div>
      <div
        className=" Question-Card-Div"
        
      >
        {selectedStep === 1 ? (
          <div>
            {formlabelData.label899 && (
              <div>
                <h6 >{formlabelData.label899.question}</h6>
                {
                  <Radio.Group
                    onChange={onChange}
                    value={artificialAdministration}
                    name="artificialAdministration"
                  >
                    {formlabelData.label899.response.map((answer, index) => {
                      return (
                        <Radio value={answer.responseId} className="ms-1">
                          {answer.response}
                        </Radio>
                      );
                    })}
                  </Radio.Group>
                }
                {}
              </div>
            )}

            {artificialAdministration == 211 ? (
              <>
                <div className="mt-3">
                  <h6>Do you want the following treatments:</h6>
                </div>
                {formlabelData.label900 && (
                  <div>
                    <h6 className="mt-2">{formlabelData.label900.question}</h6>
                    {
                      <Radio.Group
                        name="SecondQuestion"
                        value={CRPQuestionDetails.CRPAnswerId}
                        onChange={secondQuestion}
                      >
                        {formlabelData.label900.response.map(
                          (answer, index) => {
                            return (
                              <Radio value={answer.responseId} className="ms-1">
                                {answer.response}
                              </Radio>
                            );
                          }
                        )}
                      </Radio.Group>
                    }
                    {}
                  </div>
                )}
                {formlabelData.label901 && (
                  <div>
                    <h6 className="mt-2">{formlabelData.label901.question}</h6>
                    {
                      <Radio.Group
                        name="ThirdQuestion"
                        value={artificially.artificiallyAnswerId}
                        onChange={secondQuestion}
                      >
                        {formlabelData.label901.response.map(
                          (answer, index) => {
                            return (
                              <Radio value={answer.responseId} className="ms-1">
                                {answer.response}
                              </Radio>
                            );
                          }
                        )}
                      </Radio.Group>
                    }
                    {}
                  </div>
                )}
                {formlabelData.label902 && (
                  <div>
                    <h6 className="mt-2">{formlabelData.label902.question}</h6>
                    {
                      <Radio.Group
                        name="FourthQuestion"
                        value={Cardiopulmnatory.CardiopulmnatoryAnswerId}
                        onChange={secondQuestion}
                      >
                        {formlabelData.label902.response.map(
                          (answer, index) => {
                            return (
                              <Radio value={answer.responseId} className="ms-1">
                                {answer.response}
                              </Radio>
                            );
                          }
                        )}
                      </Radio.Group>
                    }
                    {}
                  </div>
                )}
                {formlabelData.label903 && (
                  <div>
                    <h6 className="mt-2">{formlabelData.label903.question}</h6>
                    {
                      <Radio.Group
                        name="FifthQuestion"
                        value={Antibiotic.AntibioticAnswerId}
                        onChange={secondQuestion}
                      >
                        {formlabelData.label903.response.map(
                          (answer, index) => {
                            return (
                              <Radio value={answer.responseId} className="ms-1">
                                {answer.response}
                              </Radio>
                            );
                          }
                        )}
                      </Radio.Group>
                    }
                    {}
                  </div>
                )}
                {formlabelData.label904 && (
                  <div>
                    <h6 className="mt-2">{formlabelData.label904.question}</h6>
                    {
                      <Radio.Group
                        name="SixQuestion"
                        value={heroic.heroicAnswerId}
                        onChange={secondQuestion}
                      >
                        {formlabelData.label904.response.map(
                          (answer, index) => {
                            return (
                              <Radio value={answer.responseId} className="ms-1">
                                {answer.response}
                              </Radio>
                            );
                          }
                        )}
                      </Radio.Group>
                    }
                    {}
                  </div>
                )}
                <div className="mt-3">
                  <h6>Please Specify</h6>
                  <Row>
                    <Col lg={12}>
                      {formlabelData.label928 && (
                        <TextArea
                          rows={4}
                          defaultValue={
                            formlabelData.label928?.response[0].response
                          }
                          value={plzSpecify}
                          onChange={(e) => {
                            setPlzSpecify(e.target.value);
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="Sava-Button-Div">
              <button className="Save-Button" onClick={PostQuestionData}>
          Save & Proceed
                
              </button>
            </div>
          </div>
        ) : selectedStep == 2 ? (
          // <Row className="" >
          //     <Col lg={22} xl={22}>
          //         <div>
          //             <h6>Select From Friends and Family Contacts</h6>
          //             <div className="d-flex">
          //                 <Select className="w-50 m-0" defaultValue="Please Select">
          //                     <option>1</option>
          //                     <option>2</option>
          //                     <option>3</option>
          //                     <option>4</option>
          //                 </Select>
          //                 <div className="px-3" style={{ width: "170px" }}>
          //                     <Link to="#"  >Add Contacts</Link>
          //                 </div>
          //             </div>
          //             <h6 className="mt-2">Please Select Notification Type </h6>
          //             <CheckboxGroup options={plainOptions} />
          //             <TextArea rows={4} />
          //         </div>

          //         {/* <div className="mt-4">
          //             <h6>Select From Personal Contacts</h6>
          //             <div className="d-flex">
          //                 <Select className="w-50 m-0" defaultValue="Please Select">
          //                     <option>1</option>
          //                     <option>2</option>
          //                     <option>3</option>
          //                     <option>4</option>
          //                 </Select>
          //                 <div className="px-3" style={{ width: "170px" }}>
          //                     <Link to="#"  >Add Contacts</Link>
          //                 </div>
          //             </div>
          //             <h6 className="mt-2">Please Select Notification Type </h6>
          //             <CheckboxGroup options={plainOptions} />
          //             <TextArea rows={4} />
          //         </div> */}

          //         <div className="mt-4">
          //             <h6>Select From Professional Contacts</h6>
          //             <div className="d-flex">
          //                 <Select className="w-50 m-0" defaultValue="Please Select">
          //                     <option>1</option>
          //                     <option>2</option>
          //                     <option>3</option>
          //                     <option>4</option>
          //                 </Select>
          //                 <div className="px-3" style={{ width: "170px" }}>
          //                     <Link to="#"  >Add Contacts</Link>
          //                 </div>
          //             </div>
          //             <h6 className="mt-2">Please Select Notification Type </h6>
          //             <CheckboxGroup options={plainOptions} />
          //             <TextArea rows={4} />
          //         </div>

          //         <div className="mt-4">
          //             <h6>Select From Everyone</h6>
          //             <div className="d-flex">
          //                 <Select className="w-50 m-0" defaultValue="Please Select">
          //                     <option>1</option>
          //                     <option>2</option>
          //                     <option>3</option>
          //                     <option>4</option>
          //                 </Select>
          //                 <div className="px-3" style={{ width: "170px" }}>
          //                     <Link to="#"  >Add Contacts</Link>
          //                 </div>
          //             </div>
          //             <h6 className="mt-2">Please Select Notification Type </h6>
          //             <CheckboxGroup options={plainOptions} />
          //             <TextArea rows={4} />
          //         </div>
          //     </Col>
          // </Row>
          <ImmediateActions id={2}  memberUserId={props.memberid} selectSelectedStepper={selectSelectedStepper} />
        ) : selectedStep == 3 ? (
          <>
                <Row className="d-flex justify-content-center pt-2">
                <Col  className=" w-100" >
                <UploadFile memberUserId={props?.memberid} />
                    <div className="mb-2" style={{ border: "1px solid white" }}>
                     
                        {formlabelData.label996 && (
                  <div>
                    <h6 className="mt-2">{formlabelData.label996.question}</h6>
                    {
                      <Radio.Group
                        name="SevenQuestion"
                        value={heroicAgent.heroicAgentAnswerId}
                        onChange={secondQuestion}
                      >
                        {formlabelData.label996.response.map(
                          (answer, index) => {
                            return (
                              <Radio value={answer.responseId} className="ms-1">
                                {answer.response}
                              </Radio>
                            );
                          }
                        )}
                      </Radio.Group>
                    }
                    </div>
                     )}
                    </div>
                    {heroicAgent?.heroicAgentAnswerId == "391" ? (
                     <div className="mb-2 textEditor-Col" style={{ border: "1px solid white" }}>
                     {/* <h6>
                       Enter Special Instructions as to what you wish to be
                       done for you
                     </h6> */}
                     {formlabelData.label926 && (
                       <div className="mt-3">
                         <h6>{formlabelData.label926.question}</h6>
                         <TextArea
                           rows={4}
                           placeholder="Please type something here"
                           defaultValue={
                             formlabelData.label926?.response[0].response
                           }
                           value={specialInsAns}
                           onChange={(e) => {
                             setSpecialInsAns(e.target.value);
                           }}
                         />
                       </div>
                     )}
                   </div> )
                   :("")}
                  </Col>
                 </Row>
            <div className="Sava-Button-Div d-flex justify-content-between">
            <button className="Save-Button" onClick={backButton}>Back</button>
              <button className="Save-Button" onClick={postSubjectDatastep3}>Save & Proceed</button>
          
            </div>
          </>
        ) : selectedStep == 4 ? (
          <>
            <AgentPreview memberUserId={props?.memberid}  selectSelectedStepper={selectSelectedStepper}/>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Endoflife;
