import Navbar from "../Navbar";
import Sidemenu from "../Sidemenu";
import "../Fiduciaries/ManageFiduciaries.css";

import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Space,
  Table,
  Input,
  Button,
  Col,
  Modal,
  Typography,
  ColumnsType,
  Radio,
} from "antd";
import { AudioOutlined } from "@ant-design/icons";
import Services from "../../network/Services";

import axios from "axios";
// import AddBeneficiaries from '../Fiduciaries/AddBeneficiaries';
import { Link } from "react-router-dom";
import AddFidBeneficiaries from "../Fiduciaries/AddFidBeneficiaries";
import commonLib from "../../control/commonLib";

import { UserContext } from "../../App";
import konsole from "../../network/konsole";
import AgentsTable from "./AgentsTable";
import AgentsTableLegal from "../MyAgents/AgentsTableLegal";

const { Search } = Input;
const onSearch = (value) => console.log(value);

export default function BeneficiarysName() {
  // let userId = '2278ab5d-1c21-452b-9387-362e3547c3ea'

  const userId = commonLib.getObjFromStorage(
    "userPrimaryInDetail"
  )?.memberUserId;
  const spouseUserId = commonLib.getObjFromStorage(
    "userPrimaryInDetail"
  )?.spouseUserId;
  const loggedUser = commonLib.getObjFromStorage("userLoggedInDetail");
  const userPrimaryInDetails = commonLib.getObjFromStorage("userPrimaryInDetail");
  const loggeUserId = commonLib.getObjFromStorage("stateObj")?.userId;
  const [addbeneficiriesshow, setaddbeneficiries] = useState(false);
  const [addbenefiiriespropstype, setaddbeneficiriestype] = useState("");
  const [assignOptionsList, setAssignOptionsList] = useState([]);
  const [selectRowId, setSelectRowId] = useState("");
  const { disable, modalOnDisable } = useContext(UserContext);
  const [RenderValue, setRenderValue] = useState(true);
  const [Rendermember, setRendermember] = useState("Primary");
  const [inputFilter, setInputFilter] = useState("");
  const { setLoader } = useContext(UserContext);

  // if (e.target.value = 1) {
  //   setRenderValue(false)
  // }
  // else if (e.target = 2) {
  //   setRenderValue(true)
  // }

  useEffect(() => {
    if (Rendermember == "Primary") {
      getUserAgentListByUserId(userId);
    } else {
      getUserAgentListByUserId(spouseUserId);
    }
  }, [Rendermember]);

  const getUserAgentListByUserId = (userId) => {
    setLoader(true);
    let promises = Services.getUserAgentList(userId, true);
    promises

      .then((res) => {
        konsole.log(res,"resresresres")
        const resposnseDatass = res.data.data.filter(d => d.agentUserId !== null );
        konsole.log(resposnseDatass,"resposnseDatass")
        const responseData = resposnseDatass.map((userAgent) => {
          return {
            fullName: userAgent.fullName,
            relationWithMember: userAgent.relationWithMember,
            legalDocName:
              userAgent.testSupportDocName == null &&
              userAgent.testDocId == null
                ? userAgent.legalDocName + " & " + userAgent.agentRole
                : userAgent.testDocId == null
                ? userAgent.testSupportDocName + " & " + userAgent.agentRole
                : userAgent.testSupportDocName == null
                ? userAgent.testDocName + " & " + userAgent.agentRole
                : "",
            agentRank: userAgent.agentRank,
            agentAcceptanceStatus: userAgent.agentAcceptanceStatus,
            isUserActive: userAgent.isUserActive,
            agentEmailId: userAgent.agentEmailId,
            agentMobileNo: userAgent.agentMobileNo,
            agentUserId: userAgent.agentUserId,
            agentRankId: userAgent.agentRankId,
            statusName: userAgent.statusName,
            relationWithSpouse:userAgent.relationWithSpouse
          };
        });

        konsole.log("response my agent", res);
        setAssignOptionsList(responseData);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        konsole.log("error", err.response.data.status);
        if(err.response.data.status == "404"){
          setAssignOptionsList([]);
        }
      });
      

  };

  console.log("assignOptionsList", assignOptionsList);

  const selectradiovalue = (e) => {
    console.log("selectradiovalue", e.target.value);
    setRenderValue(e.target.value);
  };
  const selectradiovalue2 = (e) => {
    console.log("selectradiovalue", e.target.value);
    setRendermember(e.target.value);
  };

  var filterdata = assignOptionsList.filter((item) => {
    return (
      item?.fullName?.toLowerCase()?.includes(inputFilter?.toLocaleLowerCase()) +
      item?.legalDocName?.toLowerCase()?.includes(inputFilter?.toLocaleLowerCase())
    );
  });
  var filterdatalegal = assignOptionsList.filter((item) => {
    return (
      item.legalDocName
        .toLowerCase()
        .includes(inputFilter.toLocaleLowerCase()) +
      item.fullName.toLowerCase().includes(inputFilter.toLocaleLowerCase())
    );
  });
  console.log(filterdatalegal, "filterdata");

  return (
    <div>
      <div className="container-fluid">
        <h6>
          <Link to="/Dashboard" className="text-primery">
            Dashboard
          </Link>
          <span>{">"}</span>My Agents
        </h6>
        <br />

        <div className="row">
          <div>
            <div className="className='col-sm-6 d-flex'">
              <div
                className=" top-btnM bg-white fw-bold shadow"
                // className={`${(changeBorder === 1) ? 'borderOnClick' : ''} schedule  bg-white fw-bold ms-2 text-center`}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                My Agents
              </div>
              {/* <div>
                {" "}
                <button className=" top-btnM bg-white fw-bold shadow">
                  My Agents lklk
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <br></br>
        <div>
          {
            <>
              <div className="row mt-3 d-block d-md-flex">
                <div className="col-12 p-0 d-block d-md-flex">
                  <div class="form-check ">
                    <label
                      class="form-check-label  fw-bold"
                      for="flexCheckDefault"
                    >
                      View by :
                    </label>
                  </div>
                  <div class="form-check">
                    <Radio.Group
                      name="radiogroupmember"
                      onChange={selectradiovalue2}
                      value={Rendermember}
                    >
                      <Radio value={"Primary"}>{userPrimaryInDetails?.memberName}</Radio>
                      {userPrimaryInDetails?.spouseName ? (
                        <>
                          {" "}
                          <Radio value={"Spouse"}>
                            {userPrimaryInDetails?.spouseName}
                          </Radio>
                        </>
                      ) : null}
                    </Radio.Group>
                    {" | "}
                    <Radio.Group
                      className="ms-2"
                      name="radiogroup"
                      onChange={selectradiovalue}
                      value={RenderValue}
                    >
                      <Radio value={true}>Agent</Radio>
                      <Radio value={false}>Legal Documents</Radio>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              {/* 
              <div  classNAme="AgentRedio"  >
                <div class="form-check " onClick={modalOnDisable}>
                  <label
                    class="form-check-label  fw-bold"
                    for="flexCheckDefault"
                  >
                    View by
                  </label>
                </div>
                <div class="form-check ">
                  <Radio.Group
                    name="radiogroup"
                    onChange={selectradiovalue}
                    value={RenderValue}
                  >
                    <Radio value={true}>Agent</Radio>
                    <Radio value={false}>Legal Documents</Radio>
                  </Radio.Group>
                </div>


              </div> */}
              <div>
                <form>
                  <Row className="">
                    <Col span={14} xs={24} sm={18} md={14} lg={14} xl={14}>
                      <Input.Search
                        className="colors1 w-100"
                        placeholder="Search for  Agents"
                        onSearch={onSearch}
                        style={{
                          marginTop: 15,
                          borderRadius: 10,
                        }}
                        onChange={(e) => {
                          setInputFilter(e.target.value);
                        }}
                      />
                    </Col>
                    {/* <button className='Fiduciaries-add border-0 px-3 text-light rounded  fw-bold p-2 ms-5 mt-3' onClick={() => addFiduciries("AddFiduciries")}>
                    + ADD NEW
                  </button> */}
                  </Row>
                </form>
              </div>
              <br></br>
              <div>
                {RenderValue == false ? (
                  <AgentsTableLegal dataSource={filterdatalegal} Rendermember={Rendermember} />
                ) : (
                  <AgentsTable
                    dataSource={filterdata}
                    Rendermember={Rendermember}
                  />
                )}
              </div>
              {/*  */}
            </>
          }
        </div>
      </div>
    </div>
  );
}
