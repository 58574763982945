import { Modal } from 'antd'
import React from 'react'

export default function ModalComponent(props) {
  return (
      <Modal
          title={props.title}
          visible={props.visible}
          style={{ top: 10, height: `calc(${window.innerHeight}px - 20rem)` }}
          footer=""
          onOk={props.onOk}
          onCancel={props.onCancel}
          okText="Submit"
          width={`calc(${window.innerWidth}px - 5rem)`}
          className="my-modal-class"
      >
        {props.children}
    </Modal>
  )
}
