import {
  Col,
  Form,
  Input,
  Row,
  Typography,
  Popconfirm,
  InputNumber,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import konsole from "../../../network/konsole";
import Services from "../../../network/Services";
import "antd/dist/antd.css";
import { UserContext } from "../../../App";
import commonLib from "../../../control/commonLib";
// import "./PersonalInfo.css"
const { Text } = Typography;

function MemberInfoMaritalStatus({ form, setnoofchildConfirm,setmartalstatusid }) {
  const [relationshiptype, setRelationshipType] = useState([]);
  const [noofChildValue, setnoofChildValue] = useState(false);
  useEffect(() => {
    getRelationshiptype();
    if(setmartalstatusid){
      setmartalstatusid(form?.getFieldsValue()?.maritalStatusId)
    }
  }, []);
  const getRelationshiptype = () => {
    let getrelationshiptypeApiCall = Services.getMaritalStatus();
    getrelationshiptypeApiCall
      .then((res) => {
        konsole.log("resrelationshiptype", res.data.data);
        setRelationshipType(res.data.data);
      })
      .catch((error) => {
        konsole.log("errorrelationshiptype", error);
      });
  };
  const confirm = (e) => {
    setnoofchildConfirm(true);
    setnoofChildValue(false);
  };
  const cancel = (e) => {
    setnoofchildConfirm(true);
    form.setFieldsValue({
      noOfChildren: 0,
    });
    setnoofChildValue(false);
  };

  const onChange = (value) => {
    console.log("changed", value);
    if (value > 10) {
      setnoofChildValue(true);
      setnoofchildConfirm(false);
    } else {
      setnoofChildValue(false);
      setnoofchildConfirm(true);
    }
  };

  const selectrelationship=(e)=>{
    if(setmartalstatusid){
      setmartalstatusid(e.target.value)
    }
  }

  return (
    <Row>
      <Col span={24} xs={24} className="px-2">
        <div className="Info">
          <div className="main">
            <Text strong={true} type="danger" style={{ color: "#76272B" }}>
              Marital Information
            </Text>
            <Row gutter={[8, 8]} style={{ marginBottom: "7px", }}>
              <Col span={8} xs={24} sm={8} md={8} lg={8} >
                <label> {commonLib.mandatory("Relationship status")}</label>
                <Form.Item
                  name="maritalStatusId"
                  rules={[
                    {
                      required: true,
                      message: "Please choose your Relationship Status",
                    },
                  ]}
                >
                  <select
                    className="form-select form-select-sm"
                    style={{
                      marginTop: 10,
                      marginright: 15,
                    }}
                    showSearch
                    placeholder="Marital Status"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(e)=>{selectrelationship(e)}}
                  >
                    <option disabled selected>
                      Marital Status
                    </option>
                    {relationshiptype.map((items, index) => {
                      return (
                        <option key={items.value} value={items.value}>
                          {items.label}
                        </option>
                      );
                    })}
                  </select>
                </Form.Item>
              </Col>
              <Col span={8} xs={24} sm={8} md={8} lg={8}>
                <label>No of children</label>
                <Popconfirm
                  title={`Are you sure No of children ?`}
                  onConfirm={confirm}
                  placement="top"
                  onCancel={cancel}
                  visible={noofChildValue}
                  okText="Yes"
                  cancelText="No"
                />
                <Form.Item
                  name="noOfChildren"
                  className="mt-2"
                  rules={[
                    // {
                    //   required: true,
                    //   message: "Please Input No. Of Children",
                    // },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Please Enter Number Only",
                    },
                  ]}
                >
                  <InputNumber
                    defaultValue={""}
                    min={0}
                    max={100}
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default MemberInfoMaritalStatus;
