import axios from "axios";
import config from "../config.json";
import { mapApi, mapKey } from "../control/Constant";
import konsole from "./konsole";
import AoUrl from "./url";

axios.defaults.timeout = 15000;
axios.defaults.baseURL = config.BASE_URL;
konsole.log("config.BASE_URL", config.BASE_URL)
// axios.defaults.headers.post['Content-Type'] = 'application/json;text/html;charset=utf-8';

const invoke = (url, method, body, cancel) => {
  if (cancel) {
    cancel();
  }
  try {
    const token = sessionStorage.getItem("AuthToken");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    console.log("URL: " + url);
    console.log("method:" + method);
    console.log((method == "POST" ? "body" : "params") + JSON.stringify(body));

    return axios({
      cancelToken: new axios.CancelToken(function executor(c) {
        cancel = c;
      }),
      method: method,
      url: url,
      params: method === "GET" ? body : null,
      data:
        method === "POST" || method === "PUT" || method === "DELETE"
          ? body
          : null,
    });
  } catch (error) {
    if (axios.isCancel(error)) {
      konsole.error("Request canceled", error.message);
    } else {
      konsole.error("Something went wrong: ", error.message);
    }
  }
};

const Services = {
  getSubtenantDetail: async (subtenantId) => {
    let cancel;
    let url = AoUrl.getSubtenantDetailPath;
    let body = {};
    body["subtenantId"] = subtenantId;

    return invoke(url, "POST", body, cancel);
  },

  getDynamicLogourlBasedOnSubtenant: async (subtenantId, isActive) => {
    let cancel;
    let url = AoUrl.getDynamicLogoBasedOnSubtenant
    let body = {};
    body['subtenantId'] = subtenantId;
    // body['subtenantLogoUrl'] = subtenantLogoUrl;
    body['isActive'] = isActive;

    return invoke(url, "POST", body, cancel);
  },
  getFiduciaryMemberList: async (userId) => {
    let cancel;
    let url = AoUrl.getFiduciariesName + userId;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  getBenTable: async (userId) => {
    let cancel;
    let url = AoUrl.getBeneficiarysName + "/" + userId;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  getFiduciaryAsgmnt: async () => {
    let cancel;
    let url = AoUrl.getFiduciaryAsgnmntTypeDetail;
    let body = {};
    // body[''] = subtenantId;
    return invoke(url, "GET", body, cancel);
  },
  postAddMember: async (
    fname,
    mname,
    lname,
    nickname,
    dob,
    isVeteran,
    isPrimaryMember,
    suffixId,
    citizenshipId,
    genderId,
    birthPlace,
    primaryUserId,
    relationshipTypeId,
    isFiduciary,
    isBeneficiary,
    relativeUserId,
    isEmergencyContact,
    createdBy,
    rltnTypeWithSpouseId
  ) => {
    let cancel;
    let url = AoUrl.postaddmember;
    let body = {};
    let memberRelationship = {};

    // body['subtenantId'] = subtenantId;
    body["fName"] = fname;
    body["mName"] = mname;
    body["lName"] = lname;
    body["nickName"] = nickname;
    body["dob"] = dob;
    body["isVetean"] = isVeteran;
    body["isPrimaryMember"] = isPrimaryMember;
    body["suffixId"] = suffixId;
    body["citizenshipId"] = citizenshipId;
    // body['noOfChildren'] = noOfChildren;
    body["genderId"] = genderId;
    // body['maritalStatusId'] = maritalStatusId;
    body["birthPlace"] = birthPlace;
    memberRelationship["primaryUserId"] = primaryUserId;
    memberRelationship["relationshipTypeId"] = relationshipTypeId;
    memberRelationship["isFiduciary"] = isFiduciary;
    memberRelationship["isBeneficiary"] = isBeneficiary;
    memberRelationship["relativeUserId"] = relativeUserId;
    memberRelationship["isEmergencyContact"] = isEmergencyContact;
    memberRelationship["rltnTypeWithSpouseId"] = rltnTypeWithSpouseId;

    body["memberRelationship"] = memberRelationship;
    body["createdBy"] = createdBy;
    return invoke(url, "POST", body, cancel);
  },
  postAddMemberById: async (postData) => {
    let cancel;
    let url = AoUrl.postaddmember;
    let body = postData;

    return invoke(url, "POST", postData, cancel);
  },
  getnamesuffixes: async () => {
    let cancel;
    let url = AoUrl.getNameSuffix;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  getMaritalStatus: async () => {
    let cancel;
    let url = AoUrl.getMaritalStatusPath;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  getgenders: async () => {
    let cancel;
    let url = AoUrl.getGenders;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  getCitizenshipType: async () => {
    let cancel;
    let url = AoUrl.getCitizenshipTypePath;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  getMemberPersonaldata: async (userId) => {
    let cancel;
    let url = AoUrl.getMemberPersonaldataPath + userId;
    let body = {};

    return invoke(url, "GET", body, cancel);
  },
  getAddressByUserIddata: async (userId) => {
   
    let cancel;
    let url = AoUrl.getAddressByUserIdPath + userId;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  getFiduciarySRank: async () => {
    let cancel;
    let url = AoUrl.getFiduciarySRankDetail;
    let body = {};
    // body[''] = subtenantId;
    return invoke(url, "GET", body, cancel);
  },
  getUpsertUserAgent: async (
    agentId,
    agentUserId,
    memberUserId,
    agentRoleId,
    agentRankId,
    agentAcceptanceStatus,
    agentActiveStatus,
    upsertedBy
  ) => {
    let cancel;
    let url = AoUrl.getUpsertUserAgentDetail;
    let body = {};
    // body['subtenantId'] = subtenantId;
    body["agentId"] = agentId;
    body["agentUserId"] = agentUserId;
    body["memberUserId"] = memberUserId;
    body["agentRankId"] = agentRankId;
    body["agentRoleId"] = agentRoleId;
    body["agentAcceptanceStatus"] = agentAcceptanceStatus;
    // body['agentEffectiveDate'] = agentEffectiveDate;
    body["agentActiveStatus"] = agentActiveStatus;
    body["upsertedBy"] = upsertedBy;

    return invoke(url, "POST", body, cancel);
  },
  getcitizemshiptype: async () => {
    let cancel;
    let url = AoUrl.getCitizenshipType;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },

  getrelationshiptype: async (relationcatId) => {
    let cancel;
    let url = AoUrl.getRelationShipType;
    let body = {};

    body["RelationshipCatId"] = relationcatId;
    return invoke(url, "GET", body, cancel);
  },

  postContactWithOther: async (contactObj) => {
    let cancel;
    let url = AoUrl.postContactWithOtherPath;
    let body = contactObj;

    return invoke(url, "POST", body, cancel);
  },
  postContactWithOther: async (contactObj) => {
    let cancel;
    let url = AoUrl.putContactWithOtherPath;
    let body = contactObj;

    return invoke(url, "PUT", body, cancel);
  },
  getcountrycode: async () => {
    let cancel;
    let url = AoUrl.getcountrycodepath;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  getUserAgent: async (UserId) => {
    let cancel;
    let url = AoUrl.getUserAgentPath;
    let body = {};
    body["UserId"] = UserId;

    return invoke(url, "GET", body, cancel);
  },
  getUserAgentRole: async () => {
    let cancel;
    let url = AoUrl.getUserAgentRolePath;
    let body = {};

    return invoke(url, "GET", body, cancel);
  },
  getUserAgentRank: async () => {
    let cancel;
    let url = AoUrl.getUserRankPath;
    let body = {};

    return invoke(url, "GET", body, cancel);
  },
  getMember: async (userid) => {
    let cancel;
    let url = AoUrl.memberPath + "/" + userid;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  postaddAddressbyuserId: async (
    method,
    userId,
    addressLine1,
    zipcode,
    county,
    suite,
    city,
    state,
    country,
    addressTypeId,
    createdBy,
    addressId
  ) => {
    let cancel;
    // let url=AoUrl.postaddressaddbyuserid
    let url =
      method == "POST" ? AoUrl.postaddressaddbyuserid : AoUrl.putupdateAddress;
    let body = {};
    let address = {};

    // address['lattitude']=lattitude;
    // address['longitude']=longitude;
    address["addressLine1"] = addressLine1;
    // address['addressLine2']=addressLine2;
    // address['addressLine3']=addressLine3;
    if (method === "PUT") {
      address["addressId"] = addressId;
      address["updatedBy"] = createdBy;
    } else {
      address["createdBy"] = createdBy;
    }
    address["zipcode"] = zipcode;
    address["county"] = county;
    address["addressLine2"] = suite;
    address["city"] = city;
    address["state"] = state;
    address["country"] = country;
    address["addressTypeId"] = addressTypeId;
    body["userId"] = userId;
    body["address"] = address;

    konsole.log(body,"bidybody")

    return invoke(url, method, body, cancel);
  },
  deleteAddressByUserId: async (userId, addressId, loggedInUserId) => {
    let cancel;
    let url =
      AoUrl.deleteAddressByUserId +
      userId +
      "/" +
      addressId +
      "/" +
      loggedInUserId;
    let body = {};
    return invoke(url, "DELETE", body, cancel);
  },
  updateaddress: async (
    userId,
    addressLine1,
    zipcode,
    city,
    state,
    county,
    country,
    addressTypeId,
    createdBy,
    addressId,
    isActive
  ) => {
    let cancel;
    let url = AoUrl.updateaddressbyuserid;
    let body = {};
    let address = {};
    body["userId"] = createdBy;
    address["addressLine1"] = addressLine1;
    address["zipcode"] = zipcode;
    address["county"] = county;
    address["city"] = city;
    address["state"] = state;
    address["country"] = country;
    address["addressTypeId"] = addressTypeId;
    address["addressId"] = addressId;
    address["updatedBy"] = userId;
    address["isActive"] = isActive;

    body["address"] = address;
    return invoke(url, "PUT", body, cancel);
  },

  updateaddmemberuserid: async (
    fName,
    mName,
    lName,
    nickName,
    dob,
    isVetean,
    isPrimaryMember,
    suffix,
    citizenship,
    gender,
    placeofBirth,
    relationshipTypeId,
    isFiduciary,
    isBeneficiary,
    isEmergencyContact,
    userId,
    updateBy,
    userRltnshipId,
    userMemberId,
    relationshipType,
    rltnTypeWithSpouseId,
    relationuserid
  ) => {
    let cancel;
    let url = AoUrl.updatemember;
    let body = {};
    let memberRelationship = {};

    body["fName"] = fName;
    body["mName"] = mName;
    body["lName"] = lName;
    body["nickName"] = nickName;
    body["dob"] = dob;
    body["isVeteran"] = isVetean;
    body["isPrimaryMember"] = isPrimaryMember;
    body["suffixId"] = suffix;
    body["citizenshipId"] = citizenship;
    body["genderId"] = gender;
    body["birthPlace"] = placeofBirth;
    body["userId"] = userId;
    body["updatedBy"] = updateBy;

    memberRelationship["primaryUserId"] = updateBy;
    memberRelationship["relationshipTypeId"] = relationshipTypeId;
    memberRelationship["isFiduciary"] = isFiduciary;
    memberRelationship["isBeneficiary"] = isBeneficiary;
    memberRelationship["relativeUserId"] = relationuserid;
    memberRelationship["isEmergencyContact"] = isEmergencyContact;
    memberRelationship["userRltnshipId"] = userRltnshipId;
    memberRelationship["userMemberId"] = userMemberId;
    memberRelationship["relationshipType"] = relationshipType;
    memberRelationship["rltnTypeWithSpouseId"] = rltnTypeWithSpouseId;

    body["memberRelationship"] = memberRelationship;

    return invoke(url, "PUT", body, cancel);
  },
  getProfessionalByUserId: async (userId) => {
    let cancel;
    let url = AoUrl.getProfessionalByUserIdPath + userId;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },

  getdata: async (userId) => {
    let cancel;
    let url = AoUrl.getFiduciaryDetailsByUserId + userId;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },

  UpdateFiduciaryStatus: async (
    fiduciaryUserId,
    userRelationshipId,
    memberId,
    isFiduciary,
    isAgent,
    primaryUserId
  ) => {
    let cancel;
    let url = AoUrl.getUpdateFiduciaryStatusPath;
    let body = {};
    body["fiduciaryUserId"] = fiduciaryUserId;
    body["userRelationshipId"] = userRelationshipId;
    body["memberId"] = memberId;
    body["isFiduciary"] = isFiduciary;
    body["isAgent"] = isAgent;
    body["primaryUserId"] = primaryUserId;
    return invoke(url, "PUT", body, cancel);
  },
  UpdateBeneficiaryStatus: async (

    userRelationshipId,
    memberId,
    primaryUserId,
    isbeneficiary,
    updatedBy,

  ) => {
    let cancel;
    let url = AoUrl.getUpdateBeneficiaryStatusPath;
    let body = {};
    body["userRelationshipId"] = userRelationshipId;
    body["beneficiaryMemberId"] = memberId;
    body["primaryUserId"] = primaryUserId;
    body["isBeneficiary"] = isbeneficiary;
    body["updatedBy"] = updatedBy;
    // body["isAgent"] = isAgent;

    return invoke(url, "PUT", body, cancel);
  },


  getBeneficiarydetailsByUserId: async (userid) => {
    let cancel;
    let url = AoUrl.getBeneficiaryDetails + "/" + userid;
    let body = {};
    // body['userId']=userid
    return invoke(url, "GET", body, cancel);
  },
  getLoggedInUser: async (userId, appState, loggedInUserId, roleId) => {
    let cancel;
    let url =
      AoUrl.getAthenticatePath +
      `${userId}/${appState}/${loggedInUserId}/${roleId}/`;
    let bookingSlot = {};
    // bookingSlot['refreshId'] = parseInt(refreshId);
    // bookingSlot['roleId'] = roleId;
    // bookingSlot['refreshToken'] = refreshToken;
    // bookingSlot['userid'] = userid;

    return invoke(url, "GET", bookingSlot, cancel);
  },
  getVeteranByUserId: async (userId) => {
    let cancel;
    let url = AoUrl.getVeteranData + userId;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  getDischargeType: async () => {
    let cancel;
    let url = AoUrl.getDischargeType;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  getWarTimePeriod: async () => {
    let cancel;
    let url = AoUrl.getWarTimePeriodPath;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  postVeteranByUserid: async (postData) => {
    let cancel;
    let url = AoUrl.postVeteranByUseridPath;
    let body = postData;
    // body[''] = subtenantId;

    return invoke(url, "POST", body, cancel);
  },
  updateVeteranData: async (postData) => {
    let cancel;
    let url = AoUrl.updateVeteranDataPath;
    let body = postData;

    return invoke(url, "PUT", body, cancel);
  },
  deleteVeteranByUserId: async (postData) => {
    let cancel;
    let url = AoUrl.deleteVeteranByUserIdPath;
    let body = postData;

    return invoke(url, "DELETE", body, cancel);
  },
  getAddressType: async () => {
    let cancel;
    let url = AoUrl.getAddressTypePath;
    let body = {};

    return invoke(url, "GET", body, cancel);
  },

  postupsertuserAgent: async (
    agentId,
    agentUserId,
    memberUserId,
    agentAcceptanceStatus,
    agentActiveStatus,
    upsertedBy,
    agentRoleId,
    agentRankId
  ) => {
    let cancel;
    let url = AoUrl.postupsertUserAgent;
    let body = {};
    let array = [];
    body["agentId"] = agentId;
    body["agentUserId"] = agentUserId;
    body["memberUserId"] = memberUserId;
    body["agentAcceptanceStatus"] = agentAcceptanceStatus;
    body["agentActiveStatus"] = agentActiveStatus;
    body["upsertedBy"] = upsertedBy;
    body["agentRoleId"] = agentRoleId;
    body["agentRankId"] = agentRankId;
    array.push(body);
    return invoke(url, "POST", array, cancel);
  },

  getfamilyMemberByParentId: async (userId) => {
    let cancel;
    let url = AoUrl.getFamilyMemberByParentId + userId;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  getOccupationByUserId: async (userId) => {
    let cancel;
    let url = AoUrl.getOccupationbyUserId + userId;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  upsertOccupationByUserId: async (method, postData) => {
    let cancel;
    let url =
      method == "POST" ? AoUrl.postAddOccupation : AoUrl.putOccupationbyUserId;
    let body = postData;
    return invoke(url, method, body, cancel);
  },
  getfamilyMemberByParentId: async (userId) => {
    let cancel;
    let url = AoUrl.getFamilyMemberByParentId + userId;

    return invoke(url, "GET", cancel);
  },

  getMemberDetails: async (memberId) => {
    let cancel;
    let url = AoUrl.getMember + memberId;
    return invoke(url, "GET", cancel);
  },
  getcontactwithother: async (userId) => {
    
    let cancel;
    let url = AoUrl.getContactwithother + userId;

    return invoke(url, "GET", cancel);
  },
  getContactType: async () => {
    let cancel;
    let url = AoUrl.getContactTypePath;
    let body = {};

    return invoke(url, "GET", body, cancel);
  },
  getaddressuserid: async (userId) => {
  
    let cancel;
    let url = AoUrl.getaddressbyuserid + userId;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },

  initMapScript: () => {
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApi}?key=${mapKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  },
  putMemberById: async (postData) => {
    let cancel;
    let url = AoUrl.putUpdateMember;
    let body = postData;
    return invoke(url, "PUT", body, cancel);
  },
  postContactWithOther: async (contactObj) => {
    let cancel;
    let url = AoUrl.postContactWithOtherPath;
    let body = contactObj;

    return invoke(url, "POST", body, cancel);
  },
  putContactWithOther: async (contactObj) => {
    let cancel;
    let url = AoUrl.putContactWithOtherPath;
    let body = contactObj;

    return invoke(url, "PUT", body, cancel);
  },
  getContactType: async () => {
    let cancel;
    let url = AoUrl.getContactTypePath;
    let body = {};

    return invoke(url, "GET", body, cancel);
  },
  getContactNyUserID: async (userId) => {
    let cancel;
    let url = AoUrl.getContactMemberPath + userId;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  getFileCabinet: async () => {
    let cancel;
    let url = AoUrl.getFileCabinetType;
    let body = {};
    console.log("asdasdas", url);
    return invoke(url, "GET", body, cancel);
  },
  postFileDocumentCabinet: async (postData) => {
    let cancel;
    let url = AoUrl.postFileDocumentCabinetById;
    let body = {};

    body["userId"] = postData.userId;
    body["fileCabinetId"] = postData.fileCabinetId;

    return invoke(url, "POST", body, cancel);
  },

  postFileCabinetRemarksPath: async (method, postData) => {
    let cancel;
    let url =
      method === "POST"
        ? AoUrl.postFileCabinetRemarksPath
        : AoUrl.putFileCabinetRemarksPath;

    return invoke(url, method, postData, cancel);
  },

  getFileCabinetRemarksp: async (postData) => {
    let cancel;
    let url = AoUrl.getFileCabinetRemarksPath;
    return invoke(url, "POST", postData, cancel);
  },
  postDownloadUserDocumentdata: async (getPost, loginUserId) => {
    let cancel;
    let url =
      AoUrl.postDownloadUserDocument +
      getPost.userId +
      "/" +
      getPost.fileId +
      "/" +
      getPost.fileTypeId +
      "/" +
      getPost.fileCategoryId +
      "/" +
      loginUserId;
    let body = {};

    return invoke(url, "GET", body, cancel);
  },
  postDownloadUserDocument: async (dataObj) => {
    let cancel;
    let url = AoUrl.postfileuploaddownloadurl;
    let body = dataObj;

    return invoke(url, "POST", body, cancel);
  },
  deletecontactuserid: async (userId, contactId, deletedBy) => {
    let cancel;
    let url = AoUrl.deletecontactbyuserid;
    let body = {};
    body["userId"] = userId;
    body["contactId"] = contactId;
    body["deletedBy"] = deletedBy;
    return invoke(url, "DELETE", body, cancel);
  },
  // postUpsertUserAgent: async () => {
  //     let cancel;
  //     let url = AoUrl.postUpsertUserAgentPath;
  //     let body={}

  //     return invoke(url, "POST", body, cancel);
  // },
  putProfessionaluser: async (dataObj) => {
    let cancel;
    let url = AoUrl.putProfessionaluserPath;
    let body = dataObj;

    return invoke(url, "PUT", body, cancel);
  },
  getUserAgentList: async (userId, isActive) => {
    let cancel;
    let body = {};
    body["userId"] = userId;
    body["isActive"] = isActive;
    let url = AoUrl.getUserAgentListPath;
    return invoke(url, "GET", body, cancel);
  },
  getSubjectQuestions: async (id) => {
    let cancel;
    let url = AoUrl.getSubjectQuestionsPath;
    let body = id;
    return invoke(url, "POST", body, cancel);
  },
  upsertSubjectdata: async (method, data) => {
    let cancel;
    let url =
      method == "POST"
        ? AoUrl.postaddusersubjectdata
        : method == "PUT"
          ? AoUrl.putSubjectResponse
          : "";
    let body = data;
    return invoke(url, method, body, cancel);
  },

  getSubjectResponse: async (userId, questionId) => {
    let cancel;
    let url = AoUrl.getSubjectResponse + userId + `/0/0/${questionId}`;
    return invoke(url, "GET", "", cancel);
  },
  postaddusersubjectapi: async (dataObj) => {
    let cancel;
    let url = AoUrl.postaddusersubjectdata;
    let body = dataObj;

    return invoke(url, "POST", body, cancel);
  },

  putaddusersubjectapi: async (dataObj) => {
    let cancel;
    let url = AoUrl.putUserAgentList;
    let body = dataObj;

    return invoke(url, "PUT", body, cancel);
  },
  getFamilyuserbyIdapi: async (userId) => {
    let cancel;
    let url = AoUrl.getFamilyuserbyId + userId;
    let body = userId;

    return invoke(url, "GET", body, cancel);
  },
  postNofityMessageapi: async (dataObj) => {
    let cancel;
    let url = AoUrl.postNofityMessage;
    let body = dataObj;

    return invoke(url, "POST", body, cancel);
  },
  postcontactmapapi: async (dataObj) => {
    let cancel;
    let url = AoUrl.postcontactmap;
    let body = dataObj;

    return invoke(url, "POST", body, cancel);
  },
  getNotifyContactapi: async (userId, contactNatureId, notifyConditionId) => {
    let cancel;
    let url =
      AoUrl.getNotifyContact +
      "/" +
      userId +
      "/" +
      contactNatureId +
      "/" +
      notifyConditionId;
    let body = userId;

    return invoke(url, "GET", body, cancel);
  },
  getNotifyMessageapi: async (
    userId,
    notifyTypeId,
    contactNatureId,
    notifyConditionId
  ) => {
    let cancel;
    let url =
      AoUrl.getNotifyMessage +
      userId +
      "/" +
      notifyTypeId +
      "/" +
      contactNatureId +
      "/" +
      notifyConditionId;
    let body = userId;

    return invoke(url, "GET", cancel);
  },
  putNotifyMessageapi: async (dataObj) => {
    let cancel;
    let url = AoUrl.putNotifyMessage;
    let body = dataObj;

    return invoke(url, "POST", body, cancel);
  },
  filecabinetPreparebyapi: async (userId, fileCabinetId) => {
    let cancel;
    let url = AoUrl.filecabinetPreparebyUrl + userId + " / " + fileCabinetId;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  filecabinetfilestatusapi: async (filestatus) => {
    let cancel;
    let url = AoUrl.filecabinetfilestatus + "?IsActive=" + filestatus;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  filecabinetdocumentapi: async (userId, FileCategoryId, PreparedByIndex, FileStatusId) => {
    let cancel;
    let url = AoUrl.filecabinetdocumenturl + "/" + userId + "?FileCategoryId=" + FileCategoryId + "&PreparedByIndex=" + PreparedByIndex + "&FileStatusId=" + FileStatusId;
    let body = {};
    // body[''] = subtenantId;

    return invoke(url, "GET", body, cancel);
  },
  emailtoparalegal: async (jsondata) => {
    let cancel;
    let url = AoUrl.postemailsendtoparalegal;
    // let body={}
    return invoke(url, "POST", jsondata, cancel)
  },
  postAddOther: async (method, arrayOfData) => {
    let cancel;
    let url = method == "POST" ? AoUrl.addOtherPath : method == "PUT" ? AoUrl.updateOtherPath : "";
    konsole.log("arryaofOther", arrayOfData, url);

    return invoke(url, method, arrayOfData, cancel)
  },
  postMapOther: async (method, arrayOfData) => {
    let cancel;
    konsole.log("arryaofOther", arrayOfData);
    let url = AoUrl.mapOtherwithFormPath;

    return invoke(url, method, arrayOfData, cancel)
  },
  getOtherFromAPI: async (arrayOfData) => {
    let cancel;
    let url = AoUrl.getOtherFromAPI;

    return invoke(url, "POST", arrayOfData, cancel)
  },
  getCommMediumPathapi: async (dataobj) => {
    let cancel;
    let url = AoUrl.getCommMediumPath;
    let body = dataobj;

    return invoke(url, "POST", body, cancel);
  },
  GetEmailTemplateapi: async (tempid, isactive, subid) => {
    let cancel;
    let url = AoUrl.GetEmailTemplate + "?" + "TemplateId" + "=" + tempid + "&" + "IsActive" + "=" + isactive + "&SubtenantId" + "=" + subid;
    let body = [];

    return invoke(url, "GEt", body, cancel);
  },
  getTextTemplateapi: async (tempid, isactive, subid) => {
    let cancel;
    let url = AoUrl.getTextTemplate + "?" + "TextTemplateId" + "=" + tempid + "&" + "IsActive" + "=" + isactive + "&SubtenantId" + "=" + subid;
    let body = [];

    return invoke(url, "GEt", body, cancel);
  },
  PostEmailComapi: async (dataobj) => {
    let cancel;
    let url = AoUrl.PostEmailCom;
    let body = dataobj;

    return invoke(url, "POST", body, cancel);
  },
  postSendTextPathapi: async (dataobj) => {
    let cancel;
    let url = AoUrl.postSendTextPath;
    let body = dataobj;

    return invoke(url, "POST", body, cancel);
  },
  SendEmailCommPathAsync: async (dataobj) => {
    let cancel;
    let url = AoUrl.SendEmailCommPath;
    let body = dataobj;

    return invoke(url, "POST", body, cancel);
  },
  getCommTypeapi: async () => {
    let cancel;
    let url = AoUrl.getCommTypePath;
    let body = {};
    return invoke(url, "GET", body, cancel);
  },
  getAddMemberUserByUserIdAsync: async (dataobj) => {
    let cancel;
    let url = AoUrl.getAddMemberUserByUserIdAsyncPath;
    let body = dataobj;

    return invoke(url, "POST", body, cancel);
  },
  generateUserLinks: async (dataObj) => {
    let cencel;
    let url=AoUrl.userGenerateLinks
    return invoke(url, "POST", dataObj,cencel)

  },
  postaddmemberaddress:async(dataObj)=>{
    let cancel;
    let url=AoUrl.addmemberaddress
    return invoke(url, "POST", dataObj,cancel)
  },
  putupdteMemberAddress:async(dataObj)=>{
    let cancel;
    let url=AoUrl.putMemberAddress
    return invoke(url, "PUT", dataObj,cancel)
  },
  getProfessionalTypeapi:async()=>{
    let cancel;
    let url=AoUrl.getProfessionalType
    return invoke(url,"Get",cancel)
  },
  postprofessionalapi:async(dataObj)=>{
    let cancel;
    let url=AoUrl.postprofessional
    return invoke(url, "POST", dataObj,cancel)
  },
  postaddProfessionalUserMapping:async(dataObj)=>{
    let cancel;
    let url=AoUrl.addProfessionalUserMapping
    return invoke(url,"POST",dataObj,cancel)
  },
  putupdatememberbyid:async(dataObj)=>{
    let cancel;
    let url=AoUrl.updatememberbyid
    return invoke(url,"PUT",dataObj,cancel)
  },
  postaddmember:async(dataobj)=>{
    let cancel;
    let url=AoUrl.addmember
    return invoke(url,"POST",dataobj,cancel)
  },
  deleteprofessional:async(datobj)=>{
    let cancel;
    let url = AoUrl.deleteprofess

    return invoke(url,"DELETE",datobj,cancel)
  },
  getUserDetaildByEmail:async(emailId)=>{
    let cancel;
    let url=AoUrl.getUserDetailsByEmailId+`?emailId=${emailId}`
    let body={}
    return invoke(url,"GET",body,cancel)
  },
  getProfessionalSecDesc:async()=>{
    let cancel;
    let url=AoUrl.getProfessionalSecDesc
    let body={}
    return invoke(url,"GET",body,cancel)
  },
  upsertProfessionalUserMapping:async(dataobj)=>{
    let cancel;
    let url=AoUrl.upsertProfessionalUserMapping
    let body={}
    return invoke(url,"GET",dataobj,cancel)
  },
  postUploadUserDocument:async(formdata)=>{
        let cancel;
        let url=AoUrl.postUploadUserDocument;
        return invoke(url,"POST",formdata,cancel)

  },
  sendMailFeedBack:async(formdata)=>{
    let cancel;
    let url=AoUrl.sendMailFeedBack;
    return invoke(url,"POST",formdata,cancel)

},
  getpostFileDocumentCabinetById:async(jsonobj)=>{
    let cancel;
    let url=AoUrl.postFileDocumentCabinetById
    return invoke(url,"POST",jsonobj,cancel)
  },
  GETgetFileUploadDoc:async(fileid)=>{
    let cancel;
    let url=AoUrl.getFileUploadDoc+fileid+"/1" 
    let body={}
    return invoke(url,"GET",body,cancel)
  },
  deletefamilymemberservice:async(userIdd,memberIdd,deletedBy,memberRelationshipId,IsDeleteDescendant)=>{
    let cancel;
    let url=AoUrl.deletefamilymember+userIdd +"/"+memberIdd +"/"+deletedBy+"/"+memberRelationshipId+"/"+IsDeleteDescendant
    let body={};
    return invoke(url,"DELETE",body,cancel)
  },
  postfeedbackservice:async(dataobj)=>{
    let cancel;
    let url =AoUrl.addfeedback
    let body=dataobj
    return invoke(url,"POST",body,cancel)
  },
  postmemberrelationshipservice:async(dataobj,method)=>{
    let cancel;
    let url = AoUrl.postmemberrelationshipapi
    let body=dataobj;
    return invoke(url,method,body,cancel)
  },
  getmemberrelationshipservice:async(userid)=>{
    let cancel;
    let url = AoUrl.getmemberrelationship+userid
    let body={}
    return invoke(url,"GET",body,cancel)
  },
  updateMemberChildByUserId:async(userId,IsChildUserId)=>{

    let cancel;
    let url=AoUrl.updateMemberChildByUserId+`?userId=${userId}&IsChildUserId=${IsChildUserId}`
    let body={}
    return invoke(url,'PUT',body,cancel)
  },
  sendremarktoparalegalservice:async(dataobj)=>{
    let cancel;
    let url = AoUrl.sendremarktoparalegal
    let body = {}
    return invoke(url,"POST",dataobj,cancel)
  },
  getUserListByRoleIdpathservice:async(dataobj)=>{
    let cancel;
    let url = AoUrl.getUserListByRoleIdpath;
    let body ={}

    return invoke(url,"POST",dataobj,cancel)
  }

  
}

const loadAsyncScript = async (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
};

export default Services;
