import { message } from "antd";

const Toaster = {
    success: (content, duration, onClose) => {
        message.destroy()
        message.success(content, [1.5], onClose)
    },
    error: (content, duration, onClose) => {
        message.destroy()
        message.error(content, [1.5], onClose)
        
    },
    warning: (content, duration, onClose) => {
        message.destroy()
        message.warning(content, [1.5], onClose)
    },
    loading: (content, duration, onClose) => {
        message.destroy()
        message.loading(content, [1.5], onClose)
    },
    info: (content, duration, onClose) => {
        message.destroy()
        message.info(content, [1.5], onClose)
    },
    specialerror: (content, duration, onClose) => {
        message.destroy()
        message.error(content, [3], onClose)
    },
    
}
export default Toaster