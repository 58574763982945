import React, { useState, useEffect, useRef } from "react";
import konsole from "../network/konsole";

const InputMask = (props) => {
  const inputCard = useRef();

  const handleChange = () => {
    const cardValue = inputCard.current.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    console.log(cardValue, cardValue[2]);
    inputCard.current.value = !cardValue[2]
      ? cardValue[1]
      : `(${cardValue[1]}) ${cardValue[2]}${`${
          cardValue[3] ? `-${cardValue[3]}` : ""
        }`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;
    const numbers = inputCard.current.value.replace(/(\D)/g, "");
    props.onValueChange(numbers);
  };
  const filledPhoneNo = () => {
    konsole.log("filledPhoneNo",props.value);
    const cardValue = props.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    console.log(cardValue, cardValue[2]);
    inputCard.current.value = !cardValue[2]
      ? cardValue[1]
      : `(${cardValue[1]}) ${cardValue[2]}${`${
          cardValue[3] ? `-${cardValue[3]}` : ""
        }`}${`${cardValue[4] ? `-${cardValue[4]}` : ""}`}`;
    // const numbers = inputCard.current.value.replace(/(\D)/g, "");
    {console.log("porps",props.value, inputCard.current.value)}
  };
  if (props.value === "0"){
 console.log("propsvalue",props.value)
   
  }
  useEffect(() => {
    // handleChange(props.value)

    if(props.value !== undefined && props.value !== null){
      filledPhoneNo();
    }else{
      // alert(props.value="1")
      // filledPhoneNo();
    }
    
  }, [props.value]);
  useEffect(() => {
      
  handleChange()
  }, []);

  return (
    <>
      <input
        className="ant-input"
        placeholder={props?.placeholder ? props?.placeholder : "Cell No.*"}
        type="text"
        ref={inputCard}
        onChange={handleChange}

      />
    </>
  );
};

export default InputMask;