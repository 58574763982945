import React, { useState,useEffect } from "react";
import { Drawer, Button, Image, Popconfirm } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "../styles/NavBar.css"
import commonLib from "../control/commonLib";
import Sidemenu from "./Sidemenu";
import { AoLoginBaseUrl,AoParalegalBaseUrl } from "../network/url";
import konsole from "../network/konsole";
import Services from "../network/Services";
import { Link ,useLocation,useNavigate} from "react-router-dom";

const Navbar = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const loggedUserDetail = commonLib.getObjFromStorage('userLoggedInDetail');
  const [subtenantImage, setsubtenantImage] = useState("");
  const [roleIdValue, setRoleIdValue] = useState()
  const stateObj = commonLib.getObjFromStorage('stateObj');
  const location = useLocation()
  const navigate = useNavigate()

  const handleClick = () => {
    const stateObj = commonLib.getObjFromStorage('stateObj');
    let params = `appState=${stateObj.appState}&userId=${stateObj.userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggenInId}&success=${true}`;
    window.location.replace(`${AoLoginBaseUrl}Account/Signout?` + params);
    sessionStorage.clear()
  }

  
  useEffect(() => {
    const subtenantId = commonLib.getObjFromStorage("subtenantId");
    let valueRoleId = commonLib.getObjFromStorage("stateObj")?.roleId
    setRoleIdValue(valueRoleId)
    Services.getDynamicLogourlBasedOnSubtenant(subtenantId, true)
      .then((res) => {
        setsubtenantImage(res.data?.data[0]?.subtenantLogoUrl);
      })
      .catch((err) => {
        konsole.log("ErrorData", err);
      });
  }, []);

  
//   const handleSetup = () => {
   
//     const userPrimaryInDetail = commonLib.getObjFromStorage('userPrimaryInDetail');
//     let params = `appState=${stateObj.appState}&userId=${userPrimaryInDetail.memberUserId}&loggedUserId=${stateObj.userId}&roleId=${stateObj.roleId == "13" || stateObj.roleId == "14" || stateObj.roleId == "15"}&loggenInId=${stateObj.loggenInId}`;
   
//      if (roleId == 10 || roleId == 3 || roleId == 13  || roleId == 14 || roleId == 15  ) {
//       window.location.replace(AoIntakeBaseUrl + `?token=${window.btoa(tokenKey)}`)
//   }else{
//     window.location.replace(`${AoParalegalBaseUrl}?token=` + window.btoa(params));
//   }
//     konsole.log("stateObj", stateObj)
// }

  konsole.log("subtenantImage",subtenantImage,location.pathname)

  return (
    <div className="header d-flex justify-content-between align-items-center w-100  border-0 border-bottom" style={{height:"10vh"}} >
  
      <div class="">
        <Link to="/Dashboard">
        <img src={subtenantImage} className='logo' style={{width:"min-content",height:"50px"}}/>
        </Link>
      </div>
      <div className="d-flex align-items-center ">
      {location.pathname == "/Dashboard" ? null : <div className="me-2">
      
          <img onClick={()=>navigate(-1)} style={{cursor:"pointer"}} width="24px" height="24px" src="./images/BackIcon.svg" />

      </div>}
      <p className='text d-md-block d-lg-block m-0 '>Hi, {loggedUserDetail?.loginUserName}</p>
      <Popconfirm 
      className="cursor-pointer"
      placement="bottomRight"
      onConfirm={handleClick}
      title="Are you sure you want to logout ?"
      okText="Yes"
      cancelText="No"
      >
      <img  className='admin ms-2' style={{ cursor: 'pointer' }} src='/icons/logoutcircle.svg' width={25} />

      </Popconfirm>
      </div>
    </div>
  );
};


export default Navbar