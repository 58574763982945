import config from '../config.json'

export const BASE_URL = (config.DEMO == true) ?config.BASE_URLUAT : config.BASE_URL 
export const AoLoginBaseUrl = (config.DEMO == true) ? config.AoLoginBaseUrluat : config.AoLoginBaseUrl
export const AoIntakeBaseUrl =  (config.DEMO == true) ? config.AoIntakeBaseUrluat : config.AoIntakeBaseUrl 
export const AoParalegalBaseUrl =  (config.DEMO == true) ? config.AoParalegalBaseUrluat: config.AoParalegalBaseUrl



let AoUrl ={
    getFiduciariesName:'api/Fiduciary/GetFiduciaryListByUserId/',
    getBeneficiarysName: 'api/Beneficiary/GetBeneficiaryListByUserId/',
    getFiduciaryAsgnmntTypeDetail:'/api/FiduciaryAsgnmntType',
    getFiduciarySRankDetail:'api/FiduciarySRank',
    getUpsertUserAgentDetail:'api/UserAgent/upsertUserAgent',
    postaddmember:'/api/Member/add-member',
    getNameSuffix:'/api/NameSuffixes',
    getGenders:'/api/Genders',
    getCitizenshipType:'/api/CitizenshipType',
    getRelationShipType:'/api/RelationshipType',
    postaddressaddbyuserid:'/api/Address/add-address-by-userid',
    getCitizenshipTypePath :'api/CitizenshipType',
    getMemberPersonaldataPath: 'api/Member/',
    getAddressByUserIdPath : 'api/Address/get-address-by-userid/',
    postContactWithOtherPath: '/api/ContactWithOther/add-contactwithother-by-userid',
    putContactWithOtherPath: '/api/ContactWithOther/update-contactwithother-by-userid',
    getcountrycodepath:'/api/Country/GetRegistrationEnableCountry',
    getUserAgentPath : 'api/UserAgent/getUserAgent',
    getUserAgentRolePath : 'api/UserAgentRole/getUserAgentRole',
    getUserRankPath : 'api/UserAgentRank/getUserAgentRank',
    memberPath : 'api/Member/',
    getFiduciaryDetailsByUserId: 'api/Fiduciary/GetFiduciaryDetailsByUserId/',
    getUpdateFiduciaryStatusPath:  'api/Fiduciary/UpdateFiduciaryStatus',
    getUpdateBeneficiaryStatusPath:'api/Beneficiary/UpdateBeneficiaryStatus',
    getAthenticatePath: '/api/User/AuthenticateLoggedInUser/',
    getBeneficiaryDetails:'/api/Beneficiary/GetBeneficiaryDetailsByUserId/',
    postveteranbyuseridPath: 'api/Veteran/add-veteran-by-userid/',
    getVeteranData: "/api/Veteran/get-veteran-by-userid/",
    updateVeteranData: "/api/Veteran/update-veteran-by-userid/",
    getDischargeType: "/api/VDischargeType/",
    getWarTimePeriodPath: "/api/WarTimePeriod",
    postVeteranByUseridPath: "/api/Veteran/add-veteran-by-userid/",
    updateVeteranDataPath: "/api/Veteran/update-veteran-by-userid/",
    deleteVeteranByUserIdPath: '/api/Veteran/delete-veteran-by-userid/',
    getAddressTypePath: "/api/AddressType",
    postupsertUserAgent:'/api/UserAgent/upsertUserAgent',
    getFamilyMemberByParentId:"/api/Member/GetFamilyMembersByParentId/",
    deleteAddressByUserId: "/api/Address/delete-address-by-userid/",
    putupdateAddress: "/api/Address/update-address-by-userid/",
    getMaritalStatusPath: "/api/MaritalStatuses",
    postAddOccupation: "/api/Occupation/add-occupation-by-userid/",
    getOccupationbyUserId: '/api/Occupation/get-occupation-by-userid/',
    putOccupationbyUserId: '/api/Occupation/update-occupation-by-userid',
    putUpdateMember:  "/api/Member/update-member-by-id/",
    postContactWithOtherPath: '/api/ContactWithOther/add-contactwithother-by-userid',
    putContactWithOtherPath: 'api/ContactWithOther/update-contactwithother-by-userid',
    getContactTypePath: 'api/ContactType',
    getContactMemberPath: 'api/ContactWithOther/get-contactwithother-by-userid/',
    getMember:'/api/Member/',
    updatemember:'/api/Member/update-member-by-id/',
    getContactwithother:'/api/ContactWithOther/get-contactwithother-by-userid/',
    // updateContactwithother:'/api/ContactWithOther/update-contactwithother-by-userid/',
    getaddressbyuserid:'/api/Address/get-address-by-userid/',
    updateaddressbyuserid:'/api/Address/update-address-by-userid/',
    getContactTypePath : 'api/ContactType',
    deletecontactbyuserid:'/api/Contact/delete-contact-by-userid',

    // -----
    getFileCabinetType:'api/FileCabinet/GetFileCabinetList',
    postFileDocumentCabinetById:'/api/FileCabinet/GetUserDocsByFileCabinet',
    postDownloadUserDocument:'/api/FileUpload/downloadUserDocuments/',
    getFileCabinetRemarksPath:'/api/FileCabinet/getFileRemarks',
    postFileCabinetRemarksPath:'/api/FileCabinet/addFileRemarks',
    putFileCabinetRemarksPath:'/api/FileCabinet/updateFileRemarks',
    getProfessionalByUserIdPath: 'api/ProfessionalUser/Get-ProfessionalUser-By-UserId/',
    putProfessionaluserPath: "api/ProfessionalUser/update-ProfessionalUser",
    getSubjectQuestionsPath: "api/Subject/get-subject-for-form-label-id",
    getSubjectResponse: 'api/Subject/get-user-subject/',
    postaddusersubjectdata: "api/Subject/add-user-subject-data",
    putSubjectResponse: 'api/Subject/update-user-subject-data',
    getUserAgentListPath: 'api/UserAgent/getUserAgent',
    putUserAgentList: '/api/Subject/update-user-subject-data',
    getFamilyuserbyId:"/api/Member/get-FamilyMembers-by-userid/",
    postcontactmap:"/api/NotifyContactMap/upsertNotifyContactMap",
    postNofityMessage:"/api/NotifyMessage/upsertNotifyMessage",
    getNotifyContact:'api/NotifyContactMap/getNotifyContactMap/',
    getNotifyMessage: '/api/NotifyMessage/getNotifyMessage/',
    putNotifyMessage:'/api/NotifyMessage/upsertNotifyMessage',
    filecabinetPreparebyUrl:'api/FileCabinet/PreparedBy/',
    filecabinetfilestatus:'/api/FileCabinet/FileStatus',
    filecabinetdocumenturl:'/api/UserFileCabinet/Documents',
    postfileuploaddownloadurl:'/api/FileUpload/downloadUserDocuments/',
    postemailsendtoparalegal:'api/EmailToParalegal/SendEmailToParaLegal',
    addOtherPath: "/api/Others/add-others",
    mapOtherwithFormPath: "/api/MemberOthersMap/add-member-othersmap",
    getOtherFromAPI: "/api/MemberOthersMap/get-member-othersmap",
    updateOtherPath: "/api/Others/update-others",

    getDynamicLogoBasedOnSubtenant:"api/Subtenant/get-subtenant-details",
    getCommMediumPath: '/api/CommMedium/GetCommMedium',
    GetEmailTemplate: "/api/EmailTemplates/GetEmailTemplates",
    PostEmailCom: "/api/EmailComm/SendEmailComm",
    getTextTemplate: "/api/TextTemplates/GetTextTemplates",
    postSendTextPath: '/api/TextComm/SendText',
    getCommTypePath:'/api/CommType',
    SendEmailCommPath:"/api/EmailComm/SendEmailComm",
    getAddMemberUserByUserIdAsyncPath:'/api/User/AddMemberUserByUserIdAsync',
    getProfessionalType:'/api/ProfessionalType',
    postprofessional:"/api/ProfessionalUser/add-ProfessionalUser/",
    addProfessionalUserMapping:"/api/ProfessionalUser/add-ProfessionalUserMapping",
    updatememberbyid:'/api/Member/update-member-by-id/',
    addmember:"/api/Member/add-member",
    deleteprofess:"api/ProfessionalUser/delete-user-professionalUser",

    userGenerateLinks:'api/UserLinks/generate',
    addmemberaddress:'/api/MemberAddress',
    putMemberAddress: "/api/MemberAddress",
    getUserDetailsByEmailId:'/api/Account/GetUserDetailByEmailId',
    getProfessionalSecDesc:'/api/ProfessionalSecDesc',
    upsertProfessionalUserMapping:'api/ProfessionalUser/upsert-ProfessionalUserMapping',
    postUploadUserDocument:"/api/FileUpload/uploadUserDocuments",
    postUploadFileCabinet:'/api/UserFileCabinet/Add',
    getFileUploadDoc:'api/FileUpload/documents/',
    deletefamilymember:'/api/Member/delete-member/',
    addfeedback:"/api/Feedback/add-feedback",
    postmemberrelationshipapi:"api/MemberRelationship/",
    getmemberrelationship:"api/MemberRelationship/",
    updateMemberChildByUserId:'/api/Member/update-memberChild-by-userId/',
    sendMailFeedBack:'/api/EmailComm/SendEmailToMultipleNAttachmentAsync',
    sendremarktoparalegal:'/api/EmailComm/SendEmailToMultipleAsync',
    getUserListByRoleIdpath:'/api/User/getUserListByRoleId'
    

    
    
    
}

export default AoUrl;