import React, { useState, useEffect, useContext } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ImmediateActions from "./immediateActions";
import {
  Steps,
  Row,
  Col,
  Space,
  Table,
  Input,
  Button,
  Radio,
  Select,
  Checkbox,
  Form,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import Services from "../../network/Services";
import Toaster from "../Toaster";
import { illness } from "../../control/Constant";
import konsole from "../../network/konsole";
import commonLib from "../../control/commonLib";
import { UserContext } from "../../App";
import Addnewprofessmodal from "../Component/MyProfessionalModal/Addnewprofessmodal";
import UploadFile from "./UploadFile";
import AgentPreview from "./AgentPreview"
const { Option } = Select;

export default function Illness(props) {
  const { Step } = Steps;
  // let memberUserId = JSON.parse(
  //   sessionStorage.getItem("userPrimaryInDetail")
  // ).memberUserId;
  let memberUserId = props.memberid
  let spouseUserId = JSON.parse(
    sessionStorage.getItem("userPrimaryInDetail")
  ).spouseUserId;

  let memberName = JSON.parse(
    sessionStorage.getItem("userPrimaryInDetail")
  ).loginUserName;
  const CheckboxGroup = Checkbox.Group;
  const plainOptions = ["Emai", "Text"];
  const { TextArea } = Input;
  const [formlabelData, setFormLabelData] = useState({});
  const [switchComponent, setswitchComponent] = useState(false);
  const [secoundComponent, setsecoundComponent] = useState(false);
  const [secoundExtraComponent, setsecoundExtraComponent] = useState(false);
  const [selectedStep, setStepper] = useState(1);
  const [thirdComponent, setthirdComponent] = useState(false);
  const [thirdExtraComponent, setthirdExtraComponent] = useState(false);
  const [forthComponent, setforthComponent] = useState(false);
  const [fifthComponent, setfifthComponent] = useState(false);
  const [professionalUser, setProfessionalUser] = useState([]);
  const [professionalUserELA, setProfessionalUserELA] = useState([]);
  const [organizationCare, setOranizationCare] = useState([]);
  const [otherspecialins,setotherspecialins] = useState([])
  const [agentCareManager, setAgentCareManager] = useState([]);
  const [careManagerInMind, setCareManagerInMind] = useState([]);
  const [specialInstructions, setSpecialInstructions] = useState([]);
  const [compnayWorkWith, setCompanyWorkWith] = useState([]);
  const [answerObj, setAnswerObj] = useState();
  const [specialInsAns, setSpecialInsAns] = useState();
  const [specialInsstep2, setSpecialInsstep2] = useState();
  const [getselectedGCM, setGetselectedGCM] = useState();
  const [selectElderLawAttorney, setSelectElderLawAttorney] = useState();
  const { setLoader } = useContext(UserContext);
  const [SpecialInstructionillness, setSpecialInstructionillness] =
    useState("");
  const [showaddprofessmodal, setshowaddprofessmodal] = useState(false);
  const [professionaltype, setprofessionaltype] = useState();
  const [Addnewprofessmodaldata,setAddnewprofessmodaldata] =useState(null)
  const[selectgcm,setSelectedgcm] = useState("")

  var selectKey;
  var selectKeyELA;

  konsole.log("log", formlabelData);

  useEffect(() => {
    setStepper(1)
    setotherspecialins([]);
    setCompanyWorkWith([]);
    setSpecialInstructions([]);
    setCareManagerInMind([]);
    setAgentCareManager([]);
    setOranizationCare([]);
    getsubjectForFormLabelId(memberUserId);
    setSpecialInsAns('')
  }, [props.memberid]);

  useEffect(() => {
    getProfessionalByUser();
  }, [showaddprofessmodal,props.memberid]);

  useEffect(()=>{
    putProfessionaluserpramas()
  },[Addnewprofessmodaldata])
  const selectSelectedStepper = (e) => {
    setStepper(e);
  };

  const getsubjectForFormLabelId = (userId) => {
    setLoader(true);
    let formlabelData = {};
    illness.formLabelId.map((id, index) => {
      let data = [id.id];
      const promises = Services.getSubjectQuestions(data);
      promises.then((response) => {
        if (response) {
          konsole.log(response, "responseresponse");
          if (id.id == response.data.data[0].formLabelId) {
            let label = "label" + response.data.data[0].formLabelId;
            formlabelData[label] = response.data.data[0].question;
            const promises = Services.getSubjectResponse(
              userId,
              formlabelData[label].questionId
            );
            promises.then((response) => {
              if (response) {
                setLoader(false);
                konsole.log("getSubjectResponse", response);
                if (response.data.data.userSubjects.length !== 0) {
                  let responseData = response.data.data.userSubjects[0];
                  for (
                    let i = 0;
                    i < formlabelData[label].response.length;
                    i++
                  ) {
                    // if (formlabelData[label]?.questionId == 130) {
                    //   setSpecialInstructions(responseData?.response)
                    // } else {
                    // }
                    if (
                      formlabelData[label].response[i].responseId ===
                      responseData.responseId
                    ) {
                      if (responseData.responseNature == "Radio") {
                        const userSubjectDataId =
                          responseData.userSubjectDataId;
                        const subjectId = formlabelData[label].questionId;
                        const responseId = responseData.responseId;

                        const obj = commonLib.getSubjectData(
                          subjectId,
                          responseId,
                          memberUserId,
                          userSubjectDataId
                        );
                        konsole.log("obj", obj);
                        formlabelData[label]["userSubjectDataId"] =
                          responseData.userSubjectDataId;

                        if (
                          responseData.responseId == 199 ||
                          responseData.responseId == 200
                        ) {
                          setOranizationCare(obj);
                        }
                        if (
                          responseData.responseId == 201 ||
                          responseData.responseId == 202
                        ) {
                          setAgentCareManager(obj);
                        }
                        if (
                          responseData.responseId == 203 ||
                          responseData.responseId == 204
                        ) {
                          setCareManagerInMind(obj);
                        }
                        if (
                          responseData.responseId == 205 ||
                          responseData.responseId == 206
                        ) {
                          setSpecialInstructions(obj);
                        }
                        if (
                          responseData.responseId == 207 ||
                          responseData.responseId == 208
                        ) {
                          setCompanyWorkWith(obj);
                        }
                        if (
                          responseData.responseId == 389 ||
                          responseData.responseId == 390
                        ) {
                          setotherspecialins(obj);
                        }
                      } else if (responseData.responseNature == "Text") {
                        if (responseData.responseId == 258) {
                          setSpecialInsstep2(responseData.response);
                         
                        }
                        if (responseData.responseId == 256) {
                          setSpecialInstructionillness(responseData.response);
                        }

                        formlabelData[label].response[i]["response"] =
                          responseData.response;
                        formlabelData[label]["userSubjectDataId"] =
                          responseData.userSubjectDataId;
                      }
                    }
                  }
                }
              }
            });
            setFormLabelData(formlabelData);
          }
        }
      });
    });
  };

  const onChangeSelect = (value) => {
    konsole.log("selected value", value,professionalUser[value]);
    // setSelectkey(value);

     setGetselectedGCM(professionalUser[value])
    selectKey = value;
    return selectKey
  };

  const onChangeSelectElderLowAttorney = (value) => {
    konsole.log("selected value", value);
    // setSelectkey(value);
    setSelectElderLawAttorney(professionalUserELA[value])
    selectKeyELA = value;
    return selectKeyELA;
  };

  const CheckValue = (e) => {
    konsole.log("metadata CheckValue", e.target.value);
    let responseObj = {
      userId: memberUserId,
      userSubjectDataId: 0,
      subjectId: 0,
      responseId: 0,
    };
    if (e.target.value == 199) {
      const formObj = formlabelData.label894;
      konsole.log("metadata res", formObj, responseObj);
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      konsole.log("metadata", answer);
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("metadata responseObj", responseObj);
      setOranizationCare(responseObj);
    } else if (e.target.value == 200) {
      const formObj = formlabelData.label894;
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("eventvalue", responseObj);
      konsole.log("metadata responseObj", responseObj);
      setOranizationCare(responseObj);
    }
  };

  const CheckValuespecialinst = (e) => {
    konsole.log("metadata CheckValue", e.target.value);
    let responseObj = {
      userId: memberUserId,
      userSubjectDataId: 0,
      subjectId: 0,
      responseId: 0,
    };
    if (e.target.value == 389) {
      const formObj = formlabelData.label995;
      konsole.log("metadata res", formObj, responseObj);
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      konsole.log("metadata", answer);
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("metadata responseObj", responseObj);
      setotherspecialins(responseObj);
    } else if (e.target.value == 390) {
      const formObj = formlabelData.label995;
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("eventvalue", responseObj);
      konsole.log("metadata responseObj", responseObj);
      setotherspecialins(responseObj);
    }
  };

  const getProfessionalByUser = () => {
    let getProfessionalpramas = Services.getProfessionalByUserId(memberUserId);
    getProfessionalpramas
      .then((res) => {
        let filterdata = res.data.data.professionalUser.filter((items) => {
          return items.proSerDescId == 7;
        });
        konsole.log(res, "tryuiop");
        let selectedGCM = res.data.data.professionalUser.filter((items) => {
          return items.proSerDescId == 7 && items.lpoStatus == true;
        });

        let filterdataELA = res.data.data.professionalUser.filter((items) => {
          return items.proSerDescId == 13;
        });
        let selectELA = res.data.data.professionalUser.filter((items) => {
          return items.proSerDescId == 13 && items.lpoStatus == true;
        });
        setGetselectedGCM(selectedGCM[0]);
        setProfessionalUserELA(filterdataELA);
        setSelectElderLawAttorney(selectELA[0]);
        setProfessionalUser(filterdata);
      })
      .catch((err) => {
        konsole.log("err", err);
      });
  };

  const agentCareManagerValue = (e) => {
    let responseObj = {
      userId: memberUserId,
      userSubjectDataId: 0,
      subjectId: 0,
      responseId: 0,
    };
    if (e.target.value == 201) {
      const formObj = formlabelData.label895;
      konsole.log("formObj", formObj);
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("eventvalue2", responseObj);
      konsole.log("metadata responseObj", responseObj);
      konsole.log("metadata formObj", formObj);
      setAgentCareManager(responseObj);
    } else if (e.target.value == 202) {
      const formObj = formlabelData.label895;
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("eventvalue2", responseObj);
      konsole.log("metadata responseObj", responseObj);
      setAgentCareManager(responseObj);
    }
  };
  const careManagerInMindValue = (e) => {
    let responseObj = {
      userId: memberUserId,
      userSubjectDataId: 0,
      subjectId: 0,
      responseId: 0,
    };
    if (e.target.value == 203) {
      const formObj = formlabelData.label896;
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("eventvalue", e.target);
      setCareManagerInMind(responseObj);
      getProfessionalByUser();
    } else if (e.target.value == 204) {
      const formObj = formlabelData.label896;
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("eventvalue", e.target);
      setCareManagerInMind(responseObj);
    }
  };
  const specialInstructionsValue = (e) => {
    let responseObj = {
      userId: memberUserId,
      userSubjectDataId: 0,
      subjectId: 0,
      responseId: 0,
    };
    if (e.target.value == 205) {
      const formObj = formlabelData.label897;
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("eventvalue", e.target);
      setSpecialInstructions(responseObj);
    } else if (e.target.value == 206) {
      const formObj = formlabelData.label897;
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("eventvalue", e.target);
      setSpecialInstructions(responseObj);
    }
  };
  const compnayWorkWithValue = (e) => {
    let responseObj = {
      userId: memberUserId,
      userSubjectDataId: 0,
      subjectId: 0,
      responseId: 0,
    };
    if (e.target.value == 207) {
      const formObj = formlabelData.label898;
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("eventvalue", e.target);
      setCompanyWorkWith(responseObj);
    } else if (e.target.value == 208) {
      const formObj = formlabelData.label898;
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("eventvalue", e.target);
      setCompanyWorkWith(responseObj);
    }
  };

  const postSubjectData = () => {
    let inputArray = [];
    let specialInsst2 = {
      userSubjectDataId: formlabelData.label927?.userSubjectDataId
        ? formlabelData.label927?.userSubjectDataId
        : 0,
      subjectId: formlabelData.label927?.questionId,
      subResponseData: specialInsstep2,
      responseId: formlabelData.label927?.response[0].responseId,
    };

    if (organizationCare.responseId == 200) {
      inputArray.push(organizationCare);
    }
    if (
      organizationCare.responseId == 199 &&
      agentCareManager.responseId == 201 &&
      careManagerInMind.responseId == 203
    ) {
      inputArray.push(organizationCare, agentCareManager, careManagerInMind);
      onClickSave();
    }
    if (
      organizationCare.responseId == 199 &&
      agentCareManager.responseId == 202 &&
      specialInstructions.responseId == 205 &&
      formlabelData.label927.response[0].responseId == 258
    ) {
      inputArray.push(
        organizationCare,
        agentCareManager,
        specialInstructions,
        specialInsst2
      );
      konsole.log("KONSOLE", inputArray);
    }

    if (
      organizationCare.responseId == 199 &&
      agentCareManager.responseId == 202 &&
      specialInstructions.responseId == 206
    ) {
      inputArray.push(organizationCare, agentCareManager, specialInstructions);
    }

    if (
      organizationCare?.responseId == 199 &&
      agentCareManager?.responseId == 201 &&
      careManagerInMind?.responseId == 204 &&
      (compnayWorkWith?.responseId == 207 || compnayWorkWith?.responseId == 208)
    ) {
      inputArray.push(
        organizationCare,
        agentCareManager,
        careManagerInMind,
        compnayWorkWith
      );
    }
    if (!inputArray.length > 0) {
      Toaster.warning("Please select all the questions correctly");
    }else{

    

    inputArray = {
      userId: memberUserId,
      userSubjects: inputArray,
    };
    let promises = Services.upsertSubjectdata("PUT", inputArray);
    promises

      .then((res) => {
        Toaster.success("Successfully Saved");
        setAgentCareManager([]);
        setCareManagerInMind([]);
        setCompanyWorkWith([]);
        konsole.log("successful", res);
        selectSelectedStepper(2);
      })
      .catch((error) => konsole.log("error", error.response));
    }
    getsubjectForFormLabelId(memberUserId);
  };

  const postSubjectDatastep3 = () => {
    konsole.log(otherspecialins,"otherspecialins746464646")
    if(otherspecialins.length == 0){
      Toaster.warning("Please make selection and save")
    }
    let inputArray = {
      userId: memberUserId,
      userSubjects: [
        {
          userSubjectDataId: formlabelData.label925?.userSubjectDataId
            ? formlabelData.label925?.userSubjectDataId
            : 0,
          subjectId: formlabelData.label925?.questionId,
          subResponseData: specialInsAns,
          responseId: formlabelData.label925?.response[0].responseId,
        },
        {
          userSubjectDataId: formlabelData.label995?.userSubjectDataId
            ? formlabelData.label995?.userSubjectDataId
            : 0,
          subjectId: formlabelData.label995?.questionId,
          userId: memberUserId,
          responseId: otherspecialins.responseId,
        }
      ],
    };

    let promises = Services.upsertSubjectdata("PUT", inputArray);
    promises
      .then((res) => {
        Toaster.success("Successfully Saved");
        konsole.log("successful", res);
        selectSelectedStepper(4);
      })
      .catch((error) => konsole.log("error", error.response));
    getsubjectForFormLabelId(memberUserId);

    // selectedStep == 3;
  };

  const putProfessionaluserpramas = () => {
    // konsole.log("id", professionalUser[selectKey]);
    let stateUserId = JSON.parse(sessionStorage.getItem("stateObj")).userId;

    const dataObj = {
      userId:  props.memberid,
      updatedBy: stateUserId,
      professionalUser: {
        proTypeId: getselectedGCM?.proTypeId,
        proUserId: getselectedGCM?.proUserId,
        userProId: getselectedGCM?.userProId,
        lpoStatus: true,
      },
    };
    let dataobjELA = {
      userId:  props.memberid,
      updatedBy: stateUserId,
      professionalUser: {
        proTypeId: selectElderLawAttorney?.proTypeId,
        proUserId: selectElderLawAttorney?.proUserId,
        userProId: selectElderLawAttorney?.userProId,
        lpoStatus: true,
      },
    };
    if(Addnewprofessmodaldata != null){
      var dataaddprofessdata = {
        userId:  props.memberid,
        updatedBy: stateUserId,
        professionalUser: {
          proTypeId: Addnewprofessmodaldata?.proTypeId,
          proUserId: Addnewprofessmodaldata?.proUserId,
          userProId: Addnewprofessmodaldata?.userProId,
          lpoStatus: true,
        },
      };
    }
    konsole.log("rieuiuiuo2",Addnewprofessmodaldata,selectKey)
    let putProfessionaluserpramas = Services.putProfessionaluser(Addnewprofessmodaldata != null ? dataaddprofessdata :dataObj);
    putProfessionaluserpramas
      .then((res) => {
        konsole.log("professional", res.data.data);
        getProfessionalByUser()
        // Toaster.success("Successfully Saved");
      })
      .catch((err) => {
        konsole.log("err", err);
        // Toaster.warning("Invalid Data");
      });

    let putProfessionaluserpramasELA = Services.putProfessionaluser(Addnewprofessmodaldata != null ? dataaddprofessdata :dataobjELA);
    putProfessionaluserpramasELA
      .then((res) => {
        konsole.log("professional", res.data.data);
        getProfessionalByUser()
        // Toaster.success("Successfully Saved");
      })
      .catch((err) => {
        konsole.log("err", err);
        // Toaster.warning("Invalid Data");
      });
  };

  const onClickSave = () => {
    if (
      organizationCare?.responseId == 199 &&
      agentCareManager?.responseId == 201 &&
      careManagerInMind?.responseId == 203
    ) {
      putProfessionaluserpramas();
    }
  };

  const addprofessfuncclick = (type) => {
    konsole.log(type, "typetypetypetypetype");

    if (type == "GCM") {
      setprofessionaltype(7);
    } else if (type == "ElderLawAttorney") {
      setprofessionaltype(13);
    }
    setshowaddprofessmodal(true);
  };

  konsole.log("metadata organize", organizationCare);
  konsole.log("metadata agentCareManager", agentCareManager);
  konsole.log("metadata careManagerInMind", careManagerInMind);
  konsole.log("metadata specialInstructions", specialInstructions);
  konsole.log("metadata compnayWorkWith", compnayWorkWith);
  const backButton=()=>{
    selectSelectedStepper(2)
  }
  
  return (
    <div>
      {showaddprofessmodal && (
        <Addnewprofessmodal
          showaddprofessmodal={showaddprofessmodal}
          setshowaddprofessmodal={setshowaddprofessmodal}
          professionaltype={professionaltype}
          memberUserId={props.memberid}
          setAddnewprofessmodaldata={setAddnewprofessmodaldata}
        />
      )}
      <div
        className="mt-3 ms-3"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div style={{ width: 810 }}>
          <Steps
            labelPlacement="vertical"
            current={selectedStep - 1}
            size="small"
          >
            <Step
              title="Immediate Actions"
              onClick={(e) => selectSelectedStepper(1)}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Notify"
              onClick={(e) => selectSelectedStepper(2)}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Special Instructions"
              onClick={() => selectSelectedStepper(3)}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Agents Guidance"
              onClick={() => selectSelectedStepper(4)}
              style={{ cursor: "pointer" }}
            />
          </Steps>
        </div>
      </div>
      <div className="Question-Card-Div">
        {selectedStep == 1 ? (
          <>
            <Row>
              <Col xl={24}>
                {formlabelData.label894 && (
                  <div className="mt-2">
                    <h6>{formlabelData.label894.question}</h6>
                    <Form>
                      {
                        <Radio.Group
                          name="organizationCare"
                          value={organizationCare?.responseId}
                          onChange={CheckValue}
                        >
                          {formlabelData.label894.response.map(
                            (answer, index) => {
                              return (
                                <Radio
                                  value={answer.responseId}
                                  className="ms-1"
                                >
                                  {answer.response}
                                </Radio>
                              );
                            }
                          )}
                        </Radio.Group>
                      }
                      {organizationCare?.responseId == 199 &&
                        formlabelData.label895 && (
                          <div className="mt-2">
                            <h6>{formlabelData.label895.question}</h6>
                            <Radio.Group
                              name="agentCareManager"
                              value={agentCareManager?.responseId}
                              type="radio"
                              onChange={agentCareManagerValue}
                            >
                              {formlabelData.label895.response.map(
                                (answer, index) => {
                                  return (
                                    <Radio
                                      value={answer.responseId}
                                      className="ms-1"
                                    >
                                      {answer.response}
                                    </Radio>
                                  );
                                }
                              )}
                            </Radio.Group>
                          </div>
                        )}
                      {organizationCare?.responseId == 199 &&
                      agentCareManager?.responseId == 201
                        ? formlabelData.label896 && (
                            <div className="mt-2">
                              <h6>{formlabelData.label896.question}</h6>
                              <Radio.Group
                                name="careManagerInMind"
                                id={formlabelData.label896.questionId}
                                value={careManagerInMind?.responseId}
                                onChange={careManagerInMindValue}
                              >
                                {formlabelData.label896.response.map(
                                  (answer, index) => {
                                    return (
                                      <Radio
                                        value={answer.responseId}
                                        className="ms-1"
                                      >
                                        {answer.response}
                                      </Radio>
                                    );
                                  }
                                )}
                              </Radio.Group>
                            </div>
                          )
                        : organizationCare?.responseId == 199 &&
                          agentCareManager?.responseId == 202
                        ? formlabelData.label897 && (
                            <div className="mt-2">
                              <h6>{formlabelData.label897.question}</h6>
                              <Radio.Group
                                name="specialInstructions"
                                id={formlabelData.label897.questionId}
                                onChange={specialInstructionsValue}
                                value={specialInstructions?.responseId}
                              >
                                {formlabelData.label897.response.map(
                                  (answer, index) => {
                                    return (
                                      <Radio
                                        value={answer.responseId}
                                        className="ms-1"
                                      >
                                        {answer.response}
                                      </Radio>
                                    );
                                  }
                                )}
                              </Radio.Group>
                            </div>
                          )
                        : ""}
                      {organizationCare?.responseId == 199 &&
                      agentCareManager?.responseId == 201 &&
                      careManagerInMind?.responseId == 203 ? (
                        <>
                          <div className="mt-2">
                            <h6>Care Manager</h6>
                            <div className="d-flex">
                              <div className="">
                                <Select
                                  placeholder={"Select a person"}
                                  optionFilterProp="children"
                                  style={{
                                    width: 250,
                                    height: "40px",
                                    border: "black",
                                  }}
                                  value={
                                    getselectedGCM == null
                                     ? "Select a person" 
                                      : getselectedGCM.fName +
                                        " " +
                                      ((getselectedGCM?.mName == null || getselectedGCM?.mName == undefined || getselectedGCM?.mName == "" ) ? "" :  getselectedGCM.mName )  +
                                        " " +
                                        getselectedGCM.lName
                                  }
                                  onChange={onChangeSelect}
                                >
                                  {professionalUser.map((data, key) => (
                                    <Option value={key}>
                                      {data.fName} {data.mName} {data.lName}
                                    </Option>
                                  ))}
                                </Select>
                                <button
                                  className="addnewproffbtn"
                                  onClick={() => {
                                    addprofessfuncclick("GCM");
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="mt-2">
                            <h6>Elder Law Attorney</h6>
                            <div className="d-flex">
                              <div className="">
                                <Select
                                  placeholder={"Select a person"}
                                  optionFilterProp="children"
                                  style={{
                                    width: 250,
                                    height: "40px",
                                    border: "black",
                                  }}
                                  value={
                                    selectElderLawAttorney == null ? "Select a person" : selectElderLawAttorney.fName +
                                        " " +
                                        (selectElderLawAttorney.mName != null ?  selectElderLawAttorney.mName  : "") +
                                        " " +
                                        selectElderLawAttorney.lName
                                  }
                                  onChange={onChangeSelectElderLowAttorney}
                                >
                                  {professionalUserELA.map((data, key) => (
                                    <Option value={key}>
                                      {data.fName} {data.mName} {data.lName}
                                    </Option>
                                  ))}
                                </Select>
                                <button
                                  className="addnewproffbtn"
                                  onClick={() => {
                                    addprofessfuncclick("ElderLawAttorney");
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : organizationCare?.responseId == 199 &&
                        agentCareManager?.responseId == 201 &&
                        careManagerInMind?.responseId == 204 ? (
                        formlabelData?.label898 && (
                          <div className="mt-2">
                            <h6>{formlabelData.label898.question}</h6>
                            <Radio.Group
                              name="compnayWorkWith"
                              id={formlabelData.label898.questionId}
                              onChange={compnayWorkWithValue}
                              value={compnayWorkWith?.responseId}
                            >
                              {formlabelData.label898.response.map(
                                (answer, index) => {
                                  return (
                                    <Radio
                                      value={answer?.responseId}
                                      className="ms-1"
                                    >
                                      {answer?.response}
                                    </Radio>
                                  );
                                }
                              )}
                            </Radio.Group>
                          </div>
                        )
                      ) : (
                        ""
                      )}

                      {organizationCare?.responseId == 199 &&
                      agentCareManager.responseId == 202 &&
                      specialInstructions?.responseId == 205 ? (
                        <div className="mt-2">
                          <h6>Special instructions</h6>
                          {selectedStep == 1}

                          {formlabelData.label927 && (
                            <TextArea
                              rows={4}
                              defaultValue={
                                formlabelData.label927?.response[0].response
                              }
                              value={specialInsstep2}
                              onChange={(e) => {
                                // setSpecialInsstep2(formlabelData.label927.response[0].response)
                                setSpecialInsstep2(e.target.value);
                              }}
                            />
                          )}
                        </div>
                      ) : forthComponent ? (
                        <div></div>
                      ) : (
                        ""
                      )}
                      {organizationCare?.responseId == 199 &&
                      agentCareManager?.responseId == 201 &&
                      careManagerInMind?.responseId == 204 &&
                      compnayWorkWith?.responseId == 207 ? (
                        <div className="mt-2">
                          <h6>Find an Expert</h6>
                          <a
                            href="https://www.agingoptions.com/"
                            target="_blank"
                          >
                            https://www.agingoptions.com
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </Form>
                  </div>
                )}
              </Col>
            </Row>
            <div className="Sava-Button-Div">
              <button className="Save-Button" onClick={postSubjectData}>
                Save & Proceed
              </button>
            </div>
          </>
        ) : selectedStep == 2 ? (
          <ImmediateActions
            id={1}
            memberUserId={props.memberid}
            selectSelectedStepper={selectSelectedStepper}
          />
        ) : selectedStep == 3 ? (
          <Row>
            <Col lg={24}>
               {/* <div className="d-flex justify-content-center"> */}
                {/* <h2>What About Me?</h2> */}
            
              <UploadFile  memberUserId={props?.memberid} />
              {/* </div> */}
              {/* <Row className="d-flex justify-content-center text-center">
                <Col lg={24}>
                  <h6>
                    What your family needs to know about your preferences when
                    they have to act
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col lg={24}>
                  <h6 className="text-center px-3">
                    This material is proprietary to Robert Pittman, Esq., who
                    has been kind enough to allow us to use it for this purpose.
                    Please do not take, use, disclose, alter, or copy this
                    information without the express permission of Robert C.
                    Pittman, CAP®, Pittman Law Office, www.bobpittman.com, 1019
                    Regents Blvd., Suite 201, Fircrest, WA 98466, 253-471-9779
                  </h6>
                </Col>
              </Row> */}
              {/* <Row className="d-flex justify-content-center">
                <Col lg={24}>
                  <h6 className="text-center px-3">
                    As you’ve figured out by now, estate planning is more than
                    just Wills, Trusts, and Powers of Attorney. Giving your
                    loved ones and caregivers insight into who you really are
                    will enhance their ability to take care of you should you
                    face a time when you are more dependent on others. Consider
                    filling in the following answers to these discerning
                    questions as a way to offer your caregivers insight into
                    important aspects of your life. If you do end up facing a
                    scenario in which you become incapacitated or are unable to
                    speak for yourself, your answers will be helpful to your
                    family and loved ones. Most caregivers want to do a good job
                    of taking care of you. One of the ways they can be
                    successful is knowing and understanding your likes and
                    dislikes. What would you say to introduce yourself to a
                    caregiver? Tell your story by taking some time to carefully
                    note down your thoughts about the several areas listed here.
                  </h6>
                </Col>
              </Row>  */}
              <Row>
                <Col>
                  {formlabelData.label995 && (
                    <div className="mt-2 ms-4">
                      <h6>{formlabelData.label995.question}</h6>
                      {
                        <Radio.Group
                          name="organizationCare"
                          value={otherspecialins?.responseId}
                          onChange={CheckValuespecialinst}
                        >
                          {formlabelData.label995.response.map(
                            (answer, index) => {
                              return (
                                <Radio
                                  value={answer.responseId}
                                  className="ms-1"
                                >
                                  {answer.response}
                                </Radio>
                              );
                            }
                          )}
                        </Radio.Group>
                      }
                    </div>
                  )}
                </Col>
              </Row>
              { otherspecialins?.responseId == 389 && <Row className="d-flex justify-content-center pt-2 mt-2">
                <Col lg={23} className="textEditor-Col">
                  {/* <h6>Enter Special Instructions as to what you wish to be done for you</h6> */}

                  {formlabelData.label925 && (
                    <>
                      <div className="mt-3">
                        <h6>{formlabelData.label925?.question}</h6>

                        <TextArea
                          rows={4}
                          placeholder="Please type something here"
                          defaultValue={
                            formlabelData.label925?.response[0].response
                          }
                          value={specialInsAns}
                          onChange={(e) => {
                            setSpecialInsAns(e.target.value);
                          }}
                        />
                      </div>

                      {/* <CKEditor
                        editor={ClassicEditor}
                        data={formlabelData.label925?.response[0].response}
                        onReady={(editor) => {
                          konsole.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          konsole.log({ event, editor, data });
                          setSpecialInsAns(data);
                        }}
                        onBlur={(event, editor) => {
                          konsole.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          konsole.log("Focus.", editor);
                        }} */}
                      {/* /> */}
                    </>
                  )}
                </Col>
              </Row>}
              <div className="Sava-Button-Div d-flex justify-content-between">
              <button className="Save-Button" 
              onClick={backButton}
              >
          Back
                
              </button>
                <button className="Save-Button" onClick={postSubjectDatastep3}>
                  Save & Proceed
                </button>
              </div>
            </Col>
          </Row>
        ) : (
          <>
            {/* <Row className=""> */}
              {/* <Col lg={23} className="Agent-Preview-Col"> */}
              <AgentPreview memberUserId={props?.memberid} selectSelectedStepper={selectSelectedStepper}/>
              {/* </Col> */}
            {/* </Row> */}
            {/* <div className="Sava-Button-Div">
              <button className="Save-Button" onClick={postSubjectData}>
                Save{" "}
              </button>
            </div> */}
          </>
        )}

        {/* <div className="Sava-Button-Div">
          <button className="Save-Button" onClick={postSubjectData}>
            Save{" "}
          </button>
        </div> */}
      </div>
    </div>
  );
}
