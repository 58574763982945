import React, { useEffect, useState, createRef, useContext } from "react";
import { Button, Modal, Table, Form, Tab, Row, Col } from "react-bootstrap";
import konsole from "../network/konsole"
import Services from "../network/Services"
import commonLib from "../control/commonLib";
import Toaster from "./Toaster";
import { UserContext } from "../App";

const Feedback = (props) => {
  const userId = commonLib.getObjFromStorage("userPrimaryInDetail").memberUserId || "";
  const loggedInUser = commonLib.getObjFromStorage("userLoggedInDetail").loggedUserId || "";
  const [show, setshow] = useState(false);
  const [feedbackQuery, setfeedbackQuery] = useState("");
  const [imageURL, setimageURL] = useState("");
  const { setLoader } = useContext(UserContext);


  const handleClose = () => {
    setshow(!show);
  };
  const handleShow = () => {
    setshow(!show);
  };
  const handleChange = (e) => {
    let value = e.target.value;
    setfeedbackQuery(value);
  };

  const handleImageSubmit = async () => {
    const fileTypeId = 3; //for feedback
    const fileCategoryId = 7; //for feedback
    const fileStatusId = 2; //for feedback
    const imageSrc = props.image;
    const blob = await fetch(imageSrc).then((res) => res.blob());

    if (feedbackQuery == "") {
      Toaster.error("feedback query cannot be Blank")
      return;
    }
    let formdata = new FormData()
    formdata.append('file', blob)
    formdata.append('UserId', userId)
    formdata.append('UploadedBy', loggedInUser)
    formdata.append('FileTypeId', fileTypeId)
    formdata.append('FileCategoryId', fileCategoryId);
    formdata.append('FileStatusId', fileStatusId)

    setLoader(true);
    let postUploadUserDocumentparams =  Services.postUploadUserDocument(formdata)
    postUploadUserDocumentparams.then((response)=>{
      konsole.log(response,"response")
      setLoader(false);
          const imageUrl = response.data.data.fileURL;
          const fileId = response.data.data.fileId;
          handleFeedbackSubmit(imageUrl, fileId,blob);
    }).catch((error)=>{
      konsole.log(error,"error")
      setLoader(false);

    })
  };

  const handleFeedbackSubmit = (imageUrl, fileId,blob) => {
    let totalinuptdata = {
      userId: userId,
      feedbackQuery: feedbackQuery,
      feedbackType: "string",
      applicationURL: window.location.href,
      imageURL: imageUrl,
      fileId: fileId,
      createdBy: loggedInUser,
    };

    konsole.log("PostData at realProperty", totalinuptdata);
    setLoader(true);
    let postfeedbackservicepamas=Services.postfeedbackservice(totalinuptdata)
    postfeedbackservicepamas.then((response)=>{
      setLoader(false);
      konsole.log(response,"responsefeedback")
      let subtenantId = sessionStorage.getItem('subtenantId')
      if (subtenantId == 2 || subtenantId == 742) {
        sendFeedBackEmail(blob, subtenantId)
      } else {
        Toaster.success("Feedback submitted successfully")   
       handleClose()
      }
    }).catch((error)=>{
      konsole.log(error,"errorfeedback")
      setLoader(false);
      handleClose()
    })
  };

  const sendFeedBackEmail=(blob,subtenantId)=>{
    const file = new File([blob], 'FEEDBACK.jpg', { type: "image/png" });
    let clientName = JSON.parse(sessionStorage.getItem('userPrimaryInDetail'))
    let emailto = subtenantId == 742 ? 'TechSupport@agingoptions.com' : 'Nitinkumar@agingoptions.com';

    
    let emailContent = ` <p style="font-size: 16px; color: #333; background-color: #f5f5f5; padding: 10px; margin: 0; box-sizing: border-box;">
    <span style="display: block; padding-bottom: 10px;">   <b> Client : </b> @@CLIENT_NAME <br></span>
    <span style="display: block; padding-bottom: 10px;">   <b> Email : </b> @@CLIENT_EMAIL <br></span>
    <span style="display: block; padding-bottom: 10px;">   <b>Application url : </b>@@APPLICATION_URL<br></span>
    <span><b>Query: </b> @@FEEDBACK_QUERY</span> </p>`

    emailContent = emailContent.replace('@@CLIENT_NAME', clientName?.memberName);
    emailContent = emailContent.replace('@@CLIENT_EMAIL', clientName?.primaryEmailId);
    emailContent = emailContent.replace('@@FEEDBACK_QUERY', feedbackQuery);
    emailContent = emailContent.replace('@@APPLICATION_URL', window.location.href);

    let formdata = new FormData();
    formdata.append('File', file)
    formdata.append('EmailType', 'Client_Feedback_Details')
    formdata.append('EmailTo', emailto)
    formdata.append('EmailSubject', 'Feedback Details')
    formdata.append('EmailContent', emailContent)
    formdata.append('EmailMappingTable', 'tblMember')
    formdata.append('EmailStatusId', '1')
    formdata.append('Emailcc',clientName?.primaryEmailId)
    formdata.append('EmailMappingTablePKId', loggedInUser)
    formdata.append('CreatedBy', loggedInUser)
    setLoader(true)
    let postUploadUserDocumentparams =  Services.sendMailFeedBack(formdata)
    postUploadUserDocumentparams.then((res)=>{
      setLoader(false)
        konsole.log('sendMailFeedBackres',res)
        Toaster.success("Feedback submitted successfully")   
        handleClose()
      }).catch((error)=>{
        setLoader(false)
      konsole.log('sendMailFeedBackres',error)
      Toaster.success("Feedback submitted successfully")   
      handleClose()
    })
  }


  return (
    <>
      <style jsx global>{`
        .modal-open .modal-backdrop.show {
          opacity: 0.5 !important;
        }
      `}</style>
      <a onClick={handleShow} className="d-grid gap-3">
        <button
          style={{ backgroundColor: "#751521", color: "white", border: "none", fontSize: "1em", borderRadius: "5px", zIndex: "999999"}}
          className={`btn ${props.classNameLocal}`}onClick={props.getImage}>Feedback</button>
      </a>

      <Modal show={show} size="lg" centered onHide={handleClose} animation="false" id="futureExpectationId" backdrop="static">
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-5 pt-4">
          <Row>
            <Col className="ms-2">
              <h3>Describe</h3>
            </Col>
          </Row>
          <Form.Control className="mt-4 ms-2" as="textarea" rows={3} onChange={(e) => handleChange(e)}/>
          <div>
            <img width="100%" src={props.image} alt={"Screenshot"} />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button className="theme-btn" onClick={handleImageSubmit}> Submit</Button>
          <Button className="theme-btn" onClick={handleClose}> Cancel</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Feedback;
