import React, { useEffect, useState, useContext,useRef } from "react";
import {
  Typography,
  Row,
  Col,
  Input,
  Checkbox,
  Select,
  Form,
  DatePicker,
  Button,
} from "antd";
import Services from "../../../network/Services";
import konsole from "../../../network/konsole";
import commonLib from "../../../control/commonLib";
import { UserContext } from "../../../App";
import AddressInfo from "../../MemberInfo/common/AddressInfo";
import MemberContact from "../../MemberInfo/common/MemberContact";
import Toaster from "../../Toaster";
import { Link } from "react-router-dom";
import Other from "../../Other";
const { Text } = Typography;
const { Option } = Select;

const AddnewProfessmember = ({ editmember, proffdata, protype,typeofmodal }) => {
  konsole.log("editmembereditmembereditmember",editmember,proffdata,protype,typeofmodal)
  const otherRef=useRef('')
  const loggedUser = commonLib.getObjFromStorage("userLoggedInDetail")?.loggedUserId;
  const primaryUser = commonLib.getObjFromStorage( "userPrimaryInDetail");
  const [form] = Form.useForm();
  const [professtype, setProfesstype] = useState([]);
  const [formdata, setFormdata] = useState();
  const [userid, setuserid] = useState();


  const [otherprofessTypeId,setOtherprofessTypeId]=useState('')
  const[docUserId,setdocUserId]=useState('')
  const[natureId,setNatureId]=useState('')
  const[sameprofess,setSameprofess]=useState(false)
  konsole.log("natureIdnatureId",docUserId,natureId,otherprofessTypeId)
  konsole.log("otherServiceProvider",otherprofessTypeId)

  const { setLoader } = useContext(UserContext);

  useEffect(()=>{
  
konsole.log("proffdataproffdataproffdata",proffdata,editmember)
if(typeofmodal=='EDIT'){
  setdocUserId(editmember?.professionalUserId)
  setNatureId(editmember?.proUserId)
  setOtherprofessTypeId(editmember?.proTypeId)
  // konsole.log("typeofmodaltypeofmodal",typeofmodal)
}else{
  setdocUserId(editmember?.userId)
}
  
  },[proffdata])

  useEffect(() => {
    getProfessionalTypeapifunc();
    let data = editmember;
    if (editmember != undefined && editmember.proTypeId) {
      form.setFieldsValue({
        fName: data.fName,
        mName: data.mName,
        lName: data.lName,
        serviceprovider: data.proTypeId,
      });
      setuserid(editmember.professionalUserId);
    } else {
      form.setFieldsValue({
        fName: "",
        mName: "",
        lName: "",
        serviceprovider: undefined,
      });
      setuserid(editmember.userId);
    }
  }, [editmember, proffdata, protype]);

  const getProfessionalTypeapifunc = () => {
    setLoader(true);
    let getProfessionalTypeapiparams = Services.getProfessionalTypeapi();
    getProfessionalTypeapiparams
      .then((response) => {
        konsole.log(response, "response87");
        if (editmember.proTypeId) {
          setProfesstype(response.data.data);
        }else {
          if (protype == "Health") {
            let professfilter = response.data.data.filter((e) => {
              return e.value == 7 || e.value == 11 || e.value == 10;
            });
            setProfesstype(professfilter);
          } else if (protype == "Finance") {
            let professfilter = response.data.data.filter((e) => {
              return e.value == 1 || e.value == 2 || e.value == 3 || e.value == 8 || e.value == 12 || e.value == 15
              
            });
            setProfesstype(professfilter);
          } else if (protype == "Housing") {
            let professfilter = response.data.data.filter((e) => {
              return e.value == 4  || e.value == 14;
            });
            setProfesstype(professfilter);
          } else if (protype == "Legal") {
            let professfilter = response.data.data.filter((e) => {
              return e.value == 6 || e.value == 13;
            });
            setProfesstype(professfilter);
          } else if (protype == "Other") {
            let professfilter = response.data.data.filter((e) => {
              return e.value == 999999 || e.value == 9;
            });
            setProfesstype(professfilter);
          }
        }
        setLoader(false);
      })
      .catch((error) => {
        konsole.log(error);
        setLoader(false);
      });
  };

  const handleFocusOut = () => {
    let attrvalue = form.getFieldsValue().mName;
    if (attrvalue.length !== 0) {
      if (attrvalue.length === 1) {
        attrvalue = attrvalue + ".";
      }
    }
    form.setFieldsValue({
      mName: attrvalue,
    });
  };

  const onFinish = (data) => {
   
    konsole.log(data, "formdata");
    if (editmember.proTypeId) {
      if(otherprofessTypeId=='999999'){
        otherRef.current.saveHandleOther(editmember.proUserId)
      }
      
      putprofessusername(editmember.professionalUserId);
      
    } else {
      let dataobj = {
        userId: editmember.userId,
        createdBy: loggedUser,
        professionalUser: {
          proSerDescId: data.serviceprovider,
          isGenAuth: true,
          isStatus: true,
        },
      };
      let dataobj2 = {
        userId: editmember.userId,
        createdBy: primaryUser.spouseUserId,
        professionalUser: {
          proSerDescId: data.serviceprovider,
          isGenAuth: true,
          isStatus: true,
        },
      };
      if(sameprofess == true){
        postaddProfessionalUserMapping(dataobj);
        postaddProfessionalUserMapping(dataobj2);
      }else{
        postaddProfessionalUserMapping(dataobj);
      }
    }
  };

  // const addmemberfunc = (data) => {
  //   let dataobj = {
  //     fName: data.fName,
  //     mName: data.mName,
  //     lName: data.lName,
  //     isPrimaryMember: false,
  //     memberRelationship: null,
  //     createdBy: loggedUser,
  //   };

  //   konsole.log(dataobj,"dataobj")
  //   let addmemberprams = Services.postaddmember(dataobj);
  //   setLoader(true)
  //   addmemberprams
  //     .then((response) => {
  //       konsole.log(response, "responseadd");
  //       let dataobj = {
  //           userId: response.data.data.member.userId,
  //           createdBy: loggedUser,
  //           professionalUser: {
  //             proSerDescId: data.serviceprovider,
  //             isGenAuth: true,
  //             isStatus: true,
  //           },
  //         };
  //         postaddProfessionalUserMapping(dataobj);
  //         setuserid(response.data.data.member.userId)
  //         setLoader(false)
  //     })
  //     .then((error) => {
  //       konsole.log(error, "error");
  //       setLoader(false)
  //     });
  // };

  const postaddProfessionalUserMapping = (dataobj) => {
   
    setLoader(true);
    let postaddProfessionalUserMappingparams =
      Services.postaddProfessionalUserMapping(dataobj);
    postaddProfessionalUserMappingparams
      .then((response) => {
        konsole.log(response, "response");
        let dataobj = {
          createdBy: loggedUser,
          professionalUser: {
            proTypeId: response.data.data.proSerDescId,
            proUserId: response.data.data.proUserId,
          },
          userId: response.data.data.createdBy,
        };
        if(otherprofessTypeId==='999999'){
          otherRef.current.saveHandleOther(response.data.data.proUserId)
        }
        postprofessionalapifunc(dataobj);
        setLoader(false);
      })
      .then((error) => {
        konsole.log(error, "error");
        setLoader(false);
      });
  };
  const postprofessionalapifunc = (dataobj) => {
    setLoader(true);
    let postprofessionalapiparams = Services.postprofessionalapi(dataobj);
    postprofessionalapiparams
      .then((resposnse) => {
        konsole.log(resposnse, "resposnse1");
       
        putprofessusername( resposnse.data.data.professionalUser[0].professionalUserId);
        setLoader(false);
      })
      .then((error) => {
        konsole.log(error);
        setLoader(false);
      });
  };

  const putprofessusername = (professionalUserId) => {
    setLoader(true);
   
    let dataobj = {
      fName: form.getFieldValue().fName,
      mName: form.getFieldValue().mName,
      lName: form.getFieldValue().lName,
      birthPlace: null,
      citizenshipId: null,
      dob: null,
      genderId: null,
      isPrimaryMember: false,
      isVeteran: false,
      maritalStatusId: null,
      memberRelationshipVM: null,
      nickName: null,
      noOfChildren: null,
      suffixId: null,
      updatedBy: loggedUser,
      userId: professionalUserId,
    };
    let putupdatememberbyidparams = Services.putupdatememberbyid(dataobj);
    putupdatememberbyidparams
      .then((response) => {
        konsole.log(response, "response2");
        Toaster.success("Data saved successfully");
       
            window.location.replace("/myprofessoinal");
       
      
        setLoader(false);
      })
      .then((error) => {
        konsole.log(error, "error");
        setLoader(false);
        // Toaster.error("error")
      });
  };
  konsole.log(protype, "protype");

  const handleChangeServiceProvider=(e)=>{
    setOtherprofessTypeId(e.target.value)
    konsole.log("handleChangeServiceProvider",e.target.value)
  }
  return (
    <>
      <div className="d-flex ms-1">
        &nbsp;{" "}
        <h6>
          <Link to="/Dashboard" className="text-primery">
            Dashboard
          </Link>
          {">"}
          {/* <Link to="/myprofessoinal" className="pt-3"> */}
          My Professional
          {/* </Link> */}
          {">"} {protype}{" "}
        </h6>
      </div>
      <Text
        strong={true}
        className="ms-2"
        type="danger"
        style={{ color: "#76272B" }}
      >
        Professional Information
      </Text>
      <Form form={form} onFinish={onFinish} scrollToFirstError={{
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        }}>
        <Row className="mt-2">
          <Col lg={24} xs={24}>
            <div className="Info ms-2">
              <div className="main">
                <Row gutter={[8, 8]}>
                  <Col span={8} xs={24} lg={8}>
                    <Form.Item
                      className="w-100"
                      name="serviceprovider"
                      rules={[
                        {
                          required: true,
                          message: "Please select your service provider",
                        },
                      ]}
                    >
                      <select
                        className="form-select form-select-sm"
                        style={{  marginTop: 10,  marginright: 15,}}
                        disabled={  editmember != undefined && editmember.proTypeId ? true  : false}
                        onChange={(e)=>handleChangeServiceProvider(e)}
                 //   defaultValue={187}
                      >
                        <option value={"-1"} disabled selected>
                          Select Service Provider
                        </option>

                        {professtype.map((props) => (
                          <option
                            key={props.value}
                            value={props.value}
                            title={props.label}
                          >
                            {props.label}
                          </option>
                        ))}
                      </select>
                    </Form.Item>
                  </Col>
               
                {
                    otherprofessTypeId == "999999"
                  &&
                  <Col span={8} xs={24} lg={8}>
                    <Form.Item name="serviceOther"  className="mt-2">
                      <Other
                            othersCategoryId={26}
                            dropValue={otherprofessTypeId}
                            // ref={this.warTimeRef}
                            ref={otherRef}
                            show="true"
                            natureId={natureId}
                            userId={docUserId}
                          />
                    </Form.Item>
                  </Col> 
                } 
                </Row>
                <Row gutter={[8, 8]} className="mt-2">
                  <Col span={8} xs={24} lg={8}>
                    <Form.Item
                      name="fName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter first name",
                        },
                        {
                          pattern: "^[a-zA-Z 0-9]*$",
                          message: "Please enter valid first name",
                        },
                      ]}
                    >
                      <Input
                        size=""
                        name="fName"
                        // onChange={(e) => setFname(e.target.value)}
                        placeholder={commonLib.mandatory("First Name")}
                        // placeholder={fName}
                        className="Input"
                        onInput={(e) =>
                          (e.target.value =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1))
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8} xs={24} lg={8}>
                    <Form.Item name="mName">
                      <Input
                        className="Input"
                        size=""
                        name="Middle Name"
                        onBlur={handleFocusOut}
                        placeholder="Middlename"
                        onInput={(e) =>
                          (e.target.value =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1))
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} xs={24} lg={8}>
                    <Form.Item
                      name="lName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter last name",
                        },
                        {
                          pattern: "^[a-zA-Z 0-9]*$",
                          message: "Please enter valid Last Name",
                        },
                      ]}
                    >
                      <Input
                        className="Input"
                        size=""
                        name="Lastname"
                        placeholder={commonLib.mandatory("Last Name")}
                        onInput={(e) =>
                          (e.target.value =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1))
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <AddressInfo userId={userid ? userid : editmember?.professionalUserId} form={form} />
        <MemberContact userId={userid ? userid : editmember?.professionalUserId} form={form} />
       {primaryUser.spouseUserId != null && <Row className="p-2">
          <Checkbox onClick={(e)=>{setSameprofess(e.target.checked)}}>Same professional for spouse</Checkbox>
        </Row>}
        <Row className="p-2 d-flex justify-content-between align-item-center ">
          <Button
            htmlType="submit"style={{color: "#ffffff",backgroundColor: "#720C20", borderRadius: 5, border:"none"}}>Save</Button>

          <Button style={{color: "#720C20", borderColor: "#720C20",borderRadius: 5,border:"1px solid rgb(114, 12, 32)"}} onClick={() => {window.location.replace("/myprofessoinal");}}>
            Cancel
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default AddnewProfessmember;
