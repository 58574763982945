import { Button, Checkbox, Form, Row, Typography } from "antd";
import React, { useEffect, useRef, useState, useContext } from "react";
import AddressInfo from "../common/AddressInfo";
import MemberInfoMaritalStatus from "../common/MemberInfoMaritalStatus";
import PersonalInformation from "../common/PersonalInformation";
import MemberInfoOccupation from "../common/MemberInfoOccupation";
import commonLib from "../../../control/commonLib";
import konsole from "../../../network/konsole";
import Toaster from "../../Toaster";
import Services from "../../../network/Services";
import moment from "moment";
import MemberContact from "./MemberContact";

import { UserContext } from "../../../App";
import AddnewmemberAddress from "./AddnewmemberAddress";
import AddMemberContact from "./AddMemberContact";
import { Link } from "react-router-dom";
import RelationshipInfo from "./RelationshipInfo";

const { Text } = Typography;

export default function EditMemberDetails(props) {

konsole.log('propsprops',props)
  const { disable, modalOnDisable, getSavebtn, setGetSaveBtn } = useContext(UserContext);
  const [form] = Form.useForm();
  const userId = props.userId;
  const parentUserId = props.parentUserId;
  const editProfieType = props.editProfieType;
  const occupationRef = useRef(null);
  const loggedInUserId = commonLib.getObjFromStorage("stateObj").userId;
  let primaryUserId = commonLib.getObjFromStorage("userPrimaryInDetail").memberUserId;
  let spouseUserId = commonLib.getObjFromStorage("userPrimaryInDetail").spouseUserId;
  const [relationshipVM, setRelationshipVM] = useState({});
  const [noofchildConfirm, setnoofchildConfirm] = useState(true);
  const [formData, setFormData] = useState();
  const [adduserId, setadduserId] = useState(userId);
  const [putmemberRelationship, setputmemberRelationship] = useState({});
  const { setLoader } = useContext(UserContext);
  const [clickedby, setClickedby] = useState("");
  const defaultopen = useRef(null);
  const [userAge, setuserAge] = useState(null)
  const [genderCheck, setGenderCheck] = useState("")
  konsole.log("props at memberForm", props);
  // useEffect(() => {

  // }, [props.userId]);
  useEffect(() => {
    if (getSavebtn == true && adduserId != undefined) {
      putMemberById(formData);
    }

    if (clickedby == "" && adduserId == undefined && getSavebtn == true) {
      postMemberById(formData);
    }
  }, [getSavebtn, formData]);

  const onFinish = (values) => {
    let data = values;

    konsole.log(data, "dataofuser");
    let datecheck = commonLib?.calculate_age( moment(form.getFieldsValue().dob).format("MM/DD/YY"));
    konsole.log( "datecheckdatecheck",  datecheck,  data.dob?._d,  data.isFiduciary    );
    konsole.log("datadatadata2",datecheck,commonLib.calculate_age(moment(data.dob?._d).format("MM/DD/YY")));
    if (data.isFiduciary !== undefined && data.isFiduciary == true && data.dob == "") {
      Toaster.specialerror("Fiduciary cannot be Minor. Please enter correct date of birth");
      return;
    }
    if(data.maritalStatusId==1 && data.dob == ""){
      Toaster.specialerror("Member can not be minor.")
      return;
    }

    if (data.isBeneficiary == undefined || data.isBeneficiary == false) {
      data.isBeneficiary = false;
    }

    if (data.isFiduciary == undefined || data.isFiduciary == false) {
      data.isFiduciary = false;
    }

    let memberRelationshipVM = {
      primaryUserId: primaryUserId,
      relationshipTypeId:editProfieType == "In Law" ? 1 : editProfieType == "Children" ? 2 : editProfieType == "Grand Children" ? 3 : "",
      relativeUserId: parentUserId,
      isEmergencyContact: false,
      IsFiduciary:data.isFiduciary == undefined || data.isFiduciary == false ? false : true,
      isBeneficiary: data.isBeneficiary == undefined || data.isBeneficiary == false ? false : true,
      rltnTypeWithSpouseId: data.relationshipwithspouse,
    };

    konsole.log( "memberRelationshipVM", putmemberRelationship, memberRelationshipVM);

    let putmemberRelationshipdata = {
      isBeneficiary: data.isBeneficiary != false ? data.isBeneficiary : false,
      isEmergencyContact: false,
      isFiduciary: data.isFiduciary != false ? data.isFiduciary : false,
      primaryUserId: putmemberRelationship.primaryUserId,
      relationshipType: putmemberRelationship.relationshipTyperelationshipType,
      relationshipTypeId: putmemberRelationship.relationshipTypeId,
      relativeUserId: putmemberRelationship.relativeUserId,
      rltnTypeWithSpouseId: putmemberRelationship.rltnTypeWithSpouseId,
      userMemberId: putmemberRelationship.userMemberId,
      userRltnshipId: putmemberRelationship.userRltnshipId,
    };

    const inputData = {
      fName: data.fName,
      mName: data.mName,
      lName: data.lName,
      dob: data.dob,
      nickName: data.nickName,
      genderId: data.genderId,
      maritalStatusId: data.maritalStatusId,
      suffixId: data.suffixId == "null" ? null : data.suffixId,
      birthPlace: data.birthPlace,
      citizenshipId: data.citizenshipId,
      noOfChildren: data.noOfChildren,
      isVeteran: data.isVeteran,
      isPrimaryMember: false,
      createdBy: loggedInUserId,
      memberRelationship: memberRelationshipVM,
    };
    const putinputData = {
      userId: adduserId,
      fName: data.fName,
      mName: data.mName,
      lName: data.lName,
      dob: data.dob,
      nickName: data.nickName,
      genderId: data.genderId,
      maritalStatusId: data.maritalStatusId,
      suffixId: data.suffixId == "null" ? null : data.suffixId,
      birthPlace: data.birthPlace,
      citizenshipId: data.citizenshipId,
      noOfChildren: data.noOfChildren,
      isVeteran: data.isVeteran,
      isPrimaryMember: false,
      updatedBy: loggedInUserId,
      memberRelationship: putmemberRelationshipdata,
    };

    konsole.log(adduserId, clickedby, inputData, "adduserId");
    if (adduserId == undefined && clickedby != "") {
      postMemberById(inputData);
      konsole.log("postMemberByIdioio");
    }

    if (clickedby == "" && adduserId == undefined) {
      setFormData(inputData);
      modalOnDisable();
    }

    if (adduserId != undefined && noofchildConfirm == true) {
      modalOnDisable();
      setFormData(putinputData);
      konsole.log("putMemberByIdioio");
    }

    konsole.log("uueiuie", noofchildConfirm, inputData, userId);
  };

  const postMemberById = (inputData) => {
    konsole.log("formDatann", inputData);
    setLoader(true);
    const promises = Services.postAddMemberById(inputData);
    promises
      .then(async(res) => {
        konsole.log("res personal post", res);

        if(props?.editProfieType=='Children' || props?.editProfieType=='Grand Children' && props.editProfieActionType=='ADD'){
          let IsChildUserId = props?.editProfieType == "Children" ? false : true
          let result=await Services.updateMemberChildByUserId(res?.data?.data?.member?.memberRelationship?.relativeUserId,IsChildUserId)
          konsole.log('resultresult',result)
        }
        
        // props.redirectToFamily();
        setadduserId(res.data.data.member.userId);
        // setGetSaveBtn(false);
        setputmemberRelationship(res.data.data.member.memberRelationship);
        setLoader(false);
        Toaster.success("Saved Succesfully");
        if (clickedby == "") {
          props.redirectToFamily();
        }
      })
      .catch((err) => {
        konsole.log("errorFmaily", err);
        setLoader(false);
      })
      .finally(() => {
        konsole.log("finish");
        setGetSaveBtn(false);
      });
  };

  const putMemberById = (inputData) => {
    konsole.log("formDatannput", inputData);
    setLoader(true);
    const promises = Services.putMemberById(inputData);
    promises
      .then((res) => {
        konsole.log("res personal update", res);
        setGetSaveBtn(false);
        setLoader(false);
        props.redirectToFamily();
      })
      .catch((err) => {
        konsole.log("error", err);
        setLoader(false);
      })
      .finally(() => {
        setGetSaveBtn(false);
        konsole.log("finish");
      });
  };
  // const onMetachangefun=(value)=>{
  //   console.log("form",form)
  //   let checkname=value?.target?.name;
  //   let checkeid=value?.target?.checked

  //   console.log("valuevalue",checkeid,checkname,form.getFieldValue())
  // }
  const userAges =(data)=>{
    console.log("famoush",data)
    if(userAge === null || data !== userAge ){
     setuserAge(data)
    }
  //  setuserAge(data)
  }

  const redirectToFamilyPage = () => {
    window.location.replace("/Familyinfo");
  };
  // konsole.log(props, "clickedby");
  return (
    <div>
      <spnn className="ms-2">Add {props.editProfieType}</spnn>
      <Form name="memberForm" form={form} onFinish={onFinish} scrollToFirstError={{   behavior: "smooth",   block: "center",   inline: "center", }}>
        <PersonalInformation  userId={userId}  form={form}  editProfieType={props.editProfieType}  userAges ={userAges}  setGenderCheck={setGenderCheck}/>
        <RelationshipInfo editProfieType={props.editProfieType} userId={userId} form={form} genderCheck={genderCheck} />
        {props.editProfieType !== "Spouse" && (<><MemberInfoMaritalStatus form={form} setnoofchildConfirm={setnoofchildConfirm} /></> )}
        <AddnewmemberAddress userId={adduserId} form={form} onFinish={onFinish} setClickedby={setClickedby} clickedby={clickedby} editProfieType={props.editProfieType} userAge ={userAge}/>
        <AddMemberContact userId={adduserId} form={form} onFinish={onFinish} setClickedby={setClickedby} clickedby={clickedby} />
        {userId !== primaryUserId && (
          <div className="Info ms-2">
            <Text strong={true} type="danger" style={{ color: "#76272B" }}>
             Information
            </Text>
            <Form.Item name="isFiduciary" valuePropName="checked" className="p-0 m-0"
              // onChange={onMetachangefun}
            >
              <Checkbox>Fiduciary</Checkbox>
            </Form.Item>
            <Form.Item name="isBeneficiary" valuePropName="checked"
              //  onChange={onMetachangefun}
            >
              <Checkbox>Beneficiary</Checkbox>
            </Form.Item>
          </div>
        )}

        <Row className=" w-100 p-2 justify-content-between">
          <Form.Item>
            <Button  type="primary"  htmlType="submit"  className="ms-2"  style={{ borderRadius: 5 }} > Save</Button> 
          </Form.Item>
          <Form.Item>
            <Button className="fw-bold mb-2" style={{   color: "#720C20",   borderColor: "#720C20",   borderRadius: 5, }} onClick={redirectToFamilyPage}>Cancel</Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
