import { Button, Checkbox, Form, Row, Typography } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import AddressInfo from "../common/AddressInfo";
import MemberInfoMaritalStatus from "../common/MemberInfoMaritalStatus";
import PersonalInformation from "../common/PersonalInformation";
import MemberInfoOccupation from "../common/MemberInfoOccupation";
import commonLib from "../../../control/commonLib";
import konsole from "../../../network/konsole";
import Toaster from "../../Toaster";
import Services from "../../../network/Services";
import moment from "moment";
import { PresetColorTypes } from "antd/lib/_util/colors";
import { UserContext } from "../../../App";
import MemberContact from "./MemberContact";
import { Link } from "react-router-dom";
import RelationshipInfo from "./RelationshipInfo";

const { Text } = Typography;

export default function EditMemberDetails(props) {
  const { modalOnDisable, getSavebtn, setGetSaveBtn } = useContext(UserContext);
  const [form] = Form.useForm();
  const userId = props.userId;
  const occupationRef = useRef(null);
  const loggedInUserId = commonLib.getObjFromStorage("stateObj").userId;
  let primaryUserId = commonLib.getObjFromStorage("userPrimaryInDetail").memberUserId;
  let spouseUserId = commonLib.getObjFromStorage("userPrimaryInDetail").spouseUserId;
  let primarymember = commonLib.getObjFromStorage("userPrimaryInDetail")
  const [genderCheck, setGenderCheck] = useState("")
  const [relationshipVM, setRelationshipVM] = useState({});
  const [editMemberData, setEditMemberDAta] = useState();
  const [noofchildConfirm, setnoofchildConfirm] = useState(true);
  const [userAge, setuserAge] = useState(null)
  const { setLoader } = useContext(UserContext);
  konsole.log("props at memberForm", props);
  useEffect(() => {
    getMemberInfoByUserId(userId);
  }, [props.userId]);

  useEffect(() => {
    if (getSavebtn == true) {
      runEditMemberAPi();
    }
  }, [getSavebtn]);

  const getMemberInfoByUserId = (userId) => {
    setLoader(true);
    let getMemberPersonalpramas = Services.getMemberPersonaldata(userId);
    getMemberPersonalpramas
      .then((res) => {
        konsole.log("response56", res.data.data.member);
        setGenderCheck(res.data.data.member?.genderId)
        let relationshipVM = res.data.data.member.memberRelationship;
        let dob = moment.utc(res.data.data.member.dob);
        form.setFieldsValue(res.data.data.member);
        form.setFieldsValue({
          relationshipwithprimary: relationshipVM.relationshipTypeId,
          relationshipwithspouse: relationshipVM.rltnTypeWithSpouseId,
        });
        if (res.data.data.member.dob != null) {
          form.setFieldsValue({
            dob: dob,
            isFiduciary: relationshipVM.isFiduciary,
            isBeneficiary: relationshipVM.isBeneficiary,
          });
        } else {
          form.setFieldsValue({
            isFiduciary: relationshipVM.isFiduciary,
            isBeneficiary: relationshipVM.isBeneficiary,
          });
        }
        // res.data.data.member.dob != null
        //   ? form.setFieldsValue({
        //       dob: dob,
        //       isFiduciary: relationshipVM.isFiduciary,
        //       isBeneficiary: relationshipVM.isBeneficiary,
        //     })
        //   : form.setFieldsValue({
        //       isFiduciary: relationshipVM.isFiduciary,
        //       isBeneficiary: relationshipVM.isBeneficiary,
        //     }),
        setRelationshipVM(res.data.data.member.memberRelationship);
        setuserAge(res.data.data.member.dob)
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const onFinish = (data) => {
    konsole.log(data, "dhyduyud");
    let datecheck = commonLib?.calculate_age(
      moment(data.dob).format("MM/DD/YY")
    );
    konsole.log(
      "datecheckdatecheck1",
      form.getFieldsValue().dob,
      datecheck,
      data.dob,
      data.isFiduciary
    );
    konsole.log(
      "datadatadata1",
      data.dob,
      datecheck,
      form.getFieldsValue().dob,
      commonLib.calculate_age(moment(data.dob?._d).format("MM/DD/YY"))
    );
    if (
      (data.isFiduciary !== undefined && data.isFiduciary == true && data.dob == "")
    ) {
      // alert("Please enter Date of birth and Fiduciary cannot be minor")
      Toaster.specialerror(
        "Please enter Date of birth and Fiduciary cannot be minor"
      );
      return;
    }
    if( data.maritalStatusId ==1&&data.dob == "" ){
      Toaster.specialerror(
        "Please enter Date of birth."
      );
      return;
    }

    if (noofchildConfirm == true) {
      modalOnDisable();
    }

    if (userId === primaryUserId || userId === spouseUserId) {
      let value = occupationRef.current.handleOccupationSubmit();
      if (value !== undefined) {
        if (value == "occuption") {
          Toaster.warning("Occupation cannot be empty");
          return;
        }
        if (value == "ageOfRetirement") {
          Toaster.warning("Age of Retirement cannot be empty");
          return;
        }
        if (value == "professBackground") {
          Toaster.warning("Professional Background cannot be empty");
          return;
        }
        // if (value == "isDisabled") {
        //   Toaster.warning("please select Disabled choice.");
        //   return;
        // }
        if (value == "occuption") {
          Toaster.warning("Occupation cannot be empty");
          return;
        }
      }
    }

    konsole.log("data member", data);
    let isPrimaryMember = false;
    let memberRelationshipVM = {};

    if (userId === primaryUserId) {
      isPrimaryMember = true;
      memberRelationshipVM = null;
    } else {
      memberRelationshipVM = {
        primaryUserId: relationshipVM.primaryUserId,
        relationshipTypeId: data.relationshipwithprimary == null ?  relationshipVM.relationshipTypeId : data.relationshipwithprimary,
        isFiduciary: data.isFiduciary,
        isBeneficiary: data.isBeneficiary,
        userRltnshipId: relationshipVM.userRltnshipId,
        userMemberId: relationshipVM.userMemberId,
        relationshipType: relationshipVM.relationshipType,
        relativeUserId: relationshipVM.relativeUserId,
        rltnTypeWithSpouseId: data.relationshipwithspouse,
        isEmergencyContact: false,
      };
    }

    const inputData = {
      userId: userId,
      fName: data.fName,
      mName: data.mName,
      lName: data.lName,
      dob: data.dob,
      nickName: data.nickName,
      genderId: data.genderId,
      maritalStatusId: data.maritalStatusId,
      suffixId: data.suffixId == "null" ? null : data.suffixId,
      birthPlace: data.birthPlace,
      citizenshipId: data.citizenshipId,
      noOfChildren: data.noOfChildren,
      isVeteran: data.isVeteran,
      isPrimaryMember: isPrimaryMember,
      updatedBy: loggedInUserId,
      memberRelationship: memberRelationshipVM,
    };

    setEditMemberDAta(inputData);

    konsole.log("logData", noofchildConfirm, inputData);
  };

  const runEditMemberAPi = () => {
    if (props.editProfieActionType === "EDIT") {
      putMemberById(editMemberData);
    }

    // konsole.log("editMemberData",editMemberData)
  };
  const putMemberById = (inputData) => {
    const promises = Services.putMemberById(inputData);
    promises
      .then((res) => {
        konsole.log("res personal update", res);
        if(props.editProfieType == "Spouse"){
          let userPrimaryInDetails= JSON.parse(sessionStorage.getItem("userPrimaryInDetail"))
          konsole.log(userPrimaryInDetails,"userPrimaryInDetails")
          userPrimaryInDetails.spouseName = res.data.data.member.fName +" "+ (res.data.data.member.mName != null ? res.data.data.member.mName : "") +" "+ res.data.data.member.lName
          sessionStorage.setItem("userPrimaryInDetail",JSON.stringify(userPrimaryInDetails))
        }
        Toaster.success("Saved Succesfully");
        setGetSaveBtn(false);
        props.redirectToFamily();
      })
      .catch((err) => {
        konsole.log("error", err);
      })
      .finally(() => {
        setGetSaveBtn(false);
        konsole.log("finish");
      });
  };

  // const postMemberById = (inputData) => {
  //     const promises = Services.postAddMember(inputData);
  //     promises.then(res => {
  //         konsole.log("res personal update", res);
  //         props.redirectToFamily();
  //     }).catch(err => {
  //         konsole.log("error", err);
  //     }).finally(() => {
  //         konsole.log("finish")
  //     })
  // }moment(form.getFieldValue().dob).subtract(18, "years").format('MM-DD-YYYY')
  // console.log(
  //   form.getFieldValue().dob?.format("MM-DD-YYYY"),
  //   commonLib.calculate_age(form.getFieldValue().dob?.format("MM-DD-YYYY")) >=
  //     18
  //     ? "true"
  //     : "false",

  //   "dateofbirth"
  // );

  const redirectToFamilyPage = () => {
    window.location.replace("/Familyinfo");
  };

  const userAges =(data)=>{
    console.log("famoush",data)
    if(userAge === null || data !== userAge ){
     setuserAge(data)
    }
  //  setuserAge(data)
  
  }

  konsole.log("abbbbbbbbbbbbbb", props?.editProfieType);
  return (
    <div className="">
      {/* <p><span className='cursor-pointer ms-3' onClick={()=>props.redirectToFamily()}>{'<-'}</span> {props.editProfieType}</p>  */}
      <Form
        name="memberForm"
        form={form}
        onFinish={onFinish}
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
      >
        <PersonalInformation
          editProfieType={props.editProfieType}
          userId={userId}
          form={form}
          userAges ={userAges}
          setGenderCheck={setGenderCheck}
        />
        <AddressInfo userId={userId} profiletype={props?.editProfieType} userAge ={userAge}/>
        <MemberContact userId={userId} />
        <RelationshipInfo
          editProfieType={props.editProfieType}
          userId={userId}
          form={form}
          genderCheck={genderCheck}
        />
        {props.editProfieType !== "Spouse" &&
          props.editProfieType !== "In Law" &&
          props.editProfieType !== "Grand Children" && (
            <>
              <MemberInfoMaritalStatus
                form={form}
                setnoofchildConfirm={setnoofchildConfirm}
              />
            </>
          )}
        {(userId === primaryUserId || userId === spouseUserId) && (
          <MemberInfoOccupation userId={userId} ref={occupationRef} />
        )}

        {userId !== primaryUserId && (
          <div className="Info ms-2">
            <Text strong={true} type="danger" style={{ color: "#76272B" }}>
              Information
            </Text>
            <Form.Item
              name="isFiduciary"
              valuePropName="checked"
              className="p-0 m-0"
            >
              <Checkbox>{userId === spouseUserId ?`Fiduciary for ${primarymember.memberName}` :"Fiduciary"}</Checkbox>
            </Form.Item>
            <Form.Item name="isBeneficiary" valuePropName="checked">
              <Checkbox>{userId == spouseUserId ? `Beneficiary of ${primarymember.memberName}` :"Beneficiary"}</Checkbox>
            </Form.Item>
          </div>
        )}

        <Row className=" w-100 p-2 justify-content-between">
          <Form.Item>
            <Button
              className="ms-2 mb-2"
              type="primary"
              htmlType="submit"
              style={{ borderRadius: 5 }}
            >
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            {/* <Button className="ms-2 mb-2"> */}

            <Button
              className="fw-bold mb-2"
              style={{
                color: "#720C20",
                borderColor: "#720C20",
                borderRadius: 5,
              }}
              onClick={redirectToFamilyPage}
            >
              Cancel
            </Button>

            {/* </Button> */}
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}
