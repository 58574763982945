import React, { useState } from "react";
import { Link } from "react-router-dom";
import MemberForm from "./MemberForm";
import FamilyInfo from "./Familyinfo";
import commonLib from "../../control/commonLib";
import Navbar from "../Navbar";

function MemberInfo() {
  const [changeBorder, setChangeBorder] = useState(1);
  const [familyinfo, setFamilyinfo] = useState(true);
  const userId = commonLib.getObjFromStorage(
    "userPrimaryInDetail"
  ).memberUserId;
  console.log("checkuserId", userId);
  const memberInfo = () => {
    // setChange(false)
    // setshowaddfiduciries(false)
    setChangeBorder(1);
    setFamilyinfo(true);
  };

  const familyInfo = (addFiduciries) => {
    // setshowaddfiduciries(true)
    // setficuciriespropstype(addFiduciries)
    setChangeBorder(2);
    setFamilyinfo(false);
  };

  return (
    <div>
      <h6 className="ms-2">
        <Link to="/Dashboard" className="text-primery">
          Dashboard
        </Link>
        {">"}Member Information
      </h6>

      <div className="row MemberInfo-btn">
        <div className="col-sm-6 d-flex">
          <div
            className={`${
              changeBorder === 1 ? "borderOnClick" : ""
            } schedule  bg-white fw-bold ms-2 text-center`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Member Information
          </div>

          {/* <button className={`${(changeBorder === 1) ? 'borderOnClick' : ''} schedule  bg-white fw-bold ms-2`} onClick={memberInfo} style={{cursor:""}} >Member Information</button> */}
          {/* <span  >

  <button className={`${(changeBorder === 2) ? 'borderOnClick' : ''} schedule ms-3 bg-white fw-bold familyInfoButton`} onClick={familyInfo} >Family Info</button></span>*/}
        </div>
        <br />
        {/* <br /> */}
        <div className="col-sm-6 "></div>
      </div>
      {familyinfo ? (
        <MemberForm userId={userId} familyInfo={familyInfo} />
      ) : (
        <FamilyInfo userId={userId} />
      )}
    </div>
  );
}

export default MemberInfo;
