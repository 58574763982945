import React, { useEffect, useState } from "react";
import {
  Steps,
  Row,
  Col,
  Space,
  Table,
  Input,
  Button,
  Radio,
  Select,
  Checkbox,
  Form,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link } from "react-router-dom";
import Services from "../../network/Services";
import konsole from "../../network/konsole";
import Toaster from "../Toaster";
import "./Immediate.css";
const { TextArea } = Input;
const { Option } = Select;
export default function ImmediateActions(props) {
  // let memberUserId = JSON.parse(
  //   sessionStorage.getItem("userPrimaryInDetail")
  // ).memberUserId;

  let memberUserId = props.memberUserId
  let loggedUserId = JSON.parse(
    sessionStorage.getItem("userLoggedInDetail")
  ).loggedUserId;

  let memberName = props.memberUserId == JSON.parse(
    sessionStorage.getItem("userPrimaryInDetail")
  ).memberUserId ? JSON.parse(
    sessionStorage.getItem("userPrimaryInDetail")
  ).memberName :JSON.parse(
    sessionStorage.getItem("userPrimaryInDetail")
  ).spouseName;

  let primaryMemberId =  JSON.parse(
    sessionStorage.getItem("userPrimaryInDetail")
  ).memberUserId 

  let primarymember = JSON.parse(sessionStorage.getItem("userPrimaryInDetail"))



  const [professionalUsers, setProfessionalUser] = useState([]);
  const [familyMember, setFamilyMember] = useState([]);
  const [selected, setSelected] = useState(1);
  const [selected2, setSelected2] = useState(1);
  // const [selected3, setSelected3] = useState([]);
  const [familyNoify, setFamilynotify] = useState(
    // `We writing to let you know that ${memberName} is dealing with an illness. We are praying for a quick recovery. We will keep you updated.`
  );
  const [familyNoifytext, setFamilynotifytext] = useState(
    `We writing to let you know that ${memberName} is dealing with an illness. We are praying for a quick recovery. We will keep you updated.`
  );
  const [professNoify, setProfessnotify] = useState(
    // `We writing to let you know that ${memberName} is dealing with an illness. We are praying for a quick recovery. We will keep you updated.`
  );
  const [professNoifytext, setProfessnotifytext] = useState(
    `We writing to let you know that ${memberName} is dealing with an illness. We are praying for a quick recovery. We will keep you updated.`
  );
  const [familyMemberId, setFamilyMemberId] = useState("");
  const [familyMemberName, setFamilyMemberName] = useState([]);
  const [professionalUserId, setprofessionalUserId] = useState("");
  const [professionalUserName, setProfessionalUserName] = useState([]);
  const [notifyMemId, setNotifyMemId] = useState("");
  const [notifyMemIdtext, setNotifyMemIdtext] = useState("");
  const [notifyMemId2, setNotifyMemId2] = useState("");
  const [notifyMemId2text, setNotifyMemId2text] = useState("");
  const [contactmapIdFamily, setcontactmapIdFamily] = useState([]);
  const [contactmapIdProfess, setcontactmapIdProfess] = useState([]);
  const [familyMemberGetapi, setFamilyMemberGetapi] = useState([]);
  const [professionalGetapi, setProfessionalGetapi] = useState([]);
  const [familycreated, setFamilycreated] = useState([]);
  const [value, setValue] = useState(1);
  const [value2, setValue2] = useState(1);
  const [curselected, setCurselected] = useState([]);
  const [famselected, setFamselected] = useState([]);
  const [emailfam, setEmailfam] = useState();
  const [textfam, setTextfam] = useState();
  const [emailpro, setEmailpro] = useState();
  const [textpro, setTextpro] = useState();

  const options = [
    {
      label: "Email",
      value: 1,
    },
    {
      label: "Text",
      value: 2,
    },
  ];

  const onChangeSelect = (value, userid) => {
    setFamilyMemberId(userid);
    setFamilyMemberName(value);
  };

  const onChangeProfess = (value, userid) => {
    setprofessionalUserId(userid);
    setProfessionalUserName(value);
  };


  useEffect(()=>{
    let membername = props.memberUserId == JSON.parse(
      sessionStorage.getItem("userPrimaryInDetail")
    ).memberUserId ? JSON.parse(
      sessionStorage.getItem("userPrimaryInDetail")
    ).memberName :JSON.parse(
      sessionStorage.getItem("userPrimaryInDetail")
    ).spouseName;
    konsole.log(props.memberUserId == JSON.parse(
      sessionStorage.getItem("userPrimaryInDetail")
    ).memberUserId,"rtyuiopotyuiop")
    konsole.log("membername",membername)
    setFamilynotify(
      `We are writing to let you know that ${membername} is dealing with an illness. We are praying for a quick recovery. We will keep you updated.`
      )
    setProfessnotify(
      `We are writing to let you know that ${membername} is dealing with an illness. We are praying for a quick recovery. We will keep you updated.`
      )
     setFamilyMemberName()
     setFamselected()
     setProfessionalUserName()
     setCurselected()
  },[props.memberUserId])


  useEffect(() => {
    professionalapi();
    familyMemberapi();
    // getNotifyMessage();
    familynotify1();
    familynotify2();
    professnotify1();
    professnotify2();
    setCurselected()
    setFamselected()
    setEmailpro()
    setTextpro()
    setEmailfam()
    setTextfam()
    setNotifyMemId2()
    setNotifyMemId()
  }, [props.memberUserId]);

  konsole.log(props.memberUserId,curselected,famselected,"moiiyegdgddjdod")

  const onChangefam = (e) => {
    konsole.log("radio checked", e[0]);
    setFamselected(e);
  };
  const onChange2 = (e) => {
    konsole.log("checked", e[0]);
    setCurselected(e);
  };

  const notifyCondition = (familyList) => {
    var contactNatureId = 1;
    let notifyConditionId = props.id;
    let notifyConditionpramas = Services.getNotifyContactapi(
      memberUserId,
      contactNatureId,
      notifyConditionId
    );
    notifyConditionpramas
      .then((res) => {
        var arr = [];
        var arr2 = [];
        var arr3 = [];
        var arr4 = [];

        for (let i = 0; i < res.data.data.length; i++) {
          // console.log(res.data.data[i], "familygetdata");
          arr3.push(res.data.data[i]);
          const userName = familyList.filter((list) => {
            return list.relativeUserId == res.data.data[i].contactUserId;
          });
          const userName2 = familyList.filter((list) => {
            return list.relativeUserId !== res.data.data[i].contactUserId;
          });
          if (userName.length > 0) {
            arr.push(userName[0].fName + " " + userName[0].lName);
          }
          arr4.push(res.data.data[i].contactUserId);
        }
        konsole.log(arr4, "arrarr");
        setcontactmapIdFamily(arr2);
        setFamilyMemberName(arr4);
        setFamilyMemberGetapi(arr3);
      })
      .catch((err) => {
        konsole.log(err, "ressss");
      });
  };

  const notifyCondition2 = (professionallist) => {
    var contactNatureId = 2;
    let notifyConditionId = props.id;
    let notifyConditionprama = Services.getNotifyContactapi(
      memberUserId,
      contactNatureId,
      notifyConditionId
    );
    // console.log(professionallist, "professionaldata");
    notifyConditionprama
      .then((res) => {
        var arr = [];
        var arr2 = [];
        var arr3 = [];
        var arr4 = [];
        for (let i = 0; i < res.data.data.length; i++) {
          arr3.push(res.data.data[i]);
          // console.log(res.data.data[i].contactMapId,"professnalcontact")
          const userName = professionallist.filter((list) => {
            return list.professionalUserId == res.data.data[i].contactUserId;
          });
          if (userName.length > 0) {
            arr.push(userName[0].fName + " " + userName[0].lName);
          }
          arr2.push(res.data.data[i].contactMapId);
          // console.log(res.data.data, "professionalGetapi");
          arr4.push(res.data.data[i].contactUserId);
        }
        setcontactmapIdProfess(arr2);
        setProfessionalUserName(arr4);
        setProfessionalGetapi(arr3);
      })
      .catch((err) => {
        konsole.log(err, "ressss");
      });
  };

  const familynotify1 = () => {
    var contactNatureId = 1;
    let notifyConditionId = props.id;
    let notifyTypeId = 1;
    let createdOn1;
    let createdOn3;
    let createdOn2 = [];
    setEmailfam();
    setTextfam();
    let getNotifyMessagepramas = Services.getNotifyMessageapi(
      memberUserId,
      notifyTypeId,
      contactNatureId,
      notifyConditionId
    );
    getNotifyMessagepramas
      .then((res) => {
        konsole.log("getNotifymsg11", res);

        // createdOn11(res.data.data[0]);

        setFamilycreated(res.data.data[0].createdOn);
        setEmailfam(res.data.data);

        // setFamselected([res.data.data[0].notifyTypeId])
        setNotifyMemId(res.data.data[0].notifyMemId);
      })
      .catch((err) => {
        konsole.log("getNotifymsg1", err);
      });
  };
  const familynotify2 = () => {
    var contactNatureId = 1;
    let notifyConditionId = props.id;
    let notifyTypeId = 1;
    let createdOn1;
    let createdOn3;
    let createdOn2 = [];

    let notifyTypeId2 = 2;
    let getNotifyMessagepramas2 = Services.getNotifyMessageapi(
      memberUserId,
      notifyTypeId2,
      contactNatureId,
      notifyConditionId
    );
    getNotifyMessagepramas2
      .then((res) => {
        konsole.log("getNotifymsg12", res);
        // setFamilynotify(res.data.data[0].notifyText);
        setTextfam(res.data.data);
        setNotifyMemIdtext(res.data.data[0].notifyMemId);
      })
      .catch((err) => {
        konsole.log("getNotifymsg11", err);
      });
  };

  useEffect(() => {
    setFamselected([]);
    if (emailfam && textfam) {
      function gfg_Run(date) {
        var Str =
          ("00" + (date.getMonth() + 1)).slice(-2) +
          "/" +
          ("00" + date.getDate()).slice(-2) +
          "/" +
          date.getFullYear() +
          " " +
          ("00" + date.getHours()).slice(-2) +
          ":" +
          ("00" + date.getMinutes()).slice(-2) +
          ":" +
          ("00" + date.getSeconds()).slice(-3);

        return Str;
      }

      var t1 = gfg_Run(
        new Date(
          emailfam[0]?.updatedOn == "0001-01-01T00:00:00"
            ? emailfam[0]?.createdOn
            : emailfam[0]?.updatedOn
        )
      );
      var t2 = gfg_Run(
        new Date(
          textfam[0]?.updatedOn == "0001-01-01T00:00:00"
            ? textfam[0]?.createdOn
            : textfam[0]?.updatedOn
        )
      );

      konsole.log(t1, t2, emailfam[0], textfam[0], "familytimenew");
      if (familyMemberName?.length > 0) {
        if (t1 > t2) {
          setFamselected([1]);
          setFamilynotify(emailfam[0]?.notifyText);
        }

        if (t2 > t1) {
          setFamselected([2]);
          setFamilynotify(textfam[0]?.notifyText);
        }
        if (t1 == t2) {
          setFamselected([1, 2]);
          setFamilynotify(textfam[0]?.notifyText);
        }
      } else {
        setFamselected([]);
        setFamilynotify(textfam[0]?.notifyText);
      }
    } else if (emailfam && !textfam) {
      setFamselected([1]);
      setFamilynotify(emailfam[0]?.notifyText);
    } else if (textfam && !emailfam) {
      setFamselected([2]);
      setFamilynotify(textfam[0]?.notifyText);
    }
  }, [emailfam, textfam,familyMemberName,props.memberUserId]);

  const professnotify1 = () => {
    var contactNatureId = 2;
    let notifyConditionId = props.id;

    let createdOn21;
    let createdOn22 = [];

    function createdon21(data) {
      var dataNew = [];
      dataNew.push(data);
      createdOn21 = dataNew;
      konsole.log("aaazzzsssxxxx2", memberUserId,dataNew);
    }
    let notifyTypeId = 1;
    let getNotifyMessageprama = Services.getNotifyMessageapi(
      memberUserId,
      notifyTypeId,
      contactNatureId,
      notifyConditionId
    );
    setEmailpro();
    setTextpro();
    getNotifyMessageprama
      .then((res) => {
        konsole.log("getNotifymsg21", res);
        createdon21(res.data.data[0]);
        setNotifyMemId2(res.data.data[0].notifyMemId);
        setEmailpro(res.data.data);
      })
      .catch((err) => {
        konsole.log("getNotifymsg21", err);
      });
  };
  const professnotify2 = () => {
    var contactNatureId = 2;
    let notifyConditionId = props.id;
    let notifyTypeId = 2;

    let getNotifyMessageprama2 = Services.getNotifyMessageapi(
      memberUserId,
      notifyTypeId,
      contactNatureId,
      notifyConditionId
    );

    getNotifyMessageprama2
      .then((res) => {
        konsole.log("getNotifymsg22", res);
        setNotifyMemId2text(res.data.data[0].notifyMemId);
        setTextpro(res.data.data);
      })
      .catch((err) => {
        konsole.log("getNotifymsg22", err);
      });
  };

  useEffect(() => {
    setCurselected([]);

    if (emailpro && textpro) {
      function gfg_Run(date) {
        var Str =
          ("00" + (date.getMonth() + 1)).slice(-2) +
          "/" +
          ("00" + date.getDate()).slice(-2) +
          "/" +
          date.getFullYear() +
          " " +
          ("00" + date.getHours()).slice(-2) +
          ":" +
          ("00" + date.getMinutes()).slice(-2) +
          ":" +
          ("00" + date.getSeconds()).slice(-3);

        return Str;
      }

      var t1 = gfg_Run(
        new Date(
          emailpro[0]?.updatedOn == "0001-01-01T00:00:00"
            ? emailpro[0]?.createdOn
            : emailpro[0]?.updatedOn
        )
      );
      var t2 = gfg_Run(
        new Date(
          textpro[0]?.updatedOn == "0001-01-01T00:00:00"
            ? textpro[0]?.createdOn
            : textpro[0]?.updatedOn
        )
      );

      // konsole.log(t1, t2, emailpro[0], textpro[0], professionalUserName,"professnew");
      if (professionalUserName?.length > 0) {
        if (t1 > t2) {
          setCurselected([1]);
          setProfessnotify(emailpro[0]?.notifyText);
        }

        if (t2 > t1) {
          setCurselected([2]);
          setProfessnotify(textpro[0]?.notifyText);
        }
        if (t1 == t2) {
          setCurselected([1, 2]);
          setProfessnotify(textpro[0]?.notifyText);
        }
      } else {
        setCurselected([]);
        setProfessnotify(textpro[0]?.notifyText);
      }
      konsole.log("professsss1");
    } else if (emailpro && !textpro) {
      setCurselected([1]);
      setProfessnotify(emailpro[0]?.notifyText);
      konsole.log("professsss2");
    } else if (textpro && !emailpro) {
      setCurselected([2]);
      setProfessnotify(textpro[0]?.notifyText);
      konsole.log("professsss3");
    }
  }, [emailpro, textpro, professionalUserName,props.memberUserId]);

  const professionalapi = () => {
    let getProfessionalpramas = Services.getProfessionalByUserId(primaryMemberId);
    getProfessionalpramas
      .then((res) => {
        setProfessionalUser(res.data.data.professionalUser);
        notifyCondition2(res.data.data.professionalUser);
      })
      .catch((err) => {
        konsole.log(err);
      });
  };

  const familyMemberapi = () => {
    let getFamilyuserbyIdpramas = Services.getFamilyuserbyIdapi(primaryMemberId);
    getFamilyuserbyIdpramas
      .then((res) => {
        setFamilyMember(res.data.data);
        notifyCondition(res.data.data);
      })
      .catch((err) => {
        konsole.log(err);
      });
  };

  // const everyOne = professionalUsers.concat(familyMember);

  const onChange3 = (checkedValues) => {
    konsole.log("checked = ", checkedValues);
    let arr = [];
    checkedValues.forEach((a) => {
      arr.push(a);
    });
    // setSelected3(arr);
  };

  const onSavebtn = () => {
    //  if((familyMemberName.length < 0 || famselected.length == 0 ) || (curselected.length == 0 && professionalUserId.length != 0)){
    //   Toaster.error("Please Select Notify type.")
    //  }

    // putNotifyMessage();

    // const dataObj1 = [
    //   selected == 1 && notifyMemId == 0
    //     ? {
    //         notifyMemId: 0,
    //         primaryMemId: memberUserId,
    //         notifyTypeId: selected,
    //         contactNatureId: 1,
    //         notifyConditionId: props.id,
    //         notifyText: familyNoify,
    //         isActive: true,
    //         upsertedBy: loggedUserId,
    //       }
    //     : selected == 1 && notifyMemId != 0
    //     ? {
    //         notifyMemId: notifyMemId,
    //         primaryMemId: memberUserId,
    //         notifyTypeId: selected,
    //         contactNatureId: 1,
    //         notifyConditionId: props.id,
    //         notifyText: familyNoify,
    //         isActive: true,
    //         upsertedBy: loggedUserId,
    //       }
    //     : selected == 2 && notifyMemIdtext == 0
    //     ? {
    //         notifyMemId: 0,
    //         primaryMemId: memberUserId,
    //         notifyTypeId: selected,
    //         contactNatureId: 1,
    //         notifyConditionId: props.id,
    //         notifyText: familyNoifytext,
    //         isActive: true,
    //         upsertedBy: loggedUserId,
    //       }
    //     : selected == 2 && notifyMemIdtext != 0
    //     ? {
    //         notifyMemId: notifyMemIdtext,
    //         primaryMemId: memberUserId,
    //         notifyTypeId: selected,
    //         contactNatureId: 1,
    //         notifyConditionId: props.id,
    //         notifyText: familyNoifytext,
    //         isActive: true,
    //         upsertedBy: loggedUserId,
    //       }
    //     : {},
    // ];

    var dataObj1 = [];
    famselected.map((selectprof) => {
      if (selectprof == 1) {
        dataObj1.push({
          notifyMemId: notifyMemId ? notifyMemId : 0,
          primaryMemId: memberUserId,
          notifyTypeId: selectprof,
          contactNatureId: 1,
          notifyConditionId: props.id,
          notifyText: familyNoify,
          isActive: true,
          upsertedBy: loggedUserId,
        });
      }
      if (selectprof == 2) {
        dataObj1.push({
          notifyMemId: notifyMemIdtext ? notifyMemIdtext : 0,
          primaryMemId: memberUserId,
          notifyTypeId: selectprof,
          contactNatureId: 1,
          notifyConditionId: props.id,
          notifyText: familyNoify,
          isActive: true,
          upsertedBy: loggedUserId,
        });
      }

      //       if(famselected.length == 1 && famselected == 1 ){
      //         dataObj1.push(  {
      //           notifyMemId: (notifyMemId || notifyMemIdtext) ? (selectprof != 1 ? notifyMemId : notifyMemIdtext) : 0,
      //           primaryMemId: memberUserId,
      //           notifyTypeId: 2,
      //           contactNatureId: 1,
      //           notifyConditionId: props.id,
      //           notifyText: familyNoify,
      //           isActive:  false ,
      //           upsertedBy: loggedUserId,
      //         })
      //         dataObj1.push(  {
      //           notifyMemId: (notifyMemId || notifyMemIdtext) ? (selectprof == 1 ? notifyMemId : notifyMemIdtext) : 0,
      //           primaryMemId: memberUserId,
      //           notifyTypeId: 1,
      //           contactNatureId: 1,
      //           notifyConditionId: props.id,
      //           notifyText: familyNoify,
      //           isActive:  true ,
      //           upsertedBy: loggedUserId,
      //         }
      // )
      //       }
      //       if(famselected.length == 1 && famselected == 2 ){
      //         dataObj1.push(  {
      //           notifyMemId: (notifyMemId || notifyMemIdtext) ? (selectprof == 1 ? notifyMemId : notifyMemIdtext) : 0,
      //           primaryMemId: memberUserId,
      //           notifyTypeId: 1,
      //           contactNatureId: 1,
      //           notifyConditionId: props.id,
      //           notifyText: familyNoify,
      //           isActive:  false ,
      //           upsertedBy: loggedUserId,
      //         }
      // )
      // dataObj1.push(  {
      //   notifyMemId: (notifyMemId || notifyMemIdtext) ? (selectprof == 1 ? notifyMemId : notifyMemIdtext) : 0,
      //   primaryMemId: memberUserId,
      //   notifyTypeId: 2,
      //   contactNatureId: 1,
      //   notifyConditionId: props.id,
      //   notifyText: familyNoify,
      //   isActive:  true ,
      //   upsertedBy: loggedUserId,
      // }
      // )
      //       }
    });

    // const dataObj2 = [
    //   selected2 == 1 && notifyMemId2 == 0
    //     ? {
    //         notifyMemId: 0,
    //         primaryMemId: memberUserId,
    //         notifyTypeId: selected2,
    //         contactNatureId: 2,
    //         notifyConditionId: props.id,
    //         notifyText: professNoify,
    //         isActive: true,
    //         upsertedBy: loggedUserId,
    //       }
    //     : selected2 == 1 && notifyMemId2 != 0
    //     ? {
    //         notifyMemId: notifyMemId2,
    //         primaryMemId: memberUserId,
    //         notifyTypeId: selected2,
    //         contactNatureId: 2,
    //         notifyConditionId: props.id,
    //         notifyText: professNoify,
    //         isActive: true,
    //         upsertedBy: loggedUserId,
    //       }
    //     : selected2 == 2 && notifyMemId2text == 0
    //     ? {
    //         notifyMemId: 0,
    //         primaryMemId: memberUserId,
    //         notifyTypeId: selected2,
    //         contactNatureId: 2,
    //         notifyConditionId: props.id,
    //         notifyText: professNoifytext,
    //         isActive: true,
    //         upsertedBy: loggedUserId,
    //       }
    //     : selected2 == 2 && notifyMemId2text != 0
    //     ? {
    //         notifyMemId: notifyMemId2text,
    //         primaryMemId: memberUserId,
    //         notifyTypeId: selected2,
    //         contactNatureId: 2,
    //         notifyConditionId: props.id,
    //         notifyText: professNoifytext,
    //         isActive: true,
    //         upsertedBy: loggedUserId,
    //       }
    //     : {},
    //     curselected.map((selectedpro)=>{

    //     })
    // ];

    var dataObj2 = [];
    curselected.map((selectprof) => {
      konsole.log(selectprof, "selectprof");
      if (selectprof == 1) {
        dataObj2.push({
          notifyMemId: notifyMemId2 ? notifyMemId2 : 0,
          primaryMemId: memberUserId,
          notifyTypeId: selectprof,
          contactNatureId: 2,
          notifyConditionId: props.id,
          notifyText: professNoify,
          isActive: true,
          upsertedBy: loggedUserId,
        });
      }
      if (selectprof == 2) {
        dataObj2.push({
          notifyMemId: notifyMemId2text ? notifyMemId2text : 0,
          primaryMemId: memberUserId,
          notifyTypeId: selectprof,
          contactNatureId: 2,
          notifyConditionId: props.id,
          notifyText: professNoify,
          isActive: true,
          upsertedBy: loggedUserId,
        });
      }
      //       if(curselected.length == 1 && curselected == 1 ){
      //         dataObj2.push(  {
      //           notifyMemId: (notifyMemId || notifyMemIdtext) ? (selectprof == 1 ? notifyMemIdtext : notifyMemId ) : 0,
      //           primaryMemId: memberUserId,
      //           notifyTypeId: 2,
      //           contactNatureId: 2,
      //           notifyConditionId: props.id,
      //           notifyText: professNoify,
      //           isActive:  false ,
      //           upsertedBy: loggedUserId,
      //         })
      //         dataObj2.push(  {
      //           notifyMemId: (notifyMemId || notifyMemIdtext) ? (selectprof == 1 ? notifyMemId : notifyMemIdtext) : 0,
      //           primaryMemId: memberUserId,
      //           notifyTypeId: 1,
      //           contactNatureId: 2,
      //           notifyConditionId: props.id,
      //           notifyText: professNoify,
      //           isActive:  false ,
      //           upsertedBy: loggedUserId,
      //         }
      // )
      //       }
      //       if(curselected.length == 1 && curselected == 2 ){
      //         dataObj2.push(  {
      //           notifyMemId: (notifyMemId || notifyMemIdtext) ? (selectprof == 1 ? notifyMemId : notifyMemIdtext) : 0,
      //           primaryMemId: memberUserId,
      //           notifyTypeId: 1,
      //           contactNatureId: 2,
      //           notifyConditionId: props.id,
      //           notifyText: professNoify,
      //           isActive:  false ,
      //           upsertedBy: loggedUserId,
      //         }
      // )
      // dataObj2.push(  {
      //   notifyMemId: (notifyMemId || notifyMemIdtext) ? (selectprof == 1 ? notifyMemId : notifyMemIdtext) : 0,
      //   primaryMemId: memberUserId,
      //   notifyTypeId: 2,
      //   contactNatureId: 2,
      //   notifyConditionId: props.id,
      //   notifyText: professNoify,
      //   isActive:  false ,
      //   upsertedBy: loggedUserId,
      // }
      // )
      //       }
    });

    const dataObj = [...dataObj1, ...dataObj2];

    // console.log(profobj, "dataObj");

    // professionalGetapi

    var arr;
    const contactdata = [];
    const contactdataprofess = [];
    const removearr = [];

    function arr_diff(a1, a2) {
      var a = [],
        diff = [];

      for (var i = 0; i < a1.length; i++) {
        a[a1[i]] = true;
      }

      for (var i = 0; i < a2.length; i++) {
        if (a[a2[i]]) {
          delete a[a2[i]];
        }
      }

      for (var k in a) {
        diff.push(k);
      }

      return diff;
    }
    var arr = [];
    var arrgetid = [];
    var professuser = [];
    var professgetid = [];
    if (familyMemberId != 0) {
      familyMemberId.forEach((e) => {
        arr.push(e.userid);
      });
    }
    if (familyMemberGetapi != 0) {
      familyMemberGetapi.forEach((e) => {
        arrgetid.push(e.contactUserId);
      });
    }

    if (professionalUserId != 0) {
      professionalUserId.forEach((e) => {
        professuser.push(e.userid);
      });
    }
    if (professionalGetapi != 0) {
      professionalGetapi.forEach((e) => {
        professgetid.push(e.contactUserId);
      });
    }
    // const familygetuserid = arrgetid.contactUserId

    var removedId = arr_diff(arrgetid, arr);
    var removedIdProfess = arr_diff(professgetid, professuser);

    let removearray;

    if (familyMemberName?.length == 0 && famselected?.length == 0) {
      for (i = 0; i < familyMemberGetapi?.length; i++) {
        arr = [
          {
            contactMapId: familyMemberGetapi[i]?.contactMapId,
            primaryMemberId: memberUserId,
            contactNatureId: 1,
            contactUserId: familyMemberGetapi[i]?.contactUserId,
            notifyConditionId: props.id,
            contactStatus: false,
            upsertedBy: loggedUserId,
          },
        ];
        removearr.push(arr);
        konsole.log("arr", arr);
      }
    }

    if (professionalUserName?.length == 0 && curselected?.length == 0) {
      for (i = 0; i < professionalGetapi?.length; i++) {
        arr = [
          {
            contactMapId: professionalGetapi[i]?.contactMapId,
            primaryMemberId: memberUserId,
            contactNatureId: 1,
            contactUserId: professionalGetapi[i]?.contactUserId,
            notifyConditionId: props.id,
            contactStatus: false,
            upsertedBy: loggedUserId,
          },
        ];
        removearr.push(arr);
        konsole.log("arr", arr);
      }
    }

    if (
      removedId.length != 0 &&
      familyMemberId.length != 0 &&
      removedIdProfess.length != 0 &&
      professionalUserId.length != 0
    ) {
      for (var i = 0; i < removedId?.length; i++) {
        for (var x = 0; x < familyMemberGetapi?.length; x++) {
          if (removedId[i] == familyMemberGetapi[x]?.contactUserId) {
            arr = [
              {
                contactMapId: familyMemberGetapi[x]?.contactMapId,
                primaryMemberId: memberUserId,
                contactNatureId: 1,
                contactUserId: removedId[i],
                notifyConditionId: props.id,
                contactStatus: false,
                upsertedBy: loggedUserId,
              },
            ];
            removearr.push(arr);
          }
        }
      }
      for (var i = 0; i < removedIdProfess?.length; i++) {
        for (var x = 0; x < professionalGetapi?.length; x++) {
          if (removedIdProfess[i] == professionalGetapi[x]?.contactUserId) {
            // console.log(familyMemberGetapi[x].contactMapId,removedId[i],"new member")
            arr = [
              {
                contactMapId: professionalGetapi[x]?.contactMapId,
                primaryMemberId: memberUserId,
                contactNatureId: 2,
                contactUserId: removedIdProfess[i],
                notifyConditionId: props.id,
                contactStatus: false,
                upsertedBy: loggedUserId,
              },
            ];

            removearr.push(arr);
          }
          konsole.log(removearr, "removearr");
        }
      }
    } else if (removedId?.length != 0 && familyMemberId?.length != 0) {
      for (var i = 0; i < removedId?.length; i++) {
        for (var x = 0; x < familyMemberGetapi?.length; x++) {
          if (removedId[i] == familyMemberGetapi[x]?.contactUserId) {
            arr = [
              {
                contactMapId: familyMemberGetapi[x]?.contactMapId,
                primaryMemberId: memberUserId,
                contactNatureId: 1,
                contactUserId: removedId[i],
                notifyConditionId: props.id,
                contactStatus: false,
                upsertedBy: loggedUserId,
              },
            ];
            removearr.push(arr);
          }
          konsole.log(removearr, "removearr");
        }
      }
    } else if (removedIdProfess?.length != 0 && professionalUserId?.length != 0) {
      for (var i = 0; i < removedIdProfess?.length; i++) {
        for (var x = 0; x < professionalGetapi?.length; x++) {
          if (removedIdProfess[i] == professionalGetapi[x]?.contactUserId) {
            // console.log(familyMemberGetapi[x].contactMapId,removedId[i],"new member")
            arr = [
              {
                contactMapId: professionalGetapi[x]?.contactMapId,
                primaryMemberId: memberUserId,
                contactNatureId: 2,
                contactUserId: removedIdProfess[i],
                notifyConditionId: props.id,
                contactStatus: false,
                upsertedBy: loggedUserId,
              },
            ];

            removearr.push(arr);
          }
        }
      }
    } else {
      arr = [];
      removearr.push(arr);
    }

    if (familyMemberId?.length == 0 && professionalUserId?.length != 0) {
      for (
        var i = 0;
        i < (professionalUserId?.length || contactmapIdProfess?.length);
        i++
      ) {
        arr = [
          {
            contactMapId: 0,
            primaryMemberId: memberUserId,
            contactNatureId: 2,
            contactUserId: professionalUserId[i]?.userid,
            notifyConditionId: props.id,
            contactStatus: true,
            upsertedBy: loggedUserId,
          },
        ];

        konsole.log(arr, "arr");
        contactdataprofess.push(arr);
        // console.log(contactdata, "arr2");
      }
    } else if (familyMemberId?.length != 0 && professionalUserId?.length == 0) {
      for (
        var i = 0;
        i < (familyMemberId?.length || contactmapIdFamily?.length);
        i++
      ) {
        arr = [
          {
            contactMapId: 0,
            primaryMemberId: memberUserId,
            contactNatureId: 1,
            contactUserId: familyMemberId[i]?.userid,
            notifyConditionId: props.id,
            contactStatus: true,
            upsertedBy: loggedUserId,
          },
        ];
        // console.log(arr, "arr");
        contactdata.push(arr);
        // console.log(contactdata, "arr2");
      }
    } else if (familyMemberId?.length != 0 && professionalUserId?.length != 0) {
      for (
        var i = 0;
        i < (familyMemberId?.length || contactmapIdFamily?.length);
        i++
      ) {
        arr = [
          {
            contactMapId: 0,
            primaryMemberId: memberUserId,
            contactNatureId: 1,
            contactUserId: familyMemberId[i]?.userid,
            notifyConditionId: props.id,
            contactStatus: true,
            upsertedBy: loggedUserId,
          },
        ];
        // console.log(arr, "arr");
        contactdata.push(arr);
        // console.log(contactdata, "arr2");
      }
      for (
        var i = 0;
        i < (professionalUserId?.length || contactmapIdProfess?.length);
        i++
      ) {
        arr = [
          {
            contactMapId: 0,
            primaryMemberId: memberUserId,
            contactNatureId: 2,
            contactUserId: professionalUserId[i]?.userid,
            notifyConditionId: props.id,
            contactStatus: true,
            upsertedBy: loggedUserId,
          },
        ];

        konsole.log(arr, "arr");
        contactdataprofess.push(arr);
        // console.log(contactdata, "arr2");
      }
    }
    //  else {
    //   Toaster.warning("Please Select the members");

    //   arr = [];
    //   contactdata.push(arr);
    //   contactdataprofess.push(arr);
    // }

    // if (
    //   (selected.length != 0 && familyMemberId.length != 0) ||
    //   (selected2.length != 0 && professionalUserId != 0)
    // ) {

    const familyArr = contactdata?.flat(1);
    const professArr = contactdataprofess?.flat(1);

    // console.log("FlattenArrayPost",FlattenArray2)

    let newArrfamily = familyArr.filter((e) => {
      return !familyMemberGetapi.some((object) => {
        return object?.contactUserId == e?.contactUserId;
      });
    });
    // console.log("newArrf", newArrfamily);
    let newArrContact = professArr?.filter((e) => {
      return !professionalGetapi?.some((object) => {
        return (
          object?.contactUserId == e?.contactUserId &&
          object?.contactNatureId == e?.contactNatureId
        );
      });
    });
    // console.log("newArrp", newArrContact);

    let postapidata = [...newArrfamily, ...newArrContact];
    // console.log("newArrAll", postapidata);
    const FinalArr = [...removearr, ...postapidata];
    const FlattenArray = FinalArr?.flat(1);

    konsole.log("FlattenArray", FlattenArray);
    // calling the function
    // passing array argument

    // console.log(getUniqueAfterMerge,"FlattenArr")

    // }
    // console.log(newArr, "newArr3");

    //   professionalGetapi.forEach((e)=>{
    //     var filtered = FlattenArray2.filter(function(value){
    //       return e.contactUserId === value.contactUserId;
    //   });
    //   console.log(filtered,"filtered")
    // });

    // console.log(filtered,"filtered")
    // if( e.contactUserId === FlattenArray2[x].contactUserId)
    // {
    //   console.log(FlattenArray2[x], "newArray");
    // }

    // for (var y = 0; y < familyMemberGetapi.length; y++) {
    //   console.log("laststage1", familyMemberId[x].userid);
    //   console.log("laststage2", familyMemberGetapi[y].contactUserId);
    //   if (
    //     familyMemberId[x].contactUserId == familyMemberGetapi[y].contactUserId
    //   ) {
    //     console.log("laststage api is not running");
    //   }
    // }
    // }
    // // Toaster.success("api run");
    // console.log(FlattenArray2, "FlattenArray");

    if (familyMemberName?.length == 0 && famselected?.length != 0) {
      Toaster.error("Please Select From Friends and Family Contacts");
    } else if (familyMemberName?.length != 0 && famselected?.length == 0) {
      Toaster.error("Please select and proceed");
    } else if (professionalUserName?.length == 0 && curselected?.length != 0) {
      Toaster.error("Please Select From Professional Contacts");
    } else if (
      (familyMemberName?.length != 0 && famselected?.length == 0) &&
      (professionalUserName?.length != 0 && curselected?.length == 0)
    ) {
      Toaster.error("Please select and proceed");
    } else {
      let postNofityMessageapipramas = Services.postNofityMessageapi(dataObj);
      postNofityMessageapipramas
        .then((res) => {
          konsole.log(res, "postapi");
          Toaster.success("Successfully Saved");
          props.selectSelectedStepper(3);
        })
        .catch((err) => {
          konsole.log(err, "postapi");
        });

      let postcontactmapapipramas = Services.postcontactmapapi(FlattenArray);
      postcontactmapapipramas
        .then((res) => {
          konsole.log(res, "res");
        })
        .catch((err) => {
          konsole.log(err, "err");
        });
    }
  };
  const backButton=()=>{
    props?.selectSelectedStepper(1)
  }
  return (
    <div>
      <Row className="">
        <Col lg={22} xl={22}>
          <div>
            <h6>Select From Friends and Family Contacts</h6>

            <div className="d-flex">
              <Select
                mode="multiple"
                className="w-50 m-0"
                labelInValue
                tokenSeparators={[" ", ","]}
                // defaultValue={null}
                value={familyMemberName}
                placeholder="Please Select Family Member"
                onChange={onChangeSelect}
              >
                {primarymember?.spouseUserId == props?.memberUserId && <Option key={primarymember?.memberUserId} value={primarymember?.memberUserId} userid={primarymember?.memberUserId}>{primarymember?.memberName}</Option>}
                {familyMember.map((data, key) => (
                  <Option
                    key={data.relativeUserId}
                    value={data.relativeUserId}
                    userid={data.relativeUserId}
                  >
                    {data.fName + " " + data.lName}
                  </Option>
                ))}
              </Select>
            </div>
            <h6 className="mt-2">
              This is the Email/Text that will go to the selected people. Please
              compose or edit.
            </h6>
            <Checkbox.Group
              value={famselected}
              options={options}
              onChange={onChangefam}
            />
            <div className="mt-2 border  border-dark">
              <label className="e-float-text e-label-top text-muted px-2">
                Dear User,
              </label>

              <TextArea
                style={{ border: "none" }}
                rows={4}
                value={familyNoify}
                onChange={(e) => setFamilynotify(e.target.value)}
              ></TextArea>
            </div>
          </div>

          <div className="mt-4">
            <h6>Select From Professional Contacts</h6>
            <div className="d-flex">
              <Select
                mode="multiple"
                className="w-50 m-0"
                labelInValue
                tokenSeparators={[" ", ","]}
                value={professionalUserName}
                onChange={onChangeProfess}
                placeholder="Please Select Professional Contact"
              >
                {professionalUsers.map((data, key) => (
                  <Option
                    key={data.professionalUserId}
                    value={data.professionalUserId}
                    userid={data.professionalUserId}
                  >
                    {data.fName + " " + data.lName}
                  </Option>
                ))}
              </Select>
            </div>
            <h6 className="mt-2">
              This is the Email/Text that will go to the selected people. Please
              compose or edit.
            </h6>
            <Checkbox.Group
              value={curselected}
              options={options}
              onChange={onChange2}
            />
            <div className="mt-2 border  border-dark">
              <label className="e-float-text e-label-top text-muted px-2">
                Dear User,
              </label>

              <TextArea
                style={{ border: "none" }}
                rows={4}
                value={professNoify}
                onChange={(e) => setProfessnotify(e.target.value)}
              ></TextArea>
            </div>
          </div>
        </Col>
      </Row>
      <div className="Sava-Button-Div d-flex justify-content-between">
      <button className="Save-Button" onClick={backButton}> Back </button>
        <button className="Save-Button" onClick={onSavebtn}>
          Save & Proceed
        </button>
      </div>
    </div>
  );
}
