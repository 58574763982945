import { Radio, Table } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import Services from '../../network/Services';
import commonLib from '../../control/commonLib';
import { UserContext } from '../../App';
import konsole from "../../network/konsole";
import ModalComponent from '../ModalComponent';

export default function AgentPreview(props) {
    const userId = commonLib.getObjFromStorage("userPrimaryInDetail")?.memberUserId;
    const spouseUserId = commonLib.getObjFromStorage("userPrimaryInDetail")?.spouseUserId;
    const loggedUser = commonLib.getObjFromStorage("userLoggedInDetail");
    const [RenderValue, setRenderValue] = useState(true);
    const [Rendermember, setRendermember] = useState("Primary");
    const [tokenForIframe, setTokenForIframe] = useState("");
    const [visible, setVisible] = useState(false);
    const [assignOptionsList, setAssignOptionsList] = useState([])
    const { setLoader } = useContext(UserContext);


    const selectradiovalue = (e) => {
        console.log("selectradiovalue", e.target.value);
        setRendermember(e.target.value);
    };

    useEffect(() => {
        // if (Rendermember == "Primary") {
        //     getUserAgentListByUserId(userId);
        // } else {
        //     getUserAgentListByUserId(spouseUserId);
        // }
        getUserAgentListByUserId(props?.memberUserId)
    }, [props?.memberUserId]);

    const getUserAgentListByUserId = (userIdd) => {
        setLoader(true);
        let promises = Services.getUserAgentList(userIdd, true);
        promises

            .then((res) => {
                let responseData = res.data.data.filter(d => d.agentUserId !== null).map((userAgent) => {
                    return {
                        fullName: userAgent.fullName,
                        relationWithMember: userAgent.relationWithMember,
                        legalDocName: userAgent.testSupportDocName == null && userAgent.testDocId == null ? userAgent.legalDocName + " & " + userAgent.agentRole : userAgent.testDocId == null
                            ? userAgent.testSupportDocName + " & " + userAgent.agentRole : userAgent.testSupportDocName == null ? userAgent.testDocName + " & " + userAgent.agentRole : "",
                        agentRank: userAgent.agentRank,
                        agentAcceptanceStatus: userAgent.agentAcceptanceStatus,
                        isUserActive: userAgent.isUserActive,
                        agentEmailId: userAgent.agentEmailId,
                        agentMobileNo: userAgent.agentMobileNo,
                        agentUserId: userAgent.agentUserId,
                        agentRankId: userAgent.agentRankId,
                        statusName: userAgent.statusName,
                        relationWithSpouse: userAgent.relationWithSpouse
                    };
                });
                responseData = (userIdd === spouseUserId) ? responseData.map((d) => {return (d.agentUserId === userId)? {...d,relationWithSpouse: "Spouse"}: d}): responseData;
                // konsole.log("response my agent", userIdd,spouseUserId,userId,res,responseData);
                const uniqueArr = [...new Set(responseData.map(item => item.agentUserId))].map(name => { return responseData.find(item => item.agentUserId === name)});
                setAssignOptionsList(uniqueArr);
                setLoader(false);
            })
            .catch((err) => {
                setLoader(false);
                konsole.log("error", err.response.data.status);
                if (err.response.data.status == "404") {
                    setAssignOptionsList([]);
                }
            });
    };


    const columns = [
        {
            title: "Agent",
            dataIndex: "fullName",
            key: "Agent",
            className: "manage text-center",
        },
        {
            title: "Relation",
            key: "Relation",
            render: (relationWithSpouse, data) => (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: `0.5rem 0` }} >
                    <span>{props.memberUserId === spouseUserId && (data.relationWithSpouse != null || data.relationWithSpouse != undefined) ? data.relationWithSpouse : data.relationWithMember}</span>
                </div>
            ),
            dataIndex: "relation",
            className: "manage",
        },
        {
            title: "Action",
            className: "manage",
            render: (action, data) => (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => handlePreview(data)}>
                    <button className='ant-btn ant-btn-primary'>Preview</button>
                </div>
            ),
        },
    ];




    const handlePreview = (agentObj) => {
        konsole.log("show agent", agentObj);
        const stateObj = commonLib.getObjFromStorage('stateObj');
        let params = `appState=${stateObj.appState}&userId=${stateObj.userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggenInId}&agentUserId=${agentObj.agentUserId}`;

        konsole.log("asdasdasd", window.btoa(params));

        setTokenForIframe(`https://aoagentdev.azurewebsites.net/?token=${window.btoa(params)}`);
        setVisible(true)
    }


    const handleClosePreview = () => {
        konsole.log("hide modal");
        setTokenForIframe("");
        setVisible(false);
    }
    const backButton=()=>{
        props?.selectSelectedStepper(3)
    }

    return (
        <>
            {/* <div className="row mt-3 d-block">
                <div className="col-12 p-0 d-block d-md-flex">
                    <div class="form-check ">
                        <label class="form-check-label  fw-bold" for="flexCheckDefault" > View Agent for: </label>
                    </div>
                    <div class="form-check">
                        <Radio.Group name="radiogroupmember" onChange={selectradiovalue} value={Rendermember} >
                            <Radio value={"Primary"}>{loggedUser?.memberName}</Radio>
                            {loggedUser?.spouseName ? <> {" "} <Radio value={"Spouse"}> {loggedUser?.spouseName} </Radio></> : null}
                        </Radio.Group>
                    </div>
                </div>
            </div> */}
            {
                assignOptionsList?.length > 0 ? <Table dataSource={assignOptionsList} columns={columns} bordered pagination={false} className='w-100'/> : <p>Your agents are not yet assigned please contact your legal team.</p>
            }
            {
                visible ===true && 
                <ModalComponent title="Agent Preview (The screen which you are viewing is just a preview of an Agent.)" visible={visible} onCancel={handleClosePreview} onOk={handleClosePreview}>
                    {tokenForIframe !== "" && <iframe className='w-100' style={{height: `calc(100vh - 10rem)`}} src={tokenForIframe}></iframe>}
                </ModalComponent>
            }
                <button className="Save-Button mt-2" 
                onClick={backButton}
                >
          Back
                
              </button>
        </>
    )
}
