import React, { useContext } from "react";
import Services from "../../network/Services";
import konsole from "../../network/konsole";
import { useEffect } from "react";
import { UserContext } from "../../App";
import { useState } from "react";
import { mentalhealth } from "../../control/Constant";
import { Row, Col, Form, Input, Radio, Select, Modal, Button, Steps } from "antd";
import Toaster from "../Toaster";
import { Document, Page, pdfjs } from "react-pdf"
import commonLib from "../../control/commonLib";
import Addnewprofessmodal from "../Component/MyProfessionalModal/Addnewprofessmodal";
import ImmediateActions from "./immediateActions";
import AgentPreview from "./AgentPreview";
import UploadFile from "./UploadFile";
const { Option } = Select;
const { TextArea } = Input;

export default function Mentalhealth(props) {
  const { Step } = Steps;
  const [formlabelData, setFormLabelData] = useState({});
  const [callgcm, setCallgcm] = useState()
  const [contactagent, setContactagent] = useState()
  const [locategcm, setLocategcm] = useState()
  const [getselectedGCM, setGetselectedGCM] = useState();
  const { setLoader } = useContext(UserContext);
  const [professionalUser, setProfessionalUser] = useState([]);
  const [openfile, setopenfile] = useState(false)
  const [numPages, setNumPages] = useState(null);
  const [showaddprofessmodal, setshowaddprofessmodal] = useState(false);
  const [professionaltype, setprofessionaltype] = useState();
  const [Addnewprofessmodaldata, setAddnewprofessmodaldata] = useState(null)
  const [selectSelectedStepper, setselectSelectedStepper] = useState(1)
  const [specialInsAns, setSpecialInsAns] = useState("");
  const [otherspecialins, setotherspecialins] = useState([])
  let selectKey;
  let memberUserId = props.memberid
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }


  useEffect(() => {
    setselectSelectedStepper(1)
    getsubjectForFormLabelId(props?.memberid)
    getProfessionalByUser(props?.memberid);
    setCallgcm('')
    setContactagent('')
    setLocategcm('')
    setSpecialInsAns('')
    setotherspecialins([])
  }, [props?.memberid])

  useEffect(() => {
    getProfessionalByUser(props?.memberid);
  }, [showaddprofessmodal, props.memberid]);

  useEffect(() => {
    putProfessionaluserpramas()
  }, [Addnewprofessmodaldata])

  const getsubjectForFormLabelId = (userId) => {
    setLoader(true);
    let formlabelData = {};
    mentalhealth.formLabelId.map((id, index) => {
      let data = [id.id];
      const promises = Services.getSubjectQuestions(data);
      promises.then((response) => {
        if (response) {
          konsole.log(response, "responseresponsemental");
          if (id.id == response.data.data[0].formLabelId) {
            let label = "label" + response.data.data[0].formLabelId;
            formlabelData[label] = response.data.data[0].question;
            const promises = Services.getSubjectResponse(
              userId,
              formlabelData[label].questionId
            );
            promises.then((response) => {
              if (response) {
                konsole.log(response, "response")
                setLoader(false);
                if (response.data.data.userSubjects.length !== 0) {
                  let responseData = response.data.data.userSubjects[0];
                  for (
                    let i = 0;
                    i < formlabelData[label].response.length;
                    i++
                  ) {
                    if (
                      formlabelData[label].response[i].responseId ===
                      responseData.responseId
                    ) {
                      if (responseData.responseNature == "Radio") {
                        formlabelData[label].response[i]["checked"] = true;
                        if (formlabelData[label].questionId == 202) {
                          setCallgcm(responseData.responseId);
                        } else {
                        }
                        if (formlabelData[label].questionId == 203) {
                          setContactagent(responseData.responseId);
                        } else {
                        }
                        if (formlabelData[label].questionId == 204) {
                          setLocategcm(
                            responseData.responseId
                          );
                        } else {
                        }
                        if (formlabelData[label].questionId == 207) {
                          setotherspecialins(responseData)
                        }
                        //   formlabelData[label]["userSubjectDataId"] =
                        //     responseData.userSubjectDataId;
                        // } else if (responseData.responseNature == "Text") {
                        //   if (responseData.responseId == 264) {
                        //     setDisposedOther(responseData.response);
                        //   }
                        //   if (responseData.responseId == 265) {
                        //     setAshesOther(responseData.response);
                        //   }
                        // formlabelData[label].response[i]["response"] =
                        //   responseData.response;
                        
                      } else if (responseData.responseNature == "Text") {
                        if (responseData.responseId == 402) {
                          setSpecialInsAns(responseData.response)
                        }
                        formlabelData[label]["userSubjectDataId"] =
                          responseData.userSubjectDataId;
                      }
                    }
                  }
                }
              }
            });
            setFormLabelData(formlabelData);
          }
        }
      });
    });
  };

  const onChangeSelect = (value) => {
    console.log("selected value", value);
    // setSelectkey(value);
    setGetselectedGCM(professionalUser[value])
    selectKey = value;
    return selectKey;
  };

  const getProfessionalByUser = (memberid) => {
    let getProfessionalpramas = Services.getProfessionalByUserId(memberid);
    getProfessionalpramas
      .then((res) => {
        let filterdata = res.data.data?.professionalUser.filter((items) => {
          return items.proSerDescId == 7;
        });
        let selectedGCM = res.data.data?.professionalUser.filter((items) => {
          return items.proSerDescId == 7 && items.lpoStatus == true;
        });
        konsole.log(res, "trysiop")
        setGetselectedGCM(selectedGCM[0]);
        setProfessionalUser(filterdata);
      })
      .catch((err) => {
        konsole.log("err", err);
      });
  };

  const CheckValue998 = (e) => {
    konsole.log(e.target.value, "CheckValue998")
    setCallgcm(e.target.value)
  }
  const CheckValue999 = (e) => {
    konsole.log(e.target.value, "CheckValue998")
    setContactagent(e.target.value)
  }
  const CheckValue1000 = (e) => {
    konsole.log(e.target.value, "CheckValue998")
    setLocategcm(e.target.value)
  }

  konsole.log(formlabelData, "formlabelData")

  const putProfessionaluserpramas = () => {

    let stateUserId = JSON.parse(sessionStorage.getItem("stateObj")).userId;

    const dataObj = {
      userId: memberUserId,
      updatedBy: stateUserId,
      professionalUser: {
        proTypeId: professionalUser[selectKey]?.proTypeId,
        proUserId: professionalUser[selectKey]?.proUserId,
        userProId: professionalUser[selectKey]?.userProId,
        lpoStatus: true,
      },
    };
    if (Addnewprofessmodaldata != null) {
      var dataaddprofessdata = {
        userId: memberUserId,
        updatedBy: stateUserId,
        professionalUser: {
          proTypeId: Addnewprofessmodaldata?.proTypeId,
          proUserId: Addnewprofessmodaldata?.proUserId,
          userProId: Addnewprofessmodaldata?.userProId,
          lpoStatus: true,
        },
      };
    }
    let putProfessionaluserpramas = Services.putProfessionaluser(Addnewprofessmodaldata != null ? dataaddprofessdata : dataObj);
    putProfessionaluserpramas
      .then((res) => {
        konsole.log("professional", res.data.data);
      })
      .catch((err) => {
        konsole.log("err", err);
      });
  };

  const postSubjectData = () => {
    let inputArray = [];

    let callgcmdata = {
      userSubjectDataId: formlabelData.label998?.userSubjectDataId != undefined ? formlabelData.label998?.userSubjectDataId : 0,
      subjectId: formlabelData.label998?.questionId,
      responseId: callgcm,
      userId: props.memberid,
    }

    let contactagentdata = {
      userSubjectDataId: formlabelData.label999?.userSubjectDataId != undefined ? formlabelData.label999?.userSubjectDataId : 0,
      subjectId: formlabelData.label999?.questionId,
      responseId: contactagent,
      userId: props.memberid,
    }

    let locategcmdata = {
      userSubjectDataId: formlabelData.label1000?.userSubjectDataId != undefined ? formlabelData.label1000?.userSubjectDataId : 0,
      subjectId: formlabelData.label1000?.questionId,
      responseId: locategcm,
      userId: props.memberid,
    }

    inputArray.push(callgcmdata)
    if (callgcm == 395) {
      inputArray.push(contactagentdata)
      putProfessionaluserpramas()
    }
    if (contactagent == 398) {
      inputArray.push(locategcmdata)
      putProfessionaluserpramas()
    }

    inputArray = {
      userId: props?.memberid,
      userSubjects: inputArray,
    };
    konsole.log(inputArray, "postSubjectDatapostSubjectData")
    let promises = Services.upsertSubjectdata("PUT", inputArray);
    promises

      .then((res) => {
        Toaster.success("Successfully Saved");
        konsole.log("successful", res);
        getsubjectForFormLabelId(props?.memberid)
        setselectSelectedStepper(2)
      })
      .catch((error) => {
        konsole.log("error", error?.response)
        Toaster.warning("Please select all the questions correctly");
      });


  };



  const addprofessfuncclick = (type) => {
    konsole.log(type, "typetypetypetypetype");

    if (type == "GCM") {
      setprofessionaltype(7);
    }
    setshowaddprofessmodal(true);
  };

  const CheckValuespecialinst = (e) => {
    konsole.log("metadata CheckValue", e.target.value);
    let responseObj = {
      userId: memberUserId,
      userSubjectDataId: 0,
      subjectId: 0,
      responseId: 0,
    };
    if (e.target.value == 403) {
      const formObj = formlabelData.label1003;
      konsole.log("metadata res", formObj, responseObj);
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      konsole.log("metadata", answer);
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("metadata responseObj", responseObj);
      setotherspecialins(responseObj);
    } else if (e.target.value == 404) {
      const formObj = formlabelData.label1003;
      const answer = formObj.response.filter((a) => {
        return a.responseId == e.target.value;
      })[0];
      responseObj.subjectId = formObj.questionId;
      responseObj.responseId = answer.responseId;
      responseObj.userSubjectDataId = formObj.userSubjectDataId;
      konsole.log("eventvalue", responseObj);
      konsole.log("metadata responseObj", responseObj);
      setotherspecialins(responseObj);
    }
  };

  const postSubjectDatastep3 = () => {
    konsole.log(otherspecialins, "otherspecialins746464646")
    if (otherspecialins.length == 0) {
      Toaster.warning("Please make selection and save")
    }
    let inputArray = {
      userId: memberUserId,
      userSubjects: [
        {
          userSubjectDataId: formlabelData.label1002?.userSubjectDataId
            ? formlabelData.label1002?.userSubjectDataId
            : 0,
          subjectId: formlabelData.label1002?.questionId,
          subResponseData: specialInsAns,
          responseId: formlabelData.label1002?.response[0].responseId,
        },
        {
          userSubjectDataId: formlabelData.label1003?.userSubjectDataId
            ? formlabelData.label1003?.userSubjectDataId
            : 0,
          subjectId: formlabelData.label1003?.questionId,
          userId: memberUserId,
          responseId: otherspecialins.responseId,
        }
      ],
    };

    let promises = Services.upsertSubjectdata("PUT", inputArray);
    promises
      .then((res) => {
        Toaster.success("Successfully Saved");
        konsole.log("successful", res);
        setselectSelectedStepper(4)
      })
      .catch((error) => konsole.log("error", error.response));
    getsubjectForFormLabelId(memberUserId);

    // selectedStep == 3;
  };

  const backButton = () => {
    setselectSelectedStepper(2)
  }


  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return (
    <>
      {showaddprofessmodal && (
        <Addnewprofessmodal
          showaddprofessmodal={showaddprofessmodal}
          setshowaddprofessmodal={setshowaddprofessmodal}
          professionaltype={professionaltype}
          memberUserId={props.memberid}
          setAddnewprofessmodaldata={setAddnewprofessmodaldata}
        />
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "810px" ,marginTop:"10px"}}>
          <Steps
            labelPlacement="vertical"
            current={selectSelectedStepper - 1}
            size="small"
          >
            <Step
              title="Immediate Actions"
              onClick={(e) => setselectSelectedStepper(1)}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Notify"
              onClick={(e) => setselectSelectedStepper(2)}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Special Instructions"
              onClick={() => setselectSelectedStepper(3)}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Agents Guidance"
              onClick={() => setselectSelectedStepper(4)}
              style={{ cursor: "pointer" }}
            />
          </Steps>
        </div>
      </div>
      {selectSelectedStepper == 1 && <div className="Question-Card-Div">
        <h5>
          If an agent feels that you are not making rational decisions:
        </h5>


        {formlabelData?.label998 && <div>
          <h6>{formlabelData?.label998?.question}</h6>
          <Radio.Group
            name="organizationCare"
            value={callgcm}
            onChange={CheckValue998}
          >
            {formlabelData?.label998?.response.map(
              (answer, index) => {
                return (
                  <Radio
                    value={answer?.responseId}
                    className="ms-1"
                  >
                    {answer?.response}
                  </Radio>
                );
              }
            )}
          </Radio.Group>
        </div>
        }
        {(callgcm == 395 && formlabelData?.label999) && <div>
          <h6>{formlabelData?.label999?.question}</h6>
          <Radio.Group
            name="organizationCare"
            value={contactagent}
            onChange={CheckValue999}
          >
            {formlabelData?.label999?.response?.map(
              (answer, index) => {
                return (
                  <Radio
                    value={answer?.responseId}
                    className="ms-1"
                  >
                    {answer?.response}
                  </Radio>
                );
              }
            )}
          </Radio.Group>
        </div>
        }
        {(callgcm == 395 && contactagent == 397) && <div>
          <div className="mt-2">
            <h6>Care Manager</h6>
            <div className="d-flex">
              <div className="">
                <Select
                  placeholder={"Select a person"}
                  optionFilterProp="children"
                  style={{
                    width: 250,
                    height: "40px",
                    border: "black",
                  }}
                  value={
                    getselectedGCM == null
                      ? "Select a person"
                      : getselectedGCM.fName +
                      " " +
                      ((getselectedGCM?.mName == null || getselectedGCM?.mName == undefined || getselectedGCM?.mName == "") ? "" : getselectedGCM.mName) +
                      " " +
                      getselectedGCM.lName
                  }
                  onChange={onChangeSelect}
                >
                  {professionalUser?.map((data, key) => (
                    <Option value={key}>
                      {data?.fName} {data?.mName} {data?.lName}
                    </Option>
                  ))}
                </Select>
                <button
                  className="addnewproffbtn"
                  onClick={() => {
                    addprofessfuncclick("GCM");
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        </div>}

        {(callgcm == 395 && contactagent == 398 && formlabelData?.label1000) && <div>
          <h6>{formlabelData?.label1000?.question}</h6>
          <Radio.Group
            name="organizationCare"
            value={locategcm}
            onChange={CheckValue1000}
          >
            {formlabelData?.label1000?.response.map(
              (answer, index) => {
                return (
                  <Radio
                    value={answer?.responseId}
                    className="ms-1"
                  >
                    {answer?.response}
                  </Radio>
                );
              }
            )}
          </Radio.Group>
        </div>
        }

        {(contactagent == 398 && locategcm == 399) && <a style={{ color: "blue" }} href="https://aoorgdev.azurewebsites.net/" target="blank">Aging Options Resource Guide</a>}
        {(callgcm == 395 && contactagent == 398 && locategcm == 400) && <p className="mt-2">You can always go to Aging Options Resource Guide to locate a geriatric care manger.</p>}
        {callgcm == 395 && <div className="mt-2">
          <h6>
            <button style={{ backgroundColor: "transparent", border: "none", color: "blue" }} onClick={() => setopenfile(true)}>Click here</button>to see what a geriatric care manager will do for your children
          </h6>
        </div>}
        <Modal
          title="File View"
          visible={openfile}
          onCancel={() => setopenfile(false)}
          footer={[
            <Button key="cancel" onClick={() => setopenfile(false)}>
              Cancel
            </Button>,

          ]}
        >

          <div style={{ height: "57vh", overflow: "scroll" }} className='mt-4'>
            <Document
              file={commonLib.baseurl64()}
              onLoadSuccess={onDocumentLoadSuccess}
              onContextMenu={(e) => e.preventDefault()}
            >
              {Array.from(new Array(numPages ?? 0), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>

        </Modal>
        <div className="Sava-Button-Div">
          <button className="Save-Button" onClick={postSubjectData}>
            Save & Proceed
          </button>
        </div>
      </div>}
      {selectSelectedStepper == 2 && <div className="p-2 Question-Card-Div"><ImmediateActions id={4} memberUserId={props.memberid} selectSelectedStepper={setselectSelectedStepper} /></div>}
      {selectSelectedStepper == 3 && <div className="p-2 Question-Card-Div"> <div lg={24} className="mt-2">
        <div className="ps-4"><UploadFile memberUserId={props?.memberid} /></div>
        <Row>
          <Col>
            {formlabelData.label1003 && (
              <div className="mt-2 ms-4">
                <h6>{formlabelData.label1003.question}</h6>
                {
                  <Radio.Group
                    name="organizationCare"
                    value={otherspecialins?.responseId}
                    onChange={CheckValuespecialinst}
                  >
                    {formlabelData.label1003.response.map(
                      (answer, index) => {
                        return (
                          <Radio
                            value={answer.responseId}
                            className="ms-1"
                          >
                            {answer.response}
                          </Radio>
                        );
                      }
                    )}
                  </Radio.Group>
                }
              </div>
            )}
          </Col>
        </Row>
        {otherspecialins?.responseId == 403 && <Row className="d-flex p-2 pt-2 mt-2">
          <Col lg={23} className="textEditor-Col">
            {formlabelData.label1002 && (
              <>
                <div className="mt-3">
                  <h6>{formlabelData.label1002?.question}</h6>

                  <TextArea
                    rows={4}
                    placeholder="Please type something here"
                    defaultValue={
                      formlabelData.label1002?.response[0].response
                    }
                    value={specialInsAns}
                    onChange={(e) => {
                      setSpecialInsAns(e.target.value);
                    }}
                  />
                </div>
              </>
            )}
          </Col>
        </Row>}
        <div className="Sava-Button-Div d-flex justify-content-between">
          <button className="Save-Button"
            onClick={backButton}
          >
            Back

          </button>
          <button className="Save-Button" onClick={postSubjectDatastep3}>
            Save & Proceed
          </button>
        </div>
        <div />
      </div>
      </div>}
      {selectSelectedStepper == 4 && <div className="p-2 Question-Card-Div"><AgentPreview memberUserId={props.memberid} selectSelectedStepper={setselectSelectedStepper} /></div>}
    </>
  )
}