import React, { useEffect, useState, useContext } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import Remarks from './Remarks';
import konsole from '../../network/konsole';
import Services from '../../network/Services';
import './Filecabinet.css'
import { UserContext } from "../../App";



const PdfViwer = ({ doctypeName, setpdfname,setBreadcrumbdata,breadcrumbdata,setDoctypeName,setShowCabinetModal,setSelectedIndex,fileCategoryType }) => {
  const { setLoader } = useContext(UserContext);

  // const selectFileIndex = props.selectFileIndex;
  // const selectFileTypeIndex = props.selectFileTypeIndex;
  // const primaryUserId = sessionStorage.getItem("SessPrimaryUserId");
  const primaryUserId = JSON.parse(sessionStorage.getItem("userPrimaryInDetail")).memberUserId
  const loginUserId = sessionStorage.getItem('loggedUserId');
  // const cabinetFiles = props.cabinetFiles ?? [];


  // const selectedFileType = (selectFileIndex !== null) ? cabinetFiles.length > 0 ? cabinetFiles[0].fileTypes[selectFileTypeIndex] : {} : {};

  // const selectedFiles = (selectedFileType !== null) ? selectedFileType.files[selectFileIndex] : {};


  // konsole.log("selected Files",selectedFileType, selectedFiles,selectFileTypeIndex,selectFileIndex)
  const [numPages, setNumPages] = useState(null);
  const [show, setshow] = useState(false);
  const [base64data, setBase64Data] = useState(false);

  // konsole.log("selectedId",selectFileIndex);

  useEffect(() => {
    // konsole.log("selectedfiles", selectedFiles, selectFileIndex);
    if (doctypeName !== undefined) {
      if (doctypeName.fileId !== undefined && doctypeName.fileStatusId !== undefined) {
        getBinaryFilesFromUpload();
      }
    }

  }, [doctypeName?.fileId]);
  setpdfname(doctypeName?.fileTypeName)

  const getBinaryFilesFromUpload = () => {
    const getPost = {
      userId: primaryUserId,
      fileId: doctypeName?.fileId,
      fileTypeId: doctypeName?.fileTypeId,
      fileStatusId: doctypeName?.fileStatusId,
      fileCategoryId: doctypeName?.fileCategoryId,
      requestedBy: primaryUserId,
    }
    konsole.log("post Data at pdf", getPost)
    // props.dispatchloader(true);
    // $CommonServiceFn.InvokeCommonApi("GET", $Service_Url.postDownloadUserDocument + `/${primaryUserId}/${selectedFiles?.fileId}/${selectedFileType?.fileTypeId}/${cabinetFiles[0]?.fileCabinetId}/${loginUserId}`, '', (response) => {
    //   props.dispatchloader(false);
    //   if (response) {
    //     let fileDocObj = response.data.data;
    //     let data = 'data:application/pdf;base64,'+ fileDocObj.fileInfo.fileDataToByteArray;
    //     setBase64Data(data);
    //     konsole.log("response ar pdfView", fileDocObj);
    //   }
    // })

    let promises = Services.postDownloadUserDocument(getPost)
    setLoader(true)
    promises.then((response) => {
      console.log("postDownloadUserDocument", response)
      if (response) {



        let fileDocObj = response.data.data;
        let data = 'data:application/pdf;base64,' + fileDocObj.fileInfo.fileDataToByteArray;
        setBase64Data(data);
        konsole.log("response ar pdfView", fileDocObj);
              setLoader(false);
      }
    }).catch((err) => {
      console.log("postDownloadUserDocument-errr", err)
      setLoader(false);
    })
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


  const addWidth = (e) => {
    e.preventDefault();
    let menuBar = document.getElementById("fileCabinetList");
    let currentWidth = document.getElementById("fileCabinetPdfView");
    let ZoomOut = document.getElementById("zoomOut")
    let zoonIn = document.getElementById("zoom-In")
    currentWidth.classList.add('col-sm-12');
    currentWidth.classList.remove('col-sm-8');
    menuBar.classList.add('d-none');
    ZoomOut.classList.remove('d-none');
    zoonIn.classList.add('d-none');

  }
  const removeWidth = (e) => {
    e.preventDefault();
    let menuBar = document.getElementById("fileCabinetList");
    let currentWidth = document.getElementById("fileCabinetPdfView");
    let ZoomOut = document.getElementById("zoomOut")
    let zoonIn = document.getElementById("zoom-In")

    currentWidth.classList.remove('col-sm-12');
    currentWidth.classList.add('col-sm-9');
    menuBar.classList.remove('d-none');
    ZoomOut.classList.add('d-none');
    zoonIn.classList.remove('d-none');

  }


  const handleClose = () => {
    setshow(!show);

  };

  const Closepdf = () => {
    // window.location.replace('/filecabinet')
    setBreadcrumbdata("")
    setDoctypeName("")
    setShowCabinetModal("")
    setSelectedIndex(-1)
  }

  return (

    <div className='container-fluid pdf' id='pdfId'>

      {show && <Remarks show={show} handleClose={handleClose} fileCategoryId={doctypeName?.fileCategoryId} fileId={doctypeName?.fileId} fileTypeId={doctypeName?.fileTypeId} breadcrumbdata={breadcrumbdata} fileCategoryType={fileCategoryType} doctypeName={doctypeName} />}
      <div id='logo' className=''>
        <div className="container-fluid mt-1 p-0 m-0">
          <div className="side-menu p-0 mx-1 d-flex align-items-center justify-content-between">
            <button className="rounded-circle" id='remark' onClick={handleClose} >
              <img src='/icons/remarksIcon.svg' alt='g4' />
              <p>Comments</p>
            </button>
            <button className="rounded-circle" onClick={Closepdf} >
              X
            </button>

          </div>
        </div>
      </div>

      <div className='mt-2'>
        <Document className="outer-doc" file={base64data} noData={"Loading PDF…"}  onLoadSuccess={onDocumentLoadSuccess} onContextMenu={(e) => e.preventDefault()}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} loading={false} />

          ))}
        </Document>

      </div>
    </div>
  );
};


export default PdfViwer;