export const mapApi = "https://maps.googleapis.com/maps/api/js";

export const mapKey = 'AIzaSyBaUn22pwovCzOxH7Uthivbd8_ScMkaEAI';
export const imagePath = [
    "icons/personalInfoHeader.svg",
    "icons/FamilyInfoHeader.svg",
    "icons/healthInfoHeader.svg",
    "icons/housingInfoHeader.svg",
    "icons/financialInfoHeader.svg",
    "icons/legalHeader.svg",
  ]



  export const illness = {
    formLabelId :[
      { id: 894, },
      { id: 895, },
      { id: 896, },
      { id: 897, },
      { id: 898, },
      { id: 925, },
      { id: 927, },
      { id: 995, },
    ]
  }

  export const endOfLife = {
  formLabelId: [
        { id: 899, },
        { id: 900, },
        { id: 901, },
        { id: 902, },
        { id: 903, },
        { id: 904, },
        { id: 926, },
        { id: 928, },
        { id: 996, },
    ]
  }

export const death = {
  formLabelId: [
    { id: 905, },
    { id: 906, },
    { id: 907, },
    { id: 908, },
    { id: 927, },
    { id: 929, },
    { id: 930, },
    { id: 931, },
    { id: 932, },
    { id: 933, },
    { id: 934, },
    { id: 935, },
    { id: 936, },
    { id: 937, },
    { id: 938, },
    { id: 939, },
    { id: 940, },
    { id: 941, },
    { id: 942, },
    { id: 943, },
    { id: 944, },
    { id: 945, },
    { id: 946, },
    { id: 947, },
    { id: 997, },
   
  ]
}
export const mentalhealth = {
 formLabelId: [
    {id:998,},
    {id:999,},
    {id:1000},
    {id:1002},
    {id:1003}
  ]
}