import React, { useRef, useEffect, useState,useContext } from 'react'
import { message, Modal, Button } from 'antd'
import konsole from '../../network/konsole'
import Services from '../../network/Services'
import { Document, Page, pdfjs } from "react-pdf"
import { UserContext } from '../../App'

const UploadFile = (props) => {
    const inputFileRef = useRef(null)
    const { setLoader } = useContext(UserContext);
    const [filedetails, setfiledetails] = useState([])
    const [currentFileesDetails, setCurrentFilsDetaild] = useState([])

    const [numPages, setNumPages] = useState(null);
    const [fileBase64, setfileBase64] = useState('')
    const [openfile, setopenfile] = useState(false)
    konsole.log("filedetaildfiledetaild", filedetails, currentFileesDetails, fileBase64)

    // let userPrimaryInDetail = JSON.parse(sessionStorage.getItem("userPrimaryInDetail"))
    let memberuserid = props?.memberUserId
    let loggedUserId = sessionStorage.getItem("loggedUserId")

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }


    useEffect(() => {
        let userPrimaryInDetail = JSON.parse(sessionStorage.getItem("userPrimaryInDetail"))
        getFileDocumentCabinetById(props?.memberUserId)
        setfiledetails([])
        konsole.log(props.memberUserId,"propsmemberUserId")
    }, [props?.memberUserId])

    const getFileDocumentCabinetById = (userId) => {
        let jsonobj = {
            "userId": userId,
            // "userId": "9ae2a4d7-aeff-402b-9a32-ea8bc8b06ad3",
            "fileCabinetId": 12,
            "fileTypeId": 58
        }
        setLoader(true)
        let results = Services.getpostFileDocumentCabinetById(jsonobj)
        results.then((res) => {
            setLoader(false)
            konsole.log("getpostFileDocumentCabinetById", res?.data?.data)
            setfiledetails(res?.data?.data?.cabinetFiles)
            setCurrentFilsDetaild(res?.data?.data?.cabinetFiles[0]?.fileTypes[0])
            getfileinfo(res?.data?.data?.cabinetFiles[0]?.fileTypes[0]?.currentFiles[0])

        }).catch((err) => {
            setLoader(false)
            konsole.log("getpostFileDocumentCabinetById", err)
        })
    }
    const getfileinfo = (filedetails) => {
        console.log('filedetails', filedetails)
        setLoader(true)
        let results = Services.GETgetFileUploadDoc(filedetails.fileId)
        results.then((res) => {
            setLoader(false)
            konsole.log("GETgetFileUploadDoc", res)
            let fileDocObj = res?.data?.data;
            let data = 'data:application/pdf;base64,' + fileDocObj.fileInfo.fileDataToByteArray;
            setfileBase64(data)
        }).catch((err) => {
            setLoader(false)
            konsole.log("GETgetFileUploadDoc", err)
        })

    }

    const fileuploadhandlechange = (e) => {
        konsole.log("EEEEEEEEEEEEEE", e)
        let selectFiles = e.target.files
        console.log("selectFiles", selectFiles)
        if (selectFiles.length > 0) {
            let { size, type } = selectFiles[0]
            konsole.log("selectFilesselectFiles", selectFiles, size, type)
            if (type !== "application/pdf") {
                message.error("Only pdf format allowed")
                return;
            }
            if (size > 29360128) {
                message.error("File is too large, file size should be 28MB")
                return;
            }
        }
        Modal.confirm({
            title: 'Confirmation',
            content: 'Are you sure you want to upload this file?',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => { handleFileUpload(selectFiles[0]) },
            onCancel: () => { },
            okButtonProps: { style: { backgroundColor: '#f5222d', borderColor: '#f5222d', } },
            style: { height: '140px', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' },
        });
    }

    const handleFileUpload = (fileobj) => {
        konsole.log("selectFilesselectFilesa", fileobj)
        let fileTypeId = 58
        let fileStatusId = 1
        let fileCategoryId = 12

        let formdata = new FormData()
        formdata.append('file', fileobj)
        formdata.append('UserId', memberuserid)
        formdata.append('UploadedBy', loggedUserId)
        formdata.append('FileTypeId', fileTypeId)
        formdata.append('FileCategoryId', fileCategoryId);
        formdata.append('FileStatusId', fileStatusId)
        setLoader(true)
        let results = Services.postUploadUserDocument(formdata)
        results.then((res) => {
            setLoader(false)
            konsole.log("postUploadUserDocument", res)
            getFileDocumentCabinetById(memberuserid)
        }).catch((err) => {
            setLoader(false)
            konsole.log("postUploadUserDocument", err)
        })
    }

    konsole.log("openfileopenfile", openfile)

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    return (
        <>
         <div className="d-flex justify-content-start">
         <span className='me-5 mt-1'>What about me ?</span>
            {filedetails?.length === 1 ? (
                <>
                    <button className="btn" style={{ color: "#720c20", borderColor: "#720c20" }} onClick={() => setopenfile(true)}> View File</button>
                    <Modal
                    title="File View"
    visible={openfile}
    onCancel={() => setopenfile(false)}
    footer={[
        <Button key="cancel" onClick={() => setopenfile(false)}>
            Cancel
        </Button>,
       
    ]}
>
    <div style={{ height: "57vh",overflow: "scroll" }} className='mt-4'>
        <Document
            file={fileBase64}
            onLoadSuccess={onDocumentLoadSuccess}
            onContextMenu={(e) => e.preventDefault()}
        >
            {Array.from(new Array(numPages ?? 0), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
        </Document>
    </div>
</Modal>

                </>
            ) : (
                <>
                    <button
                        className="btn"
                        style={{ color: "#720c20", borderColor: "#720c20" }}
                        onClick={() => inputFileRef.current.click()}
                    >
                        Upload File
                    </button>
                    <input
                        type="file"
                        className="d-none"
                        ref={inputFileRef}
                        onChange={fileuploadhandlechange}
                    />
                </>
            )}
            </div>
        </>
    );

}

export default UploadFile