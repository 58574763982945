import { Button, Checkbox, Form, Row, Typography, message } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import konsole from "../../network/konsole";
import Services from "../../network/Services";
import AddressInfo from "./common/AddressInfo";
import MemberInfoMaritalStatus from "./common/MemberInfoMaritalStatus";
import PersonalInformation from "./common/PersonalInformation";
import MemberInfoOccupation from "./common/MemberInfoOccupation";
import Toaster from "../Toaster";
import commonLib from "../../control/commonLib";
import MemberContact from "./common/MemberContact";
import moment, { isDate } from "moment";
import { UserContext } from "../../App";
const { Text } = Typography;

function MemberForm(props) {
  const { setLoader } = useContext(UserContext);

  const [form] = Form.useForm();
  const { disable, modalOnDisable, getSavebtn, setGetSaveBtn } =
    useContext(UserContext);
  const [formData, setFormData] = useState();
  const [noofchildConfirm, setnoofchildConfirm] = useState(true);
  const [userAge, setuserAge] = useState(null)
  const [maritalstatusid,setmartalstatusid] = useState(null)
  const[getmemberrelationshipdata,setgetmemberrelationshipdata]=useState(null)
  const userId = props.userId;
  const occupationRef = useRef(null);
  const selectedCitizenshipId = Form.useWatch("citizenshipId", form);
  const loggedInUserId = commonLib.getObjFromStorage("stateObj").userId;
  let primaaryUserId = commonLib.getObjFromStorage(
    "userPrimaryInDetail"
  )?.memberUserId;
  let spouseUserId = commonLib.getObjFromStorage(
    "userPrimaryInDetail"
  )?.spouseUserId;
  konsole.log("props at memberForm", props);
  useEffect(() => {
  
    getMemberInfoByUserId(userId);
    getMemberrelationship(spouseUserId)
    getVeteranByUserIdfunc(userId)
  }, [props.userId]);

  useEffect(() => {
    if (getSavebtn == true) {
      RunMemberAPi();
    }
  }, [getSavebtn]);

  const getMemberInfoByUserId = (userId) => {
   
    let getMemberPersonalpramas = Services.getMemberPersonaldata(userId);
    setLoader(true);
    getMemberPersonalpramas
      .then((res) => {
        // konsole.log("responseaaMemberPersonal", res.data.data.member.dob == null ? "" : res.data.data.member.dob);
        let dob =
          res.data.data.member.dob == null
            ? null
            : moment.utc(res.data.data.member.dob);
        form.setFieldsValue(res.data.data.member);
        form.setFieldsValue({
          dob: dob,
        });
      let  maxDate =moment(res.data.data.member.dob).format("YYYY-MM-DD");
        console.log("dob77", maxDate);

        setuserAge(maxDate)
        // setisVeteran(res.data.data.member.isVeteran);
        // console.log(res.data.data.member.isVeteran,"reuryeuryeu")
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const getVeteranByUserIdfunc = (userId) => {
    const getVeteranByUserparams=  Services.getVeteranByUserId(userId)
    getVeteranByUserparams.then((res)=>{
      form.setFieldsValue({
        isVeteran: true,
      });
      konsole.log(res,"getvetern")
    }).catch((error)=>{
      form.setFieldsValue({
        isVeteran: false,
      });
      konsole.log(error.data.data,"getveternerror")
    })
    }
  const getMemberrelationship =(userid)=>{
    let getmemberrelationshiparamas = Services.getmemberrelationshipservice(userid)
    getmemberrelationshiparamas.then((response)=>{
      konsole.log(response,"responserelation")
      form.setFieldsValue({
        isFiduciary:response?.data?.data?.isFiduciary,
        isBeneficiary:response?.data?.data?.isBeneficiary
      })
      setgetmemberrelationshipdata({
        userMemberId:response.data.data.userMemberId,
        userRltnshipId:response.data.data.userRltnshipId
      })
    }).catch((error)=>{
      konsole.log(error,"error")
    })
  }
  const onFinish = (data) => {
    konsole.log("data member", data.noOfChildren);
    konsole.log("datatatatatat", data.isVeteran);

   
    let value = occupationRef.current.handleOccupationSubmit();
    if (value !== undefined) {
      if (value == "occuption") {
        Toaster.warning("Occupation cannot be empty");
        return;
      }
      // if (value == "ageOfRetirement") {
      //   Toaster.warning("Age of Retirement cannot be empty");
      //   return;
      // }
      if (value == "professBackground") {
        Toaster.warning("Professional Background cannot be empty");
        return;
      }
      // if (value == "isDisabled") {
      //   Toaster.warning("please select Disabled choice.");
      //   return;
      // }
      if (value == "occuption") {
        Toaster.warning("Occupation cannot be empty");
        return;
      }
    }
    if (disable === "true") {
      modalOnDisable();
      return;
    } else {
      let isPrimaryMember = false;
      let memberRelationshipVM = {};

      if (userId === primaaryUserId) {
        isPrimaryMember = true;
        memberRelationshipVM = null;
      } else {
        memberRelationshipVM = {
          primaryUserId: this.state.parentUserId,
          relationshipTypeId: this.state.relationshipTypeId,
          isFiduciary: this.state.isFiduciary,
          isBeneficiary: this.state.isBeneficiary,
          userRltnshipId: this.state.userRltnshipId,
          userMemberId: this.state.userMemberId,
          relationshipType: this.state.relationshipType,
          relativeUserId: this.state.relativeUserId,
        };
      }
      const inputData = {
        userId: userId,
        fName: data.fName,
        mName: data.mName,
        lName: data.lName,
        dob: data.dob,
        nickName: data.nickName,
        genderId: data.genderId,
        maritalStatusId: data.maritalStatusId,
        suffixId: data.suffixId == "null" ? null : data.suffixId,
        birthPlace: data.birthPlace,
        citizenshipId: data.citizenshipId,
        noOfChildren: data.noOfChildren,
        isVeteran: data.isVeteran,
        isPrimaryMember: isPrimaryMember,
        updatedBy: loggedInUserId,
        memberRelationship: memberRelationshipVM,
      };
      setFormData(inputData);
      konsole.log("logmember", inputData);
    }
    if (noofchildConfirm == true) {
      modalOnDisable();
    }
  };

  const RunMemberAPi = () => {
    konsole.log("sendSavebtn", formData);
    const promises = Services.putMemberById(formData);
    promises
      .then((res) => {
        konsole.log("res personal update", res);
        setGetSaveBtn(false);
        konsole.log(maritalstatusid,"maritalstatusid")
       let userPrimaryInDetails= JSON.parse(sessionStorage.getItem("userPrimaryInDetail"))
       let userLoggedInDetails = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
       konsole.log(userPrimaryInDetails,"userPrimaryInDetails")
       userPrimaryInDetails.spouseUserId = res.data.data.member.spouseUserId
       if(userPrimaryInDetails.spouseName == null || userPrimaryInDetails.spouseName == undefined){
       userPrimaryInDetails.spouseName = "Spouse Name"
       }
       if(userPrimaryInDetails.memberUserId == res.data.data.member.userId){
        userPrimaryInDetails.memberName = res.data.data.member.fName +" "+  (res.data.data.member.mName != null ? res.data.data.member.mName : " ") +" "+  res.data.data.member.lName 
        userLoggedInDetails.memberName = res.data.data.member.fName +" "+  (res.data.data.member.mName != null ? res.data.data.member.mName : " ") +" "+  res.data.data.member.lName 
        userPrimaryInDetails.loginUserName = res.data.data.member.fName +" "+  (res.data.data.member.mName != null ? res.data.data.member.mName : " ") +" "+  res.data.data.member.lName 
        userLoggedInDetails.loginUserName = res.data.data.member.fName +" "+  (res.data.data.member.mName != null ? res.data.data.member.mName : " ") +" "+  res.data.data.member.lName 
      }
       sessionStorage.setItem("userPrimaryInDetail",JSON.stringify(userPrimaryInDetails))
       sessionStorage.setItem("userLoggedInDetail",JSON.stringify(userLoggedInDetails))
        if((maritalstatusid == 1 || form?.getFieldsValue()?.maritalStatusId == 1)&& (form?.getFieldValue()?.isFiduciary != undefined ||form?.getFieldValue()?.isBeneficiary != undefined)){
          postmemberrelationfunc(res.data.data.member.spouseUserId)
        }else{
          Toaster.success("Saved Succesfully");
          setTimeout(() => {
            window.location.replace("/Dashboard");
          }, 500);
        }
      })
      .catch((err) => {
        konsole.log("error", err);
      })
      .finally(() => {
        konsole.log("finish");
        setGetSaveBtn(false);
      });
  };
  const userAges =(data)=>{
    console.log("famoush",data)
    if(userAge === null || data !== userAge ){
     setuserAge(data)
    }
   
  
  }
  const postmemberrelationfunc = (spouseUserId)=>{
    let method = getmemberrelationshipdata !=null ? "PUT" : "POST" 
    let dataobj = getmemberrelationshipdata !=null ? {
      "primaryUserId": spouseUserId ,
      "relationshipTypeId": 1,
      "isFiduciary": form?.getFieldValue()?.isFiduciary,
      "isBeneficiary": form?.getFieldValue()?.isBeneficiary,
      "relativeUserId": spouseUserId,
      "userRltnshipId":getmemberrelationshipdata.userRltnshipId,
      "userMemberId":getmemberrelationshipdata.userMemberId
    } : {
      "primaryUserId": spouseUserId,
      "relationshipTypeId": 1,
      "isFiduciary": form?.getFieldValue()?.isFiduciary != undefined ? form?.getFieldValue()?.isFiduciary : false,
      "isBeneficiary": form?.getFieldValue()?.isBeneficiary != undefined ? form?.getFieldValue()?.isBeneficiary:false,
      "relativeUserId": spouseUserId,
    }
    let postmemberrelationshipparams = Services.postmemberrelationshipservice(dataobj,method)
    postmemberrelationshipparams.then((response)=>{
      konsole.log(response,"responserelation")
      Toaster.success("Saved Succesfully");
      setTimeout(() => {
        window.location.replace("/Dashboard");
      }, 500);
    }).catch((error)=>{
      konsole.log(error,"error")
    })
  }
konsole.log(maritalstatusid,"setmartalstatusid")
  return (
    <div>
      <Form
        name="memberForm"
        form={form}
        onFinish={onFinish}
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
      >
        <PersonalInformation
          userId={userId}
          form={form}
          selectedCitizenshipId={selectedCitizenshipId}
          userAges ={userAges}
        />
        <AddressInfo   userAges ={userAges} userId={userId} />
        <MemberContact userId={userId} />
        <MemberInfoMaritalStatus
          form={form}
          setnoofchildConfirm={setnoofchildConfirm}
          setmartalstatusid={setmartalstatusid}
        />
        <MemberInfoOccupation userId={userId} userAge={userAge} ref={occupationRef} />
        {(userId== primaaryUserId && ( maritalstatusid == 1 || form.getFieldsValue().maritalStatusId == 1)) && (
          <div className="Info me-2 ms-2">
            <Text strong={true} type="danger" style={{ color: "#76272B" }}>
              Information
            </Text>
            <Form.Item
              name="isFiduciary"
              valuePropName="checked"
              className="p-0 m-0"
            >
              <Checkbox defaultChecked={form?.getFieldsValue()?.isFiduciary}>Fiduciary for spouse</Checkbox>
            </Form.Item>
            <Form.Item name="isBeneficiary" valuePropName="checked">
              <Checkbox valuePropName="checked" defaultChecked={form?.getFieldsValue()?.isBeneficiary}>Beneficiary of spouse</Checkbox>
            </Form.Item>
          </div>
        )}
        <Row className=" w-100 p-2 justify-content-between">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className=" mb-2"
              style={{ borderRadius: 5 }}
            >
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            {/* <Button className="ms-2 mb-2"> */}
            <Link to="/Dashboard" className="text-primery">
              <Button
                className="fw-bold mb-2"
                style={{
                  color: "#720C20",
                  borderColor: "#720C20",
                  borderRadius: 5,
                }}
              >
                Cancel
              </Button>
            </Link>

            {/* </Button> */}
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}

export default MemberForm;
