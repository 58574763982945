import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
  Dropdown,
  Menu,
  message,
  Space,
  Radio,
  Checkbox,
  List,
  AutoComplete,
} from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useRef, useContext } from "react";

// import FormItem from "antd/lib/form/FormItem";
import Address from "../address";

import Services from "../../network/Services";
import moment from "moment";
import konsole from "../../network/konsole";
import EditContactInfo from "./EditContactInfo";
import Toaster from "../Toaster";
import commonLib from "../../control/commonLib";
import { UserContext } from "../../App";
import { useNavigate } from "react-router";
import DatePickerComponent from "../DatePickerComponent";
import Other from "../Other";

function AddFidBeneficiaries(props) {
  const { disable, modalOnDisable, getSavebtn, setGetSaveBtn } =
    useContext(UserContext);
  konsole.log("propsuserid", props);
  const navigate = useNavigate();
  const [updateAddMember, setUpdateAddMember] = useState({
    fName: "",
    mName: "",
    lName: "",
    nickName: "",
    dob: "",
    isVetean: "",
    isPrimaryMember: "",
    suffix: "",
    citizenship: "",
    gender: "",
    placeofBirth: "",
    primaryUserId: "",
    relation: "",
    isFiduciary: "",
    isBeneficiary: "",
    isEmergencyContact: "",
    address: "",
    zipcode: "",
    county: "",
    country: "",
    city: "",
    state: "",
  });

  const [useridfordelete, setuserIdfordelete] = useState("");
  useEffect(() => {
    if (
      props.userId !== "" &&
      props.userId !== null &&
      props.userId !== undefined
    ) {
      setuserIdfordelete(props.userId);
    }
  }, []);

  useEffect(() => {
    if (getSavebtn == true) {
      runAPIAddFidBeneficiarise();
    }
  }, [getSavebtn]);

  const [form] = Form.useForm();

  const { Option } = Select;
  const { Title } = Typography;
  const addressRef = useRef(null);
  const loggedInUser =
    commonLib.getObjFromStorage("stateObj").userId || "development";

  const [group, setGroup] = useState();
  const [selectedIndex, setSelectedIndex] = useState(null);

  const [suffixname, setSuffixname] = useState([]);
  const [genders, setGenders] = useState([]);
  const [citizenshipType, setcitizenshipType] = useState([]);
  const [relationshiptype, setRelationshipType] = useState([]);

  const [addbeneficiries, showaddbeneficiries] = useState(false);
  const [addfeduciries, showaddfeduciries] = useState(false);
  const [contactinfo, setContactInfo] = useState([]);
  const [refreshh, setrefresh] = useState();
  const [contactype, setContactType] = useState([]);


  const [contactype1, setContactType1] = useState([]);
  const [deleteState, setDeleteState] = useState(false);




  const [editAddress, setEditAddress] = useState([]);
  const [userMemberId, setuserMemberId] = useState("");
  const [userRltnshipId, setuserRltnshipId] = useState("");
  const [addressIdupdate, setAddressIdUpdate] = useState("");
  const [addresstypeid, setAddressTYpeId] = useState("");
  const [method, setMethod] = useState("POST");
  const [emailsmobiles, setEmailsMobiles] = useState([]);
  const [relationTypeLabel, setRelationTypeLabel] = useState("");
  const [relationTypeIdspouse, setRelationTypeIdspouse] = useState(null);
  const [relationshipid,setrelationshipid] = useState('')
  const [illnesscheck, setillnesscheck] = useState(false);
  const [eofcheck, seteofcheck] = useState(false);
  const [deathcheck, setdeathcheck] = useState(false);
  const [resillnessarr, setresillnessarr] = useState([]);
  const [reseolsarr, setreseolarr] = useState([]);
  const [resdeatharr, setresdeatharr] = useState([]);
  const { setLoader } = useContext(UserContext);
  const [getuserdata, setgetUserdata] = useState();
  const [currAddress, setcurrAddress] = useState();
  const [commTypevalue, setCommTypevalue] = useState("1");
  const [errorMsg, seterrorMsg] = useState("");
  const [addNewListSelectData, setNewListSelectData] = useState(false);
  const [newContactListState, setNewContactListSelectData] = useState([]);
  const [stateOne, setStateOne] = useState([]);
  const [render, setrender] = useState(false);
  const [countryCodeSelectState, setCountryCodeSelectState] = useState();
  const [addressfiledError, setAddressfieldError] = useState(false);
  const [showrelationforspouse,setshowrelationforspouse] = useState(false)
  const primaryrelationref = useRef(null)
  const spouserelationref = useRef(null)

  konsole.log("countryCodeSelectState", contactinfo);

  useEffect(() => {
    getNameSuffixes();
    getGendersdata();
    getcitizenship();
    getRelationshiptype();
    contactType();

    if (props.type == "EditBeneficiries" || props.type == "EditFiduciary") {
      getaddmberDetails();
      getaddress();
      setMethod("PUT");
      getNotifycheck();

      // getcontactwithother();
    }
  }, []);

  useEffect(()=>{
    getRelationshiptype();
  },[getuserdata])

  const primaryUserId = commonLib.getObjFromStorage("stateObj").userId;
  konsole.log("primaryUserIdprimaryUserId", primaryUserId);
  let regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
  let emailregex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const userPrimaryInDetail = commonLib.getObjFromStorage(
    "userPrimaryInDetail"
  ).memberUserId;
  
  const spouseUserId =  commonLib.getObjFromStorage("userPrimaryInDetail").spouseUserId;

  // konsole.log("zzzz", userPrimaryInDetail);

  const getaddress = () => {
    if(props.userId !== undefined && props.userId !== null){
      setLoader(true);
      let promise = Services.getaddressuserid(props.userId);
      promise
        .then((res) => {
          konsole.log("addressres", res.data.data);
  
          konsole.log("relationshipid", res);
          setAddressIdUpdate(res.data.data.addresses[0].addressId);
          setAddressTYpeId(res.data.data.addresses[0].addressTypeId);
          let resadd = res.data.data.addresses[0];
          setcurrAddress(resadd);
          setEditAddress(res.data.data.addresses);
          konsole.log("addressressss", resadd);
  
          form.setFieldsValue({
            city: resadd.city,
            address: resadd.addressLine1,
            state: resadd.state,
            county: resadd.county,
            country: resadd.country,
            zipcode: resadd.zipcode,
            address: resadd.addressLine1,
          });
          setLoader(false);
        })
        .catch((error) => {
          konsole.log("Errrr", error);
          setLoader(false);
        });

    }
   
  };

  const contactType = () => {
    let promise = Services.getContactType();
    promise
      .then((res) => {
        konsole.log("contacttypejjj", res.data.data);
        setContactType(res.data.data);
        setNewContactListSelectData(res.data.data);
        if (props.type == "EditBeneficiries" || props.type == "EditFiduciary") {
          getcontactwithother(res.data.data);
          setContactType1(res.data.data)
          setLoader(false)
          setDeleteState(false)
        }
      })
      .catch((err) => {
        konsole.log("Errcontacttype", err);
      });
  };
  konsole.log("newContactListState", newContactListState);

  const getcontactwithother = (ContactTypeList) => {
    konsole.log("ContactList",ContactTypeList)
    let MemberContactpromise = Services.getcontactwithother(props.userId);
    MemberContactpromise.then((res) => {
      let contactListArray = [];
      let emailMobiles = [];
      konsole.log("konsole res contact", res.data.data);
      const contactListRes = res.data.data.contact;

      for (let type of ContactTypeList) {
        const contactType = type.value;
        const contactName = type.label;
        konsole.log("konsole res type", type);
        const mobiles = contactListRes.mobiles.filter((res) => {
          return res.contactTypeId == contactType;
        });
        const emails = contactListRes.emails.filter((res) => {
          return res.contactTypeId == contactType;
        });
        if (mobiles.length > 0 || emails.length > 0) {
          const mobileNo =
            mobiles.length > 0 ? mobiles[0].mobileNo.slice(-10) : "";
          const countryCode =
            mobiles.length > 0
              ? mobiles[0].mobileNo.substring(
                  0,
                  mobiles[0].mobileNo.length - 10
                )
              : "";
          const mobilrContactId =
            mobiles.length > 0 ? mobiles[0].contactId : "";
          const mobileCommtypeId =
            mobiles.length > 0 ? mobiles[0].commTypeId : "";
          const emailContactId = emails.length > 0 ? emails[0].contactId : "";
          const emailId = emails.length > 0 ? emails[0].emailId : "";
          contactListArray.push(
            commonLib.getContactObj(
              // mobilrCommtypeId,
              contactType,
              contactName,
              mobilrContactId,
              mobileNo,
              loggedInUser,
              countryCode,
              mobileCommtypeId,
              emailContactId,
              emailId
            )
          );
          emailMobiles.push(
            commonLib.getContactObj(
              contactType,
              contactName,
              mobilrContactId,
              mobileNo,
              loggedInUser,
              countryCode,
              mobileCommtypeId,
              emailContactId,
              emailId
            )
          );
        }
      }
      // setDeleteState(false)

      setContactInfo(contactListArray);
      setEmailsMobiles(emailMobiles);
      konsole.log(contactListArray, emailMobiles, "emailMobiles");
      // setSelectedIndex(1);
    }).catch((err) => {
      konsole.log("error", err);
    });
  };
  konsole.log("deleteState", deleteState)
  if (deleteState == true){

    contactType()
    setLoader(true)
  }
  const saveGroup = (value) => {
    konsole.log("valueContactTypeId", value);

    let jsonObj = {
      ContactTypeId: null,
      name: "",
      Type: "add",
      error: "",
      mobiles: {
        ContactTypeId: null,
        mobileNo: "",
        createdBy: loggedInUser,
        countryCode: "",
        // mobileOther: {
        //   othersName: this.state.emailOthersName,
        //   othersCategoryId: 6,
        //   isActive: true,
        //   createdBy: this.state.loggedInUser,
        // }
      },
      emailId: {
        ContactTypeId: null,
        email: "",
        createdBy: loggedInUser,
      },
    };

    jsonObj["ContactTypeId"] = value;
    jsonObj["name"] =
      value == 1
        ? "Primary Contact"
        : value == 2
        ? "Secondary Contact"
        : value == 3
        ? "Work Contact"
        : value == 4
        ? "Home Contact"
        : value == 5
        ? "Fax Contact"
        : "Other";
    jsonObj["mobiles"]["ContactTypeId"] = value;
    jsonObj["emailId"]["ContactTypeId"] = value;

    konsole.log("json", jsonObj);
    konsole.log("contact", contactinfo);

    setGroup(value);
    konsole.log("id", value);
    setContactInfo([...contactinfo, jsonObj]);
  };

  konsole.log("form change", form);

  const getNameSuffixes = () => {
    let getnamesuffixesApiCall = Services.getnamesuffixes();
    getnamesuffixesApiCall
      .then((res) => {
        konsole.log("resnamesuffixes", res.data.data);
        setSuffixname(res.data.data);
      })
      .catch((error) => {
        konsole.log("errnamesuffixes", error);
      });
  };

  const getGendersdata = () => {
    let getgendersApiCall = Services.getgenders();
    getgendersApiCall
      .then((res) => {
        konsole.log("resgeders", res.data.data);
        setGenders(res.data.data);
      })
      .catch((error) => {
        konsole.log("errgenders", error);
      });
  };

  const getcitizenship = () => {
    let getcitizenshiptypeApiCall = Services.getcitizemshiptype();
    getcitizenshiptypeApiCall
      .then((res) => {
        konsole.log("rescitizemshiptype", res.data.data);
        setcitizenshipType(res.data.data);
      })
      .catch((error) => {
        konsole.log("errcoitizenshiptype", error);
      });
  };

  const getRelationshiptype = () => {
    console.log("hfkjhkgryegu",props?.type)
    let getrelationshiptypeApiCall = Services.getrelationshiptype()
      .then((res) => {
         let filterData = res?.data?.data?.filter((ele)=>{
          return ele?.value !== "12" &&  ele?.value !== "4"
         })
        console.log("resrelationshiptype", res.data.data);
        if(props?.type == "EditBeneficiries" || props?.type == "EditFiduciary"){
          newDropdown(filterData)
        }else{
          let filterDatas = res?.data?.data?.filter((ele)=>{
            return ele?.value !== "12" &&  ele?.value !== "4" && ele?.value !== "1" && ele?.value !== "2" && ele?.value !== "5" && ele?.value !== "6"  && ele?.value !== "9" && ele?.value !== "10" && ele?.value !== "13" && ele?.value !== "16" && ele?.value !== "17" && ele?.value !== "24" & ele?.value !== "25" && ele?.value !== "26" && ele?.value !== "27" && ele?.value !== "28" && ele?.value !== "29" &&  ele?.value !== "44" && ele?.value !== "47" &&  ele?.value !== "48" && ele?.value !== "49" && ele?.value !== "50" })
          setRelationshipType(filterDatas);
        }
        
      })
      .catch((error) => {
        konsole.log("errorrelationshiptype", error);
      });
  };

  // let loggedUser = '5a58b3dd-27d8-4db4-aec7-05ebc7566548';

  konsole.log("colasd", contactinfo);

  const onFinish = (items) => {
    console.log("itemsitems", items);
    let address = items.address;

    let city = items.city;
    let county = items.county;
    let country = items.country;
    let fName = items.fName;
    let mName = items.mName;
    let lName = items.lName;
    let nickName = items.nickName;
    let dob = items?.dob;

    let isVetean = false;
    let isPrimaryMember = false;

    let suffix = items.suffix;
    konsole.log("suff", suffix);
    let citizenship =
      items.citizenship == undefined
        ? citizenshipType[186]?.value
        : items.citizenship;
    let gender = items.gender;
    let placeofBirth = items.placeofBirth;
    let relation = items.relation;
    let relationwithspouse = items.relationwithspouse;

    let isFiduciary = false;
    let isBeneficiary = false;

    if (items.radioisfiduciries == true) {
      isFiduciary = true;
    } else if (items.radioisBenificiries == true) {
      isBeneficiary = true;
    }

    if (props.type == "AddFiduciries" || props.type == "EditFiduciary") {
      isFiduciary = true;
    } else if (
      props.type == "AddBeneficiaries" ||
      props.type == "EditBeneficiries"
    ) {
      isBeneficiary = true;
    }

    let relativeUserId = userPrimaryInDetail;

    let isEmergencyContact = false;

    // let radioemergencycontact = items.radioemergencycontact
    if (items.radioemergencycontact == true) {
      isEmergencyContact = true;
    }

    let state = items.state;

    let zipcode = items.zipcode;

    let suite = items.suite;

    let streetAddress = items.streetAddress;

    let createdBy = primaryUserId;

    for (let index = 0; index < contactinfo.length; index++) {
      if (
        contactinfo[index].emailId.email == "" &&
        contactinfo[index].mobiles.countryCode == "" &&
        contactinfo[index].mobiles.mobileNo == ""
      ) {
        contactinfo[index].error = "Please enter the contact detail.";
        Toaster.warning("please enter the contact detail.");
        seterrorMsg("Please enter the contact detail.");
        setSelectedIndex(index);
        return;
      } else if (!regex.test(contactinfo[index].emailId.email)) {
        // contactinfo[index].error = "Please enter valid Email";
        Toaster.warning("Please enter the valid contact details.");
        setSelectedIndex(index);

        return;
      }
      // else if (
      //   contactinfo[index].mobiles.mobileNo !== "" &&
      //   contactinfo[index].mobiles.countryCode == ""
      // ) {
      //   contactinfo[index].error = "Please choose the country code.";
      //   // Toaster.warning("please choose the country code.");
      //   setSelectedIndex(index);
      //   seterrorMsg("Please choose the country code.")
      //   return;
      // }
      else if (
        contactinfo[index].mobiles.mobileNo == "" &&
        contactinfo[index].mobiles.countryCode !== ""
      ) {
        contactinfo[index].error = "Please enter the mobile no.";
        seterrorMsg("Please enter the mobile no.");
        // Toaster.warning("please enter the mobile no.");
        setSelectedIndex(index);
        return;
      }
    }

    setUpdateAddMember({
      fName: fName,
      mName: mName,
      lName: lName,
      nickName: nickName,
      dob: dob,
      isVetean: isVetean,
      isPrimaryMember: isPrimaryMember,
      suffix: suffix,
      citizenship: citizenship,
      gender: gender,
      placeofBirth: placeofBirth,
      primaryUserId: primaryUserId,
      relation: relation,
      relationwithspouse: relationwithspouse,
      isFiduciary: isFiduciary,
      isBeneficiary: isBeneficiary,
      isEmergencyContact: isEmergencyContact,
      address: address,
      zipcode: zipcode,
      county: county,
      country: country,
      city: city,
      state: state,
      suite,
      streetAddress,
    });

    // commonLib.calculate_age(form.getFieldValue("dob")?.format("MM-DD-YYYY")) >= 18 ?
    // : Toaster.error("Age cannot be less than 18")
    if (address != undefined) {
      form.validateFields().then((err, values) => {
        if (
          err.address !== "" &&
          err.streetAddress !== "" &&
          err.city !== "" &&
          err.state !== "" &&
          err.zipcode !== "" &&
          err.country !== ""
        ) {
          setAddressfieldError(false);
          return modalOnDisable();
        } else {
          setAddressfieldError(true);
        }
      });
    } else {
      setAddressfieldError(false);
      return modalOnDisable();
    }

    konsole.log("Addressssave", address, zipcode, county, country, city, state);
  };

  const runAPIAddFidBeneficiarise = () => {
    konsole.log("updateAddMember", JSON.stringify(updateAddMember));
    props.type == "EditFiduciary" || props.type == "EditBeneficiries"
      ? callupdateaddmember(
          updateAddMember.fName,
          updateAddMember.mName,
          updateAddMember.lName,
          updateAddMember.nickName,
          updateAddMember.dob,
          updateAddMember.isVetean,
          updateAddMember.isPrimaryMember,
          updateAddMember.suffix,
          updateAddMember.citizenship,
          updateAddMember.gender,
          updateAddMember.placeofBirth,
          updateAddMember.relation,
          updateAddMember.isFiduciary,
          updateAddMember.isBeneficiary,
          updateAddMember.isEmergencyContact,
          updateAddMember.address,
          updateAddMember.zipcode,
          updateAddMember.country,
          updateAddMember.city,
          updateAddMember.state
        )
      : callAddMemberApi(
          updateAddMember.fName,
          updateAddMember.mName,
          updateAddMember.lName,
          updateAddMember.nickName,
          updateAddMember.dob,
          updateAddMember.isVetean,
          updateAddMember.isPrimaryMember,
          updateAddMember.suffix,
          updateAddMember.citizenship,
          updateAddMember.gender,
          updateAddMember.placeofBirth,
          updateAddMember.primaryUserId,
          updateAddMember.relation,
          updateAddMember.isFiduciary,
          updateAddMember.isBeneficiary,
          props.userId,
          updateAddMember.isEmergencyContact,
          updateAddMember.createdBy,
          updateAddMember.address,
          updateAddMember.zipcode,
          updateAddMember.county,
          updateAddMember.suite,
          updateAddMember.city,
          updateAddMember.state,
          updateAddMember.country,
          updateAddMember.streetAddress
        );
  };

  konsole.log("props.userIdprops.userId", props.userId);

  const callupdateaddmember = (
    fName,
    mName,
    lName,
    nickName,
    dob,
    isVetean,
    isPrimaryMember,
    suffix,
    citizenship,
    gender,
    placeofBirth,
    relation,
    isFiduciary,
    isBeneficiary,
    isEmergencyContact,
    address,
    zipcode,
    country,
    city,
    state
  ) => {
    let promise = Services.updateaddmemberuserid(
      fName,
      mName,
      lName,
      nickName,
      dob,
      isVetean,
      isPrimaryMember,
      suffix,
      citizenship,
      gender,
      placeofBirth,
      relation,
      isFiduciary,
      isBeneficiary,
      isEmergencyContact,
      props.userId,
      primaryUserId,
      userRltnshipId,
      userMemberId,
      relationTypeLabel,
      relationTypeIdspouse,
      getuserdata.memberRelationship.relativeUserId
    );
    console.log("relationTypeIdspouse66",relationTypeIdspouse)
    promise
      .then((res) => {
        console.log("updateAddMemberRES", JSON.stringify(res.data.data));
        console.log("postmemberdata", res.data.data.member,);

        const difference = (obj1, obj2) => {
          let keyFound = [];
          let data;
          let dataArr = [];
          Object.keys(obj1).forEach((key) => {
            if (obj1[key] !== obj2[key]) {
              keyFound.push(key);
              konsole.log(obj1[key]?.relationshipType, "jfirjfi");
              if (key != "updatedOn" && key != "updatedBy") {
                if (
                  key == "memberRelationship" &&
                  obj2[key]?.relationshipType != obj1[key]?.relationshipType
                ) {
                  data =
                    "Relationship" +
                    ": From " +
                    obj2[key]?.relationshipType +
                    " to " +
                    obj1[key]?.relationshipType;
                  dataArr.push(data);
                } else if (
                  key != "memberRelationship" &&
                  key != "genderId" &&
                  key != "citizenshipId" &&
                  key != "suffixId"
                ) {
                  data =
                    (key == "fName"
                      ? "First Name"
                      : key == "mName"
                      ? "Middle Name"
                      : key == "lName"
                      ? "Last Name"
                      : key == "nickName"
                      ? "Nick Name"
                      : key == "dob"
                      ? "Dob"
                      : key == "birthPlace"
                      ? "BirthPlace"
                      : "") +
                    (obj2[key] == null
                      ? ": Added " + obj1[key]
                      : ": From " + obj2[key] + " to " + obj1[key]);
                  dataArr.push(data);
                } else if (key == "genderId") {
                  let value1 = genders[obj1[key] - 1]?.label;
                  let value2 = genders[obj2[key] - 1]?.label;
                  data =
                    "Gender" +
                    (obj2[key] == null
                      ? ": Added " + value1
                      : ": From " + value2 + " to " + value1);
                  dataArr.push(data);
                } else if (key == "citizenshipId") {
                  let value1 = citizenshipType[obj1[key] - 1]?.label;
                  let value2 = citizenshipType[obj2[key] - 1]?.label;
                  data =
                    "Citizenship" +
                    (obj2[key] == null
                      ? ": Added " + value1
                      : ": From " + value2 + " to " + value1);
                  dataArr.push(data);
                } else if (key == "suffixId") {
                  let value1 = suffixname[obj1[key] - 1]?.label;
                  let value2 = suffixname[obj2[key] - 1]?.label;
                  data =
                    "Suffix" +
                    (obj2[key] == null
                      ? ": Added " + value1
                      : ": From " + value2 + " to " + value1);
                  dataArr.push(data);
                }
              }
            }
          });
          return dataArr;
        };

        let addresdiff = [];
        konsole.log(currAddress, "currAddresscurrAddress");
        if (currAddress != undefined && currAddress.addressLine1 != address) {
          let data =
            "Address" +
            " : From " +
            currAddress.addressLine1 +
            " to " +
            address;
          addresdiff.push(data);
        }
        if (currAddress == undefined && address != undefined) {
          let data = " Address: Added " + address;
          addresdiff.push(data);
        }
        let contactarr = [];
        if (contactinfo.length != 0 && emailsmobiles.length == 0) {
          contactinfo.map((contactitem) => {
            let data = "Email: " + contactitem.emailId.email;
            let data1 = "Cell Number: " + contactitem.mobiles.countryCode;
            let data2 = contactitem.mobiles.mobileNo;
            let datafinal =
              contactitem.name +
              " " +
              ": Added " +
              data +
              " <br> " +
              data1 +
              " " +
              data2;
            contactarr.push(datafinal);
          });
        } else if (contactinfo.length != 0 && emailsmobiles.length != 0) {
          for (let i = 0; i < emailsmobiles.length; i++) {
            if (
              contactinfo[i].mobiles.mobileNo !=
              emailsmobiles[i].mobiles.mobileNo
            ) {
              let datafinal =
                contactinfo[i].name +
                " Contact " +
                ": " +
                "Updated from " +
                emailsmobiles[i].mobiles.mobileNo +
                " to " +
                contactinfo[i].mobiles.mobileNo;
              contactarr.push(datafinal);
              console.log(datafinal, "datefinal");
            }
            if (
              contactinfo[i].emailId.email != emailsmobiles[i].emailId.email
            ) {
              let datafinal =
                contactinfo[i].name +
                " Contact " +
                ": " +
                "Updated from " +
                emailsmobiles[i].emailId.email +
                " to " +
                contactinfo[i].emailId.email;
              contactarr.push(datafinal);
              konsole.log(datafinal, "datefinal");
            }
          }
        }

        konsole.log(contactype, "contactypecontactype");
        let final = [
          ...difference(res.data.data.member, getuserdata),
          ...addresdiff,
          ...contactarr,
        ].flat();
        konsole.log(props.type, "props.typeprops.type");
        if (props.type == "EditFiduciary") {
          Toaster.success("Fiduciary updated successfully");
          setTimeout(() => {
            props.Addfiduser(true);
          props.Fiduserdata(final);
          putNotifyFun(props.userId);
          }, 1000);
          
        } else if (props.type == "EditBeneficiries") {
          Toaster.success("Beneficiaries updated successfully");
          setTimeout(() => {
            putNotifyFun(props.userId);
          props.Addbenuser(true);
          props.benuserdata(final);
          }, 1000);
         
          
        }
        if(res.data.data.member.memberRelationship.relationshipTypeId == 999999){
          primaryrelationref.current.saveHandleOther(res.data.data.member.memberId)
        }
        if(res.data.data.member.memberRelationship.rltnTypeWithSpouseId == 999999){
          spouserelationref.current.saveHandleOther(res.data.data.member.memberId)
        }
        setGetSaveBtn(false);
        if (editAddress.length == 0) {
          if (
            form.getFieldsValue()?.address !== undefined &&
            form.getFieldsValue()?.address !== null &&
            form.getFieldsValue()?.address !== ""
          ) {
            calladdressapi(
              props.userId,
              form.getFieldsValue()?.address,
              form.getFieldsValue()?.zipcode,
              form.getFieldsValue()?.county,
              form.getFieldsValue()?.country,
              form.getFieldsValue()?.city,
              form.getFieldsValue()?.state
            );
          } else {
            updatecontactwithother(props.userId);
          }
        } else {
          callupdateaddress(
            form.getFieldsValue()?.address,
            form.getFieldsValue()?.zipcode,
            form.getFieldsValue()?.county,
            form.getFieldsValue()?.country,
            form.getFieldsValue()?.city,
            form.getFieldsValue()?.state
          );
        }

        // if(getaddress !== null && getaddress !== undefined && getaddress !== "" ){
        // }else{
        // callupdateaddress(address, zipcode, country, city, state);
        //   calladdressapi(props.userId, address, zipcode, country, city, state)
        // }

        // } else{
        //   updatecontactwithother(props.userId)
        // }

        setGetSaveBtn(false);
        // refresh()
      })
      .catch((err) => {
        Toaster.error(err.response);
        konsole.log(err, "errrr");
      })
      .finally(() => {
        setGetSaveBtn(false);
      });
  };

  const callupdateaddress = (
    address,
    zipcode,
    county,
    country,
    city,
    state
  ) => {
    let promise = Services.updateaddress(
      props.userId,
      address,
      zipcode,
      city,
      state,
      county,
      country,
      addresstypeid,
      primaryUserId,
      addressIdupdate,
      true
    );
    promise
      .then((res) => {
        konsole.log("response", res);
        setGetSaveBtn(false);
        // addContactWithOther(props.userId);
        updatecontactwithother(props.userId);
      })
      .catch((err) => {
        konsole.log("Errupdateaddress", err);
      })
      .finally(() => {
        setGetSaveBtn(false);
      });
  };

  const updatecontactwithother = (userId) => {
    let mobiles = [];
    let emails = [];

    const emailsmobil = emailsmobiles;
    if (contactinfo.length == 0) {
      //refresh();
      // window.location.replace("/Fiduciaries");
      return;
    }
    konsole.log("mobilessss3 ", emailsmobiles, contactinfo);
    for (let i = 0; i < emailsmobil.length; i++) {
      const Objmobiles = emailsmobil[i].mobiles;
      const Objemails = emailsmobil[i].emailId;

      for (let i = 0; i < contactinfo.length; i++) {
        const mobilesObj = contactinfo[i].mobiles;
        const emailsObj = contactinfo[i].emailId;
        konsole.log("emailObjemailObj", emailsObj, mobilesObj);
        if (
          mobilesObj.mobileNo !== "" &&
          mobilesObj.mobileNo !== Objmobiles.mobileNo
        ) {
          mobiles = [
            ...mobiles,
            {
              // ...mobilesObj,
              contactTypeId: mobilesObj.ContactTypeId,
              updatedBy: mobilesObj.createdBy,
              mobileNo: mobilesObj.countryCode + mobilesObj.mobileNo,
              contactId: mobilesObj.contactId,
            },
          ];
        }
        if (emailsObj.email !== "" && emailsObj.email !== Objemails.email) {
          emails = [
            ...emails,
            {
              contactTypeId: emailsObj.ContactTypeId,
              emailId: emailsObj.email,
              updatedBy: emailsObj.createdBy,
              contactId: emailsObj.contactId,
            },
          ];
        }
      }
    }

    konsole.log("mobilessss2 ", mobiles, contactinfo);

    if (contactinfo.length != 0) {
      const mobilesObj = contactinfo[0].mobiles;
      const emailsObj = contactinfo[0].emailId;
      mobiles.push({
        contactTypeId: mobilesObj.ContactTypeId,
        updatedBy: mobilesObj.createdBy,
        commTypeId: commTypevalue,
        mobileNo: mobilesObj.countryCode + mobilesObj.mobileNo,
        contactId: mobilesObj.contactId,
      });
      emails.push({
        contactTypeId: emailsObj.ContactTypeId,
        emailId: emailsObj.email,
        updatedBy: emailsObj.createdBy,
        contactId: emailsObj.contactId,
      });
    }
    konsole.log(mobiles, emails, "mobiles");
    let contactPutData = {
      userId: userId,
      activityTypeId: 4,

      contact: {
        mobiles: mobiles.length !== 0 ? mobiles : null,
        emails: emails.length !== 0 ? emails : null,
      },
    };
    konsole.log("contactpostData", contactPutData);
    const promises = Services.putContactWithOther(contactPutData);

    promises
      .then((res) => {
        // //refresh();
        konsole.log("response contact with other", res);
        konsole.log("zzzz", res.data.data.userId);
        let filterdataforemails = contactPutData.contact?.emails?.filter(
          (value) => {
            return value.contactId == undefined;
          }
        );
        let filterdataformobiles = contactPutData.contact?.mobiles?.filter(
          (value) => {
            return value.contactId == undefined;
          }
        );

        if (
          (filterdataformobiles !== "" &&
            filterdataformobiles !== null &&
            filterdataformobiles !== undefined) ||
          (filterdataforemails !== "" &&
            filterdataforemails !== null &&
            filterdataforemails !== undefined)
        ) {
          addcontactwithotherupdate(
            contactPutData,
            filterdataforemails,
            filterdataformobiles
          );
        } else {
          refresh();
        }
        // //refresh();
        // CallApi(res.data.data.userId)

        // if (props.type == "AddFiduciries" || props.type == "EditFiduciary") {
        //   window.location.replace("/Fiduciaries");
        // } else if (
        //   props.type == "AddBeneficiaries" ||
        //   props.type == "EditBeneficiries"
        // ) {
        //   window.location.replace("/beneficiaries");
        // }
      })
      .catch((err) => {
        konsole.log("error contact with other", err);
        let mobiles1 = [];
        let emails1 = [];
        if (contactinfo.length != 0) {
          const mobilesObj = contactinfo[0].mobiles;
          const emailsObj = contactinfo[0].emailId;
          mobiles1.push({
            contactTypeId: mobilesObj.ContactTypeId,
            createdBy: mobilesObj.createdBy,
            commTypeId: commTypevalue,
            mobileNo: mobilesObj.countryCode + mobilesObj.mobileNo,
            contactId: mobilesObj.contactId,
          });
          emails1.push({
            contactTypeId: emailsObj.ContactTypeId,
            emailId: emailsObj.email,
            createdBy: emailsObj.createdBy,
            contactId: emailsObj.contactId,
          });
        }
        let contactData = {
          userId: userId,
          activityTypeId: 4,

          contact: {
            mobiles: mobiles1,
            emails: emails1,
          },
        };
        konsole.log(
          contactData,
          contactPutData,
          "cont6767ctPutDatacontactPutData"
        );
        let filterdataforemails = contactData.contact?.emails?.filter(
          (value) => {
            return value.contactId == undefined;
          }
        );
        let filterdataformobiles = contactData.contact?.mobiles?.filter(
          (value) => {
            return value.contactId == undefined;
          }
        );

        if (
          (filterdataformobiles !== "" &&
            filterdataformobiles !== null &&
            filterdataformobiles !== undefined) ||
          (filterdataforemails !== "" &&
            filterdataforemails !== null &&
            filterdataforemails !== undefined)
        ) {
          const promises = Services.postContactWithOther(contactData);
          promises
            .then((res) => {
              konsole.log("responsepost", res);
              setLoader(false);

              refresh();
            })
            .catch((err) => {
              konsole.log("responsepost", err);
              setLoader(false);

              refresh();
            });
        } else {
          refresh();
        }
      })
      .finally(() => {
        konsole.log("finish contact with other");
      });
  };

  const emailjsonforupdate = (contactTypeId, emailId, createdBy) => {
    return {
      contactTypeId: contactTypeId,
      emailId: emailId,
      createdBy: createdBy,
    };
  };
  const mobilejsonforupdate = (contactTypeId, mobile, createdBy, code) => {
    return {
      contactTypeId: contactTypeId,
      mobileNo: mobile,
      createdBy: createdBy,
      countryCode: code,
    };
  };

  const addcontactwithotherupdate = (
    contactPutData,
    filterdataforemails,
    filterdataformobiles
  ) => {
    let putpostdata = contactPutData;

    let emailsdata = filterdataforemails.filter(
      (v, i, a) =>
        a.findIndex((v2) => v2.contactTypeId === v.contactTypeId) === i
    );

    let mobilesdata = filterdataformobiles.filter(
      (v, i, a) =>
        a.findIndex((v2) => v2.contactTypeId === v.contactTypeId) === i
    );

    let emailsjson = [];
    let mobilejson = [];
    for (let i = 0; i < emailsdata.length; i++) {
      let jsonda = emailjsonforupdate(
        emailsdata[i].contactTypeId,
        emailsdata[i].emailId,
        emailsdata[i].updatedBy
      );
      emailsjson.push(jsonda);
    }
    for (let i = 0; i < mobilesdata.length; i++) {
      konsole.log("cococco", mobilesdata[i].mobileNo.slice(1, 2));
      let jsonda = mobilejsonforupdate(
        mobilesdata[i].contactTypeId,
        mobilesdata[i].mobileNo,
        mobilesdata[i].updatedBy,
        mobilesdata[i].mobileNo.slice(1, 2)
      );
      mobilejson.push(jsonda);
    }

    putpostdata.contact.emails = emailsjson;
    putpostdata.contact.mobiles = mobilejson;

    konsole.log("putpostdataputpostdata", putpostdata);

    const promises = Services.postContactWithOther(putpostdata);
    promises
      .then((res) => {
        konsole.log("responsepost", res);
        setLoader(false);

        refresh();
      })
      .catch((err) => {
        konsole.log("responsepost", err);
        setLoader(false);

        refresh();
      });
  };

  konsole.log("updateAddMemberupdateAddMember", updateAddMember);

  const callAddMemberApi = (
    fName,
    mName,
    lName,
    nickName,
    dob,
    isVetean,
    isPrimaryMember,
    suffix,
    citizenship,
    gender,
    placeofBirth,
    primaryUserId,
    relation,
    isFiduciary,
    isBeneficiary,
    relativeUserId,
    isEmergencyContact,
    createdBy,
    address,
    zipcode,
    county,
    suite,
    city,
    state,
    country,
    streetAddress
  ) => {
    let postAddMemberApiCall = Services.postAddMember(
      fName,
      mName,
      lName,
      nickName,
      dob,
      isVetean,
      isPrimaryMember,
      suffix,
      citizenship,
      gender,
      placeofBirth,
      primaryUserId,
      relation,
      isFiduciary,
      isBeneficiary,
      relativeUserId,
      isEmergencyContact,
      primaryUserId,
      relationTypeIdspouse
    );

    postAddMemberApiCall
      .then((res) => {
        konsole.log("ressaddmember", res);
        const responseUser = res.data.data.member.userId;
        konsole.log(
          "addressaddress",
          responseUser,
          address,
          zipcode,
          county,
          suite,
          city,
          state,
          country,
          streetAddress
        );

        if (address !== null && address !== undefined && address !== "") {
          calladdressapi(
            responseUser,
            address,
            zipcode,
            county,
            suite,
            city,
            state,
            country,
            streetAddress
          );
        } else {
          addContactWithOther(responseUser);
          if (
            illnesscheck == false &&
            eofcheck == false &&
            deathcheck == false
          ) {
            // //refresh()
          } else {
            postNotifyFun(responseUser);
          }
        }
        // calladdressapi(responseUser, address, zipcode, country, city, state);
        if (props.type == "AddFiduciries") {
          Toaster.success("Fiduciary added successfully");
          setTimeout(()=>{
            props.Addfiduser(true);
            props.Fiduserdata(res.data.data.member);
          },2000)
        } else {
          Toaster.success("Beneficiries added successfully");
          setTimeout(()=>{
            props.benuserdata(res.data.data.member);
            props.Addbenuser(true);
          },2000)
        }
        konsole.log(res.data.data.member, "jfuri");
        if(res.data.data.member.memberRelationship.relationshipTypeId == 999999){
          primaryrelationref.current.saveHandleOther(res.data.data.member.memberId)
        }
        if(res.data.data.member.memberRelationship.rltnTypeWithSpouseId == 999999){
          spouserelationref.current.saveHandleOther(res.data.data.member.memberId)
        }
        setGetSaveBtn(false);
      })
      .catch((error) => {
        konsole.log("res", error.response);
        Toaster.error(error.response);
      })
      .finally(() => {
        setGetSaveBtn(false);
      });
  };

  const calladdressapi = (
    userId,
    address,
    zipcode,
    county,
    suite,
    city,
    state,
    country,
    streetAddress
  ) => {
    konsole.log(
      userId,
      address,
      zipcode,
      county,
      suite,
      city,
      state,
      country,
      streetAddress,
      "ureiureire"
    );
    let calladdressapi = Services.postaddAddressbyuserId(
      "POST",
      userId,
      address,
      zipcode,
      county,
      suite,
      city,
      state,
      country,
      1,
      primaryUserId
    );

    calladdressapi
      .then((res) => {
        konsole.log("address", res);
        addContactWithOther(userId);

        // if(illnesscheck == false && eofcheck == false && deathcheck == false ){
        //   refresh()
        // }else {
        //   postNotifyFun(userId);
        // }
      })
      .catch((error) => {
        konsole.log("addressError", error);
        addContactWithOther(userId);
      });
  };

  const refresh = () => {
    if (props.type == "AddFiduciries" || props.type == "EditFiduciary") {
      // window.location.replace("/Fiduciaries");
    } else if (
      props.type == "AddBeneficiaries" ||
      props.type == "EditBeneficiries"
    ) {
      // window.location.replace("/beneficiaries");
    }
  };

  for (let i = 0; i < contactinfo.length; i++) {}

  const addContactWithOther = (userId) => {
    let mobiles = [];
    let emails = [];

    if (
      contactinfo?.length == 0 &&
      illnesscheck == false &&
      eofcheck == false &&
      deathcheck == false
    ) {
      refresh();
      return;
    } else if (contactinfo.length == 0) {
      return;
    }

    for (let i = 0; i < contactinfo.length; i++) {
      const mobilesObj = contactinfo[i].mobiles;
      const emailsObj = contactinfo[i].emailId;
      konsole.log("emailObj", emailsObj);
      if (mobilesObj.mobileNo !== "") {
        mobiles = [
          ...mobiles,
          {
            ...mobilesObj,
            mobileNo: mobilesObj.countryCode + mobilesObj.mobileNo,
            commTypeId: commTypevalue,
          },
        ];
      }
      if (emailsObj.email !== "") {
        emails = [
          ...emails,
          {
            contactTypeId: emailsObj.ContactTypeId,
            emailId: emailsObj.email,
            createdBy: emailsObj.createdBy,
          },
        ];
      }
    }
    konsole.log("mobilessss1 ", mobiles, emails);

    let contactPostData = {
      userId: userId,
      activityTypeId: 4,
      contact: {
        mobiles: mobiles.length !== 0 ? mobiles : null,
        emails: emails.length !== 0 ? emails : null,
      },
    };
    konsole.log("putpostdataputpostdata", contactPostData);
    const promises = Services.postContactWithOther(contactPostData);

    promises
      .then((res) => {
        konsole.log("response contact with other", res);
        konsole.log("zzzzzzzzzzzzz", res?.data?.data);
        setGetSaveBtn(false);
        refresh();

        // //refresh();
        // CallApi(res.data.data.userId)

        // if (props.type == "AddFiduciries" || props.type == "EditFiduciary") {
        //   window.location.replace("/Fiduciaries");
        // } else if (
        //   props.type == "AddBeneficiaries" ||
        //   props.type == "EditBeneficiries"
        // ) {
        //   window.location.replace("/beneficiaries");
        // }
      })
      .catch((err) => {
        konsole.log("error contact with other", err);
        setGetSaveBtn(false);
      })
      .finally(() => {
        konsole.log("finish contact with other");
        setGetSaveBtn(false);
        refresh();
      });
  };

  const handleChange = (type, index, e) => {
    konsole.log("value5656c", e, index, type);
    if (type == "email") {
      konsole.log("value5656", e.target.value);
      contactinfo[index].emailId.email = e.target.value;
      console.warn("mail", emailregex.test(e.target.value));

      if (!emailregex.test(e.target.value)) {
        contactinfo[index].error = "Please enter valid email";
        seterrorMsg("Please enter valid email");
        setSelectedIndex(index);
      }
      if (emailregex.test(e.target.value)) {
        contactinfo[index].error = "";
        setSelectedIndex(index);
        seterrorMsg("");
      }
    } else if (!regex.test(contactinfo[index].emailId.email)) {
      // contactinfo[index].error = "Please enter valid Email";
    }
    if (type == "mobile") {
      if ( e.length == 10 ||  e.length == 0 || e.length == null || e.length == undefined ||  e.length < 10) {
        contactinfo[index].error1 = "";
        contactinfo[index].mobiles.mobileNo = e == "" ? "" : e;
        seterrorMsg(" ");
      }
      if (e.length >= 1 && e.length <= 9) {
        contactinfo[index].error1 = `Please enter the valid ${
          commTypevalue == 1 ? "cell" : "land line"
        } no.`;
        setSelectedIndex(index);
        seterrorMsg(
          `Please enter the valid ${
            commTypevalue == 1 ? "cell" : "land line"
          } no.`
        );
        return;
      }
    } else if (type == "code") {
      contactinfo[index].mobiles.countryCode = e;
      setCountryCodeSelectState(e);
    }
    konsole.log("contact", contactinfo);
  };
  konsole.log("value5656 contact7483784", contactinfo);
  konsole.log("formvalue", form.getFieldValue());

  const handleClick = (type, index) => {
    konsole.log("type, index", type, index);
    if (type == "add") {
      
      if (contactinfo[index].emailId.email == "" && contactinfo[index].mobiles.countryCode == "" && contactinfo[index].mobiles.mobileNo == "") {
        contactinfo[index].error = "Please enter the email detail";
        // Toaster.warning("please enter the contact detail.")
        setSelectedIndex(index);
        seterrorMsg("Please enter the email detail");
        return;
      } else if (!emailregex.test(contactinfo[index].emailId.email)) {
        setSelectedIndex(index);
        return;
      } else if (
        countryCodeSelectState == "" ||
        countryCodeSelectState == null ||
        countryCodeSelectState == undefined
        // contactinfo[index].mobiles.mobileNo !== "" &&
        // contactinfo[index].mobiles.countryCode == ""
      ) {
        // contactinfo[index].error = "Please choose the country code.";
        // seterrorMsg("Please choose the country code.")
        // contactinfo[index].mobiles.countryCode = "+1"
        // setSelectedIndex(index);
        // return;

      } else if (contactinfo[index].mobiles.mobileNo == "" || contactinfo[index].mobiles.mobileNo.length < 10) {
        contactinfo[index].error1 = `Please enter the valid ${
          commTypevalue == 1 ? "cell" : "land line"
        } no.`;
        // Toaster.warning("please enter the mobile no.");
        setSelectedIndex(index);
        seterrorMsg(
          `Please enter the valid ${
            commTypevalue == 1 ? "cell" : "land line"
          } no.`
        );
        return;
      }
      contactinfo[index].mobiles.countryCode = countryCodeSelectState;
      setrender(!render);
      contactinfo[index].Type = "Edit";
    } else if (type == "Edit") {
      contactinfo[index].Type = "addedit";
    }
    console.log("ghsdjfghsjdfh",contactinfo);
    setSelectedIndex(index);
    setContactInfo(contactinfo)
  };

  konsole.log("CONTACTYPE", contactype, contactinfo);

  const cancelfiduciriesbeneficiries = () => {
    if (props.type == "AddFiduciries" || props.type == "EditFiduciary") {
      // window.location.replace("/Fiduciaries");
      props.ManageFiduciaries();
    } else if (
      props.type == "AddBeneficiaries" ||
      props.type == "EditBeneficiries"
    ) {
      // window.location.replace("/beneficiaries");
      props.setaddbeneficiries();
    }
  };

  const getaddmberDetails = () => {
    let promise = Services.getMemberDetails(props.userId);
    promise
      .then((res) => {
        konsole.log("getmemberdata", res.data.data);
        setgetUserdata(res.data.data.member);
        

        konsole.log("ResMember", JSON.stringify(res.data.data.member));
        setuserMemberId(res.data.data.member.memberId);

        setuserRltnshipId(
          res.data.data.member.memberRelationship.userRltnshipId
        );

        let resMember = res.data.data.member;
        setRelationTypeIdspouse(resMember.memberRelationship.rltnTypeWithSpouseId)
        if(resMember.memberRelationship.relationshipTypeId == 1 && resMember.memberRelationship.relativeUserId == userPrimaryInDetail ){
           setshowrelationforspouse(true)
        }
        if(resMember.memberRelationship.relationshipTypeId == "999999"){
          primaryrelationref.current.saveHandleOther(resMember.memberId)
        }
        if(resMember.memberRelationship.rltnTypeWithSpouseId == "999999"){
          spouserelationref.current.saveHandleOther(resMember.memberId)
        }
        let dob = moment.utc(res.data.data.member.dob);
        // newDropdown(resMember)
        console.log("res.data.data.member.dob", resMember.memberRelationship.rltnTypeWithSpouseId);
        let dobset = res?.data?.data?.member?.dob !== null ? dob : "";
        form.setFieldsValue({
          dob: dobset,
          fName: resMember.fName,
          mName: resMember.mName,
          lName: resMember.lName,
          nickName: resMember.nickName,
          suffix: resMember.suffixId,
          gender: resMember.genderId,
          placeofBirth: resMember.birthPlace,
          citizenship: resMember.citizenshipId,
          relation: resMember.memberRelationship.relationshipTypeId,
          relationwithspouse: resMember.memberRelationship.rltnTypeWithSpouseId,
          radioemergencycontact:
            resMember.memberRelationship.isEmergencyContact,
          radioisfiduciries: resMember.memberRelationship.isFiduciary,
          radioisBenificiries: resMember.memberRelationship.isBeneficiary,
        });
      })
      .catch((err) => {
        konsole.log("ErrorMember", err);
      });
  };

  const relationSelected = (e) => {
    // let getAttributedata = e.target.getAttribute("data_Attribute")
    konsole.log("relationSelected", e.target.value);
    konsole.log("e.target.valuee.target.value", e.target.value);
    let filterData = relationshiptype.filter((x) => {
      return x.value == e.target.value;
    });
    // konsole.log("relationSelected",key.key,key.value)
    konsole.log("relationSelected", filterData[0].label);
    setRelationTypeLabel(filterData[0].label);
    setrelationshipid(e.target.value)
  };
  const relationSelectedwithspouse = (e) => {
    // let getAttributedata = e.target.getAttribute("data_Attribute")
    konsole.log("relationSelected", e.target.value);
    konsole.log("e.target.valuee.target.value", e.target.value);
    let filterData = relationshiptype.filter((x) => {
      return x.value == e.target.value;
    });
    // konsole.log("relationSelected",key.key,key.value)
    konsole.log("relationSelected", filterData[0].label);
    setRelationTypeIdspouse(filterData[0].value);
  };

  konsole.log("relationTypeLabelrelationTypeLabel", relationTypeLabel);

  const onChangeSelect = (e) => {
    const checkedstatus = e.target.checked;
    const checkid = e.target.id;

    if (checkedstatus == true && checkid == "illness") {
      setillnesscheck(true);
    } else if (checkedstatus == false && checkid == "illness") {
      setillnesscheck(false);
    }

    if (checkedstatus == true && checkid == "eof") {
      seteofcheck(true);
    } else if (checkedstatus == false && checkid == "eof") {
      seteofcheck(false);
    }

    if (checkedstatus == true && checkid == "death") {
      setdeathcheck(true);
    } else if (checkedstatus == false && checkid == "death") {
      setdeathcheck(false);
    }

    konsole.log("eeeeee", e.target.checked, e.target.id);
  };

  const postNotifyFun = (userId) => {
    let jsonforObj = [];

    const primarysessionid = commonLib.getObjFromStorage(
      "userPrimaryInDetail"
    ).memberUserId;
    const loggedInUserId = commonLib.getObjFromStorage("stateObj").userId;
    let jsonforillness = {
      contactMapId: 0,
      primaryMemberId: primarysessionid,
      contactNatureId: 1,
      contactUserId: userId,
      notifyConditionId: 1,
      contactStatus: illnesscheck,
      upsertedBy: loggedInUserId,
    };
    let josnforeof = {
      contactMapId: 0,
      primaryMemberId: primarysessionid,
      contactNatureId: 1,
      contactUserId: userId,
      notifyConditionId: 2,
      contactStatus: eofcheck,
      upsertedBy: loggedInUserId,
    };
    let jsonfordeath = {
      contactMapId: 0,
      primaryMemberId: primarysessionid,
      contactNatureId: 1,
      contactUserId: userId,
      notifyConditionId: 3,
      contactStatus: deathcheck,
      upsertedBy: loggedInUserId,
    };

    if (illnesscheck == true) {
      jsonforObj.push(jsonforillness);
    }
    if (eofcheck == true) {
      jsonforObj.push(josnforeof);
    }
    if (deathcheck == true) {
      jsonforObj.push(jsonfordeath);
    }

    konsole.log("jsonobjjsonobj", JSON.stringify(jsonforObj));
    let promise = Services.postcontactmapapi(jsonforObj);
    promise
      .then((res) => {
        konsole.log("responseresponse", res);
        refresh();
      })
      .catch((err) => {
        konsole.log("responseerror", err);
        refresh();
      })
      .finally(() => {
        setGetSaveBtn(false);
        refresh();
      });
  };

  const getNotifycheck = () => {
    const primarysessionid = commonLib.getObjFromStorage(
      "userPrimaryInDetail"
    ).memberUserId;
    const promise = Services.getNotifyContactapi(primarysessionid, 1, 1);
    promise
      .then((res) => {
        const filterdata = res.data.data.filter(
          (items) => items.contactUserId == props.userId
        );
        if (filterdata.length !== 0) {
          setresillnessarr(filterdata);
          setillnesscheck(filterdata[0]?.contactStatus);
          konsole.log("ababababab", filterdata);
        }
      })
      .catch((err) => {
        konsole.log("errerrerr", err);
      });
    const promisea = Services.getNotifyContactapi(primarysessionid, 1, 2);
    promisea
      .then((res) => {
        const filterdata = res.data.data.filter(
          (items) => items.contactUserId == props.userId
        );
        if (filterdata.length !== 0) {
          setreseolarr(filterdata);
          seteofcheck(filterdata[0]?.contactStatus);
          konsole.log("ababababab", filterdata);
        }
      })
      .catch((err) => {
        konsole.log("errerrerr", err);
      });
    const promiseb = Services.getNotifyContactapi(primarysessionid, 1, 3);
    promiseb
      .then((res) => {
        const filterdata = res.data.data.filter(
          (items) => items.contactUserId == props.userId
        );
        if (filterdata.length !== 0) {
          setresdeatharr(filterdata);
          setdeathcheck(filterdata[0]?.contactStatus);
          konsole.log("ababababab", filterdata);
        }
      })
      .catch((err) => {
        konsole.log("errerrerr", err);
        // refresh()
      });
  };

  konsole.log("rerererre", resdeatharr);

  const putNotifyFun = (userId) => {
    konsole.log("ababababa", userId);
    let jsonobj = [];
    const primarysessionid = commonLib.getObjFromStorage(
      "userPrimaryInDetail"
    ).memberUserId;
    const loggedInUserId = commonLib.getObjFromStorage("stateObj").userId;

    konsole.log("abababababab", primarysessionid, loggedInUserId);

    let jsonforillness = {
      contactMapId: 0,
      primaryMemberId: primarysessionid,
      contactNatureId: 1,
      contactUserId: userId,
      notifyConditionId: 1,
      contactStatus: illnesscheck,
      upsertedBy: loggedInUserId,
    };

    konsole.log("jsonforillness", jsonforillness);
    let josnforeof = {
      contactMapId: 0,
      primaryMemberId: primarysessionid,
      contactNatureId: 1,
      contactUserId: userId,
      notifyConditionId: 2,
      contactStatus: eofcheck,
      upsertedBy: loggedInUserId,
    };
    let jsonfordeath = {
      contactMapId: 0,
      primaryMemberId: primarysessionid,
      contactNatureId: 1,
      contactUserId: userId,
      notifyConditionId: 3,
      contactStatus: deathcheck,
      upsertedBy: loggedInUserId,
    };

    let jsonforupdateillness = {
      contactMapId: resillnessarr[0]?.contactMapId,
      primaryMemberId: resillnessarr[0]?.primaryMemberId,
      contactNatureId: resillnessarr[0]?.contactNatureId,
      contactUserId: resillnessarr[0]?.contactUserId,
      notifyConditionId: resillnessarr[0]?.notifyConditionId,
      contactStatus: illnesscheck,
      upsertedBy: loggedInUserId,
    };
    let jsonforupdateeol = {
      contactMapId: reseolsarr[0]?.contactMapId,
      primaryMemberId: reseolsarr[0]?.primaryMemberId,
      contactNatureId: resillnessarr[0]?.contactNatureId,
      contactUserId: reseolsarr[0]?.contactUserId,
      notifyConditionId: reseolsarr[0]?.notifyConditionId,
      contactStatus: eofcheck,
      upsertedBy: loggedInUserId,
    };
    let jsonforupdatedeath = {
      contactMapId: resdeatharr[0]?.contactMapId,
      primaryMemberId: resdeatharr[0]?.primaryMemberId,
      contactNatureId: resillnessarr[0]?.contactNatureId,
      contactUserId: resdeatharr[0]?.contactUserId,
      notifyConditionId: resdeatharr[0]?.notifyConditionId,
      contactStatus: deathcheck,
      upsertedBy: loggedInUserId,
    };
    if (resillnessarr.length !== 0) {
      if (resillnessarr[0]?.contactStatus !== illnesscheck) {
        jsonobj.push(jsonforupdateillness);
      }
    } else {
      if (illnesscheck == true) {
        jsonobj.push(jsonforillness);
      }
    }

    if (reseolsarr.length !== 0) {
      if (reseolsarr[0]?.contactStatus !== eofcheck) {
        jsonobj.push(jsonforupdateeol);
      }
    } else {
      if (eofcheck == true) {
        jsonobj.push(josnforeof);
      }
    }
    if (resdeatharr.length !== 0) {
      if (resdeatharr[0]?.contactStatus !== deathcheck) {
        jsonobj.push(jsonforupdatedeath);
      }
    } else {
      if (deathcheck == true) {
        jsonobj.push(jsonfordeath);
      }
    }

    konsole.log("ababababababab", jsonobj);
    konsole.log("jsonobjjsonobj", JSON.stringify(jsonobj));
    let promise = Services.postcontactmapapi(jsonobj);
    promise
      .then((res) => {
        konsole.log("responseresponse", res);
        // refresh();
      })
      .catch((err) => {
        konsole.log("responseerror", err);
        // refresh();
      });
  };

  // useEffect(()=>{
  // setContactType(stateOne)
  // },[])

  const newAddListFunction = (array1, array2) => {
    konsole.log(
      "khlkhlkhlhlhlhlhlhlhlkhklhlhlhklhlhl",
      array1[0]?.label,
      array2
    );
    konsole.log(
      "yyyyyyyyy",
      newContactListState[array1[0]?.value - 1]?.value,
      newContactListState[array1[0].value - 1]?.label
    );

    setrender(!render);

    // newContactListState.filter(array1.ContactTypeId)
    // setContactType(datadata1)

    konsole.log(
      array1[0]?.label == "Other"
        ? contactype.push({ value: 999999, label: "Other" })
        : contactype.splice(
            0,
            // array1.ContactTypeId == 1 ? 0 : array1.ContactTypeId == 2 ? 1 : array1.ContactTypeId == 3 ? 2 : array1.ContactTypeId == 4 ? 3 : array1.ContactTypeId == 5 ? 4 : "",
            0,

            {
              value: newContactListState[array1[0].value - 1]?.value,
              label: newContactListState[array1[0].value - 1]?.label,
            }
          ),
      contactype,
      "contactypecontactypecontactypecontactypecontactypecontactype"
    );

    newfunction(array1);

    // setStateOne(array2)
  };

  konsole.log("khlkhlkhlhlfdfdflhlhlhklhlhl", contactype);

  const newfunction = (array1) => {
    setrender(!render);

    if (contactype.length > 0) {
      const datadatata = contactinfo.filter(
        (item) => item?.ContactTypeId != array1[0]?.value
      );
      konsole.log("datauiuioioioidata", datadatata);

      setContactInfo(datadatata);
    }
  };

  const getDifference = (array1, array2) => {
    return array2?.filter((object1) => {
      return !array1?.some((object2) => {
        return object1?.value === object2?.ContactTypeId;
      });
    });
  };
  // console.log("getDifference", getDifference(contactinfo, contactype));
  konsole.log("resillnessarr", resillnessarr, reseolsarr, resdeatharr);
  // console.log("contactinfocontactinfo", contactinfo);

  useEffect(() => {
    if (contactype.length > 0) {
      let datadata = getDifference(contactinfo, contactype);
      konsole.log("dfdfdfdfdff",contactinfo,contactype)
      konsole.log("datauiudata", datadata);
      setContactType(datadata);
    }
  }, [contactinfo,contactype1]);

  const handleFocusOut = () => {
    let attrvalue = form.getFieldsValue().mName;
    if (attrvalue.length !== 0) {
      if (attrvalue.length === 1) {
        attrvalue = attrvalue + ".";
      }
    }
    form.setFieldsValue({
      mName: attrvalue,
    });
  };
  const newDropdown=(data)=>{
   let relationTypeId = form.getFieldsValue()
  console.log("samplejdb8989",getuserdata.memberRelationship.relativeUserId, userPrimaryInDetail,getuserdata,showrelationforspouse,relationTypeId)
  if(relationTypeId?.relation == "2" || relationTypeId?.relation == "41" || relationTypeId?.relation == "28 " || relationTypeId?.relation == "5" || relationTypeId?.relation == "6" || relationTypeId?.relation == "29"){

    let filterRelationship = data.filter((e) => {
      return (
        e.value == "2" || e.value == "41" || e.value == "6" || e.value == "28" || e.value == "5" ||e.value == "29"

      );
    });
    setRelationshipType(filterRelationship)
  
  }
  else if(relationTypeId?.relation == "1" && getuserdata.memberRelationship.relativeUserId == userPrimaryInDetail){
    let filterRelationship = data.filter((e) => {
      return (
        e.value == "1"
      );
    });
    setRelationshipType(filterRelationship)
  }else if( (relationTypeId?.relation ==  "1" && showrelationforspouse == false) || relationTypeId?.relation ==  "49" || relationTypeId?.relation == "44" || relationTypeId?.relation == "48" || relationTypeId?.relation == "49" || relationTypeId?.relation == "50"){
  
    let filterRelationship = data.filter((e) => {
      return (
        e.value == "44" || e.value == "47" || e.value == "48" || e.value == "49" || e.value == "50" || e.value == "1"

      );
    });
    setRelationshipType(filterRelationship)
  }else if(relationTypeId?.relation == "3"){
    let filterRelationship = data.filter((e) => {
      return (
        e.value == "3"
        );
    });
    setRelationshipType(filterRelationship)
  }
  else{
 
let filterRelationship = data.filter((e) => {
return (
  e.value != "2" && e.value != "41" && e.value != "6" & e.value != "28" && e.value != "5" && e.value != "29" && e.value != "44" && e.value != "47" && e.value != "48" && e.value != "3" && e.value != "1" && e.value != "49" && e.value != "50" && e.value != "4"  && e.value != "12" 
        );
    });
    // console.log("kjgfKYSDIFUYH",filterRelationship)
    setRelationshipType(filterRelationship)

  }
  
  

  }

  konsole.log("contactinfo", contactinfo);
  return (
    <div className="">
      <Form
        form={form}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 0 }}
        className="w-100"
        name="horizontal_login"
        layout="inline"
        onFinish={onFinish}
        scrollToFirstError={{
          behavior: "smooth",
          block: "center",
          inline: "center",
        }}
      >
        <div className="Info mt-2 w-100">
          <div className="main">
            <Title level={5}>Personal Information </Title>

            <Row className="w-100" gutter={[8, 8]}>
              <Col xs={24} lg={8}>
                <Form.Item
                  name="fName"
                  rules={[
                    { required: true, message: "Please enter First name" },
                    {
                      // pattern: /^[A-Z a-z]/,
                      pattern: "^[a-zA-Z 0-9]*$",
                      message: "Please enter valid First name",
                    },
                  ]}
                >
                  <Input
                    placeholder={commonLib.mandatory("First Name")}
                    onInput={(e) =>
                      (e.target.value =
                        e.target.value.charAt(0).toUpperCase() +
                        e.target.value.slice(1))
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  name="mName"
                  rules={[
                    {
                      // pattern: /^[A-Z a-z]/,
                      pattern: "^[a-zA-Z0-9.]*$",
                      message: "Please enter valid Middle  name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Middle Name"
                    onInput={(e) =>
                      (e.target.value =
                        e.target.value.charAt(0).toUpperCase() +
                        e.target.value.slice(1))
                    }
                    onBlur={handleFocusOut}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  name="lName"
                  rules={[
                    { required: true, message: "Please enter Last name!" },
                    {
                      // pattern: /^[A-Z a-z]/,
                      pattern: "^[a-zA-Z 0-9]*$",
                      message: "Please enter valid Last name",
                    },
                  ]}
                >
                  <Input
                    placeholder={commonLib.mandatory("Last Name")}
                    onInput={(e) =>
                      (e.target.value =
                        e.target.value.charAt(0).toUpperCase() +
                        e.target.value.slice(1))
                    }
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  name="nickName"
                  rules={[
                    {
                      // pattern: /^[A-Z a-z]/,
                      // pattern: "^[a-zA-Z 0-9]*$",
                      message: "Please enter valid Nickname",
                    },
                  ]}
                >
                  <Input placeholder="Nickname" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item name="suffix">
                  <select
                    showSearch
                    className="form-select form-select-sm"
                    placeholder="Suffix"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {suffixname.map((items, index) => {
                      return (
                        <option key={items.value} value={items.value}>
                          {items.label}
                        </option>
                      );
                    })}
                  </select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  name="gender"
                  // rules={[
                  //   { required: true, message: "Please Select your gender" },
                  // ]}
                >
                  <select
                    placeholder="Gender"
                    className="form-select form-select-sm"
                  >
                    <option disabled selected>
                      Gender
                    </option>
                    {genders.map((items, index) => {
                      return (
                        <option value={items.value} key={items.label}>
                          {items.label}
                        </option>
                      );
                    })}
                  </select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                {props.type == "AddBeneficiaries" ||
                props.type == "EditBeneficiries" ? (
                  <Form.Item
                    name="dob"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please entar  date of birth",
                    //   },
                    // ]}
                  >
                    <DatePickerComponent
                      name="dob"
                      value={form.getFieldsValue().dob}
                      setvalue={(e) => form.setFieldsValue({ dob: e })}
                      placeholderText="D.O.B."
                      maxDate="18"
                      minDate="100"
                      validDate={
                        props.type == "EditBeneficiries" ||
                        props.type == "AddBeneficiaries"
                          ? 0
                          : 18
                      }
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    name="dob"
                    rules={[
                      {
                        required: true,
                        message: "Please enter date of birth",
                      },
                    ]}
                  >
                    <DatePickerComponent
                      name="dob"
                      value={form.getFieldsValue().dob}
                      setvalue={(e) => form.setFieldsValue({ dob: e })}
                      placeholderText="D.O.B."
                      maxDate="18"
                      minDate="100"
                      validDate={
                        props.type == "EditBeneficiries" ||
                        props.type == "AddBeneficiaries"
                          ? 0
                          : 18
                      }
                    />
                  </Form.Item>
                )}
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item name="placeofBirth">
                  <Input placeholder="Place of Birth" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  name="citizenship"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter Citizenship",
                  //   },
                  // ]}
                >
                  <select
                    showSearch
                    className="form-select form-select-sm"
                    placeholder="Please enter citizenship"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {" "}
                    <option
                      key={citizenshipType[186]?.value}
                      value={citizenshipType[186]?.value}
                    >
                      {citizenshipType[186]?.label}
                    </option>
                    {citizenshipType.map((items, index) => {
                      return (
                        <option key={items.value} value={items.value}>
                          {items.label}
                        </option>
                      );
                    })}
                  </select>
                </Form.Item>
              </Col>
              <Col xs={24} lg={8}>
                <Form.Item
                  name="relation"
                  rules={[
                    {
                      required: true,
                      message: "Please choose your Relationship",
                    },
                  ]}
                >
                  <select
                    placeholder={commonLib.mandatory("Relation with client")}
                    className="form-select form-select-sm"
                    onChange={relationSelected}
                    disabled={showrelationforspouse}
                    // value={showrelationforspouse ? 1 : ''}
                  >
                    <option value="-1" disabled selected>
                      {commonLib.mandatory("Select relation")}
                    </option>
                    {relationshiptype.map((items, index) => {
                      // konsole.log("relationshiptype", items);
                      return (
                        <option key={items.label} value={items.value}>
                          {items.label}
                        </option>
                      );
                    })}
                  </select>
                </Form.Item>
                <div className="mt-2" style={{width:"96%"}}>
                <Other othersCategoryId={27} form={form} userId={primaryUserId} dropValue={form.getFieldsValue()?.relation} ref={primaryrelationref} natureId={(props.type == "EditBeneficiries" || props.type == "EditFiduciary") ? userMemberId : ""} />
                 </div>
              </Col>
              {(spouseUserId !== null && spouseUserId !== undefined && spouseUserId !== "") &&
              <Col xs={24} lg={8}>
                {showrelationforspouse == false && <> <Form.Item
                  name="relationwithspouse"
                  rules={[
                    {
                      required: true,
                      message: "Please choose your relationship with spouse",
                    },
                  ]}
                >
                  <select
                    value={relationTypeIdspouse}
                    placeholder={commonLib.mandatory("Relation with spouse")}
                    className="form-select form-select-sm"
                    onChange={relationSelectedwithspouse}
                    // disabled={showrelationforspouse}
                  >
                    <option value="-1" disabled selected>
                      {commonLib.mandatory("Select relation with spouse")}
                    </option>
                    {relationshiptype.map((items, index) => {
                      // konsole.log("relationshiptype", items);
                      return (
                        <option key={items.label} value={items.value}>
                          {items.label}
                        </option>
                      );
                    })}
                  </select>
                </Form.Item> 
                <div className="mt-2" style={{width:"96%"}}>
             <Other
                  othersCategoryId={27} form={form} userId={spouseUserId} dropValue={form.getFieldsValue()?.relationwithspouse} ref={spouserelationref} natureId={(props.type == "EditBeneficiries" || props.type == "EditFiduciary") ? userMemberId : ""}
                />
                </div>
                </>
                }
              </Col>
              }
            </Row>
          </div>
        </div>

        <div className="Info mt-2 w-100">
          <div className="main">
            <Title level={5}>Contact Address</Title>
            <Address form={form} addressfiledError={addressfiledError}setAddressfieldError={setAddressfieldError} />
          </div>
        </div>
        <div className="Info mt-2 w-100">
          <div className="main">
            <Title level={5}>Contact Information</Title>
            {contactinfo.map((items, index) => {
              konsole.log(items, index, "itemsindex");
              return (
                <EditContactInfo
                  getcontactwithother={getcontactwithother}
                  selectuserId={useridfordelete}
                  keyIndex={index}
                  selectedIndex={selectedIndex}
                  handleChange={handleChange}
                  onChange={(e) => {
                    console.warn(e.length);
                  }}
                  items={items}
                  index={index}
                  errorMsg={errorMsg}
                  setSelectedIndex={setSelectedIndex}
                  handleClick={handleClick}
                  contactinfo={contactinfo}
                  commTypevalue={commTypevalue}
                  setCommTypevalue={setCommTypevalue}
                  newAddListFunction={newAddListFunction}
                  setCountryCodeSelectState={setCountryCodeSelectState}
                  contactype={contactype}
                  setNewListSelectData={setNewListSelectData}
                  contactype1={contactype1}
                  setDeleteState={setDeleteState}
                  deleteState={deleteState}
                />
              );
            })}
            <Row gutter={[8, 8]}>
              <Col xs={24} lg={24}>
                <Form.Item
                  name="addcontact"
                  //  rules={[
                  //   {
                  //     required: true,
                  //     message: "Please add contact information",
                  //   },
                  // ]}
                  style={{ padding: "0px" }}
                >
                  {konsole.log("contacttcontactypeype", contactype)}
                  <Select
                    placeholder="Add Contact Information"
                    className="mt-2"
                    style={{
                      borderColor: "#720C20",
                      borderRadius: 5,
                      width: 250,
                      color: "black",
                    }}
                    value={group}
                    onSelect={saveGroup}
                  >
                    {contactype
                      .sort((a, b) => a.value - b.value)
                      .map((items) => {
                        return (
                          <Option value={items.value}>{items.label}</Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
        {props.type == "AddFiduciries" || props.type == "EditFiduciary" ? (
          <Row className="w-100 mt-4" gutter={[8, 8]}>
            <Col span={8}>
              <h6>Add this contact as Beneficiaries ?</h6>

              <Form.Item name="radioisBenificiries">
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <h6>Make this as an emergency contact ?</h6>

              <Form.Item name="radioemergencycontact">
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row className="w-100 mt-4" gutter={[8, 8]}>
            <Col span={10} xs={24} sm={24} md={10} lg={10}>
              <h6>Add this contact as Fiduciaries ?</h6>

              <Form.Item name="radioisfiduciries">
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>NO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={10} xs={24} sm={24} md={10} lg={10}>
              <h6>Make this as an emergency contact ?</h6>

              <Form.Item name="radioemergencycontact">
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>NO</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        )}

        <Col span={24} className="mt-4">
          <h6>Assign Notification for</h6>

          <Form.Item>
            <Checkbox
              onChange={onChangeSelect}
              id="illness"
              checked={illnesscheck}
            >
              Illness
            </Checkbox>
            <br />
            <Checkbox onChange={onChangeSelect} id="eof" checked={eofcheck}>
              End of Life
            </Checkbox>
            <br />
            <Checkbox onChange={onChangeSelect} id="death" checked={deathcheck}>
              Death
            </Checkbox>
          </Form.Item>
        </Col>

        <Col
          xs={24}
          lg={24}
          className="d-flex justify-content-between w-100 mt-3"
        >
          <Button
            className="fw-bold text-right mb-2"
            style={{
              textAlign: "right",
              background: "#720C20",
              color: "white",
              borderRadius: 5,
              border:"none"
            }}
            htmlType="submit"

            // onClick={modalOnDisable}
          >
            Save
          </Button>

          <Button
            className="fw-bold mb-2"
            style={{
              color: "#720C20",
              borderColor: "#720C20",
              borderRadius: 5,
            }}
            onClick={() => cancelfiduciriesbeneficiries()}
          >
            Cancel
          </Button>
        </Col>
      </Form>
    </div>
  );
}

export default AddFidBeneficiaries;
