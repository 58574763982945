import React, { useEffect, useState, useContext } from "react";
import "./PersonalInfo.css";
import {
  Typography,
  Row,
  Col,
  Input,
  Checkbox,
  Select,
  Form,
  DatePicker,
} from "antd";
import Services from "../../../network/Services";
import konsole from "../../../network/konsole";
import Veteran from "./Veteran";
import commonLib from "../../../control/commonLib";
import moment from "moment";
import { UserContext } from "../../../App";
import DatePickerComponent from "../../DatePickerComponent";
const { Text } = Typography;
const { Option } = Select;

export default function PersonalInformation(props) {
  const formValue = props.form.getFieldsValue();
  const [nickName, setNickName] = useState("");
  const userId = props.userId;
  const [genderList, setGenderList] = useState([]);
  const [citizenship, setCitizenship] = useState([]);
  const [isVeteran, setisVeteran] = useState(
    props.form.getFieldsValue().isVeteran
  );
  const [suffixList, setSuffixList] = useState([]);
  const { disable, modalOnDisable } = useContext(UserContext);

  let primaryUserId = commonLib.getObjFromStorage("userPrimaryInDetail").memberUserId;
  let spouseUserId = commonLib.getObjFromStorage("userPrimaryInDetail").spouseUserId;
  let primaryUser = commonLib.getObjFromStorage("userPrimaryInDetail");
  let primaryuserfullname = primaryUser?.memberName?.split(" ")
  konsole.log("state", isVeteran);
  useEffect(() => {
    getCitizenshipList();
    getGenderList();
    getSuffixList();
  }, [props]);
  konsole.log(props.editProfieType, "tryuiop");
  konsole.log("form validate value", props.form.getFieldsValue());

  if (props.form.getFieldsValue().fName?.includes("-")) {
    props.form.setFieldsValue({ fName: "" });
  }
  if (props.form.getFieldsValue().lName == "Name") {
    props.form.setFieldsValue({ lName: "" });
  }

  if(props.userId == spouseUserId && props.form.getFieldsValue().lName == ""){
    props.form.setFieldsValue({ lName: primaryuserfullname[primaryuserfullname.length -1] });
    }
  konsole.log(
    "form validate value2",
    props.form.getFieldsValue().lName,
    props.form.getFieldsValue()
  );

  useEffect(() => {
    setisVeteran(props.form.getFieldsValue().isVeteran);
  }, [props.form.getFieldsValue().isVeteran]);


  const getCitizenshipList = () => {
    let getCitizenshippramas = Services.getCitizenshipType();
    getCitizenshippramas
      .then((res) => {
        setCitizenship(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSuffixList = () => {
    let getSuffixList = Services.getnamesuffixes();
    getSuffixList
      .then((res) => {
        setSuffixList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGenderList = () => {
    let genderspramas = Services.getgenders();
    genderspramas
      .then((res) => {
        setGenderList(res.data.data);
        konsole.log("genre", res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkVeternProfile = (checked) => {
    props.form.setFieldsValue({ isVeteran: checked });
    setisVeteran(checked);
    konsole.log("isChecked", checked);
  };

  const handleFocusOut = () => {
    let attrvalue = props.form.getFieldsValue().mName;
    if (attrvalue.length !== 0) {
      if (attrvalue.length === 1) {
        attrvalue = attrvalue + ".";
      }
    }
    props.form.setFieldsValue({
      mName: attrvalue,
    });
  };

  if (props.form.getFieldsValue().citizenshipId == undefined) {
    props.form.setFieldsValue({
      citizenshipId: citizenship[186]?.value,
    });
  }

 
  // filter((e)=>(e.value == 41 || e.value  == 44 || e.value  == 47 || e.value  == 48 || e.value  == 2 || e.value  == 28|| e.value  == 29))
  return (
    <>
      <Row className="mt-2">
        <Col lg={24} xs={24} className="px-2">
          <div className="Info">
            <div className="main">
              <Text strong={true} type="danger" style={{ color: "#76272B" }}>
                Personal Information
              </Text>
              <Row gutter={[8, 8]} className="mt-1">
                <Col span={8} xs={24} lg={8}>
                  <Form.Item
                    name="fName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter first name",
                      },
                      {
                        pattern: "^[a-zA-Z 0-9]*$",
                        message: "Please enter valid first name",
                      },
                    ]}
                  >
                    <Input
                      size=""
                      name="fName"
                      // onChange={(e) => setFname(e.target.value)}
                      placeholder={commonLib.mandatory("First Name")}
                      // placeholder={fName}
                      className="Input"
                      onInput={(e) =>
                        (e.target.value =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1))
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={8} xs={24} lg={8}>
                  <Form.Item name="mName">
                    <Input
                      className="Input"
                      size=""
                      name="Middle Name"
                      onBlur={handleFocusOut}
                      placeholder="Middlename"
                      onInput={(e) =>
                        (e.target.value =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} lg={8}>
                  <Form.Item
                    name="lName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter last name",
                      },
                      {
                        pattern: "^[a-zA-Z 0-9]*$",
                        message: "Please enter valid Last Name",
                      },
                    ]}
                  >
                    <Input
                      className="Input"
                      size=""
                      name="Lastname"
                      placeholder={commonLib.mandatory("Last Name")}
                      onInput={(e) =>
                        (e.target.value =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1))
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row className="d-flex align-items-center" gutter={[8, 8]}>
                <Col span={8} xs={24} lg={8}>
                  <Form.Item
                    name="nickName"
                    className="mt-2"
                    value={nickName}
                    onChange={(e) => {
                      setNickName(e.target.value);
                    }}
                    rules={[
                      {
                        pattern: "^[a-zA-Z 0-9]*$",
                        message: "Please enter valid Nickname",
                      },
                    ]}
                  >
                    <Input
                      size=""
                      name="Nick Name"
                      // value={fName}
                      placeholder="Nickname"
                      className="Input"
                    />
                  </Form.Item>
                </Col>

                <Col span={8} xs={24} lg={8}>
                  <Form.Item name="suffixId">
                    <select
                      className="form-select form-select-sm suffixed-classs "
                      placeholder="Suffix"
                      size=""
                    >
                      <option disabled selected>
                        Suffix
                      </option>
                      {suffixList.map((props) => (
                        <option key={props.value} value={props.value}>
                          {props.label}
                        </option>
                      ))}
                    </select>
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} lg={8}>
                  <Form.Item name="genderId">
                    <select
                      className="form-select form-select-sm suffixed-classs"
                      placeholder="Gender"
                      onChange={(e) => props.setGenderCheck(e.target.value)}
                    >
                      <option disabled selected>
                        Gender
                      </option>
                      {genderList.map((props) => (
                        <option key={props.value} value={props.value}>
                          {props.label}
                        </option>
                      ))}
                    </select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8} xs={24} lg={8}>
                  <Form.Item
                    name="dob"
                    className="mt-2 "
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please entar  Date of birth",
                    //   },
                    // ]}
                  >
                    {/* <DatePicker
                      className="w-100"
                      name="dob"
                      value={moment("16-2-1999").format("MM-DD-YYYY")}
                      placeholder="Date of Birth"
                      format="MM-DD-YYYY"
      
                      // disabledDate={
                      //   (d) => d > moment().subtract(18, "years")}
                        // props.editProfieType !== "In Law" || props.editProfieType !== "Siblings" || props.editProfieType !== "Spouse" || props.editProfieType !== "Friend" ? null
                      // props.editProfieType !== "In Law" || props.editProfieType !== "Siblings" || props.editProfieType !== "" ?  null :
                    /> */}
                    <DatePickerComponent
                      name="dob"
                      value={props.form.getFieldsValue().dob}
                      userAges={props?.userAges}
                      setvalue={(e) => props.form.setFieldsValue({ dob: e })}
                      placeholderText="D.O.B."
                      maxDate={  props?.form?.getFieldsValue()?.isFiduciary || props?.form?.getFieldsValue()?.maritalStatusId == 1 ? "18" : "0"}
                      minDate="100"
                      validDate={
                        (props?.form?.getFieldsValue()?.isFiduciary || props?.form?.getFieldsValue()?.maritalStatusId == 1)
                          ? 18
                          : 0
                      }
                    />
                  </Form.Item>
                </Col>

                <Col span={8} xs={24} lg={8} className="w-100">
                  <Form.Item
                    name="birthPlace"
                    className="suffixed-classs w-100"
                  >
                    <Input
                      className="Input"
                      size=""
                      placeholder="Place of Birth"
                    />
                  </Form.Item>
                </Col>
                <Col span={8} xs={24} lg={8}>
                  <Form.Item
                    className="w-100"
                    name="citizenshipId"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Please select your citizenship",
                    //   },
                    // ]}
                  >
                    <select
                      className="form-select form-select-sm suffixed-classs"
                      defaultValue={187}
                    >
                      {/* <option disabled selected>
                        Citizenship 
                      </option> */}
                      <option
                        key={citizenship[186]?.value}
                        value={citizenship[186]?.value}
                      >
                        {citizenship[186]?.label}
                      </option>
                      {citizenship.map((props) => (
                        <option
                          key={props.value}
                          value={props.value}
                          title={props.label}
                        >
                          {props.label}
                        </option>
                      ))}
                    </select>
                  </Form.Item>
                </Col>
                {(userId === primaryUserId || userId === spouseUserId) && (
                  <Veteran
                    checkVeternProfile={checkVeternProfile}
                    userId={userId}
                    form={props.form}
                    isVeteranChecked={isVeteran}
                  />
                )}
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
