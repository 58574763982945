import React, { useState, useEffect, useContext } from "react";
import {
  Steps,
  Row,
  Col,
  Space,
  Table,
  Input,
  Button,
  Radio,
  Select,
  Checkbox,
  Upload,
  InputNumber,
  message,
  Form,
} from "antd";
// import Form from 'react-bootstrap/Form';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {} from "antd";
import { Link } from "react-router-dom";
import "./setguidance.css";
import "./Death.css";
import konsole from "../../network/konsole";
import Services from "../../network/Services";
import Toaster from "../Toaster";
import { death } from "../../control/Constant";
import ImmediateActions from "./immediateActions";
import InputMask from "../PhoneNumberInputMask";
import Address from "../address";
import { UserContext } from "../../App";
import commonLib from "../../control/commonLib";
import UploadFile from "./UploadFile";
import AgentPreview from "./AgentPreview";
// import validator from 'validator'

const { Step } = Steps;
const CheckboxGroup = Checkbox.Group;
const plainOptions = ["Emai", "Text"];
const { TextArea } = Input;

const Death = (props) => {
  const [selectedStepper, selectSelectedStepper] = useState(1);
  const [changeBorder, setChangeBorder] = useState(1);

  //  const [yesTrueStateOne, setYesTrueStateOne] = useState(0)
  // const [yesTrueStateTwo, setYesTrueStateTwo] = useState(23)
  // const [yesTrueStateThree, setYesTrueStateThree] = useState(1)
  // const [yesTrueStateFour, setYesTrueStateFour] = useState(1)
  let memberName = JSON.parse(
    sessionStorage.getItem("userPrimaryInDetail")
  ).loginUserName;

  const [radioValue, setRadioValue] = useState("Buried");
  const [radioFuneralValueTwo, setRadioFuneralValueTwo] = useState("funeralno");
  const [radioFuneralValueTwoburied, setRadioFuneralValueTwoburied] =
    useState("funeralno");
  const [radioValueDisposed, setRadioValueDisposed] = useState();
  const [radioNationalCemetery, setRadioNationalCemetery] = useState();
  const [formlabelData, setFormLabelData] = useState({});
  const [memberuserId, setMeberUserId] = useState();
  const [specialInsAns, setSpecialInsAns] = useState();
  const [disposedOther, setDisposedOther] = useState();
  const [ashesOther, setAshesOther] = useState();
  const [holdashesOther, setHoldAshesOther] = useState();
  const [nameofcontact, setnameofcontact] = useState();
  const [nameoffuneral, setnameoffuneral] = useState();
  const [contactnum, setcontactnum] = useState();
  const [Phonenum, setPhonenum] = useState();
  const [faxnum, setfaxnum] = useState();
  const [wesite, setwesite] = useState("");
  const [address, setaddress] = useState();
  const [nameofcontactburied, setnameofcontactburied] = useState();
  const [nameoffuneralburied, setnameoffuneralburied] = useState();
  const [contactnumburied, setcontactnumburied] = useState();
  const [Phonenumburied, setPhonenumburied] = useState();
  const [faxnumburied, setfaxnumburied] = useState();
  const [wesiteburied, setwesiteburied] = useState("");
  const [addressburied, setaddressburied] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [form] = Form.useForm();
  const { setLoader } = useContext(UserContext);
  const [Helduntilseconddeath, setHelduntilseconddeath] = useState();
  const [SpecialInstructions,setSpecialInstructions] = useState('')
  const[otherspecialinstrshow,setotherspecialinstrshow] = useState(null)
  const [Veteanstatus,setVeteranstatus]=useState(false)

  const hidebtn = true;

  let memberUserId = props.memberid

  useEffect(()=>{
    let memberUserId = props.memberid
    selectSelectedStepper(1)
    getsubjectForFormLabelId(memberUserId);
    setMeberUserId(memberUserId);
    getVeteranByUserIdfunc(memberUserId)
    setRadioNationalCemetery()
    setSpecialInsAns()
    setDisposedOther()
    setAshesOther()
    setHoldAshesOther()
    setnameofcontact()
    setnameoffuneral()
    setcontactnum()
    setPhonenum()
    setfaxnum()
    setwesite()
    setaddress()
    setnameofcontactburied()
    setnameoffuneralburied()
    setcontactnumburied()
    setPhonenumburied()
    setfaxnumburied()
    setwesiteburied()
    setaddressburied()
    setErrorMessage()
    setHelduntilseconddeath()
    setSpecialInstructions()
    setotherspecialinstrshow()
    setSpecialInsAns('')
    setRadioValue('')
    setRadioFuneralValueTwo('')
    setRadioFuneralValueTwoburied('')
    setRadioNationalCemetery('')
    setRadioValueDisposed('')
    setFormLabelData('')
  },[props.memberid])

  // useEffect(() => {
  //   let memberUserId = props.memberid

  //   getsubjectForFormLabelId(memberUserId);
  //   setMeberUserId(memberUserId);
  //   getVeteranByUserIdfunc(memberUserId)

  //   // putaddusersubject()
  // }, [props.memberid]);

  const getVeteranByUserIdfunc = (userId) => {
  const getVeteranByUserparams=  Services.getVeteranByUserId(userId)
  getVeteranByUserparams.then((res)=>{
    setVeteranstatus(true)
    // alert("1")
    console.log(res,"getvetern")
  }).catch((error)=>{
    // alert("2")
    setVeteranstatus(false)
    console.log(error.data.data,"getveternerror")
  })
  }


  console.log("Helduntilseconddeaths",selectedStepper, Helduntilseconddeath);
  const onFinish = (data) => {
    console.log("data45dat", data);
  };

  function isUrlValid(userInput) {
    if (
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
        userInput
      )
    ) {
      setErrorMessage(false);

      setwesite(userInput);
    } else {
      setwesite(userInput);

      setErrorMessage(true);
    }

    // if (res !== null ) {
    //   setErrorMessage(false);

    //   setwesite(res[0]);
    // } else {
    //   setwesite();

    //   setErrorMessage(true);
    // }
  }
  function isUrlValidburied(userInput) {
    if (
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(
        userInput
      )
    ) {
      setErrorMessage(false);

      setwesiteburied(userInput);
    } else {
      setwesiteburied(userInput);

      setErrorMessage(true);
    }

    // if (res !== null ) {
    //   setErrorMessage(false);

    //   setwesite(res[0]);
    // } else {
    //   setwesite();

    //   setErrorMessage(true);
    // }
  }

  const getsubjectForFormLabelId = (userId) => {
    setLoader(true);
    let formlabelData = {};
    death.formLabelId.map((id, index) => {
      let data = [id.id];
      const promises = Services.getSubjectQuestions(data);
      promises.then((response) => {
        konsole.log("response", response);
        if (response) {
          setLoader(false);
          if (id.id == response.data.data[0].formLabelId) {
            let label = "label" + response.data.data[0].formLabelId;
            formlabelData[label] = response.data.data[0].question;
            setLoader(true)
            const promises = Services.getSubjectResponse(
              userId,
              formlabelData[label].questionId
            );
            promises.then((response) => {
              if (response) {
                // setLoader(false)
                konsole.log(response,"responsetryeuiop")
                if (response.data.data.userSubjects.length !== 0) {
                  let responseData = response.data.data.userSubjects[0];
                  for (
                    let i = 0;
                    i < formlabelData[label].response.length;
                    i++
                  ) {
                    // konsole.log("datashownatcaregiver at", i, responseData);
                    if (formlabelData[label]?.questionId == 130) {
                      setSpecialInstructions(responseData?.response)
                    } else {
                    }
                    if (
                      formlabelData[label].response[i].responseId ===
                      responseData.responseId
                    ) {
                      if (responseData.responseNature == "Radio") {
                        formlabelData[label].response[i]["checked"] = true;
                        if (formlabelData[label].questionId == 108) {
                          setRadioValue(responseData.responseId);
                        } else {
                        }
                        if (formlabelData[label].questionId == 109) {
                          setRadioFuneralValueTwo(responseData.responseId);
                        } else {
                        }
                        if (formlabelData[label].questionId == 144) {
                          setRadioFuneralValueTwoburied(
                            responseData.responseId
                          );
                        } else {
                        }
                        if (formlabelData[label].questionId == 110) {
                          setRadioValueDisposed(responseData.responseId);
                        } else {
                        }
                        if (formlabelData[label].questionId == 111) {
                          setRadioNationalCemetery(responseData.responseId);
                        } else {
                        }
                        if (formlabelData[label].questionId == 133) {
                          setHelduntilseconddeath(responseData.responseId);
                        } else {
                        }
                        if (formlabelData[label].questionId == 201) {
                          setotherspecialinstrshow(responseData.responseId);
                        } else {
                        }

                        formlabelData[label]["userSubjectDataId"] =
                          responseData.userSubjectDataId;
                      } else if (responseData.responseNature == "Text") {
                        if (responseData.responseId == 264) {
                          setDisposedOther(responseData.response);
                        }
                        if (responseData.responseId == 265) {
                          setAshesOther(responseData.response);
                        }
                        if (responseData.responseId == 266) {
                          setHoldAshesOther(responseData.response);
                        }
                        if (responseData.responseId == 267) {
                          setnameofcontact(responseData.response);
                        }
                        if (responseData.responseId == 268) {
                          setnameoffuneral(responseData.response);
                        }
                        if (responseData.responseId == 269) {
                          setcontactnum(responseData.response);
                        }
                        if (responseData.responseId == 270) {
                          setPhonenum(responseData.response);
                        }
                        if (responseData.responseId == 271) {
                          setaddress(responseData.response);
                          form.setFieldsValue({
                            address: responseData.response,
                          });
                        }
                        if (responseData.responseId == 272) {
                          setwesite(responseData.response);
                        }
                        if (responseData.responseId == 273) {
                          setfaxnum(responseData.response);
                        }


                        if (responseData.responseId == 276) {
                          setnameofcontactburied(responseData.response);
                        }
                        if (responseData.responseId == 277) {
                          setnameoffuneralburied(responseData.response);
                        }
                        if (responseData.responseId == 278) {
                          setcontactnumburied(responseData.response);
                        }
                        if (responseData.responseId == 279) {
                          setPhonenumburied(responseData.response);
                        }
                        if (responseData.responseId == 280) {
                          setaddressburied(responseData.response);
                          form.setFieldsValue({
                            address: responseData.response,
                          });
                        }
                        if (responseData.responseId == 281) {
                          setwesiteburied(responseData.response);
                        }
                        if (responseData.responseId == 282) {
                          setfaxnumburied(responseData.response);
                        }

                        formlabelData[label].response[i]["response"] =
                          responseData.response;
                        formlabelData[label]["userSubjectDataId"] =
                          responseData.userSubjectDataId;
                      }
                    }
                  }
                }
              }
              setLoader(false)
            });
            setFormLabelData(formlabelData);
          }
        }
      });
    });
  };
  console.log("formdata", formlabelData);
  const onChange = (e) => {
    setRadioValue(e.target.id);
  };

  const onchangePhone = (value) => {
    // console.log("value",value);
    setPhonenum(value);
  };
  const onchangeContact = (value) => {
    // console.log("value",value);
    setcontactnum(value);
  };

  const onchangeFax = (value) => {
    // console.log("value",value);
    setfaxnum(value);
  };

  //buried data
  const onchangePhoneburied = (value) => {
    // console.log("value",value);
    setPhonenumburied(value);
  };
  const onchangeContactburied = (value) => {
    // console.log("value",value);
    setcontactnumburied(value);
  };

  const onchangeFaxburied = (value) => {
    // console.log("value",value);
    setfaxnumburied(value);
  };

  const onChangeFuneral = (e) => {
    setRadioFuneralValueTwo(e.target.id);
  };
  const onChangeFuneralburied = (e) => {
    setRadioFuneralValueTwoburied(e.target.id);
  };
  const onChangespecialinstrshow = (e) => {
    konsole.log(e,"ertyui")
    setotherspecialinstrshow(e.target.value);
  };
  const onChangeDisposed = (e) => {
    setRadioValueDisposed(e.target.id);
  };
  const onChangeNationalCemetery = (e) => {
    setRadioNationalCemetery(e.target.id);
  };
  const onChangeHelduntil = (e) => {
    setHelduntilseconddeath(e.target.id);
  };

  const shwoComponents = (e) => {
    selectSelectedStepper(e);
  };
  const shwoEndLifeComponent = (e) => {
    setChangeBorder(e);
  };
  const yesNoQuestionFun = (event) => {
    // setYesTrueStateOne(event)
    // setYesTrueStateTwo(event)
    // console.log("yesno", yesTrueStateOne)
    // console.log("yestwo", yesTrueStateTwo)
  };
  const formUploadFun = (e) => {
    // setYesTrueStateTwo(e)
  };
  const backButton = () => {
    selectSelectedStepper(2)
  }


  const Submitbutton = () => {
    // console.log("radioNationalCemetery", radioNationalCemetery);
    konsole.log(form.getFieldValue().address, "addressfiled");

    let arrdata =
      radioValue == 222
        ? [
            {
              userSubjectDataId: formlabelData.label905?.userSubjectDataId,
              subjectId: formlabelData.label905?.questionId,
              responseId: radioValue,
              userId: memberuserId,
            },
            {
              userSubjectDataId: formlabelData.label906?.userSubjectDataId,
              subjectId: formlabelData.label906?.questionId,
              responseId: radioFuneralValueTwo,
              userId: memberuserId,
            },

            {
              userSubjectDataId: formlabelData.label907?.userSubjectDataId,
              subjectId: formlabelData.label907?.questionId,
              responseId: radioValueDisposed,
              userId: memberuserId,
            },
          ]
        : radioValue == 223
        ? [
            {
              userSubjectDataId: formlabelData.label905?.userSubjectDataId,
              subjectId: formlabelData.label905?.questionId,
              responseId: radioValue,
              userId: memberuserId,
            },
            {
              userSubjectDataId: formlabelData.label906?.userSubjectDataId,
              subjectId: formlabelData.label906?.questionId,
              responseId: radioFuneralValueTwo,
              userId: memberuserId,
            },
            {
              userSubjectDataId: formlabelData.label940?.userSubjectDataId,
              subjectId: formlabelData.label940?.questionId,
              responseId: radioFuneralValueTwoburied,
              userId: memberuserId,
            },
          ]
        : radioValue == 224
        ? [
            {
              userSubjectDataId: formlabelData.label905?.userSubjectDataId,
              subjectId: formlabelData.label905?.questionId,
              responseId: radioValue,
              userId: memberuserId,
            },
            {
              userSubjectDataId: formlabelData.label930?.userSubjectDataId
                ? formlabelData.label930?.userSubjectDataId
                : 0,
              subjectId: formlabelData.label930?.questionId,
              subResponseData: disposedOther,
              responseId: formlabelData.label930?.response[0].responseId,
            },
          ]
        : [];

    
        if( Veteanstatus && radioValue == 222){
          let NationalCemeterydata =  {
            userSubjectDataId: formlabelData.label908?.userSubjectDataId,
            subjectId: formlabelData.label908?.questionId,
            userId: memberuserId,
            responseId: radioNationalCemetery,
          }
          arrdata.push(NationalCemeterydata)
        }
            if (radioValueDisposed == 230) {
      let newdata = {
        userSubjectDataId: formlabelData.label931?.userSubjectDataId
          ? formlabelData.label931?.userSubjectDataId
          : 0,
        subjectId: formlabelData.label931?.questionId,
        subResponseData: ashesOther,
        responseId: formlabelData.label931?.response[0].responseId,
      };
      arrdata.push(newdata);
    }
    if (Helduntilseconddeath == 263) {
      let newdata = {
        userSubjectDataId: formlabelData.label932?.userSubjectDataId
          ? formlabelData.label932?.userSubjectDataId
          : 0,
        subjectId: formlabelData.label932?.questionId,
        subResponseData: holdashesOther,
        responseId: formlabelData.label932?.response[0].responseId,
      };
      let newdata2 = {
        userSubjectDataId: formlabelData.label929?.userSubjectDataId
          ? formlabelData.label929?.userSubjectDataId
          : 0,
        subjectId: formlabelData.label929.questionId,
        responseId: Helduntilseconddeath,
        userId: memberuserId,
      };

      arrdata.push(newdata);
      arrdata.push(newdata2);
    } else if (Helduntilseconddeath == 261 || Helduntilseconddeath == 262) {
      let newdata = {
        userSubjectDataId: formlabelData.label929?.userSubjectDataId
          ? formlabelData.label929?.userSubjectDataId
          : 0,
        subjectId: formlabelData.label929.questionId,
        responseId: Helduntilseconddeath,
        userId: memberuserId,
      };

      arrdata.push(newdata);
    } else {
    }

    if(radioFuneralValueTwo === 225 && radioValue !== 223){

    // if (
    //   // (
    //   //   errorMessage == true &&
    //   //   typeof wesite === "string" &&
    //   //   wesite.length !== 0)
    //   //    ||
    //   (contactnum.length !== 0 && contactnum.length !== 10) ||
    //   (Phonenum.length !== 0 && Phonenum.length !== 10) ||
    //   (faxnum.length !== 0 && faxnum.length !== 10)
    // ) {
    //   // if (contactnum.length !== 0 && contactnum.length !== 10) {
    //   //   Toaster.error("Contact number is not valid");
    //   // } else if (Phonenum.length !== 0 && Phonenum.length !== 10) {
    //   //   Toaster.error("Cell number is not valid");
    //   // } else if (faxnum.length !== 0 && faxnum.length !== 10) {
    //   //   Toaster.error("Fax number is not valid");
    //   // } else {
    //   // }
    //   // if (errorMessage == true && wesite?.length != 0) {
    //   //   // Toaster.error("Url is not valid");
    //   // } else {
    //   // }
    // } else {
      if (radioFuneralValueTwo == 225) {
        konsole.log(contactnum?.length,"contactnum.length")
        let uplaodinfo = [
          {
            userSubjectDataId: formlabelData.label933?.userSubjectDataId
              ? formlabelData.label933?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label933?.questionId,
            subResponseData: nameofcontact,
            responseId: formlabelData.label933?.response[0].responseId,
          },
          {
            userSubjectDataId: formlabelData.label934?.userSubjectDataId
              ? formlabelData.label934?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label934?.questionId,
            subResponseData: nameoffuneral,
            responseId: formlabelData.label934?.response[0].responseId,
          },

          {
            userSubjectDataId: formlabelData.label935?.userSubjectDataId
              ? formlabelData.label935?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label935?.questionId,
            subResponseData:(contactnum?.length == 10) ? contactnum : null,
            responseId: formlabelData.label935?.response[0].responseId,
          },
          {
            userSubjectDataId: formlabelData.label936?.userSubjectDataId
              ? formlabelData.label936?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label936?.questionId,
            subResponseData: (Phonenum?.length == 10) ? Phonenum : null,
            responseId: formlabelData.label936?.response[0].responseId,
          },
          {
            userSubjectDataId: formlabelData.label937?.userSubjectDataId
              ? formlabelData.label937?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label937?.questionId,
            subResponseData: form.getFieldValue().address,
            responseId: formlabelData.label937?.response[0].responseId,
          },
          {
            userSubjectDataId: formlabelData.label938?.userSubjectDataId
              ? formlabelData.label938?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label938?.questionId,
            subResponseData: errorMessage == true ? null : wesite,
            responseId: formlabelData.label938?.response[0].responseId,
          },
          {
            userSubjectDataId: formlabelData.label939?.userSubjectDataId
              ? formlabelData.label939?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label939?.questionId,
            subResponseData:  (faxnum?.length == 10) ? faxnum : null,
            responseId: formlabelData.label939?.response[0].responseId,
          },
        ];

        arrdata.push(uplaodinfo);
      }
    }

  
  if(radioFuneralValueTwoburied === 274 && radioValue === 223 ){
    // if (
    //   (contactnumburied.length !== 0 && contactnumburied.length !== 10) ||
    //   (Phonenumburied.length !== 0 && Phonenumburied.length !== 10) ||
    //   (faxnumburied.length !== 0 && faxnumburied.length !== 10)
    // ) {
    //   if (contactnumburied.length !== 0 && contactnumburied.length !== 10) {
    //     Toaster.error("Contact number is not valid");
    //   } else if (Phonenumburied.length !== 0 && Phonenumburied.length !== 10) {
    //     Toaster.error("Cell number is not valid");
    //   } else if (faxnumburied.length !== 0 && faxnumburied.length !== 10) {
    //     Toaster.error("Fax number is not valid");
    //   } else {
    //   }
    //   // if (errorMessage == true && wesiteburied?.length != 0) {
    //   //   // Toaster.error("Url is not valid");
    //   // } else {
    //   // }
    // } else {
      konsole.log(radioFuneralValueTwo,"radioFuneralValueTwo77878787")
      if (radioFuneralValueTwoburied == 274) {
        let uplaodinfo = [
          {
            userSubjectDataId: formlabelData.label941?.userSubjectDataId
              ? formlabelData.label941?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label941?.questionId,
            subResponseData: nameofcontactburied,
            responseId: formlabelData.label941?.response[0].responseId,
          },
          {
            userSubjectDataId: formlabelData.label942?.userSubjectDataId
              ? formlabelData.label942?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label942?.questionId,
            subResponseData: nameoffuneralburied,
            responseId: formlabelData.label942?.response[0].responseId,
          },

          {
            userSubjectDataId: formlabelData.label943?.userSubjectDataId
              ? formlabelData.label943?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label943?.questionId,
            subResponseData:  (contactnumburied?.length == 10) ? contactnumburied : null,
            responseId: formlabelData.label943?.response[0].responseId,
          },
          {
            userSubjectDataId: formlabelData.label944?.userSubjectDataId
              ? formlabelData.label944?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label944?.questionId,
            subResponseData:  (Phonenumburied?.length == 10) ? Phonenumburied : null,
            responseId: formlabelData.label944?.response[0].responseId,
          },
          {
            userSubjectDataId: formlabelData.label945?.userSubjectDataId
              ? formlabelData.label945?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label945?.questionId,
            subResponseData: form.getFieldValue().address,
            responseId: formlabelData.label945?.response[0].responseId,
          },
          {
            userSubjectDataId: formlabelData.label946?.userSubjectDataId
              ? formlabelData.label946?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label946?.questionId,
            subResponseData: errorMessage == true ? null : wesiteburied,
            responseId: formlabelData.label946?.response[0].responseId,
          },
          {
            userSubjectDataId: formlabelData.label947?.userSubjectDataId
              ? formlabelData.label947?.userSubjectDataId
              : 0,
            subjectId: formlabelData.label947?.questionId,
            subResponseData:  (faxnumburied?.length == 10) ? faxnumburied : null,
            responseId: formlabelData.label947?.response[0].responseId,
          },
        ];

        arrdata.push(uplaodinfo);
      }
    }
  // }

    let dataArr = arrdata.flat(1);
    const dataObj = {
      userId: memberuserId,
      userSubjects: dataArr,
    };
    if(errorMessage == true){ 
      Toaster.warning("Url is not valid")
    }else{
      putaddusersubject(dataObj);
    }
    konsole.log(dataArr,"dataArrdataArr")
  };

  const putaddusersubject = (dataObj) => {
    let promise = Services.putaddusersubjectapi(dataObj);
    promise
      .then((res) => {
        // if (errorMessage === false) {s
        Toaster.success("Data Saved Successfully");
        selectSelectedStepper(2)
        // }
        // getsubjectForFormLabelId(memberUserId);
      })
      .catch((err) => {
        Toaster.warning("All Questions are required.");
        konsole.log("PutQuestionData", err);
      });
  };

  const postSubjectDatastep3 = () => {
    let inputArray = {
      userId: memberuserId,
      userSubjects: [
        {
          userSubjectDataId: formlabelData.label927?.userSubjectDataId
            ? formlabelData.label927?.userSubjectDataId
            : 0,
          subjectId: formlabelData.label927?.questionId,
          subResponseData: specialInsAns,
          responseId: formlabelData.label927?.response[0].responseId,
        },
        {
          userSubjectDataId: formlabelData.label997?.userSubjectDataId
          ? formlabelData.label997?.userSubjectDataId
          : 0,
        subjectId: formlabelData.label997.questionId,
        responseId: otherspecialinstrshow,
        userId: memberuserId,
        }
      ],
    };
    if(otherspecialinstrshow == null){
      Toaster.warning("Please make selection and save")
    }
    konsole.log(otherspecialinstrshow,"otherspecialinstrshow")
    let promises = Services.upsertSubjectdata("PUT", inputArray);

    promises
      .then((res) => {
        konsole.log("successful", res);
        Toaster.success("Data Saved Successfully");
        getsubjectForFormLabelId(memberUserId);
        shwoComponents(4)
        // return showFrom == 1;
      })
      .catch((error) => konsole.log("error", error.response));

    //  return showFrom == 3;
  };

  return (
    <div>
      <div
         className="mt-3 ms-3"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div style={{ width: 810 }}>
          <Steps
            className="custome-step"
            labelPlacement="vertical"
            current={selectedStepper - 1}
            size="small"
          >
            <Step
              title="Immediate Actions"
              onClick={(e) => shwoComponents(1)}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Notify"
              onClick={(e) => shwoComponents(2)}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Special Instructions"
              onClick={() => shwoComponents(3)}
              style={{ cursor: "pointer" }}
            />
            <Step
              title="Agents Guidance"
              onClick={() => shwoComponents(4)}
              style={{ cursor: "pointer" }}
            />
          </Steps>
        </div>
      </div>

      {selectedStepper == 4 ? (
        <div className="Question-Card-Div">
          <AgentPreview memberUserId={props?.memberid} selectSelectedStepper={selectSelectedStepper} />
        </div>
      ) : selectedStepper == 3 ? (
        <Row className="Question-Card-Div">
          <Col lg={24} className="w-100">
        <UploadFile memberUserId={props?.memberid} />
          <Row>
                <Col>
                  {formlabelData.label997 && (
                    <div className="mt-2">
                      <h6>{formlabelData.label997.question}</h6>
                      {
                        <Radio.Group
                          name="organizationCare"
                          value={otherspecialinstrshow}
                          onChange={onChangespecialinstrshow}
                        >
                          {formlabelData.label997.response.map(
                            (answer, index) => {
                              return (
                                <Radio
                                  value={answer.responseId}
                                  className="ms-1"
                                >
                                  {answer.response}
                                </Radio>
                              );
                            }
                          )}
                        </Radio.Group>
                      }
                    </div>
                  )}
                </Col>
              </Row>
            {otherspecialinstrshow == 393 && <Row className="d-flex justify-content-center pt-2">
              <Col lg={23} className="textEditor-Col w-100">
                <div className="mb-2" style={{ border: "1px solid white" }}>
                  {formlabelData.label927 && (
                    <div className="mt-3">
                      <h6 className="mt-2" >{formlabelData.label927.question}</h6>
                      <TextArea
                        rows={4}
                        placeholder="Please type something here"
                        defaultValue={
                          formlabelData.label927?.response[0].response
                        }
                        value={specialInsAns}
                        onChange={(e) => {
                          setSpecialInsAns(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>}
            <div className="Sava-Button-Div d-flex justify-content-between">
              <button className="Save-Button"
                onClick={backButton}
              >
                Back

              </button>
              <button className="Save-Button" onClick={postSubjectDatastep3}>
          Save & Proceed
                
              </button>
            </div>
          </Col>
        </Row>
      ) : selectedStepper == 2 ? (
        // <Row className="Question-Card-Div">
        //   <Col lg={22} xl={22}>
        //     <div>
        //       <h6>Select From Friends and Family Contacts</h6>
        //       <div className="d-flex">
        //         <Select className="w-50 m-0" defaultValue="Please Select">
        //           <option>1</option>
        //           <option>2</option>
        //           <option>3</option>
        //           <option>4</option>
        //         </Select>
        //         <div className="px-3" style={{ width: "170px" }}>
        //           <Link to="#">Add Contacts</Link>
        //         </div>
        //       </div>
        //       <h6 className="mt-2">Please Select Notification Type </h6>
        //       <CheckboxGroup options={plainOptions} />
        //       <TextArea rows={4} />
        //     </div>

        //     <div className="mt-4">
        //       <h6>Select From Personal Contacts</h6>
        //       <div className="d-flex">
        //         <Select className="w-50 m-0" defaultValue="Please Select">
        //           <option>1</option>
        //           <option>2</option>
        //           <option>3</option>
        //           <option>4</option>
        //         </Select>
        //         <div className="px-3" style={{ width: "170px" }}>
        //           <Link to="#">Add Contacts</Link>
        //         </div>
        //       </div>
        //       <h6 className="mt-2">Please Select Notification Type </h6>
        //       <CheckboxGroup options={plainOptions} />
        //       <TextArea rows={4} />
        //     </div>

        //     <div className="mt-4">
        //       <h6>Select From Professional Contacts</h6>
        //       <div className="d-flex">
        //         <Select className="w-50 m-0" defaultValue="Please Select">
        //           <option>1</option>
        //           <option>2</option>
        //           <option>3</option>
        //           <option>4</option>
        //         </Select>
        //         <div className="px-3" style={{ width: "170px" }}>
        //           <Link to="#">Add Contacts</Link>
        //         </div>
        //       </div>
        //       <h6 className="mt-2">Please Select Notification Type </h6>
        //       <CheckboxGroup options={plainOptions} />
        //       <TextArea rows={4} />
        //     </div>

        //     <div className="mt-4">
        //       <h6>Select From Everyone</h6>
        //       <div className="d-flex">
        //         <Select className="w-50 m-0" defaultValue="Please Select">
        //           <option>1</option>
        //           <option>2</option>
        //           <option>3</option>
        //           <option>4</option>
        //         </Select>
        //         <div className="px-3" style={{ width: "170px" }}>
        //           <Link to="#">Add Contacts</Link>
        //         </div>
        //       </div>
        //       <h6 className="mt-2">Please Select Notification Type </h6>
        //       <CheckboxGroup options={plainOptions} />
        //       <TextArea rows={4} />
        //     </div>

        //     <div className="Sava-Button-Div">
        //       <button className="Save-Button">Save </button>
        //     </div>
        //   </Col>
        // </Row>
        <div className="Question-Card-Div">
          <ImmediateActions id={3} memberUserId={memberUserId} selectSelectedStepper={selectSelectedStepper} />
        </div>
      ) : (
        <Row className="Question-Card-Div">
          <Col xl={24}>
            <div>
              <div >
                {formlabelData.label905 && (
                  <>
                  {konsole.log(radioValue,"radioValueradioValue")}
                    <h6 className="mt-2">{formlabelData.label905.question}</h6>
                    <Radio.Group name="" value={radioValue} onChange={onChange}>
                      {formlabelData.label905.response.map((answer, id) => {
                        return (
                          <Radio
                            value={answer.responseId}
                            id={answer.responseId}
                            className="ms-1"
                          >
                            {answer.response}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </>
                )}
              </div>

              <div className={`${radioValue === 224 && "Hide-Div"}`}>
                <div className="mt-3">
                  {formlabelData.label905 &&
                    formlabelData.label906 &&
                    radioValue !== 223 && (
                      <>
                        <h6 className="mt-2">{formlabelData.label906.question}</h6>
                        <Radio.Group
                          name=""
                          onChange={onChangeFuneral}
                          value={radioFuneralValueTwo}
                        >
                          {formlabelData.label906.response.map(
                            (answer, index) => {
                              return (
                                <Radio
                                  value={answer.responseId}
                                  id={answer.responseId}
                                  className="ms-1"
                                >
                                  {answer.response}
                                </Radio>
                              );
                            }
                          )}
                        </Radio.Group>
                      </>
                    )}
                  {formlabelData.label905 &&
                    formlabelData.label940 &&
                    radioValue === 223 && (
                      <>
                        <h6 className="mt-2">{formlabelData.label940.question}</h6>
                        <Radio.Group
                          name=""
                          onChange={onChangeFuneralburied}
                          value={radioFuneralValueTwoburied}
                        >
                          {formlabelData.label940.response.map(
                            (answer, index) => {
                              return (
                                <Radio
                                  value={answer?.responseId}
                                  id={answer?.responseId}
                                  className="ms-1"
                                >
                                  {answer.response}
                                </Radio>
                              );
                            }
                          )}
                        </Radio.Group>
                      </>
                    )}
                </div>

                <div
                  className={`${
                    radioFuneralValueTwo === 225 && radioValue !== 223
                      ? "Show-Div"
                      : "Hide-Div"
                  }`}
                >
                  <div className="mt-3">
                    <div>
                      <h6 className="mt-2">Mention the details</h6>
                      <Row className="mt-3">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Name of Contact</h6>
                          <Input
                            type="text"
                            defaultValue={
                              formlabelData.label933?.response[0].response
                            }
                            placeholder="Name of Contact"
                            pattern="[a-zA-Z'-'\s]*"
                            value={nameofcontact}
                            onChange={(e) => {
                              const result = e.target.value.replace(
                                /[^a-zA-Z ]/gi,
                                ""
                              );
                              setnameofcontact(result);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Name of Funeral Home</h6>
                          <Input
                            placeholder="Name of Funeral Home"
                            defaultValue={
                              formlabelData.label934?.response[0].response
                            }
                            value={nameoffuneral}
                            onChange={(e) => {
                              const result = e.target.value.replace(
                                /[^a-zA-Z ]/gi,
                                ""
                              );
                              setnameoffuneral(result);
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Contact Number</h6>

                          <Input.Group>
                            <InputMask
                              value={contactnum}
                              onValueChange={onchangeContact}
                              placeholder={"Contact Number"}
                            />
                          </Input.Group>

                        </Col>

                 
                      </Row>
                      {(contactnum?.length > 0 && contactnum?.length !== 10) && <p style={{ color: "#d2222d" }}>Contact Number is not valid</p> }
                      <Row className="mt-2">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Cell Number</h6>

                          <Input.Group>
                            <InputMask
                              value={Phonenum}
                              onValueChange={onchangePhone}
                              placeholder="Cell Number"
                            />
                          </Input.Group>
                        </Col>
                      </Row>
                      {(Phonenum?.length > 0 && Phonenum?.length !== 10) && <p style={{ color: "#d2222d" }}>Cell Number is not valid</p> }
                      <Row className="mt-2">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Address</h6>

                          <Address
                            form={form}
                            address={address}
                            hidebtn={hidebtn}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Website</h6>
                          <input
                            className="ant-input"
                            defaultValue={
                              formlabelData.label938?.response[0].response
                            }
                            type="url"
                            placeholder="https://example.com"
                            pattern="https://.*"
                            size="30"
                            required
                            value={wesite}
                            onChange={(e) => isUrlValid(e.target.value)}
                          />
                      {errorMessage == true && wesite?.length != 0 ? (
                        <p style={{ color: "#d2222d" }}>Url is not valid</p>
                      ) : null} 
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Fax Number</h6>
                          <Input.Group>
                            <InputMask
                              value={faxnum}
                              onValueChange={onchangeFax}
                              placeholder={"Fax Number"}
                            />
                          </Input.Group>
                        </Col>
                      </Row>
                      {(faxnum?.length > 0 && faxnum?.length !== 10) && <p style={{ color: "#d2222d" }}>Fax Number is not valid</p> }
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    radioFuneralValueTwoburied === 274 && radioValue == 223
                      ? "Show-Div"
                      : "Hide-Div"
                  }`}
                >
                  <div className="mt-3">
                    <div>
                      <h6>Mention the details</h6>

                      <Row className="mt-3">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Name of Contact</h6>

                          <Input
                            type="text"
                            defaultValue={
                              formlabelData.label941?.response[0].response
                            }
                            placeholder="Name of Contact"
                            pattern="[a-zA-Z'-'\s]*"
                            value={nameofcontactburied}
                            onChange={(e) => {
                              const result = e.target.value.replace(
                                /[^a-zA-Z ]/gi,
                                ""
                              );
                              setnameofcontactburied(result);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Name of Funeral Home</h6>
                          <Input
                            placeholder="Name of Funeral Home"
                            defaultValue={
                              formlabelData.label942?.response[0].response
                            }
                            value={nameoffuneralburied}
                            onChange={(e) => {
                              const result = e.target.value.replace(
                                /[^a-zA-Z ]/gi,
                                ""
                              );
                              setnameoffuneralburied(result);
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Contact Number</h6>

                          <Input.Group>
                            <InputMask
                              value={contactnumburied}
                              onValueChange={onchangeContactburied}
                              placeholder="Contact Number"
                            />
                          </Input.Group>
                        </Col>
                      </Row>
                      {(contactnumburied?.length > 0 && contactnumburied?.length !== 10) && <p style={{ color: "#d2222d" }}>Contact Number is not valid</p> }
                      <Row className="mt-2">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Cell Number</h6>

                          <Input.Group>
                            <InputMask
                              value={Phonenumburied}
                              onValueChange={onchangePhoneburied}
                              placeholder="Cell Number"
                            />
                          </Input.Group>
                        </Col>
                      </Row>
                      {(Phonenumburied?.length > 0 && Phonenumburied?.length !== 10) && <p style={{ color: "#d2222d" }}>Cell Number is not valid</p> }
                      <Row className="mt-2">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Address</h6>
                          <Address
                            form={form}
                            address={addressburied}
                            hidebtn={hidebtn}
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Website</h6>
                          <input
                            className="ant-input"
                            defaultValue={
                              formlabelData.label946?.response[0].response
                            }
                            type="url"
                            placeholder="https://example.com"
                            pattern="https://.*"
                            size="30"
                            required
                            value={wesiteburied}
                            onChange={(e) => isUrlValidburied(e.target.value)}
                          />
                        </Col>
                       
                      </Row>
                      {errorMessage == true && wesiteburied?.length != 0 ? (
                        <p style={{ color: "#d2222d" }}>Url is not valid</p>
                      ) : null} 
                      <Row className="mt-2">
                        <Col xs={18} sm={18} md={18} lg={16} xl={9}>
                          <h6 className="mb-0">Fax Number</h6>
                          <Input.Group>
                            <InputMask
                              value={faxnumburied}
                              onValueChange={onchangeFaxburied}
                              placeholder="Fax Number"
                            />
                          </Input.Group>
                        </Col>
                      </Row>
                      {(faxnumburied?.length > 0 && faxnumburied?.length !== 10) && <p style={{ color: "#d2222d" }}>Fax Number is not valid</p> }
                    </div>
                  </div>
                </div>
                <div
                  className={`${
                    radioValue === 222 ||
                    (radioValue == 224 && radioValue == 223)
                      ? "Show-Div"
                      : "Hide-Div"
                  } mt-3`}
                >
                  <div className="mt-3">
                    {formlabelData.label907 && (
                      <>
                        <h6>{formlabelData.label907.question}</h6>
                        <Radio.Group
                          name=""
                          onChange={onChangeDisposed}
                          value={radioValueDisposed}
                        >
                          {formlabelData.label907.response.map(
                            (answer, index) => {
                              return (
                                <Radio
                                  value={answer.responseId}
                                  id={answer.responseId}
                                  className="ms-1"
                                >
                                  {answer.response}
                                </Radio>
                              );
                            }
                          )}
                        </Radio.Group>
                      </>
                    )}
                  </div>
                  <div
                    className={`${
                      radioValueDisposed === 224 ? "Show-Div" : "Hide-Div"
                    }`}
                  >
                    <div className="mt-3">
                      <input type="text" className="w-50 ant-input" />
                    </div>
                  </div>

                  <div
                    className={`${
                      radioValueDisposed === 230 ? "Show-Div" : "Hide-Div"
                    }`}
                  >
                    <div className="mt-3">
                      {formlabelData.label931 && (
                        <input
                          type="text"
                          className="w-50 ant-input"
                          defaultValue={
                            formlabelData.label931?.response[0].response
                          }
                          value={ashesOther}
                          onChange={(e) => {
                            setAshesOther(e.target.value);
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div
                    className={`${
                      radioValueDisposed === 227 ? "Show-Div" : "Hide-Div"
                    }`}
                  >
                    <div className="mt-3">
                      {formlabelData.label929 && (
                        <Radio.Group
                          name=""
                          onChange={onChangeHelduntil}
                          value={Helduntilseconddeath}
                        >
                          {formlabelData.label929.response.map(
                            (answer, index) => {
                              return (
                                <Radio
                                  value={answer.responseId}
                                  id={answer.responseId}
                                  className="ms-1"
                                >
                                  {answer.response}
                                </Radio>
                              );
                            }
                          )}
                        </Radio.Group>
                      )}
                    </div>
                  </div>
                  <div
                    className={`${
                      Helduntilseconddeath === 263 && radioValueDisposed === 227
                        ? "Show-Div"
                        : "Hide-Div"
                    }`}
                  >
                    <div className="mt-3">
                      {formlabelData.label932 && (
                        <input
                          type="text"
                          className="w-50 ant-input"
                          defaultValue={
                            formlabelData.label932?.response[0].response
                          }
                          value={holdashesOther}
                          onChange={(e) => {
                            setHoldAshesOther(e.target.value);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="mt-3">
                    {Veteanstatus && formlabelData.label908 && (
                      <>
                        <h6>{formlabelData.label908.question}</h6>
                        <Radio.Group
                          name=""
                          onChange={onChangeNationalCemetery}
                          value={radioNationalCemetery}
                        >
                          {formlabelData.label908.response.map(
                            (answer, index) => {
                              return (
                                <Radio
                                  value={answer.responseId}
                                  id={answer.responseId}
                                  className="ms-1"
                                >
                                  {answer.response}
                                </Radio>
                              );
                            }
                          )}
                        </Radio.Group>
                      </>
                    )}
                  </div>

                  <div
                    className={`${
                      radioNationalCemetery === 231 ? "Show-Div" : "Hide-Div"
                    } mt-2`}
                  >
                    <h6>Follwed Link</h6>
                    <div>
                      <a href="https://www.cem.va.gov/">
                        https://www.cem.va.gov
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`${
                  radioValue === 224 || (radioValue == 222 && radioValue == 223)
                    ? "Show-Div"
                    : "Hide-Div"
                } mt-3`}
              >
                {formlabelData.label930 && (
                  <input
                    type="text"
                    className="w-50 ant-input"
                    defaultValue={formlabelData.label930?.response[0].response}
                    value={disposedOther}
                    onChange={(e) => {
                      setDisposedOther(e.target.value);
                    }}
                  />
                )}
              </div>
            </div>

            <div className="Sava-Button-Div">
              <button
                className="Save-Button"
                htmlType="submit"
                type="submit"
                onClick={Submitbutton}
              >
                          Save & Proceed

              </button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Death;
