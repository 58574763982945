import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Typography,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Select,
} from "antd";
import Address from "../../address";
import Services from "../../../network/Services";
import konsole from "../../../network/konsole";
import commonLib from "../../../control/commonLib";
import FormItem from "antd/lib/form/FormItem";
import Toaster from "../../Toaster";
import { UserContext } from "../../../App";
import Other from "../../Other";
import "../../MemberInfo/common/addEditContact.css";
const { Text } = Typography;

export default function AddEditAddress(props) {
  const { disable, modalOnDisable, getSavebtn, setGetSaveBtn } =
    useContext(UserContext);
  const [form] = Form.useForm();
  const useWatch = (name) => {
    return Form.useWatch(name, form);
  };

  const addressTypeId = useWatch("addressTypeId");
  const [addressType, setAddressType] = useState([]);
  const loggedInUserId =
    commonLib.getObjFromStorage("stateObj")?.userId || "demo";
  const editAddressActionType = props.editAddressActionType;
  const editAddressObj = props.editAddressObj;
  const addressRef = useRef(null);
  const chooseaddresstypelist =
    useWatch("addressTypeId")
      ? addressType?.filter((object1) => {
        if (object1.value == "999999") return true
        if (object1?.value == addressTypeId) return true
        return !props?.address?.some((object2) => {
          return object1?.value == object2?.addressTypeId;
        });
      })
      :
      addressType?.filter((object1) => {
        if (object1.value == "999999") return true
        return !props?.address?.some((object2) => {
          return object1?.value == object2?.addressTypeId;
        });
      });
  const [addressfiledError, setAddressfieldError] = useState(false);

  useEffect(() => {
    getAddressTypeList();
    konsole.log("addressEdit Show", editAddressObj);
    if (editAddressActionType == "EDIT") {
      form.setFieldsValue(editAddressObj);
      form.setFieldsValue({
        address: editAddressObj.addressLine1,
        suite: editAddressObj.addressLine2,
      });

      konsole.log("addressEdit Show1", form.getFieldsValue());
    }
  }, []);

  useEffect(() => {
    if (getSavebtn == true) {
      postAddressByUserId(props.userId);
    }
  }, [getSavebtn]);

  console.log(
    "formfields",
    form.getFieldValue(),
    "rpops.watch",
    useWatch("addressTypeId")
  );

  const getAddressTypeList = () => {
    const promises = Services.getAddressType();
    promises.then((res) => {
      konsole.log("address type", res);
      setAddressType(res.data.data);
    });
  };

  const handleChange = (value) => {
    console.log(`Selected: ${value}`);
  };

  const handleClick = () => {
    form.submit();
    konsole.log("asda");

    form.validateFields().then((err, values) => {
      if (
        err.address !== "" &&
        err.streetAddress !== "" &&
        err.city !== "" &&
        err.state !== "" &&
        err.zipcode !== "" &&
        err.country !== ""
      ) {
        setAddressfieldError(false);
        return modalOnDisable();
      } else {
        setAddressfieldError(true);
      }
      // props.onOk();
      // console.log('Received values of form: ', values);
    });
    // postAddressByUserId(props.userId);
  };

  const postAddressByUserId = (userId) => {
    const formSavedData = form.getFieldsValue();
    konsole.log("savedAddress", formSavedData);
    const addressTypeId = formSavedData.addressTypeId;
    const addressLine1 = formSavedData.address;
    const zipcode = formSavedData.zipcode;
    const county = formSavedData.county;
    const Suite = formSavedData.suite;
    const city = formSavedData.city;
    const country = formSavedData.country;
    const state = formSavedData.state;
    const createdBy = loggedInUserId;
    const method = editAddressActionType === "EDIT" ? "PUT" : "POST";

    const promises = Services.postaddAddressbyuserId(
      method,
      userId,
      addressLine1,
      zipcode,
      county,
      Suite,
      city,
      state,
      country,
      addressTypeId,
      createdBy,
      editAddressObj?.addressId
    );
    promises
      .then((res) => {
        konsole.log("addressRes", res.data.data.addresses[0].addressId);
        konsole.log("savedAddressreess", res.data.data);
        const addressResponse = res.data.data.addresses[0];
        if (addressTypeId == "999999") {
          addressRef.current.saveHandleOther(addressResponse.addressId);
        }
        props.getAdressListByUserId(res.data.data?.userId);
        setGetSaveBtn(false);

        props.onOk();
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.data.Messages !== undefined) {
          Toaster.warning(err.response.data.Messages[0]);
        } else if (err.response.data.messages !== undefined) {
          Toaster.warning(err.response.data.messages[0]);
        }
      })
      .finally(() => {
        setGetSaveBtn(false);
      });
  };

  return (
    <Modal
      title={props.title}
      visible={props.visible}
      onOk={handleClick}
      onCancel={props.onCancel}
      okText="Save"
      width={1000}
      className="my-modal-class"
      maskClosable={false}
    >
      <Row>
        <Col span={24}>
          <Form form={form}>
            <div className="addressComponent">
              <Row
                xs={24}
                lg={24}
                span={24}
                className="pb-4 d-flex align-items-center"
                gutter={[16, 16]}
              >
                <Col xs={24} lg={8} span={8}>
                  <Form.Item
                    name="addressTypeId"
                    rules={[
                      {
                        required: true,
                        message: "Please choose your address type",
                      },
                    ]}
                  >
                    <select
                      className="form-select form-select-sm"
                      placeholder={commonLib.mandatory("Choose Address Type")}
                      onChange={handleChange}
                      size=""
                      defaultValue={1}
                    >
                      <option disabled selected>
                        {commonLib.mandatory("Please Choose Address Type")}
                      </option>
                      {chooseaddresstypelist.map((props) => (
                        <option value={props.value}>{props.label}</option>
                      ))}
                    </select>
                  </Form.Item>
                </Col>
                <Col xs={24} lg={8} span={8}>
                  {addressTypeId == "999999" && (
                    <Form.Item
                      name="Other"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the Other value",
                        },
                      ]}
                    >
                      <Other
                        othersCategoryId={2}
                        userId={props.userId}
                        dropValue={addressTypeId}
                        ref={addressRef}
                        natureId={editAddressObj.addressId}
                        form={form}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>

              <Address form={form} addressfiledError={addressfiledError} setAddressfieldError={setAddressfieldError} />
            </div>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}
