import React, { useState, useEffect } from "react";
import {
  Typography,
  Row,
  Col,
  Input,
  Checkbox,
  Select,
  Form,
  DatePicker,
} from "antd";
import commonLib from "../../../control/commonLib";
import Services from "../../../network/Services";
import konsole from "../../../network/konsole";
const { Text } = Typography;

const RelationshipInfo = (props) => {
  const [relationshiparray, setrelationshiparray] = useState([]);
  let primaryUserId = commonLib.getObjFromStorage(
    "userPrimaryInDetail"
  ).memberUserId;
  let spouseUserId = commonLib.getObjFromStorage(
    "userPrimaryInDetail"
  ).spouseUserId;
  konsole.log("spouseUserId",spouseUserId)
  const userId = props.userId;
  konsole.log(props, "6767porp");
  useEffect(() => {
    getrelationshiptypefunc();
  }, [props.genderCheck]);

  const getrelationshiptypefunc = () => {
    let getrelationshiptypeparams = Services.getrelationshiptype();
    getrelationshiptypeparams
      .then((response) => {
        konsole.log(response.data.data, "response");
        let relationships = response.data.data;
        if (props.form.getFieldsValue().relationshipwithprimary == 3) {
          let filterRelationship = relationships.filter((e) => {
            return e.value == 3;
          });
          setrelationshiparray(filterRelationship);
        } else if (
          props.form.getFieldsValue().relationshipwithprimary == 1 ||
          props.form.getFieldsValue().relationshipwithprimary == 44 ||
          props.form.getFieldsValue().relationshipwithprimary == 47 ||
          props.form.getFieldsValue().relationshipwithprimary == 48
        ) {
          let filterRelationship = relationships.filter((e) => {
            return e.value == 44 || e.value == 47 || e.value == 48;
          });
          if (props.form.getFieldsValue().relationshipwithprimary == 1) {
            props.form.setFieldsValue({
              relationshipwithprimary: -1,
            });
          }
          setrelationshiparray(filterRelationship);
        } else if (props.form.getFieldsValue().relationshipwithprimary == 2) {
          let filterRelationship = relationships.filter((e) => {
            return (
              // e.value == 41 ||
              // e.value == 44 ||
              // e.value == 47 ||
              // e.value == 48 ||
              // e.value == 2 ||
              // e.value == 28 ||
              // e.value == 29
              (props?.genderCheck == 1 ? (
                e.value == "2" ||
                e.value == "41" ||
                e.value == "5" ||
                e.value == "29")
                : props?.genderCheck == 2 ?
                  (e.value == "2" ||
                    e.value == "41" ||
                    e.value == "6" ||
                    e.value == "28"
                  ) : (e.value == "2" ||
                    e.value == "41" ||
                    e.value == "6" ||
                    e.value == "28" ||
                    e.value == "5" ||
                    e.value == "29")
              )
            );
          });
          setrelationshiparray(filterRelationship);
        } else if (props.editProfieType == "Grand Children") {
          let filterRelationship = relationships.filter((e) => {
            return e.value == 3;
          });
          setrelationshiparray(filterRelationship);
        } else if (props.editProfieType == "Children" || props.editProfieType == "Children") {
          let filterRelationship = relationships.filter((e) => {
            return (
              // e.value == 41 ||
              // e.value == 44 ||
              // e.value == 47 ||
              // e.value == 48 ||
              // e.value == 2 ||
              // e.value == 28 ||
              // e.value == 29
              (props?.genderCheck == 1 ? (
                e.value == "2" ||
                e.value == "41" ||
                e.value == "5" ||
                e.value == "29")
                : props?.genderCheck == 2 ?
                  (e.value == "2" ||
                    e.value == "41" ||
                    e.value == "6" ||
                    e.value == "28"
                  ) : (e.value == "2" ||
                    e.value == "41" ||
                    e.value == "6" ||
                    e.value == "28" ||
                    e.value == "5" ||
                    e.value == "29")
              )
            );
          });
          setrelationshiparray(filterRelationship);
        }

        konsole.log(
          props.form.getFieldsValue().relationshipwithprimary,
          relationshiparray,
          props.editProfieType,
          "propsformgetFieldsValuerelationshipwithprimary"
        );
      })
      .catch((error) => {
        konsole.log(error, "error");
      });
  };

  return (
    <>
      {userId !== primaryUserId &&
        userId !== spouseUserId &&
        userId !== 1 &&
        userId !== null &&
        props?.editProfieType !== "Spouse" && (
          <div className="Info ms-2">
            <div className="main">
              <Text strong={true} type="danger" style={{ color: "#76272B" }}>
                Relationship Information
              </Text>
              <Row className="mt-2" gutter={[8, 8]}>
              {(spouseUserId !== null && spouseUserId !== undefined && spouseUserId !== "") &&
                <Col span={8} lg={8} xs={24}>
                  <Form.Item className="w-100" name="relationshipwithspouse">
                    <select
                      className="form-select form-select-sm"
                      placeholder="Relationship with spouse"
                    >
                      <option value="-1" disabled selected>
                        Relationship with spouse
                      </option>
                      {relationshiparray.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </Form.Item>
                </Col>
                }
                <Col span={8} lg={8} xs={24}>
                  <Form.Item className="w-100" name="relationshipwithprimary">
                    <select
                      className="form-select form-select-sm"
                      placeholder="Relationship with primary member"
                    >
                      <option value="-1" disabled selected>
                        Relationship with primary member
                      </option>
                      {relationshiparray
                        ?.sort((a, b) => {
                          const value = ["2", "41", "6", "28", "5", "29"];
                          const aIndex = value.indexOf(a.value);
                          const bIndex = value.indexOf(b.value);
                          return aIndex - bIndex;
                        })
                        .map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        )}
    </>
  );
};
export default RelationshipInfo;
