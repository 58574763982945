import React, { useEffect, useState ,useContext} from "react";
import konsole from "../../../network/konsole";
import {
  Modal,
  Typography,
  Row,
  Col,
  Input,
  Checkbox,
  Select,
  Form,
  DatePicker,
  Button,
} from "antd";
import Services from "../../../network/Services";
import commonLib from "../../../control/commonLib";
import AddressInfo from "../../MemberInfo/common/AddressInfo";
import MemberContact from "../../MemberInfo/common/MemberContact";
import Toaster from "../../Toaster";
import { UserContext } from "../../../App";
const { Text } = Typography;

export default function AddnewProfessmembermodal({
  showaddprofessmodal,
  setshowaddprofessmodal,
  professionaltype,
  memberUserId,
  setAddnewprofessmodaldata
}) {
  konsole.log(showaddprofessmodal, "showaddprofessmodal");
  const [form] = Form.useForm();
  const [professtype, setProfesstype] = useState([]);
  const { setLoader } = useContext(UserContext);
  const [editmember, setEditmember] = useState()
  const loggedUser =commonLib.getObjFromStorage("userLoggedInDetail")?.loggedUserId;
  const primaryUser = commonLib.getObjFromStorage( "userPrimaryInDetail");
  const primaryUserId = memberUserId;
  const [professionalUserId, setprofessionalUserId] = useState()
  const [options,setOptions] = useState([
    {
      label: "Email",
      value: 1,
    },
    {
      label: "Text",
      value: 2,
    },
  ])
  const[sameprofess,setSameprofess]=useState(false)

  useEffect(() => {
    getProfessionalSecDescfunc()
    getProfessionalTypeapifunc();
    addmemberfunc()
  
  },[]);

  const getProfessionalSecDescfunc = () => {
    let getProfessionalSecDescparams = Services.getProfessionalSecDesc();
    getProfessionalSecDescparams.then((response) => {
      konsole.log(response, "getProfessionalSecDescresponse")
      setOptions(response.data.data)
    }).then((error) => {
      konsole.log(error, "getProfessionalSecDescerror")
    })
  }

  const getProfessionalTypeapifunc = () => {
    setLoader(true);
    let getProfessionalTypeapiparams = Services.getProfessionalTypeapi();
    getProfessionalTypeapiparams
      .then((response) => {
        konsole.log(response, "response87");
        setProfesstype(response.data.data);
        if(professionaltype !== ""){
          let professfilter = response.data.data.filter((e) => {
            return e.value == professionaltype

          });
          setProfesstype(professfilter);
          form.setFieldsValue({
            serviceprovider:professionaltype
          })
        } else {
          setProfesstype(response.data.data);
        }
        // if (editmember.proTypeId) {
        //   setProfesstype(response.data.data);
        // }else {
        //   if (protype == "Health") {
        //     let professfilter = response.data.data.filter((e) => {
        //       return e.value == 7 || e.value == 11 || e.value == 10;
        //     });
        //     setProfesstype(professfilter);
        //   } else if (protype == "Finance") {
        //     let professfilter = response.data.data.filter((e) => {
        //       return e.value == 1 || e.value == 2 || e.value == 3 || e.value == 8 || e.value == 12 || e.value == 15

        //     });
        //     setProfesstype(professfilter);
        //   } else if (protype == "Housing") {
        //     let professfilter = response.data.data.filter((e) => {
        //       return e.value == 4  || e.value == 14;
        //     });
        //     setProfesstype(professfilter);
        //   } else if (protype == "Legal") {
        //     let professfilter = response.data.data.filter((e) => {
        //       return e.value == 6 || e.value == 13;
        //     });
        //     setProfesstype(professfilter);
        //   } else if (protype == "Other") {
        //     let professfilter = response.data.data.filter((e) => {
        //       return e.value == 999999 || e.value == 9;
        //     });
        //     setProfesstype(professfilter);
        //   }
        // }
        setLoader(false);
      })
      .catch((error) => {
        konsole.log(error);
        setLoader(false);
      });
  };

  const addmemberfunc = () => {
    setLoader(true);
    let dataobj = {
      fName: "fname",
      mName: "mName",
      lName: "lName",
      isPrimaryMember: false,
      memberRelationship: null,
      createdBy: loggedUser,
    };

    konsole.log(dataobj, "dataobj");
    let addmemberprams = Services.postaddmember(dataobj);
    addmemberprams
      .then((response) => {
        konsole.log(response, "responseadd");
        // setAddnewProfessmemberis(true);
        setEditmember(response.data.data.member);
        setprofessionalUserId(response.data.data.member.userId)
        setLoader(false);
      })
      .then((error) => {
        konsole.log(error, "error");
        setLoader(false);
      });
  };

  const onFinish = (data) => {
    konsole.log(data, "formdata");
    if (editmember.proTypeId) {
    //   if(otherprofessTypeId=='999999'){
    //     otherRef.current.saveHandleOther(editmember.proUserId)
    //   }
      putprofessusername(editmember.professionalUserId);

    } else {
      let dataobj = {
        userId: editmember.userId,
        createdBy: primaryUser.memberUserId,
        professionalUser: {
          proSerDescId: data.serviceprovider,
          isGenAuth: true,
          isStatus: true,
        },
      };
      let dataobj2 = {
        userId: editmember.userId,
        createdBy: primaryUser.spouseUserId,
        professionalUser: {
          proSerDescId: data.serviceprovider,
          isGenAuth: true,
          isStatus: true,
        },
      };
      if(sameprofess==true){
        postaddProfessionalUserMapping(dataobj);
        postaddProfessionalUserMapping(dataobj2);
      }else{
        if(memberUserId == primaryUser.spouseUserId){
          postaddProfessionalUserMapping(dataobj2);
        }else{
          postaddProfessionalUserMapping(dataobj);
        }
      }
    }
  };

  const postaddProfessionalUserMapping = (dataobj) => {
    setLoader(true);
    let postaddProfessionalUserMappingparams =
      Services.postaddProfessionalUserMapping(dataobj);
    postaddProfessionalUserMappingparams
      .then((response) => {
        konsole.log(response, "response");
        let dataobj = {
          createdBy: loggedUser,
          professionalUser: {
            proTypeId: response.data.data.proSerDescId,
            proUserId: response.data.data.proUserId,
          },
          userId: response.data.data.createdBy,
        };
        // if(otherprofessTypeId==='999999'){
        //   otherRef.current.saveHandleOther(response.data.data.proUserId)
        // }
        postprofessionalapifunc(dataobj);
        setLoader(false);
      })
      .then((error) => {
        konsole.log(error, "error");
        setLoader(false);
      });
  };
  const postprofessionalapifunc = (dataobj) => {
    setLoader(true);
    let postprofessionalapiparams = Services.postprofessionalapi(dataobj);
    postprofessionalapiparams
      .then((resposnse) => {
        konsole.log(resposnse, "resposnse1");

        putprofessusername(resposnse.data.data.professionalUser[0].professionalUserId);
        // setprofessionalUserId(resposnse.data.data.professionalUser[0].professionalUserId)
        setAddnewprofessmodaldata(resposnse.data.data.professionalUser[0])
        setLoader(false);
      })
      .then((error) => {
        konsole.log(error);
        setLoader(false);
      });
  };

  const putprofessusername = (professionalUserId) => {
    setLoader(true);
    let dataobj = {
      fName: form.getFieldValue().fName,
      mName: form.getFieldValue().mName,
      lName: form.getFieldValue().lName,
      birthPlace: null,
      citizenshipId: null,
      dob: null,
      genderId: null,
      isPrimaryMember: false,
      isVeteran: false,
      maritalStatusId: null,
      memberRelationshipVM: null,
      nickName: null,
      noOfChildren: null,
      suffixId: null,
      updatedBy: loggedUser,
      userId: professionalUserId,
    };
    let putupdatememberbyidparams = Services.putupdatememberbyid(dataobj);
    putupdatememberbyidparams
      .then((response) => {
        konsole.log(response, "response2");
        // Toaster.success("Data saved successfully");
        // window.location.replace("/Dashboard");
        setLoader(false);
        setshowaddprofessmodal(false)
      })
      .then((error) => {
        konsole.log(error, "error");
        setLoader(false);
        // Toaster.error("error")
      });
  };

  const upsertProfessionalUserMappingfunc = () => {
    let dataObj = [
      {
        "proSerDescId": 0,
        "isGenAuth": true,
        "isStatus": true,
        "profileURLId": 0,
        "websiteLink": "string",
        "certificationTypeId": 0,
        "certificationDate": "2023-05-12T11:20:36.982Z",
        "proUserId": 0,
        "userId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        "isActive": true,
        "upsertedBy": "string"
      }
    ]
    let upsertProfessionalUserMappingparams = Services.upsertProfessionalUserMapping(dataObj)
    upsertProfessionalUserMappingparams.then((resposnse)=>{
      konsole.log(resposnse,"response")
    }).then((error)=>{
      konsole.log(error,"error")
    })
  }

  return (
    <Modal
    width={800}
    title="Add New Professional"
      visible={showaddprofessmodal}
      maskClosable={false}
      footer={false}
      destroyOnClose
      //  onOk={handleOk}
      onCancel={() => {
        setshowaddprofessmodal(false);
      }}
    >
      <div>
      <Form
          form={form}
          onFinish={onFinish}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
        >
          <Row className="mt-2 pe-2">
            <Col lg={24} xs={24} >
              <div className="ms-2 Info">
                <div className="main">
                  <Row gutter={[8, 8]}>
                    <Col span={8} xs={24} lg={8}>
                      <Form.Item
                        className="w-100"
                        name="serviceprovider"
                        rules={[
                          {
                            required: true,
                            message: "Please select your service provider",
                          },
                        ]}
                      >
                        <select
                          className="form-select form-select-sm"
                          style={{ marginTop: 10, marginright: 15 }}
                          // disabled={  editmember != undefined && editmember.proTypeId ? true  : false}
                          // onChange={(e)=>handleChangeServiceProvider(e)}
                          // value={professtype[0]?.value}
                          disabled
                        >

                          {professtype.map((props) => (
                            <option
                              key={props.value}
                              value={props.value}
                              title={props.label}
                            >
                              {props.label}
                            </option>
                          ))}
                        </select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]} className="mt-2">
                    <Col span={8} xs={24} lg={8}>
                      <Form.Item
                        name="fName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter first name",
                          },
                          {
                            pattern: "^[a-zA-Z 0-9]*$",
                            message: "Please enter valid first name",
                          },
                        ]}
                      >
                        <Input
                          size=""
                          name="fName"
                          // onChange={(e) => setFname(e.target.value)}
                          placeholder={commonLib.mandatory("First Name")}
                          // placeholder={fName}
                          className="Input"
                          onInput={(e) =>
                          (e.target.value =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1))
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} xs={24} lg={8}>
                      <Form.Item name="mName">
                        <Input
                          className="Input"
                          size=""
                          name="Middle Name"
                          // onBlur={handleFocusOut}
                          placeholder="Middlename"
                          onInput={(e) =>
                          (e.target.value =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1))
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8} xs={24} lg={8}>
                      <Form.Item
                        name="lName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter last name",
                          },
                          {
                            pattern: "^[a-zA-Z 0-9]*$",
                            message: "Please enter valid Last Name",
                          },
                        ]}
                      >
                        <Input
                          className="Input"
                          size=""
                          name="Lastname"
                          placeholder={commonLib.mandatory("Last Name")}
                          onInput={(e) =>
                          (e.target.value =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1))
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <AddressInfo userId={professionalUserId} form={form} />
          <MemberContact userId={professionalUserId} form={form} />
          
          {primaryUser.spouseUserId != null && <Row className="p-2">
          <Checkbox onClick={(e)=>{setSameprofess(e.target.checked)}}>Same professional for spouse</Checkbox>
        </Row>}
          <Row className="p-2 d-flex justify-content-between align-item-center ">
            <Button
              htmlType="submit"
              style={{
                color: "#ffffff",
                backgroundColor: "#720C20",
                borderRadius: 5,
              }}
            >
              Save
            </Button>
            <Button
              style={{
                color: "#720C20",
                borderColor: "#720C20",
                borderRadius: 5,
              }}
              onClick={() => {
                setshowaddprofessmodal(false)
              }}
            >
              Cancel
            </Button>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}
